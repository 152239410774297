import Form from './Form';
import stripeService from '../../services/stripeService';
import familyLedgerService from '../../services/familyLedgerService';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';


class PaymentHelper extends Form {
  state = {
    data: {},
    errors: {},
    submitting: false,
  };
  constructor(props) {
    super(props);
  }

  getStripeCustomer = async () => {
    try {
      const { id } = this.props.auth.user;
      const response = await stripeService.get(id);
      if (response.status === 200) {
        const { user, stripeUser } = response.data.data;
        this.setState({
          stripeUser,
          hasStripeUser: true,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  
  getBalance = async () => {
    try {
      const { family_id } = this.props.auth.user.personal_info;
      const response = await this.props.getBalance(family_id);
    } catch (e) {
      console.log(e);
    }
  };

  submitPayment = async () => {
    let display_error = false;

    try {
      const { hasStripeUser, changingPaymentInfo } = this.state;
      const { id } = this.props.auth.user;

      let amount = this.state.actualFee;

      if (this.state.data.using_balance) {
        // We only need to set the amount to something else if the user is using their balance
        // AND the balance is less than the fee. otherwise we can just charge the full fee.
        let balance = parseFloat(this.props.balance.balance);
        if (balance < amount) {
          amount = amount - balance;
        } else {
          amount = 0;
        }
      }

      const paymentData = {
        amount: amount,
        type: 'program',
      };
      if (this.state.coupon !== false) {
        paymentData['coupon'] = this.state.coupon;
      }

      if (hasStripeUser && !changingPaymentInfo) {
        const response = await stripeService.charge(id, paymentData);
        if (response.status === 200 && response.data.payment_accepted == true) {
          if (this.state.data.using_balance) {
            this.createBalance();
          } else {
            this.onPaymentComplete();
          }
        } else {
          toast.error(
            'Your card was declined. Please check your information and try again.'
          );
          this.setState({
            submitting: false,
          });
        }
      } else {
        const { firstname, lastname, address1, address2, city, state, zip } =
          this.state.data;
        const cardNumber = this.props.elements.getElement('cardNumber');
        let { paymentMethod, error } =
          await this.props.stripe.createPaymentMethod({
            type: 'card',
            card: cardNumber,
            billing_details: {
              name: firstname + ' ' + lastname,
              address: {
                line1: address1,
                line2: address2,
                city,
                postal_code: zip,
                state,
              },
            },
          });
        if (error) {
          this.handleStripeError(error);
          this.setState({
            submitting: false,
          });
        } else {
          paymentData['paymentMethod'] = paymentMethod;
          const response = await stripeService.charge(id, paymentData);

          if (
            response.status === 200 &&
            response.data.payment_accepted == true
          ) {
            if (this.state.data.using_balance) {
              this.createBalance();
            } else {
              this.onPaymentComplete();
            }
          } else {
            toast.error(
              'Your card was declined. Please check your information and try again.'
            );
            this.setState({
              submitting: false,
            });
          }
        }
      }
    } catch (e) {
      console.log(e);
      this.setState({
        submitting: false,
      });
    }
  };

  handleStripeError = (errorObj) => {
    const { code } = errorObj;
    if (code === 'incomplete_number') {
      //
    }
    if (code === 'incomplete_expiry') {
      //
    }
    if (code === 'incomplete_cvc') {
      //
    }
  };
  createBalance = async () => {
    try {
      const { family_id } = this.props.auth.user.personal_info;

      let balance = parseFloat(this.props.balance.balance);
      let { actualFee } = this.state;
      // Here we use the minimum of the two - if the fee is $10 and the credit is $15, we want to use the $10.
      // If the credit is $12 and the fee is $15, we would want to use the $12.
      let amount = Math.min(balance, actualFee);

      const apiData = {
        amount: -amount,
        notes: `User used $${amount} to submit a program booking on ${moment().format(
          'MM/DD [at] hh:mm a'
        )}`,
        family_id: family_id,
      };
      const response = await familyLedgerService.store(family_id, apiData);
      if (response.status === 201) {
        this.onPaymentComplete();
      } else {
        toast.error('Unusual error response from the server!');
        this.setState({
          submitting: false,
        });
      }
    } catch (e) {
      console.log(e);
      toast.error('Unusual error response from the server!');
      this.setState({
        submitting: false,
      });
    }
  };
  applyCouponCode = (coupon) => {
    const { type, amount } = coupon;
    const { bookingFee } = this.state;
    let actualFee = bookingFee;
    if (type === 'percentage') {
      actualFee = Math.max(0, bookingFee - bookingFee * amount);
    } else if (type === 'amount') {
      actualFee = Math.max(0, bookingFee - amount);
    }
    this.setState({
      coupon,
      actualFee,
    });
  };
  changePaymentInfo = (e) => {
    e.preventDefault();
    this.setState({
      changingPaymentInfo: !this.state.changingPaymentInfo,
    });
  };
}

export default PaymentHelper;
