import React from 'react';
import { connect } from 'react-redux';
import { adminEmail, adminPhone } from '../../../config';
import { mapAuthState } from '../../../helpers/mapAuthState';
import { FormStepTracker } from '../../shared';
import trackerSteps from './trackerSteps';
class ApplicationSubmitted extends React.Component {
    componentDidMount() {
        const { user } = this.props.auth;
        if (!user) {
            this.sendUserBack();
        }
    }
    sendUserBack() {
        alert('You must sign up or log in to view this page.');
        window.open('/', '_self');
    }
    render() {
        return (React.createElement("div", { className: "registration sitter step-3 completion-page" },
            React.createElement("div", { className: "form" },
                React.createElement("div", { className: "inner" },
                    React.createElement(FormStepTracker, { steps: trackerSteps, currentStep: 0 }),
                    React.createElement("div", { className: "form-header" },
                        React.createElement("h2", null, "Application Submitted"),
                        React.createElement("p", null, "Thank you for submitting your application to join our team as an STL Care Provider. Your application will be reviewed by our team. If your application is approved, we\u2019ll contact you via email.")),
                    React.createElement("div", { className: "form-body" },
                        React.createElement("p", { className: "mini-header" }, "Next steps"),
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                React.createElement("p", null,
                                    React.createElement("span", { className: "form-step-circle" }, "1"),
                                    "Our Team will review your application and be in touch in 1-2 business days.")),
                            React.createElement("li", null,
                                React.createElement("p", null,
                                    React.createElement("span", { className: "form-step-circle" }, "2"),
                                    "If approved, a Team Member will reach out to schedule a Meet & Greet Interview.")),
                            React.createElement("li", null,
                                React.createElement("p", null,
                                    React.createElement("span", { className: "form-step-circle" }, "3"),
                                    "Our Hiring Manager will reach out if you are a good fit for the team and get you started with our training!")))),
                    React.createElement("div", { className: "form-footer" },
                        React.createElement("h3", null, "Questions"),
                        React.createElement("p", null,
                            "If you have any questions, please contact us at",
                            ' ',
                            React.createElement("b", null, adminPhone),
                            " or",
                            ' ',
                            React.createElement("b", null,
                                React.createElement("a", { href: `mailto:${adminEmail}` }, adminEmail))))))));
    }
}
export default connect(mapAuthState)(ApplicationSubmitted);
