export default [
    {
        name: 'Application Questions',
        url: '/register/care-provider/application',
    },
    {
        name: 'Private Profile',
        url: '/register/family/private-profile',
    },
    {
        name: 'Public Profile',
        url: '/register/family/public-profile',
    },
    {
        name: 'Availability',
        url: '/register/family/availability',
    },
];
