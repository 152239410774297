import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import Form from '../../shared/Form';
import Joi from 'joi';
import { joiPasswordRulesHelper, updateRegisterStep } from '../../../helpers';
import { mapAuthState } from '../../../helpers/mapAuthState';

import httpService from '../../../services/httpService';

class SignupComplete extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        firstname: null,
        lastname: null,
        email: null,
        password: null,
      },
      errors: {},
      // Overall onboarding step
      stepName: 'Companion Care Signup Complete',
    };
  }
  schema = Joi.object({
    firstname: Joi.string().required().label('First Name'),
    lastname: Joi.string().required().label('Last Name'),
    email: Joi.string().required().label('Email'),
    password: Joi.string()
      .custom(joiPasswordRulesHelper, 'custom validation')
      .required()
      .messages({
        'password.lowercase':
          'Password must contain at least 1 lowercase character.',
        'password.uppercase':
          'Password must contain at least 1 uppercase character.',
        'password.number': 'Password must contain at least 1 number',
        'password.special_character':
          'Password must contain at least 1 special character.',
        'password.length': 'Password must be at least 8 characters long.',
      })
      .label('Password'),
  });
  componentDidMount () {
    this.verifyHash();
  }
  verifyHash = async () => {
    const { user } = this.props.auth;
    const params = new URLSearchParams(window.location.search);
    let loginHash = params.get('login_hash');
    let email = params.get('email');

    if ((!!email && !!loginHash) || !!user) {
      if (!!user) {
        loginHash = user.login_hash;
        email = user.email;
      } else {
        email = email.replace(' ', '+');
      }

      const data = {
        email,
        login_hash: loginHash,
      };
      const response = await httpService.post('/api/verify/email', data);
      console.log(response);
      if (response.status === 200) {
        const { user } = response.data.data;
        const { email } = user;
        const {
          firstname,
          lastname,
        } = user.personal_info;
        const { data } = this.state;
        data['firstname'] = firstname;
        data['lastname'] = lastname;
        data['email'] = email;
        this.setState({ data, user }, () => {
          this.updateStep('companion-care-signup');
        });
      } else {
        this.sendUserBack();
      }
    } else {
      this.sendUserBack();
    }
  };
  updateStep = async (step) => {
    const { id } = this.state.user;
    const response = await updateRegisterStep(id, step);
  };
  sendUserBack = () => {
    alert(
      "You must complete the previous form before filling out your profile. Please go back and fill out the initial registration form, then click on the link in your inbox once you've received the sign-up email."
    );
    window.open('/register/companion-care', '_self');
  };
  doSubmit = async (callback) => {
    const { data } = this.state;
    const response = await this.props.register(data, 'companion-care');
    console.log(response);
    callback();
  };
  render() {
    return (
      <div className="registration family step-1 signup">
        <div className="form">
            <div className="inner">
                <div className="form-group left-align">
                    <h3>Account Information</h3>
                    <form onSubmit={this.handleSubmit}>
                        {this.renderInput('firstname', 'First Name', 'text', true, {
                            disabled: true,
                        })}
                        {this.renderInput('lastname', 'Last Name', 'text', true, {
                            disabled: true,
                        })}
                        {this.renderInput('email', 'Email', 'email', true, {
                            disabled: true,
                        })}
                        {this.renderPasswordWithRules('password', 'Password')}
                        {this.renderButton('Submit', 'Submitting...', 'theme-btn blue')}
                    </form>
                </div>
            </div>
        </div>
        <div className="image"></div>
      </div>
    );
  }
}

const actionCreators = {
  register: authActions.register,
};

export default connect(mapAuthState, actionCreators)(SignupComplete);
