import { find, each } from 'lodash-es';
import { toast } from 'react-toastify';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter, } from 'react-router-dom';
import links from '../../data/links';
import { mapAuthState } from '../../helpers/mapAuthState';
import appointmentService from '../../services/appointmentService';
import coverRequestService from '../../services/coverRequestService';
import systemNoticesService from '../../services/systemNoticesService';
import sitterService from '../../services/sitterService';
import { AppointmentDetail, EmailUpdate, Header, Page, PasswordUpdate, } from '../shared';
import { AccountSettings, Availability, PrivateProfile, PublicProfile, SitterProfile, } from './Account';
import Dashboard from './Dashboard';
import OpenCoverRequests from './OpenCoverRequests';
import { ProgramDetail, ProgramListing } from './Programs';
import SitLog from './SitLog';
import UnclaimedAppointments from './UnclaimedAppointments';
class SitterSection extends React.Component {
    previousLocation;
    state = {
        appointments: [],
        notifications: [],
        systemNotices: [],
        openCoverRequests: [],
        openCoverRequestAppts: [],
        appointmentStatus: 'all',
        menuOpen: false,
        windowWidth: null,
        coverRequestsRemaining: null,
        userCoverRequests: [],
        userCoverRequestCount: 0,
        submittedAppointments: [],
    };
    constructor(props) {
        super(props);
        this.previousLocation = this.props.location;
    }
    componentDidMount() {
        this.getAppointmentsByStatus();
        this.getSystemNotices();
        this.getRemainingCoverRequests();
        this.getSubmittedAppointmentsRequests();
        this.getUserCoverRequests();
        this.getOpenCoverRequests();
        this.onWindowResize();
        window.addEventListener('resize', this.onWindowResize);
    }
    UNSAFE_componentWillUpdate() {
        const { location } = this.props;
        if (!location.state?.appointmentView) {
            this.previousLocation = location;
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }
    componentDidUpdate(prevProps) {
        const currentPath = this.props.location.pathname;
        const prevPath = prevProps.location.pathname;
        if (currentPath !== prevPath) {
            this.setState({
                menuOpen: false,
            });
        }
    }
    onWindowResize = () => {
        const windowWidth = document.documentElement.clientWidth;
        this.setState({ windowWidth });
    };
    getAppointmentsByStatus = async () => {
        const { appointmentStatus } = this.state;
        const status = appointmentStatus === 'all' ? 'accepted' : appointmentStatus;
        const response = await appointmentService.getAppointmentsByStatus({
            status,
        });
        if (response.status === 200) {
            const { appointments } = response.data.data;
            this.setState({ appointments });
        }
    };
    getSystemNotices = async () => {
        const response = await systemNoticesService.getSystemNotices('sitter');
        if (response.status === 200) {
            const { systemNotices } = response.data.data;
            this.setState({ systemNotices });
        }
    };
    changeAppointmentStatus = (status) => {
        this.setState({
            appointmentStatus: status,
        }, () => {
            this.getAppointmentsByStatus();
        });
    };
    toggleMenu = () => {
        let { menuOpen } = this.state;
        menuOpen = !menuOpen;
        this.setState({ menuOpen });
    };
    onRequestAppointmentClose = () => {
        this.getRemainingCoverRequests();
        this.getAppointmentsByStatus();
        const { pathname } = this.previousLocation;
        const redirectTo = /^\/appointment\/\d+$/.test(pathname) ? '/' : pathname;
        this.props.history.push(redirectTo);
    };
    getRemainingCoverRequests = async () => {
        try {
            const sitterPiid = this.props.auth.user.personal_info.id;
            const response = await coverRequestService.getRequestsRemaining(sitterPiid);
            if (response.status === 200) {
                const { count } = response.data.data;
                this.setState({
                    coverRequestsRemaining: count,
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    getSubmittedAppointmentsRequests = async () => {
        try {
            const sitterPiid = this.props.auth.user.personal_info.id;
            const response = await sitterService.getActiveRequests(sitterPiid);
            if (response.status === 200) {
                const { appointments } = response.data.data;
                this.setState({
                    submittedAppointments: appointments,
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    getUserCoverRequests = async () => {
        try {
            const response = await coverRequestService.getCoverRequestsForUser();
            if (response.status === 200) {
                const { coverRequests } = response.data.data;
                const filteredRequests = coverRequests.filter((request) => {
                    return request.appointment?.status !== 'completed';
                });
                this.setState({
                    userCoverRequests: filteredRequests,
                    userCoverRequestCount: filteredRequests.length,
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    getOpenCoverRequests = async () => {
        try {
            const response = await coverRequestService.getOpenCoverRequests();
            if (response.status === 200) {
                const { coverRequests } = response.data.data;
                const coverRequestAppointments = [];
                each(coverRequests, (request) => {
                    if (request.appointment) {
                        coverRequestAppointments.push(request.appointment);
                    }
                });
                this.setState({
                    openCoverRequests: coverRequests,
                    openCoverRequestAppts: coverRequestAppointments,
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    refreshAppointments = () => {
        this.getAppointmentsByStatus();
        this.getUserCoverRequests();
        this.getSubmittedAppointmentsRequests();
    };
    acceptCoverRequest = async (appointmentId) => {
        try {
            const coverRequest = find(this.state.openCoverRequests, (request) => {
                return request.appointment_id === appointmentId;
            });
            const requestId = coverRequest.id;
            const sitterPiid = this.props.auth.user.personal_info.id;
            const response = await coverRequestService.acceptCoverRequest(requestId, `${sitterPiid}`);
            if (response.status === 200) {
                this.onRequestAppointmentClose();
                this.getUserCoverRequests();
                this.getOpenCoverRequests();
                toast.success('Hooray! You have requested to fill in for this appointment!');
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    render() {
        const { appointments, systemNotices, notifications, menuOpen, windowWidth, appointmentStatus, coverRequestsRemaining, userCoverRequests, userCoverRequestCount, submittedAppointments, openCoverRequestAppts, } = this.state;
        const { location } = this.props;
        const appointmentView = location.state?.appointmentView && this.previousLocation !== location;
        const detailOpen = appointmentView || /^\/appointment\/\d+$/.test(location.pathname);
        return (React.createElement(Page, { id: "sitter-section", menuOpen: menuOpen, detailOpen: detailOpen, header: React.createElement(Header, { type: "sitter", className: "sitter", links: links.sitter, notifications: notifications, menuOpen: menuOpen, detailOpen: detailOpen, toggleMenu: this.toggleMenu, coverRequestsRemaining: coverRequestsRemaining }) },
            React.createElement(Switch, { location: appointmentView ? this.previousLocation : location },
                React.createElement(Route, { path: "/dashboard", exact: true, render: (props) => (React.createElement(Dashboard, { ...props, appointments: appointments, systemNotices: systemNotices, changeAppointmentStatus: this.changeAppointmentStatus, appointmentStatus: appointmentStatus, windowWidth: windowWidth, coverRequests: userCoverRequests, coverRequestCount: userCoverRequestCount, submittedAppointments: submittedAppointments })) }),
                React.createElement(Route, { path: "/appointment/:id", exact: true, render: (props) => (React.createElement(React.Fragment, null,
                        React.createElement(Dashboard, { ...props, appointments: appointments, systemNotices: systemNotices, changeAppointmentStatus: this.changeAppointmentStatus, appointmentStatus: appointmentStatus, windowWidth: windowWidth, coverRequests: userCoverRequests, coverRequestCount: userCoverRequestCount, submittedAppointments: submittedAppointments }),
                        React.createElement(AppointmentDetail, { id: Number.parseInt(props.match.params.id, 10), type: "sitter", onRequestClose: this.onRequestAppointmentClose, windowWidth: windowWidth, refreshAppointments: this.refreshAppointments }))) }),
                React.createElement(Route, { path: "/appointment/:id/log", exact: true, render: (props) => (React.createElement(SitLog, { ...props, windowWidth: windowWidth })) }),
                React.createElement(Route, { path: "/cover-requests", render: (props) => (React.createElement(OpenCoverRequests, { ...props, changeAppointmentStatus: this.changeAppointmentStatus, appointmentStatus: appointmentStatus, onRequestAppointmentClose: this.onRequestAppointmentClose, windowWidth: windowWidth, coverRequestAppointments: openCoverRequestAppts })) }),
                React.createElement(Route, { path: "/unclaimed-appointments", exact: true, render: () => React.createElement(UnclaimedAppointments, null) }),
                React.createElement(Route, { path: "/programs", exact: true, component: ProgramListing }),
                React.createElement(Route, { path: "/programs/:id", exact: true, render: (props) => (React.createElement(ProgramDetail, { ...props, windowWidth: windowWidth })) }),
                React.createElement(Route, { path: "/profile", exact: true, component: SitterProfile }),
                React.createElement(Route, { path: "/profile/private", exact: true, component: PrivateProfile }),
                React.createElement(Route, { path: "/profile/public", exact: true, component: PublicProfile }),
                React.createElement(Route, { path: "/profile/availability", exact: true, render: (props) => (React.createElement(Availability, { ...props, windowWidth: windowWidth })) }),
                React.createElement(Route, { path: "/settings/account", exact: true, component: AccountSettings }),
                React.createElement(Route, { path: "/password/update", exact: true, component: PasswordUpdate }),
                React.createElement(Route, { path: "/email/update", exact: true, component: EmailUpdate }),
                React.createElement(Redirect, { to: "/dashboard" })),
            appointmentView && (React.createElement(Route, { exact: true, path: "/appointment/:id", render: (props) => (React.createElement(AppointmentDetail, { id: Number.parseInt(props.match.params.id, 10), type: "sitter", onRequestClose: this.onRequestAppointmentClose, windowWidth: windowWidth, refreshAppointments: this.refreshAppointments, acceptCoverRequest: this.acceptCoverRequest })) }))));
    }
}
export default withRouter(connect(mapAuthState)(SitterSection));
