import { Grid, GridColumn, } from '@progress/kendo-react-grid';
import { findLinkGroupByTitle } from '@ss/helpers';
import appointmentService from '@ss/services/appointmentService';
import React from 'react';
import Sidebar from '../Sidebar';
import { BooleanCell, CancelledCell, ColumnMenu, DateCell, FamilyCell, ManageCell, SittersCell, StatusCell, TimeCell, } from './KendoComponents';
const INITIAL_DATA_STATE = {
    sort: [{ field: 'start_time', dir: 'asc' }],
    filter: {
        logic: 'and',
        filters: [],
    },
    filterOperators: {
        text: [
            { text: 'grid.filterContainsOperator', operator: 'contains' },
            { text: 'grid.filterEqOperator', operator: 'eq' },
        ],
    },
    skip: 0,
    take: 50,
    total: 0,
};
class AppointmentsListing extends React.Component {
    state = {
        appointments: [],
        result: [],
        loading: true,
        linkGroup: findLinkGroupByTitle('Appointments'),
        dataState: { ...INITIAL_DATA_STATE },
        filters: [
            'all',
            '24hours',
            'pending-admin',
            'pending',
            'completed',
            'cancelled',
        ],
        currentFilter: 'pending',
    };
    componentDidMount = () => {
        const { filter } = this.props.match.params;
        const { search } = this.props.history.location;
        if (search) {
            const urlParams = new URLSearchParams(search);
            const filterObj = urlParams.get('filter');
            const skip = urlParams.get('skip');
            const currentFilter = filter ?? 'pending';
            this.setState((prevState) => ({
                ...prevState,
                currentFilter,
                dataState: {
                    ...prevState.dataState,
                    skip: Number.parseInt(skip, 10),
                    filter: JSON.parse(filterObj),
                },
            }), () => {
                this.getAppointments();
            });
        }
        else {
            this.setFilter(filter);
        }
    };
    componentDidUpdate(prevProps) {
        const { filter } = this.props.match.params;
        const prevFilter = prevProps.match.params.filter;
        if (filter !== prevFilter) {
            this.setFilter(filter);
        }
    }
    getAppointments = async () => {
        const { currentFilter, dataState } = this.state;
        // Here we update the URL to reflect their current filters so that we can apply them later if they navigate back
        // from within an Appt detail view.
        const { filter, skip, take, sort } = dataState;
        let filterUrl = JSON.stringify(filter);
        filterUrl = encodeURIComponent(filterUrl);
        const newUrl = `/appointments/${currentFilter}?filter=${filterUrl}&skip=${skip}`;
        this.props.history.replace(newUrl);
        try {
            const response = await appointmentService.getAppointmentsByStatus({
                status: currentFilter,
                skip,
                take,
                sort,
                filter,
            });
            if (response.status === 200) {
                const { appointments, count } = response.data.data;
                this.setState({
                    result: appointments,
                    dataState: {
                        ...dataState,
                        total: count,
                    },
                    appointments,
                    loading: false,
                });
            }
        }
        catch {
            this.setState({
                loading: false,
            });
        }
    };
    setFilter = (currentFilter = 'pending') => {
        this.setState({
            currentFilter,
            loading: true,
            dataState: { ...INITIAL_DATA_STATE },
        }, () => {
            this.getAppointments();
        });
    };
    pageChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                skip: event.page.skip,
                take: event.page.take,
            },
        }, () => {
            this.getAppointments();
        });
    };
    sortChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                sort: event.sort,
            },
        }, () => {
            this.getAppointments();
        });
    };
    filterChange = (event) => {
        this.setState({
            dataState: {
                ...INITIAL_DATA_STATE,
                filter: event.filter,
                skip: 0,
            },
        }, () => {
            this.getAppointments();
        });
    };
    render() {
        const { loading, currentFilter, appointments, linkGroup, dataState, result, } = this.state;
        if (loading && appointments.length === 0) {
            return (React.createElement("main", { className: "admin admin-listing with-sidebar appointments" },
                React.createElement("div", { className: "sidebar-wrapper" },
                    React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-calendar-star", linkGroup: linkGroup })),
                React.createElement("div", { className: "content" },
                    React.createElement("h4", { className: "section-label" },
                        React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                        "\u00A0Loading Appointments..."))));
        }
        return (React.createElement("main", { className: "admin admin-listing with-sidebar appointments" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content" },
                React.createElement("h4", { className: "section-label" },
                    !!loading && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                    !loading && React.createElement("i", { className: "fas fa-calendar-star" }),
                    "\u00A0Viewing ",
                    currentFilter,
                    " Appointments"),
                React.createElement("div", { className: "filter-btn-wrapper" },
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('all');
                        } }, "All"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('pending');
                        } }, "Pending"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('pending-admin');
                        } }, "Admin Priority"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('24hours');
                        } }, "Next 24 Hours"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('accepted');
                        } }, "Accepted"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('completed');
                        } }, "Completed"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('cancelled');
                        } }, "Cancelled"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('time change requested');
                        } }, "Time Change Requested"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('pending-cover');
                        } }, "Sub Provider Requested")),
                React.createElement("div", { className: "kendo-table" },
                    React.createElement(Grid, { data: result, pageable: true, sortable: true, ...dataState, onPageChange: this.pageChange, onSortChange: this.sortChange, onFilterChange: this.filterChange },
                        React.createElement(GridColumn, { title: "Status", field: "status", sortable: false, cell: StatusCell, width: 80 }),
                        React.createElement(GridColumn, { title: "Date", field: "start_time", cell: DateCell, width: 125 }),
                        React.createElement(GridColumn, { title: "Start Time", field: "start_time", width: 250, cell: TimeCell }),
                        currentFilter === 'cancelled' && (React.createElement(GridColumn, { title: "Cancelled At", field: "cancelled_at", cell: CancelledCell, width: 200 })),
                        React.createElement(GridColumn, { title: "Flex", field: "flex", cell: BooleanCell, width: 70 }),
                        React.createElement(GridColumn, { title: "Family", field: "profile_family.name", cell: (props) => (React.createElement(FamilyCell, { ...props, ...this.props, var: "family" })), columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Provider", field: "sitters", sortable: false, cell: SittersCell, columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "ID", field: "id", width: 60 }),
                        React.createElement(GridColumn, { title: " ", field: "id", width: 100, cell: (props) => React.createElement(ManageCell, { ...props, ...this.props }) }))))));
    }
}
export default AppointmentsListing;
