import { buildApiUrl } from '../helpers/buildApiUrl';
import httpService from './httpService';
const get = (id) => {
    return httpService.get(buildApiUrl(`/profilePet/${id}`));
};
const store = (data, headers) => {
    const apiUrl = buildApiUrl('/profilePet/store');
    return httpService.post(apiUrl, data, headers);
};
const update = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/profilePet/update/${id}`);
    return httpService.post(apiUrl, data, headers);
};
const deletePet = (id) => {
    const apiUrl = buildApiUrl(`/profilePet/delete/${id}`);
    return httpService.delete(apiUrl);
};
export default {
    get,
    store,
    update,
    delete: deletePet,
};
