import { displayProfileColor } from '@ss/helpers';
import { mapAuthState } from '@ss/helpers/mapAuthState';
import { profileCareContactService } from '@ss/services';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CareContactForm from '../../Forms/CareContactForm';
import { Modal, ProfileColorPicker } from '../../shared';
const AvatarDisplayComponent = (props) => {
    return (React.createElement("div", { className: "avatar" },
        React.createElement("div", { className: "avatar-wrapper" },
            props.value && React.createElement("img", { src: props.value }),
            !props.value && React.createElement("i", { className: "far fa-camera" }))));
};
class CareContactProfile extends CareContactForm {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            loading: true,
            id: null,
            creating: true,
            family_id: null,
            showRemove: false,
            removing: false,
        };
    }
    componentDidMount = () => {
        const id = Number.parseInt(this.props.match.params.id, 10);
        if (Number.isNaN(id) === false) {
            this.getCareContact(id);
        }
        else {
            this.setState({
                data: { ...this.state.freshSchema },
                creating: true,
                family_id: this.props.auth.user.personal_info.family_id,
            });
        }
    };
    getCareContact = async (id) => {
        try {
            const response = await profileCareContactService.get(id);
            if (response.status === 200) {
                const { data } = response.data;
                this.formatData(data);
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    formatData = ({ personalInfo, profileCareContact, }) => {
        const { avatar_filename, firstname, lastname, family_id, profile_color, phone1, phone2, type, } = personalInfo;
        const { id, relationship, authorized_contact } = profileCareContact;
        const data = {
            firstname,
            lastname,
            avatar_filename,
            relationship,
            profile_color: profile_color !== null ? profile_color : 'primary-coral',
            phone1,
            phone2,
            authorized_contact,
        };
        this.setState({
            data,
            creating: false,
            id,
            family_id,
            allowDelete: type !== 'companion_care_other',
        });
    };
    toggleRemove = () => {
        this.setState((prevState) => ({
            ...prevState,
            showRemove: !prevState.showRemove,
        }));
    };
    doRemove = async () => {
        try {
            const id = Number.parseInt(this.props.match.params.id, 10);
            if (Number.isNaN(id) === false) {
                const response = await profileCareContactService.delete(id);
                if (response.status === 204) {
                    toast.success('This contact has been removed from your account.');
                    this.props.history.push('/profile');
                }
                else if (response.status === 200) {
                    const { message } = response.data;
                    toast.error(message);
                    this.setState({
                        showRemove: false,
                    });
                }
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    handleProfileColorChange = (colorClass) => {
        this.setState({ data: { ...this.state.data, profile_color: colorClass } });
    };
    doSubmit = async (callback) => {
        try {
            const response = await this.submitFormData();
            callback();
            toast.success('Hooray! Your changes are saved.');
            if (this.state.creating) {
                const { data } = response.data;
                const { id } = data.profileCareContact;
                this.formatData(data);
                this.setState({
                    creating: false,
                }, () => {
                    this.props.history.replace(`/profile/contacts/${id}`);
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    render() {
        const { data, creating, removing, showRemove, allowDelete } = this.state;
        return (React.createElement("main", { className: "account profile care-contact" },
            React.createElement("form", { onSubmit: this.handleSubmit },
                React.createElement("div", { className: `profile-header ${displayProfileColor(data.profile_color, 'gradient')}` },
                    React.createElement("div", { className: "inner" },
                        this.renderFile('avatar_filename', 'Upload Your Public Profile Image', 'image/jpg, image/png', false, AvatarDisplayComponent),
                        React.createElement("h2", { className: "theme-h2" },
                            data.firstname,
                            "\u00A0",
                            data.lastname))),
                React.createElement("div", { className: "profile-body" },
                    React.createElement("h2", null, "Contact Profile"),
                    React.createElement(ProfileColorPicker, { value: data.profile_color, onProfileColorChange: this.handleProfileColorChange }),
                    this.renderForm(),
                    this.renderButton('Save Contact Details', 'Saving...', 'theme-btn blue'),
                    React.createElement("div", { className: "cancel-btn" },
                        !creating && allowDelete && (React.createElement("button", { className: "theme-btn small error-red", type: "button", onClick: this.toggleRemove }, "Delete")),
                        React.createElement(Link, { className: "theme-btn small slate-blue", to: "/profile" }, "Cancel")),
                    !allowDelete && !creating && (React.createElement("div", { className: "self-recipient-delete-account-info" },
                        React.createElement("small", null, "You cannot remove yourself from the contact list."))))),
            React.createElement(Modal, { title: "Confirm Deletion", isOpen: showRemove, closeModal: this.toggleRemove, content: 'Are you sure you want to remove this contact from your account?', primaryButton: removing ? ' Removing' : 'Remove', secondaryButton: 'Close', handleClick: this.doRemove })));
    }
}
export default connect(mapAuthState)(CareContactProfile);
