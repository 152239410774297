import Joi from 'joi';
import moment from 'moment-timezone';
import React from 'react';
import childService from '../../services/childService';
import Form from '../shared/Form';
const radioOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
];
const freshSchema = {
    avatar_filename: '',
    firstname: '',
    lastname: '',
    dob_year: '',
    dob_month: '',
    dob_day: '',
    selected_recipient_needs: [],
    fall_risk: 0,
    help_standing_up: 0,
    wheelchair: 0,
    profile_color: 'primary-coral',
    notes: '',
};
class ChildForm extends Form {
    schema = Joi.object({
        avatar_filename: Joi.alternatives(Joi.string(), Joi.object())
            .optional()
            .allow(null)
            .allow('')
            .label('Avatar'),
        profile_color: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Profile Color'),
        firstname: Joi.string().required().label('First Name'),
        lastname: Joi.string().required().label('Last Name'),
        dob_year: Joi.number().integer().min(1900).required().label('DOB - Year'),
        dob_month: Joi.number()
            .integer()
            .min(1)
            .max(12)
            .required()
            .label('DOB - Month'),
        dob_day: Joi.number()
            .integer()
            .min(1)
            .max(31)
            .required()
            .label('DOB - Day'),
        help_standing_up: Joi.boolean()
            .truthy(1)
            .falsy(0)
            .label('Does the companion need help transfering from a seated postion to a standing position?'),
        fall_risk: Joi.boolean()
            .truthy(1)
            .falsy(0)
            .label('Is the companion at risk of falling or have fallen recently? '),
        wheelchair: Joi.boolean()
            .truthy(1)
            .falsy(0)
            .label('Is the companion in a wheelchair?'),
        selected_recipient_needs: Joi.array()
            .optional()
            .label('Selected Recipient Needs'),
        notes: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Notes and Rules'),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            creating: false,
            data: { ...freshSchema },
            freshSchema,
            allowDelete: false,
            careRecipientNeeds: [],
            medicalConditions: [],
        };
    }
    submitFormData = async () => {
        const apiData = { ...this.state.data };
        const { family_id, creating, id } = this.state;
        const { dob_day, dob_year, dob_month } = apiData;
        const DOB = moment(`${dob_year}-${dob_month}-${dob_day}`, 'YYYY-M-D').format('YYYY-MM-DD');
        apiData.DOB = DOB;
        apiData.family_id = family_id;
        delete apiData.dob_day;
        delete apiData.dob_year;
        delete apiData.dob_month;
        const data = new FormData();
        const headers = { headers: { 'content-type': 'multipart/form-data' } };
        data.append('_method', 'post');
        for (const key of Object.keys(apiData)) {
            if (typeof apiData[key] === 'object') {
                if (key === 'avatar_filename') {
                    if (apiData[key] !== null) {
                        data.append(key, apiData[key]);
                    }
                }
                else if (key === 'selected_recipient_needs') {
                    if (apiData[key] !== null) {
                        data.append(key, JSON.stringify(apiData[key].map(({ value }) => value)));
                    }
                }
                else {
                    data.append(key, JSON.stringify(apiData[key]));
                }
            }
            else {
                data.append(key, apiData[key]);
            }
        }
        return creating
            ? childService.store(data, headers)
            : childService.update(id, data, headers);
    };
    renderForm() {
        const { careRecipientNeeds } = this.state;
        return (React.createElement("div", { className: "child-profile-form" },
            this.renderInput('firstname', 'First Name', 'text', true),
            this.renderInput('lastname', 'Last Name', 'text', true),
            React.createElement("div", { className: "form-field-group field-with-checkbox dob" },
                React.createElement(React.Fragment, null, this.renderTextDatePicker('dob', 'Date of Birth (MM/DD/YYYY)', true))),
            this.renderSelectSearch('selected_recipient_needs', 'Please select all care that applies to the companion’s needs', careRecipientNeeds, false),
            React.createElement("div", { className: "form-grid" },
                this.renderRadio('help_standing_up', 'Does the companion need help transfering from a seated postion to a standing position?', radioOptions, true, false, 'form-inline-radio'),
                this.renderRadio('fall_risk', 'Is the companion at risk of falling or have fallen recently?', radioOptions, true, false, 'form-inline-radio'),
                this.renderRadio('wheelchair', 'Is the companion in a wheelchair?', radioOptions, true, false, 'form-inline-radio')),
            this.renderTextarea('notes', 'Notes and Rules (optional)', false)));
    }
}
export default ChildForm;
