import { Grid, GridColumn, } from '@progress/kendo-react-grid';
import { findLinkGroupByTitle } from '@ss/helpers';
import appointmentService from '@ss/services/appointmentService';
import sitterService from '@ss/services/sitterService';
import React from 'react';
import { toast } from 'react-toastify';
import { AssignCell, AvailabilityCell, ColumnMenu, SitterNameCell, } from './KendoComponents';
class AssignSitter extends React.Component {
    state = {
        data: {
            sitters: [],
        },
        currentTab: 'available',
        sitters: [],
        result: [],
        loading: true,
        linkGroup: findLinkGroupByTitle('Appointments'),
        dataState: {
            sort: [{ field: 'firstname', dir: 'asc' }],
            filter: {
                logic: 'and',
                filters: [],
            },
            filterOperators: {
                text: [
                    {
                        text: 'grid.filterContainsOperator',
                        operator: 'contains',
                    },
                    { text: 'grid.filterEqOperator', operator: 'eq' },
                ],
            },
            skip: 0,
            take: 50,
            total: 0,
        },
    };
    componentDidMount = () => {
        this.getSitters();
    };
    getSitters = async () => {
        const { currentTab } = this.state;
        const { appointment } = this.props;
        try {
            const { dataState } = this.state;
            const response = await sitterService.available(appointment.id, currentTab, {
                skip: dataState.skip,
                take: dataState.take,
                sort: dataState.sort,
                filter: dataState.filter,
            });
            if (response.status === 200) {
                const { sitters, count } = response.data.data;
                this.setState({
                    result: sitters,
                    dataState: {
                        ...dataState,
                        total: count,
                    },
                    sitters,
                    loading: false,
                });
            }
        }
        catch {
            this.setState({
                loading: false,
            });
        }
    };
    onAssign = (sitter_piid) => {
        const { data } = this.state;
        data.sitters = [sitter_piid];
        this.setState({ data }, () => {
            this.assignSitters();
        });
    };
    assignSitters = async () => {
        try {
            const { id } = this.props.appointment;
            const { sitters } = this.state.data;
            const response = await appointmentService.assignSitters(id, {
                sitters,
            });
            if (response.status === 200) {
                toast.success('Hooray! The Provider was assigned! They will receive an email shortly.');
                this.props.onSitterAssigned(response.data.data);
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    pageChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                skip: event.page.skip,
                take: event.page.take,
            },
        }, () => {
            this.getSitters();
        });
    };
    sortChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                sort: event.sort,
            },
        }, () => {
            this.getSitters();
        });
    };
    filterChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                filter: event.filter,
            },
        }, () => {
            this.getSitters();
        });
    };
    setCurrentTab = (tab) => {
        this.setState({
            currentTab: tab,
            dataState: {
                sort: [{ field: 'firstname', dir: 'asc' }],
                filter: {
                    logic: 'and',
                    filters: [],
                },
                filterOperators: {
                    text: [
                        {
                            text: 'grid.filterContainsOperator',
                            operator: 'contains',
                        },
                        { text: 'grid.filterEqOperator', operator: 'eq' },
                    ],
                },
                skip: 0,
                take: 50,
                total: 0,
            },
        }, () => {
            this.getSitters();
        });
    };
    render() {
        const { appointment } = this.props;
        const { loading, dataState, result, currentTab } = this.state;
        if (loading) {
            return (React.createElement("div", { className: "appointment-detail assign-sitters" },
                React.createElement("div", { className: "content" },
                    React.createElement("h4", { className: "section-label" },
                        React.createElement("i", { className: "fas fa-calendar-star" }),
                        "\u00A0Loading Providers..."))));
        }
        return (React.createElement("div", { className: "appointment-detail assign-sitters" },
            React.createElement("div", { className: "content" },
                React.createElement("h3", null,
                    "Find Provider for Appointment #",
                    appointment.id),
                React.createElement("div", { className: "admin-program-booking-tabs" },
                    React.createElement("div", { className: "tabs-header" },
                        React.createElement("div", { className: `tab-item ${currentTab === 'available' ? 'active' : ''}`, onClick: () => {
                                this.setCurrentTab('available');
                            } },
                            React.createElement("p", null, "Available Providers")),
                        React.createElement("div", { className: `tab-item ${currentTab === 'day_available' ? 'active' : ''}`, onClick: () => {
                                this.setCurrentTab('day_available');
                            } },
                            React.createElement("p", null, "Available Providers on Day")),
                        React.createElement("div", { className: `tab-item ${currentTab === 'all' ? 'active' : ''}`, onClick: () => {
                                this.setCurrentTab('all');
                            } },
                            React.createElement("p", null, "All Providers")))),
                React.createElement("div", { className: "kendo-table" },
                    React.createElement(Grid, { data: result, pageable: true, sortable: true, ...dataState, onPageChange: this.pageChange, onSortChange: this.sortChange, onFilterChange: this.filterChange },
                        React.createElement(GridColumn, { title: "Name", field: "name", width: 200, cell: SitterNameCell, filter: 'text', columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Phone", field: "phone1", width: 125 }),
                        React.createElement(GridColumn, { title: "Availability", field: "availability_list", sortable: false, cell: (props) => (React.createElement(AvailabilityCell, { ...props, appointment: appointment })) }),
                        React.createElement(GridColumn, { title: " ", field: "piid", width: 125, sortable: false, cell: (props) => (React.createElement(AssignCell, { onAssign: this.onAssign, ...props, ...this.props })) }))))));
    }
}
export default AssignSitter;
