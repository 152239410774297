import { displayProfileColor, mapAuthState } from '@ss/helpers';
import familyService from '@ss/services/familyService';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
class FamilyProfile extends React.Component {
    state = {
        careRecipients: [],
        pets: [],
        details: null,
        loading: true,
        contacts: [],
    };
    componentDidMount = () => {
        this.getFamilyDetails();
    };
    getFamilyDetails = async () => {
        try {
            const { family_id } = this.props.auth.user.personal_info;
            const response = await familyService.get(family_id);
            if (response.status === 200) {
                const { pets, children: careRecipients, profileFamily: details, contacts, } = response.data.data;
                this.setState({
                    pets,
                    careRecipients,
                    contacts,
                    details,
                    loading: false,
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    render = () => {
        const { user } = this.props.auth;
        const { loading, pets, careRecipients: careRecipients, contacts, } = this.state;
        if (loading) {
            return null;
        }
        return (React.createElement("main", { className: "account profile family" },
            React.createElement("div", { className: `profile-header ${displayProfileColor(user.personal_info.profile_color, 'gradient')}` },
                React.createElement("div", { className: "inner" },
                    React.createElement("div", { className: "avatar" },
                        React.createElement("div", { className: "avatar-wrapper" },
                            user.personal_info.avatar_filename && (React.createElement("img", { src: user.personal_info.avatar_filename })),
                            !user.personal_info.avatar_filename && (React.createElement("i", { className: "far fa-camera" })))),
                    React.createElement("h2", { className: "theme-h2" }, "My Household"),
                    React.createElement(Link, { to: "/settings/account", className: "theme-btn white" },
                        React.createElement("i", { className: "far fa-pen-square" }),
                        "\u00A0Edit"))),
            React.createElement("div", { className: "profile-body" },
                React.createElement("div", { className: "children profile-list" },
                    React.createElement("h4", { className: "section-label" },
                        React.createElement("i", { className: "fas fa-child" }),
                        "\u00A0Care Recipients"),
                    React.createElement("div", { className: "profile-list-wrapper" }, careRecipients.map((careRecipient) => (React.createElement("div", { className: "list-item child", key: `child-${careRecipient.id}` },
                        React.createElement(Link, { className: "inner", to: `/profile/care-recipient/${careRecipient.profile_child.id}` },
                            React.createElement("div", { className: "avatar" },
                                React.createElement("div", { className: "avatar-wrapper" },
                                    careRecipient.avatar_filename && (React.createElement("img", { src: careRecipient.avatar_filename })),
                                    !careRecipient.avatar_filename && (React.createElement("i", { className: "far fa-camera" })))),
                            React.createElement("div", { className: "bio" },
                                React.createElement("h3", { className: "theme-h3" }, careRecipient.firstname),
                                React.createElement("i", { className: "fas fa-arrow-right" }))))))),
                    React.createElement(Link, { className: "theme-btn primary-green", to: "/profile/care-recipient/new" },
                        React.createElement("i", { className: "far fa-plus-circle" }),
                        "\u00A0Add Another Care Recipient")),
                React.createElement("div", { className: "care-contacts profile-list" },
                    React.createElement("h4", { className: "section-label" },
                        React.createElement("i", { className: "far fa-address-book" }),
                        "\u00A0Contacts"),
                    React.createElement("div", { className: "profile-list-wrapper" }, contacts.map((contact) => (React.createElement("div", { className: "list-item child", key: contact.id },
                        React.createElement(Link, { className: "inner", to: `/profile/contacts/${contact.profile_care_contact.id}` },
                            React.createElement("div", { className: "avatar" },
                                React.createElement("div", { className: "avatar-wrapper" },
                                    contact.avatar_filename && (React.createElement("img", { src: contact.avatar_filename })),
                                    !contact.avatar_filename && (React.createElement("i", { className: "far fa-camera" })))),
                            React.createElement("div", { className: "bio" },
                                React.createElement("h3", { className: "theme-h3" }, contact.firstname),
                                React.createElement("i", { className: "fas fa-arrow-right" }))))))),
                    React.createElement(Link, { className: "theme-btn primary-green", to: "/profile/contacts/new" },
                        React.createElement("i", { className: "far fa-plus-circle" }),
                        "\u00A0Add Another Contact")),
                React.createElement("div", { className: "pets profile-list" },
                    React.createElement("h4", { className: "section-label" },
                        React.createElement("i", { className: "fas fa-paw" }),
                        "\u00A0Pets"),
                    React.createElement("div", { className: "profile-list-wrapper" }, pets.map((pet) => (React.createElement("div", { className: "list-item pet", key: `pet-${pet.id}` },
                        React.createElement(Link, { className: "inner", to: `/profile/pet/${pet.profile_pet.id}` },
                            React.createElement("div", { className: "avatar" },
                                React.createElement("div", { className: "avatar-wrapper" },
                                    !!pet.avatar_filename && (React.createElement("img", { src: pet.avatar_filename })),
                                    !pet.avatar_filename && (React.createElement("i", { className: "far fa-camera" })))),
                            React.createElement("div", { className: "bio" },
                                React.createElement("h3", { className: "theme-h3" }, pet.firstname),
                                React.createElement("p", null,
                                    pet.profile_pet.age_years,
                                    "\u00A0Years Old"))))))),
                    React.createElement(Link, { className: "theme-btn primary-green", to: "/profile/pet/new" },
                        React.createElement("i", { className: "far fa-plus-circle" }),
                        "\u00A0Add Another Pet")))));
    };
}
export default connect(mapAuthState)(FamilyProfile);
