import React from 'react';
import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { stripeKey } from '../../../../config';
import { mapAuthState } from '../../../../helpers/mapAuthState';
import RegularBooking from './RegularBooking';

const fontsObj = [
  {
    cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans&display=swap',
  },
];

class RegularBookingWrapper extends React.Component {
  onSubmit = () => {
    this.props.onSubmit(this.state.data);
  };
  render() {
    return (
      <main className="booking regular-booking">
        <StripeProvider apiKey={stripeKey}>
          <Elements fonts={fontsObj}>
            <RegularBooking {...this.props} />
          </Elements>
        </StripeProvider>
      </main>
    );
  }
}

export default connect(mapAuthState)(RegularBookingWrapper);
