import React from 'react';
import Form from '../../shared/Form';
import Joi from 'joi';
import httpService from '../../../services/httpService';
class Signup extends Form {
    schema = Joi.object({
        firstname: Joi.string().required().label('First Name'),
        lastname: Joi.string().required().label('Last Name'),
        email: Joi.string().required().label('Email'),
        over_18: Joi.number()
            .integer()
            .min(1)
            .max(1)
            .required()
            .messages({
            'number.greater': 'You must be over 18 to apply.',
            'number.lesser': 'You must be over 18 to apply.',
            'number.min': 'You must be over 18 to apply.',
            'number.max': 'You must be over 18 to apply.',
        })
            .label('Over 18'),
        has_transportation: Joi.number()
            .integer()
            .min(1)
            .max(1)
            .required()
            .messages({
            'number.greater': 'You must have a means of transportation to apply.',
            'number.lesser': 'You must have a means of transportation to apply.',
            'number.min': 'You must have a means of transportation to apply.',
            'number.max': 'You must have a means of transportation to apply.',
        })
            .label('Has Transportation'),
        lives_within_service_area: Joi.number()
            .integer()
            .min(1)
            .max(1)
            .required()
            .messages({
            'number.greater': 'You must live within a serviceable area to apply.',
            'number.lesser': 'You must live within a serviceable area to apply.',
            'number.min': 'You must live within a serviceable area to apply.',
            'number.max': 'You must live within a serviceable area to apply.',
        })
            .label('Lives Within Service Area'),
    });
    constructor(props) {
        super(props);
        this.state = {
            data: {
                firstname: null,
                lastname: null,
                email: null,
                over_18: 0,
                has_transportation: 0,
                lives_within_service_area: 0,
            },
            errors: {},
            submitting: false,
            recaptchaAccepted: false,
            formSubmitted: false,
        };
    }
    doSubmit = async (callback) => {
        if (!this.state.recaptchaAccepted) {
            alert('You must accept the ReCAPTCHA before proceeding.');
            callback();
            return;
        }
        const { data } = this.state;
        try {
            const response = await httpService.post('/api/signup/care-provider', data);
            if (response.status === 200 && response.data.message === 'OK') {
                this.setState({
                    formSubmitted: true,
                });
            }
            else {
                alert('Whoops! An error! Oh no!');
            }
            callback();
        }
        catch (error) {
            console.error(error);
            callback();
        }
    };
    onChange = () => {
        this.setState({
            recaptchaAccepted: true,
        });
    };
    render() {
        return (React.createElement("div", { className: "registration sitter step-1 signup" },
            React.createElement("div", { className: "form" },
                !this.state.formSubmitted && (React.createElement("div", { className: "inner" },
                    React.createElement("div", { className: "form-header" },
                        React.createElement("h2", null, "Sign up for STLCare"),
                        React.createElement("p", null, "A local St. Louis online marketplace where families find trusted and responsible care companions.")),
                    React.createElement("form", { onSubmit: this.handleSubmit },
                        this.renderInput('firstname', 'First Name', 'text', true),
                        this.renderInput('lastname', 'Last Name', 'text', true),
                        this.renderInput('email', 'Email', 'email', true),
                        this.renderBoolean('over_18', 'I am 18 years or old'),
                        this.renderBoolean('has_transportation', 'I have transportation to get to appointments'),
                        this.renderBoolean('lives_within_service_area', 'I live within designated service area'),
                        this.renderRecaptcha(this.onChange),
                        this.renderButton('Submit', 'Submitting...', 'theme-btn blue')))),
                this.state.formSubmitted && (React.createElement("div", { className: "inner" },
                    React.createElement("div", { className: "signup-completed" },
                        React.createElement("h2", null,
                            "Almost ready to",
                            React.createElement("br", null),
                            "get started"),
                        React.createElement("p", null, "We just need to verify your email. Please check your inbox and click the link within."))))),
            React.createElement("div", { className: "image" })));
    }
}
export default Signup;
