import React from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { updateRegisterStep } from '../../../helpers';
import httpService from '../../../services/httpService';
import familyService from '../../../services/familyService';
import childService from '../../../services/childService';
import { FormStepTracker } from '../../shared';
import trackerSteps from './trackerSteps';
import { mapAuthState } from '../../../helpers/mapAuthState';
import CareProfileForm from './CareProfileForm';

class CareProfiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: false,
      children: [],
      careRecipientNeeds: [],
      activeChild: null,
      creating: false,
      family_id: 0,
    };
  }
  componentDidMount() {
    const { user } = this.props.auth;
    if (!user) {
      this.sendUserBack();
    } else {
      this.getCareProfiles();
      this.getCareRecipientNeeds();
      const { family_id } = user.personal_info;
      this.setState({
        family_id,
      });
    }
  }
  getCareRecipientNeeds = async () => {
    try {
      const response = await httpService.get('/api/careRecipientNeeds');
      if (response.status === 200) {
        const { careRecipientNeeds } = response.data.data;
        this.setState({
          careRecipientNeeds: careRecipientNeeds.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  sendUserBack = () => {
    alert(
      "You must complete the previous form before filling out your profile. Please go back and fill out the initial registration form, then click on the link in your inbox once you've received the sign-up email."
    );
    window.open('/register/companion-care', '_self');
  };
  getCareProfiles = async () => {
    this.setState({
      loading: true,
    });
    try {
      const response = await familyService.getCareRecipients();

      this.setState({
        loading: true,
      });
      if (response.status === 200) {
        const { children } = response.data.data;
        this.setState({
          loading: false,
          children,
        });
      }
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };
  updateStep = async (step) => {
    const { id } = this.state.user;
    const response = await updateRegisterStep(id, step);
  };
  doSubmit = async (callback) => {
    try {
      const { data } = this.state;
      console.log(data);
      //   const response = await httpService.post('/api/profileFamily/update', data);
      callback();
      if (response.status === 200) {
        this.advanceStep();
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  advanceStep = async () => {
    try {
      const { id } = this.props.auth.user;
      const response = await this.props.updateUserStep(
        'companion-care-care-profiles',
        id
      );
    } catch (e) {
      console.log(e);
    }
  };
  setActiveChild = (child) => {
    this.setState({
      activeChild: child,
    });
  };
  onProfileComplete = () => {
    this.setState(
      {
        activeChild: null,
      },
      this.getCareProfiles
    );
  };
  toggleCreatingChild = () => {
    this.setState(
      {
        creating: !this.state.creating,
      },
      () => {
        if (!this.state.creating) {
          this.getCareProfiles();
        }
      }
    );
  };
  handleCancel = () => {
    this.setState({
      activeChild: null,
      creating: false,
    });
  };
  removeChild = async (child) => {
    console.log(child);
    try {
      const response = await childService.delete(child.profile_child.id);
      if(response.status === 204) {
        this.getCareProfiles();
        toast.success('Care recipient has been removed');
      };
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    const {
      children,
      loading,
      careRecipientNeeds,
      activeChild,
      creating,
      family_id,
    } = this.state;

    const { user } = this.props.auth;

    // You can only proceed if the care recipients have been edited
    // DOB can only be added on the CareProfileForm, and you cannot save without editing all fields
    // Therefore, we assume that if DOB is present ( a field only on the form ), that the child
    // is fully ready to go. If the length of the filtered array is the same as the length of the children,
    // then we know that all children are good to go and we can proceed.
    const canProceed =
      children.length > 0 &&
      children.filter((c) => !!c.DOB).length === children.length;

    return (
      <div className="registration family step-2 profile">
        <div className="form">
          <div className="inner">
            <FormStepTracker steps={trackerSteps} currentStep={1} />
            {!!activeChild && !creating && (
              <CareProfileForm
                child={activeChild}
                careRecipientNeeds={careRecipientNeeds}
                familyId={family_id}
                onProfileComplete={this.onProfileComplete}
                handleCancel={this.toggleCreatingChild}
              />
            )}
            {creating && (
              <CareProfileForm
                careRecipientNeeds={careRecipientNeeds}
                familyId={family_id}
                onProfileComplete={this.toggleCreatingChild}
                handleCancel={this.handleCancel}
              />
            )}
            {!activeChild && !creating && (
              <>
                <div className="form-header">
                  <h2>Add Care Profile(s)</h2>
                  <p>
                    Please provide a care profile for every recipient of care in
                    the household.
                  </p>
                </div>
                <div className="care-recipient-wrapper">
                  {children.length === 0 && !!loading && (
                    <p>Loading care recipient profiles...</p>
                  )}
                  {children.length === 0 && !loading && (
                    <p>No additional care recipients added yet.</p>
                  )}
                  {children.map((child) => (
                    <div
                      className={`care-profile-onboarding ${
                        !!child.DOB ? 'complete' : ''
                      }`}
                      key={`child-${child.id}`}
                    >
                      <div className="care-profile-status">
                        <div className="inner">
                          <i className="far fa-check-circle"></i>
                          <p>Profile{!!child.DOB ? '' : ' Not'} Complete</p>
                        </div>
                      </div>
                      <div className="care-profile-content">
                        <div className="inner">
                          <p>
                            {child.firstname}&nbsp;{child.lastname}
                          </p>
                          <div className="care-profile-btns">
                            <button className="theme-btn blue small" onClick={() => { this.setActiveChild(child); }}>
                              Edit
                            </button>
                            { user.personal_info.id !== child.id && (
                              <button className="theme-btn red small" onClick={() => { this.removeChild(child); }}>
                                Remove
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {children.length < 3 && (
                    <button
                      className="theme-btn primary-green add-new-btn"
                      onClick={this.toggleCreatingChild}
                    >
                      <i className="far fa-plus-circle"></i>&nbsp;Add Another
                      Recipient of Care
                    </button>
                  )}
                </div>
                <div className="care-recipient-btn">
                  <button
                    className="theme-btn blue tall submit-btn"
                    disabled={!canProceed}
                    onClick={this.advanceStep}
                  >
                    Next
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const actionCreators = {
  updateUserStep: authActions.updateUserStep,
};

export default connect(mapAuthState, actionCreators)(CareProfiles);
