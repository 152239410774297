import React from 'react';
import { toast } from 'react-toastify';
import { findLinkGroupByTitle } from '../../../helpers';
import { getCareRecipientNeedsOptionsRequest } from '../../../services/careRecipientNeedsOptionsService';
import childService from '../../../services/childService';
import ChildForm from '../../Forms/ChildForm';
import ReactModal from '../../shared/ReactModal';
import Sidebar from '../Sidebar';
class ChildrenEdit extends ChildForm {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            saving: false,
            deleting: false,
            isOpen: false,
            linkGroup: findLinkGroupByTitle('Families'),
            loading: true,
            id: null,
            creating: false,
            family_id: null,
            careRecipientNeeds: [],
        };
    }
    getCareRecipientNeeds = async () => {
        const careRecipientNeeds = await getCareRecipientNeedsOptionsRequest();
        this.setState({
            careRecipientNeeds: careRecipientNeeds.map((item) => ({
                value: item.id,
                label: item.name,
            })),
        });
    };
    componentDidMount = () => {
        this.getCareRecipientNeeds();
        const { id, family_id } = this.props.match.params;
        const parsedId = Number.parseInt(id, 10);
        if (Number.isNaN(parsedId) === false) {
            this.getChild(id);
        }
        else {
            this.setState({
                data: { ...this.state.freshSchema },
                creating: true,
                family_id,
            });
        }
    };
    getChild = async (id) => {
        try {
            const response = await childService.get(id);
            if (response.status === 200) {
                const { data } = response.data;
                this.formatData(data);
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    formatData = ({ personalInfo, profileChild, selectedRecipientNeeds, }) => {
        const { DOB, avatar_filename, firstname, lastname, family_id, profile_color, } = personalInfo;
        const { notes, id, fall_risk, help_standing_up, wheelchair } = profileChild;
        const { data } = this.state;
        const selected_recipient_needs = selectedRecipientNeeds.map((need) => ({
            value: need.id,
            label: need.name,
        }));
        data.firstname = firstname;
        data.lastname = lastname;
        data.avatar_filename = avatar_filename;
        const dobPieces = DOB.split('-');
        if (dobPieces.length === 3) {
            // We assume format "YYYY-MM-DD"
            // so after splitting, it should be: [ "YYYY", "MM", "DD" ]
            data.dob_year = dobPieces[0];
            data.dob_month = dobPieces[1];
            data.dob_day = dobPieces[2];
        }
        data.notes = notes;
        data.profile_color = profile_color;
        data.fall_risk = fall_risk;
        data.help_standing_up = help_standing_up;
        data.wheelchair = wheelchair;
        data.selected_recipient_needs = selected_recipient_needs;
        this.setState({
            data,
            creating: false,
            personalInfo,
            profileChild,
            id,
            family_id,
        });
    };
    doSubmit = async (callback) => {
        this.setState({ saving: true });
        const response = await this.submitFormData();
        callback();
        if (response.status === 200 || response.status === 201) {
            toast.success('Hooray! Your changes are saved.');
            this.goBack();
        }
        this.setState({ saving: false });
    };
    closeModal = () => {
        this.setState({ isOpen: false });
    };
    goBack = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.props.history.goBack();
    };
    deleteChild = async () => {
        const { id } = this.state;
        this.setState({ deleting: true });
        const response = await childService.delete(id);
        if (response.status === 204) {
            toast.success('Hooray! The care recipient was deleted.');
            this.goBack();
        }
        else {
            toast.error('Oops! Looks like something went wrong.');
        }
        this.setState({ deleting: false });
    };
    render() {
        const { saving, deleting, linkGroup, isOpen, creating } = this.state;
        return (React.createElement("main", { className: "dashboard admin manage" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content" },
                React.createElement("div", { className: "providers" },
                    React.createElement("h2", null,
                        creating === true ? 'Create' : 'Edit',
                        " Care Recipient"),
                    React.createElement("div", { className: "providers-form form" },
                        this.renderForm(),
                        React.createElement("div", { className: "divider" }),
                        React.createElement("div", { className: "align_center row" },
                            React.createElement("button", { type: "button", className: "theme-btn blue large save-btn", disabled: saving, onClick: this.handleSubmit },
                                saving && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                saving ? ' Saving...' : 'Save'),
                            React.createElement("button", { type: "button", className: "theme-btn red large save-btn", disabled: deleting, onClick: () => this.setState({ isOpen: true }) },
                                deleting && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                deleting ? ' Deleting...' : 'Delete')))),
                React.createElement(ReactModal, { title: "Confirm Delete", isOpen: isOpen, closeModal: this.closeModal, content: 'Are you sure you want to delete this care recipient?', primaryButton: deleting ? ' Deleting...' : 'Delete', secondaryButton: 'Close', handleClick: this.deleteChild }))));
    }
}
export default ChildrenEdit;
