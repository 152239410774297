import { filter } from 'lodash-es';
import React from 'react';
import { toast } from 'react-toastify';
import { pets } from '../../../data';
import { findLinkGroupByTitle } from '../../../helpers';
import petService from '../../../services/petService';
import PetForm from '../../Forms/PetForm';
import ReactModal from '../../shared/ReactModal';
import Sidebar from '../Sidebar';

class PetEdit extends PetForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      tab: 'regular',
      saving: false,
      deleting: false,
      isOpen: false,
      linkGroup: findLinkGroupByTitle('Families'),

      loading: true,
      id: null,
      creating: false,
      family_id: null,
    };
  }

  componentDidMount = () => {
    const { id, family_id } = this.props.match.params;
    if (id !== 'new' && Number(id) == id) {
      // abusing a quirk of JS where "11" == 11 to ensure the ID is a number
      this.getPet(id);
    } else {
      this.setState({
        data: { ...this.state.freshSchema },
        creating: true,
        family_id: family_id,
      });
    }
  };
  getPet = async (id) => {
    try {
      const response = await petService.get(id);

      if (response.status === 200) {
        const { data } = response.data;
        this.formatData(data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  formatData = ({ medicalInfo, personalInfo, profilePet }) => {
    const { avatar_filename, firstname, family_id, profile_color } =
      personalInfo;
    const { age_years, notes, type, veterinarian_info } = profilePet;

    let showOther = false;

    const { data } = this.state;

    data['avatar_filename'] = avatar_filename;
    data['firstname'] = firstname;
    data['age_years'] = age_years;
    data['notes'] = notes;
    data['veterinarian_info'] = veterinarian_info;
    data['profile_color'] = profile_color;

    const petType = filter(pets, (pet) => {
      return pet.id === type;
    });

    if (!petType.length) {
      data['type'] = 'Other';
      data['other'] = type;
      showOther = true;
    } else {
      data['type'] = type;
    }

    this.setState({
      data,
      creating: false,
      personalInfo,
      profilePet,
      id: profilePet.id,
      family_id,
      showOther,
    });
  };
  doSubmit = async (callback) => {
    this.setState({ saving: true });
    const response = await this.submitFormData();
    callback();
    if (response.status === 200 || response.status === 201) {
      toast.success('Hooray! Your changes are saved.');
      this.goBack();
    }
    this.setState({ saving: false });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  goBack = (e) => {
    if (!!e) {
      e.preventDefault();
    }
    this.props.history.goBack();
  };

  deletePet = async () => {
    const { id } = this.state;

    this.setState({ deleting: true });

    let data = new FormData();
    const headers = { headers: { 'content-type': 'multipart/form-data' } };
    data.append('_method', 'delete');

    const response = await petService.delete(id, data, headers);

    if (response.status === 204) {
      toast.success('Hooray! The pet was deleted.');
      this.goBack();
    } else {
      toast.error('Oops! Looks like something went wrong.');
    }

    this.setState({ deleting: false });
  };

  render() {
    const { saving, deleting, linkGroup, isOpen, creating } = this.state;

    return (
      <main className="dashboard admin manage">
        <div className="sidebar-wrapper">
          <Sidebar
            {...this.props}
            iconClass="fas fa-id-card"
            linkGroup={linkGroup}
          />
        </div>
        <div className="content">
          <div className="providers">
            <h2>{creating === true ? 'Create' : 'Edit'} Pet</h2>
            <div className="providers-form form">
              {this.renderForm(true)}
              <div className="divider"></div>
              <div className="align_center row">
                <button
                  type="button"
                  className="theme-btn blue large save-btn"
                  disabled={saving}
                  onClick={this.handleSubmit}
                >
                  {saving && <i className="fas fa-spinner fa-spin"></i>}
                  {saving ? ' Saving...' : 'Save'}
                </button>
                <button
                  type="button"
                  className="theme-btn red large save-btn"
                  disabled={deleting}
                  onClick={() => this.setState({ isOpen: true })}
                >
                  {deleting && <i className="fas fa-spinner fa-spin"></i>}
                  {deleting ? ' Deleting...' : 'Delete'}
                </button>
              </div>
            </div>
          </div>
          <ReactModal
            title="Confirm Delete"
            isOpen={isOpen}
            closeModal={this.closeModal}
            content={'Are you sure you want to delete this pet?'}
            primaryButton={deleting ? ' Deleting...' : 'Delete'}
            secondaryButton={'Close'}
            handleClick={this.deletePet}
          />
        </div>
      </main>
    );
  }
}

export default PetEdit;
