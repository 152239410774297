import Joi from 'joi';
import React from 'react';
import { pets } from '../../data';
import petService from '../../services/petService';
import Form from '../shared/Form';
const freshSchema = {
    firstname: '',
    type: '',
    other: '',
    age_years: null,
    veterinarian_info: '',
    same_veterinarian_info: false,
    profile_color: 'primary-coral',
    notes: '',
};
class PetForm extends Form {
    schema = Joi.object({
        avatar_filename: Joi.alternatives(Joi.string(), Joi.object())
            .optional()
            .allow(null)
            .label('Avatar'),
        profile_color: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Profile Color'),
        firstname: Joi.string().required().label('Pet Name'),
        type: Joi.string().required().label('What kind of pet do you have?'),
        other: Joi.string()
            .optional()
            .allow('')
            .allow(null)
            .label('What kind of pet do you have?'),
        age_years: Joi.number()
            .integer()
            .required()
            .label('What is the age of the pet?'),
        veterinarian_info: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Veterinarian Info'),
        same_veterinarian_info: Joi.boolean()
            .truthy(1)
            .falsy(0)
            .label('Same Veterinarian Info'),
        notes: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Notes and Rules'),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            data: { ...freshSchema },
            freshSchema,
            showOther: false,
        };
    }
    componentDidUpdate(_prevProps, prevState) {
        const prevData = prevState.data;
        const { data } = this.state;
        if (data.type === 'Other' && prevData.type !== 'Other') {
            this.setState({
                showOther: true,
            });
        }
        else if (data.type !== 'Other' && prevData.type === 'Other') {
            this.setState({
                showOther: false,
            });
        }
    }
    submitFormData = async () => {
        const apiData = { ...this.state.data };
        const { family_id, creating, id } = this.state;
        const { type, other } = apiData;
        apiData.family_id = family_id;
        if (type === 'Other') {
            apiData.type = other;
        }
        const data = new FormData();
        const headers = { headers: { 'content-type': 'multipart/form-data' } };
        data.append('_method', 'post');
        for (const key of Object.keys(apiData)) {
            if (typeof apiData[key] === 'object') {
                if (key === 'avatar_filename') {
                    if (apiData[key] !== null) {
                        data.append(key, apiData[key]);
                    }
                }
                else {
                    data.append(key, JSON.stringify(apiData[key]));
                }
            }
            else {
                data.append(key, apiData[key]);
            }
        }
        return creating
            ? petService.store(data, headers)
            : petService.update(id, data, headers);
    };
    renderForm(admin = false) {
        const { showOther } = this.state;
        return (React.createElement("div", { className: "pet-profile-form" },
            this.renderInput('firstname', 'Pet Name', 'text', true),
            this.renderSelect('type', 'What kind of pet do you have?', pets, true),
            !!showOther && (React.createElement(React.Fragment, null, this.renderInput('other', 'What kind of pet do you have?', 'text', true))),
            this.renderInput('age_years', 'What is the age of the pet?', 'number', true),
            React.createElement("div", { className: "form-field-group field-with-checkbox" },
                this.renderTextarea('veterinarian_info', 'Veterinarian Information (optional)', false),
                admin === false &&
                    this.renderBoolean('same_veterinarian_info', 'Check this box if all Veterinarian information is the same for all pets in your family.')),
            this.renderTextarea('notes', 'Notes and Rules (optional)', false)));
    }
}
export default PetForm;
