import Joi from 'joi';
import React from 'react';
import { states } from '../../data';
import familyService from '../../services/familyService';
import Form from '../shared/Form';
const radioOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
];
const freshSchema = {
    creating: false,
    name: '',
    email: '',
    firstname: '',
    lastname: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone1: '',
    phone2: '',
    employer: '',
    sexual_criminal_offense: 0,
    smoke: 0,
    house_rules: '',
    admin_family_notes: '',
};
class FamilyEditForm extends Form {
    schema = Joi.object({
        creating: Joi.boolean().truthy(1).falsy(0).label('creating'),
        name: Joi.string().required().label('Family Name'),
        email: Joi.string()
            .when('creating', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('Email'),
        firstname: Joi.string()
            .when('creating', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('First Name'),
        lastname: Joi.string()
            .when('creating', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('Last Name'),
        address1: Joi.string()
            .when('creating', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('Address (line 1)'),
        address2: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Address (line 2)'),
        city: Joi.string()
            .when('creating', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('City'),
        state: Joi.string()
            .when('creating', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('State'),
        zip: Joi.string()
            .when('creating', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('ZIP code'),
        phone1: Joi.string()
            .when('creating', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('Mobile Number'),
        phone2: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Secondary Number (Work)'),
        employer: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Place of Employment'),
        sexual_criminal_offense: Joi.boolean()
            .truthy(1)
            .falsy(0)
            .required()
            .label('Sexual / Criminal Offense'),
        smoke: Joi.boolean()
            .truthy(1)
            .falsy(0)
            .required()
            .label('Household Smokers'),
        house_rules: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('House Rules'),
        admin_family_notes: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Admin Family Notes'),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            data: freshSchema,
            creating: false,
            freshSchema,
        };
    }
    submitFormData = async () => {
        const apiData = { ...this.state.data };
        const data = new FormData();
        const headers = { headers: { 'content-type': 'multipart/form-data' } };
        data.append('_method', 'post');
        for (const key of Object.keys(apiData)) {
            if (typeof apiData[key] === 'object') {
                if (key === 'avatar_filename') {
                    if (apiData[key] !== null) {
                        data.append(key, apiData[key]);
                    }
                }
                else {
                    data.append(key, JSON.stringify(apiData[key]));
                }
            }
            else {
                data.append(key, apiData[key]);
            }
        }
        return this.state.creating
            ? familyService.store(data, headers)
            : familyService.update(this.state.id, data, headers);
    };
    renderForm() {
        const { creating } = this.state;
        return (React.createElement("div", { className: "account-settings-form family" },
            React.createElement("h4", { className: "section-label" }, "Family Information"),
            this.renderInput('name', 'Family Name', 'text', true),
            creating && (React.createElement(React.Fragment, null,
                React.createElement("h4", { className: "section-label" }, "Family Owner Information"),
                this.renderInput('email', 'Email', 'email', true),
                this.renderInput('firstname', 'First Name', 'text', true),
                this.renderInput('lastname', 'Last Name', 'text', true),
                React.createElement("h4", { className: "section-label" }, "Contact & Address Information"),
                this.renderInput('phone1', 'Mobile Phone', 'number', true),
                this.renderInput('phone2', 'Secondary Phone (Work)', 'number'),
                this.renderInput('address1', 'Address', 'text', true),
                this.renderInput('address2', 'Apt/Suite', 'text'),
                this.renderInput('city', 'City', 'text', true),
                this.renderSelect('state', 'State', states, true),
                this.renderInput('zip', 'ZIP', 'number', true))),
            React.createElement("h4", { className: "section-label" }, "Appointment Information"),
            this.renderTextarea('house_rules', 'House Rules'),
            this.renderTextarea('admin_family_notes', 'Admin Family Notes (only visible to Providers)'),
            React.createElement("h4", { className: "section-label" }, "History Information"),
            this.renderInput('employer', 'Place of Employment (optional)'),
            this.renderRadio('sexual_criminal_offense', 'Has anyone in the home been convicted of any sexual or criminal offenses?', radioOptions, true),
            this.renderRadio('smoke', 'Does anyone in your household smoke?', radioOptions, true)));
    }
}
export default FamilyEditForm;
