import React from 'react';
import Joi from 'joi';
import moment from 'moment-timezone';
import appointmentService from '../../../services/appointmentService';
import { toast } from 'react-toastify';

import { Modal, Form } from '..';
import { makeTimeIncrements } from '../../../helpers';
import { find, findIndex } from 'lodash-es';

const ERROR_SAME_START_END_TIME =
  'Start and/or end time must be different from the original start and end times!';

class AppointmentTimeChangeRequest extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        requested_start_time: '',
        requested_end_time: '',
      },
      errors: {},
      times: makeTimeIncrements(0),
      startTimes: makeTimeIncrements(0, 21),
      endTimes: makeTimeIncrements(0, 21),
      initialSubmit: true,
    };
  }
  schema = Joi.object({
    requested_start_time: Joi.string().required().label('Start Time'),
    requested_end_time: Joi.string().required().label('End Time'),
  });
  componentDidMount() {
    const { start_time, end_time } = this.props.appointmentData.appointment;
    let startTime = moment(start_time).format('HH:mm');
    let endTime = moment(end_time).format('HH:mm');
    const data = { ...this.state.data };
    data['requested_start_time'] = startTime;
    data['requested_end_time'] = endTime;
    this.setState({ data });
  }
  componentDidUpdate(prevProps, prevState) {
    const prevData = prevState.data;
    const data = { ...this.state.data };
    const { errors, initialSubmit } = this.state;

    const { start_time, end_time } = this.props.appointmentData.appointment;
    let origStartTime = moment(start_time).format('HH:mm');
    let origEndTime = moment(end_time).format('HH:mm');

    if (
      data['requested_start_time'] !== null &&
      prevData['requested_start_time'] !== data['requested_start_time']
    ) {
      const { overnight } = this.props.appointmentData.appointment;
      let newEndTimes = this.createNewEndTimes(
        data['requested_start_time'],
        overnight
      );
      let timeStillExists = find(newEndTimes, (time) => {
        return time.value == data['requested_end_time'];
      });
      if (!timeStillExists) {
        data['requested_end_time'] = newEndTimes[0].value;
      }
      this.setState({
        endTimes: newEndTimes,
        data,
      });
    }

    // We only want the error to be visible _after_ they try to submit. This variable is changed in the submit function.
    if (!initialSubmit) {
      if (
        data['requested_start_time'] == origStartTime &&
        data['requested_end_time'] == origEndTime &&
        !errors['same_start_end']
      ) {
        errors['same_start_end'] = ERROR_SAME_START_END_TIME;
        this.setState({ errors });
      } else if (
        (data['requested_start_time'] != origStartTime ||
          data['requested_end_time'] != origEndTime) &&
        !!errors['same_start_end']
      ) {
        errors['same_start_end'] = null;
        this.setState({ errors });
      }
    }
  }
  createNewEndTimes = (start_time, overnight) => {
    let newEndTimes = [...this.state.times];
    newEndTimes.splice(0, 1);
    if (!overnight) {
      const idx = findIndex(newEndTimes, (time) => {
        return time.value == start_time;
      });
      const length = idx + 12;
      if (length === newEndTimes.length) {
        // if they select the very last time slot (11:45pm), then there are 0 options left
        // So we force there to be only 1 item
        newEndTimes.splice(0, length - 1);
      } else {
        newEndTimes.splice(0, length);
      }
    }
    return newEndTimes;
  };
  doSubmit = async (callback) => {
    const { requested_start_time, requested_end_time } = this.state.data;
    const { start_time, end_time } = this.props.appointmentData.appointment;

    let origStartTime = moment(start_time).format('HH:mm');
    let origEndTime = moment(end_time).format('HH:mm');

    if (
      origStartTime == requested_start_time &&
      origEndTime == requested_end_time
    ) {
      const { errors, initialSubmit } = this.state;
      errors['same_start_end'] = ERROR_SAME_START_END_TIME;
      this.setState({ errors, initialSubmit: false });
      callback();
    } else {
      try {
        const { errors } = this.state;
        errors['same_start_end'] = null;
        this.setState({ errors });
        let startDay = moment(
          this.props.appointmentData.appointment.start_time
        ).format('YYYY-MM-DD');
        let endDay = moment(
          this.props.appointmentData.appointment.end_time
        ).format('YYYY-MM-DD');
        let newStartDateAndTime = moment(
          `${startDay} ${requested_start_time}`,
          'YYYY-MM-DD HH:mm'
        ).format();
        let newEndDateAndTime = moment(
          `${endDay} ${requested_end_time}`,
          'YYYY-MM-DD HH:mm'
        ).format();
        const { id } = this.props.appointmentData.appointment;
        const apiData = {
          id,
          requested_start_time: newStartDateAndTime,
          requested_end_time: newEndDateAndTime,
        };
        const response = await appointmentService.update(id, apiData);
        callback();
        if (response.status === 200 || response.status === 201) {
          toast.success('Hooray! Your time change request has been submitted.');
          this.props.refreshAppointments();
        } else {
          toast.error(
            'Oops, there were issues submitting your time change request!'
          );
        }
        this.props.toggleTimeChange();
      } catch (e) {
        toast.error(
          'Oops, there were issues submitting your time change request!'
        );
        this.props.toggleTimeChange();
        console.log(e);
      }
    }
  };
  render() {
    const { startTimes, endTimes, errors } = this.state;
    return (
      <div className="appointment-time-change-request">
        <Modal
          title="Time Change Request"
          isOpen={true}
          closeModal={this.props.toggleTimeChange}
          content={
            <form onSubmit={this.handleSubmit} className="time-change-request">
              <p>
                <b>HEADS UP!</b> Your Provider must first approve any requested
                time changes. You will be notified via email if your Provider
                approves the requested time change.
              </p>
              <p>
                <b>If your provider declines your time change request,</b> your
                originally confirmed appointment will remain.
              </p>
              <p>
                <b>
                  If your provider declines the request, but you wish to keep
                  the time change,
                </b>{' '}
                you must cancel this appointment and schedule a new one, with
                the updated times needed.
              </p>
              <p>
                Please note that all scheduling fees are non-refundable and
                non-transferable.
              </p>
              <div className="form-group times">
                {this.renderSelect(
                  'requested_start_time',
                  'Start Time',
                  startTimes,
                  true
                )}
                {this.renderSelect(
                  'requested_end_time',
                  'End Time',
                  endTimes,
                  true
                )}
                {!!errors['same_start_end'] && (
                  <p className="error">{errors['same_start_end']}</p>
                )}
              </div>
              {this.renderButton(
                'Submit Request',
                'Submitting...',
                'theme-btn blue'
              )}
            </form>
          }
          secondaryButton={'Close'}
        />
      </div>
    );
  }
}

export default AppointmentTimeChangeRequest;
