import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { baseUrl } from '../../../config';
import { findLinkGroupByTitle } from '../../../helpers';
import authService from '../../../services/authService';
import sitterService from '../../../services/sitterService';
import ProvidersForm from '../../Forms/ProvidersForm';
import ReactModal from '../../shared/ReactModal';
import ReviewsGrid from '../ReviewsGrid/ReviewsGrid';
import Sidebar from '../Sidebar';
class ProvidersEdit extends ProvidersForm {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            tab: 'regular',
            saving: false,
            deleting: false,
            isOpen: false,
            linkGroup: findLinkGroupByTitle('Providers'),
        };
    }
    doSubmit = async (callback) => {
        this.setState({ saving: true });
        try {
            await this.submitFormData();
            toast.success('Hooray! The provider was saved successfully!');
            this.goBack();
            callback();
        }
        catch (error) {
            console.error(error);
        }
        this.setState({ saving: false });
    };
    changeTab = (tab) => {
        this.setState({ tab });
    };
    closeModal = () => {
        this.setState({ isOpen: false });
    };
    deleteSitter = async () => {
        const { profileId } = this.state;
        this.setState({ deleting: true });
        const response = await sitterService.delete(profileId);
        if (response.status === 204) {
            toast.success('Hooray! The provider was deleted.');
            this.goBack();
        }
        else {
            toast.error('Oops! Looks like something went wrong.');
        }
        this.setState({ deleting: false });
    };
    sendInviteLink = async () => {
        this.setState({
            submitting: true,
        });
        try {
            const { email, login_hash } = this.state.data;
            const response = await authService.sendVerifyEmail({
                type: 'sitter',
                email,
                login_hash,
            });
            if (response.status === 200) {
                toast.success('Invite Link Sent');
                this.setState({
                    submitting: false,
                });
            }
        }
        catch (error) {
            console.error(error);
            toast.error('There was a problem sending the invite link');
            this.setState({
                sending: false,
            });
        }
    };
    goBack = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.props.history.push('/providers/manage');
    };
    render() {
        const { saving, deleting, linkGroup, isOpen, submitting, data } = this.state;
        const { id } = this.props.match.params;
        return (React.createElement("main", { className: "dashboard admin manage" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content" },
                React.createElement("div", { className: "providers" },
                    React.createElement("h2", null, "Edit Provider"),
                    React.createElement("div", { className: "providers-btn-wrapper" },
                        React.createElement(Link, { className: "theme-btn slate-blue small", to: `/providers/availability/edit/${id}` }, "Provider Availability")),
                    React.createElement("div", { className: "providers-form form" },
                        this.renderForm(),
                        data && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "divider" }),
                            React.createElement("div", { className: "subscription-cancellation" },
                                React.createElement("button", { type: "button", className: "theme-btn blue large", disabled: submitting, onClick: this.sendInviteLink },
                                    submitting && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                    submitting ? ' Sending...' : 'Send Invite Link'),
                                React.createElement("br", null),
                                React.createElement("br", null),
                                React.createElement("b", null, "Link:"),
                                ' ',
                                React.createElement("a", { href: baseUrl +
                                        '/register/care-provider/application?login_hash=' +
                                        data.login_hash +
                                        '&email=' +
                                        data.email },
                                    baseUrl,
                                    "/register/care-provider/application?login_hash=",
                                    data.login_hash,
                                    "&email=",
                                    data.email)))),
                        React.createElement("div", { className: "divider" }),
                        React.createElement("h4", { className: "section-label" }, "Sitter Reviews"),
                        React.createElement(ReviewsGrid, { sitterPiid: Number(id) }),
                        React.createElement("br", null),
                        React.createElement("div", { className: "divider" }),
                        React.createElement("div", { className: "align_center row" },
                            React.createElement("button", { type: "button", className: "theme-btn blue large save-btn", disabled: saving, onClick: this.handleSubmit },
                                saving && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                saving ? ' Saving...' : 'Save'),
                            React.createElement("button", { type: "button", className: "theme-btn red large save-btn", disabled: deleting, onClick: () => this.setState({ isOpen: true }) },
                                deleting && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                deleting ? ' Deleting...' : 'Delete')))),
                React.createElement(ReactModal, { title: "Confirm Delete", isOpen: isOpen, closeModal: this.closeModal, content: 'Are you sure you want to delete this provider?', primaryButton: deleting ? ' Deleting...' : 'Delete', secondaryButton: 'Close', handleClick: this.deleteSitter }))));
    }
}
export default ProvidersEdit;
