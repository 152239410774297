export default [
    {
        label: 'Experience with special needs',
        value: 1,
    },
    {
        label: 'Education/experience in healthcare (nursing, OT, PT)',
        value: 4,
    },
    {
        label: 'Educated/willing to be educated in the use of an epipen',
        value: 5,
    },
    {
        label: 'Add my own',
        value: 1000,
    },
];
