import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter, } from 'react-router-dom';
import { balanceActions } from '../../actions/balanceActions';
import links from '../../data/links';
import appointmentService from '../../services/appointmentService';
import systemNoticesService from '../../services/systemNoticesService';
import { AppointmentDetail, EmailUpdate, Header, Page, PasswordUpdate, } from '../shared';
import { RouteLoader } from '../shared/RouteLoader';
import { AccountSettings, BillingSettings, ChildProfile, FamilyProfile, PetProfile, } from './Account';
import CareContactProfile from './Account/CareContactProfile';
import { RegularBookingWrapper as RegularBooking } from './Booking/v2';
import { BulkBookingWrapper as BulkBooking } from './BulkBooking';
import Dashboard from './Dashboard';
import { ProgramDetail, ProgramListing } from './Programs';
import SelectFlow from './SelectFlow';
import SitLog from './SitLog';
const Sitters = React.lazy(() => import('./Sitters'));
const ReviewAppointment = React.lazy(() => import('./ReviewAppointment'));
class FamilySection extends React.Component {
    previousLocation;
    state = {
        appointments: [],
        notifications: [],
        systemNotices: [],
        appointmentStatus: 'all',
        menuOpen: false,
        windowWidth: null,
    };
    constructor(props) {
        super(props);
        this.previousLocation = this.props.location;
    }
    componentDidMount() {
        this.getAppointmentsByStatus();
        this.getSystemNotices();
        this.getBalance();
        this.onWindowResize();
        window.addEventListener('resize', this.onWindowResize);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }
    UNSAFE_componentWillUpdate() {
        const { location } = this.props;
        if (!location.state?.appointmentView) {
            this.previousLocation = location;
        }
    }
    componentDidUpdate(prevProps) {
        const currentPath = this.props.location.pathname;
        const prevPath = prevProps.location.pathname;
        if (currentPath !== prevPath) {
            this.setState({
                menuOpen: false,
            });
        }
    }
    onWindowResize = () => {
        const windowWidth = document.documentElement.clientWidth;
        this.setState({ windowWidth });
    };
    getAppointmentsByStatus = () => {
        const { appointmentStatus } = this.state;
        appointmentService
            .getAppointmentsByStatus({
            status: appointmentStatus,
        })
            .then((response) => {
            if (response.status === 200) {
                const { appointments } = response.data.data;
                this.setState({ appointments });
            }
        })
            .catch((error) => console.error(error));
    };
    onRequestAppointmentClose = () => {
        this.getBalance();
        this.getAppointmentsByStatus();
        const { pathname } = this.previousLocation;
        const redirectTo = /^\/appointment\/\d+$/.test(pathname) ? '/' : pathname;
        this.props.history.push(redirectTo);
    };
    getSystemNotices = async () => {
        const response = await systemNoticesService.getSystemNotices('parent');
        if (response.status === 200) {
            const { systemNotices } = response.data.data;
            this.setState({ systemNotices });
        }
    };
    getBalance = async () => {
        try {
            const { family_id } = this.props.auth.user.personal_info;
            if (!family_id) {
                throw new Error('Missing family_id');
            }
            await this.props.getBalance(family_id);
        }
        catch (error) {
            console.error(error);
        }
    };
    changeAppointmentStatus = (status) => {
        this.setState({
            appointmentStatus: status,
        }, this.getAppointmentsByStatus);
    };
    toggleMenu = () => {
        let { menuOpen } = this.state;
        menuOpen = !menuOpen;
        this.setState({ menuOpen });
    };
    render() {
        const { appointments, systemNotices, notifications, menuOpen, windowWidth, appointmentStatus, } = this.state;
        const { location } = this.props;
        const appointmentView = location.state?.appointmentView && this.previousLocation !== location;
        const detailOpen = appointmentView || /^\/appointment\/\d+$/.test(location.pathname);
        return (React.createElement(Page, { id: "family-section", menuOpen: menuOpen, detailOpen: detailOpen, header: React.createElement(Header, { type: "family", className: "family", links: links.family, notifications: notifications, menuOpen: menuOpen, detailOpen: detailOpen, toggleMenu: this.toggleMenu }) },
            React.createElement(React.Suspense, { fallback: React.createElement(RouteLoader, null) },
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "/dashboard", exact: true, render: (props) => (React.createElement(Dashboard, { ...props, appointments: appointments, systemNotices: systemNotices, changeAppointmentStatus: this.changeAppointmentStatus, appointmentStatus: appointmentStatus, refreshAppointments: this.getAppointmentsByStatus, windowWidth: windowWidth })) }),
                    React.createElement(Route, { path: "/appointment/:id", exact: true, render: (props) => (React.createElement(React.Fragment, null,
                            React.createElement(Dashboard, { ...props, appointments: appointments, systemNotices: systemNotices, changeAppointmentStatus: this.changeAppointmentStatus, appointmentStatus: appointmentStatus, refreshAppointments: this.getAppointmentsByStatus, windowWidth: windowWidth }),
                            React.createElement(AppointmentDetail, { id: Number.parseInt(props.match.params.id, 10), type: "family", onRequestClose: this.onRequestAppointmentClose, windowWidth: windowWidth, refreshAppointments: this.getAppointmentsByStatus }))) }),
                    React.createElement(Route, { path: "/appointment/:id/log", exact: true, render: (props) => (React.createElement(SitLog, { ...props, windowWidth: windowWidth })) }),
                    React.createElement(Route, { path: "/programs", exact: true, component: ProgramListing }),
                    React.createElement(Route, { path: "/programs/:id", exact: true, render: (props) => (React.createElement(ProgramDetail, { ...props, windowWidth: windowWidth })) }),
                    React.createElement(Route, { path: "/profile", exact: true, component: FamilyProfile }),
                    React.createElement(Route, { path: "/profile/care-recipient/:id", exact: true, component: ChildProfile }),
                    React.createElement(Route, { path: "/profile/contacts/:id", exact: true, component: CareContactProfile }),
                    React.createElement(Route, { path: "/profile/pet/:id", exact: true, component: PetProfile }),
                    React.createElement(Route, { path: "/settings/account", exact: true, component: AccountSettings }),
                    React.createElement(Route, { path: "/settings/billing", exact: true, component: BillingSettings }),
                    React.createElement(Route, { path: "/password/update", exact: true, component: PasswordUpdate }),
                    React.createElement(Route, { path: "/email/update", exact: true, component: EmailUpdate }),
                    React.createElement(Route, { path: "/appointments", exact: true, component: SelectFlow }),
                    React.createElement(Route, { path: "/appointments/book", exact: true, component: RegularBooking }),
                    React.createElement(Route, { path: "/review/:id", exact: true, component: ReviewAppointment }),
                    React.createElement(Route, { path: "/program/book", exact: true, render: (props) => (React.createElement(BulkBooking, { ...props, currentAppointments: appointments })) }),
                    React.createElement(Route, { path: "/care-providers", component: Sitters }),
                    React.createElement(Redirect, { to: "/dashboard" }))),
            appointmentView && (React.createElement(Route, { exact: true, path: "/appointment/:id", render: (props) => (React.createElement(AppointmentDetail, { id: Number.parseInt(props.match.params.id, 10), type: "family", onRequestClose: this.onRequestAppointmentClose, windowWidth: windowWidth, refreshAppointments: this.getAppointmentsByStatus })) }))));
    }
}
function mapState(state) {
    return {
        auth: state.auth,
        balance: state.balance,
    };
}
const actionCreators = {
    getBalance: balanceActions.getBalance,
};
export default withRouter(connect(mapState, actionCreators)(FamilySection));
