import { debounce, each } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { upperCaseFirst } from '../../../helpers';
import sitterService from '../../../services/sitterService';
import { Form, Modal } from '../../shared';
import Joi from 'joi';

const wiggleOptions = [
  { name: '15 min', id: 15 },
  { name: '30 min', id: 30 },
  { name: '45 min', id: 45 },
  { name: '1 hour', id: 60 },
  { name: '1h 15m', id: 75 },
  { name: '1h 30m', id: 90 },
  { name: '1h 45m', id: 105 },
  { name: '2 hours', id: 120 },
];

const radioOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

function getDateValue(date) {
  if (typeof date === 'string') {
    return date;
  }
  if (date instanceof Date) {
    return moment(date).format('YYYY-MM-DD');
  }
  if (moment.isMoment(date)) {
    return date.format('YYYY-MM-DD');
  }
  throw new Error('Invalid date');
}

class AppointmentRow extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: null,
        all_children: 1,
        start_date: new Date(),
        end_date: new Date(),
        start_time: null,
        end_time: null,
        flex: 0,
        flex_room: null,
        driving_needed: 0,
        driving_distance: null,
        selected_sitters: null,
        selected_children: [],
      },
      errors: {},
      end_times: [],
      showWiggleTime: false,
      showDrivingDistance: false,
      showingRemoveAppt: false,
      sameProvider: false,
      sameStart: false,
      sameEnd: false,
    };
  }
  componentDidMount = () => {
    const { appointment } = this.props;
    let { data } = this.state;
    data = { ...appointment };
    if (appointment.all_children === undefined) {
      data.all_children = 1;
    }
    if (data.selected_sitters !== null) {
      data.selected_sitters = data.selected_sitters[0];
    }
    this.setState({ data });
  };
  componentDidUpdate = (prevProps, prevState) => {
    let prevData = prevState.data;
    let { data } = this.state;

    let currentAppointment = this.props.appointment;
    let prevAppointment = prevProps.appointment;

    const prevStartDate = getDateValue(prevData.start_date);
    const nextStartDate = getDateValue(data.start_date);

    const prevEndDate = getDateValue(prevData.end_date);
    const nextEndDate = getDateValue(data.end_date);

    if (nextStartDate !== '' && prevStartDate !== nextStartDate) {
      // Start date has changed
      console.log('Start date changed');
      const startDate = moment(nextStartDate, 'YYYY-MM-DD');
      const endDate = moment(nextEndDate, 'YYYY-MM-DD');

      if (endDate.isBefore(startDate)) {
        data.end_date = moment(startDate).format('YYYY-MM-DD');
        this.setState({ data });
      }
    }

    if (nextEndDate !== '' && prevEndDate !== nextEndDate) {
      // End date has changed
      console.log('End date changed');
      const startDate = moment(nextStartDate, 'YYYY-MM-DD');
      const endDate = moment(nextEndDate, 'YYYY-MM-DD');
      if (endDate.isBefore(startDate)) {
        data.end_date = moment(startDate).format('YYYY-MM-DD');
        this.setState({ data });
      }
    }

    // If driving is true, show the driving_distance field
    if (
      data['driving_needed'] === 1 &&
      prevData['driving_needed'] !== data['driving_needed']
    ) {
      this.setState({
        showDrivingDistance: true,
      });
    } else if (
      data['driving_needed'] === 0 &&
      prevData['driving_needed'] !== data['driving_needed']
    ) {
      this.setState({
        showDrivingDistance: false,
      });
    }

    if (data['flex'] === 1 && prevData['flex'] !== 1) {
      const { data } = this.state;
      data['flex_room'] = `${wiggleOptions[0].id}`; // num to string conversion hack
      this.setState({
        showWiggleTime: true,
        data,
      });
    } else if (data['flex'] === 0 && prevData['flex'] !== 0) {
      const { data } = this.state;
      data['flex_room'] = null;
      this.setState({
        showWiggleTime: false,
        data,
      });
    }

    if (prevData !== data) {
      const { idx } = this.props;
      let appointmentData = { ...data };
      appointmentData['start_date'] = moment(
        appointmentData['start_date']
      ).format('YYYY-MM-DD');
      appointmentData['end_date'] = moment(appointmentData['end_date']).format(
        'YYYY-MM-DD'
      );
      this.props.onAppointmentDataChange(idx, appointmentData);
    }

    if (
      !!currentAppointment['start_time'] &&
      currentAppointment['start_time'] !== data['start_time']
    ) {
      const { data } = this.state;
      data['start_time'] = currentAppointment['start_time'];
      this.setState({ data });
    }

    if (
      !!currentAppointment['end_time'] &&
      currentAppointment['end_time'] !== data['end_time']
    ) {
      const { data } = this.state;
      data['end_time'] = currentAppointment['end_time'];
      this.setState({ data });
    }

    if (
      !!currentAppointment['selected_sitters'] &&
      currentAppointment['selected_sitters'] !== data['selected_sitters']
    ) {
      const { data } = this.state;
      data['selected_sitters'] = currentAppointment['selected_sitters'];
      this.setState({ data });
    }
  };
  schema = Joi.object({
    //
  });
  debounceLoadSitters = debounce((search, callback) => {
    this.loadSitters(search, callback);
  }, 1000);
  loadSitters = async (search, callback) => {
    try {
      const response = await sitterService.search({ search });
      if (response.status === 200) {
        let { sitters } = response.data.data;
        sitters = each(sitters, (sitter) => {
          sitter['value'] = sitter.id;
          sitter['label'] =
            sitter.firstname + ' ' + upperCaseFirst(sitter.lastname);
        });
        this.setState(
          {
            sitters,
          },
          () => {
            callback(sitters);
          }
        );
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  toggleRemoveAppointment = (e) => {
    if (e) e.preventDefault();
    this.setState({
      showingRemoveAppt: !this.state.showingRemoveAppt,
    });
  };
  removeAppointment = (e) => {
    e.preventDefault();
    this.props.removeAppointment(this.props.idx);
  };
  togglePrimaryProvider = () => {
    let sameProvider = !this.state.sameProvider;
    this.setState({ sameProvider }, () => {
      if (sameProvider) {
        this.props.setPrimaryProvider(this.state.data.selected_sitters);
      } else {
        this.props.setPrimaryProvider();
      }
    });
  };
  toggleStartTime = () => {
    let sameStart = !this.state.sameStart;
    this.setState({ sameStart }, () => {
      if (sameStart) {
        this.props.setTime('start_time', this.state.data.start_time);
      } else {
        this.props.setTime('start_time');
      }
    });
  };
  toggleEndTime = () => {
    let sameEnd = !this.state.sameEnd;
    this.setState({ sameEnd }, () => {
      if (sameEnd) {
        this.props.setTime('end_time', this.state.data.end_time);
      } else {
        this.props.setTime('end_time');
      }
    });
  };
  render() {
    const { times, children, idx } = this.props;
    const {
      end_times,
      showWiggleTime,
      showDrivingDistance,
      data,
      showingRemoveAppt,
      sameProvider,
      sameStart,
      sameEnd,
    } = this.state;
    const { all_children, start_time } = data;
    // end_times is not set when the component mounts. the select does weird things when there is no value
    // so we do this to make sure it's set to something on initial load
    const endTimes = end_times.length > 0 ? end_times : times;
    const endTimeDisabled = !start_time;
    return (
      <form className="appointment-row">
        <div className="form-field-wrapper date">
          {this.renderDatePicker('start_date', 'Start Date', true, 'MMM do')}
        </div>
        <div className="form-field-wrapper start_time">
          {this.renderSelect('start_time', 'Start Time', times, true)}
          {idx === 0 && (
            <div className="time start">
              <div className={`form-field boolean checkbox same_start_time`}>
                <span>
                  <input
                    name="same_start_time"
                    id="same_start_time"
                    type="checkbox"
                    checked={!!sameStart ? true : false}
                    className="checkbox"
                    onChange={this.toggleStartTime}
                  />
                  <label htmlFor="same_start_time">
                    <div className="checkbox">
                      {!!sameStart && <i className="far fa-check"></i>}
                    </div>
                    Set as Default Start Time
                  </label>
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="form-field-wrapper date">
          {this.renderDatePicker(
            'end_date',
            'End Date',
            true,
            'MMM do',
            false,
            moment(data['start_date'], 'YYYY-MM-DD').toDate()
          )}
        </div>
        <div className="form-field-wrapper end_time">
          {this.renderSelect('end_time', 'End Time', times, true, {
            disabled: endTimeDisabled,
          })}
          {idx === 0 && (
            <div className="time end">
              <div className={`form-field boolean checkbox same_end_time`}>
                <span>
                  <input
                    name="same_end_time"
                    id="same_end_time"
                    type="checkbox"
                    checked={!!sameEnd ? true : false}
                    className="checkbox"
                    onChange={this.toggleEndTime}
                  />
                  <label htmlFor="same_end_time">
                    <div className="checkbox">
                      {!!sameEnd && <i className="far fa-check"></i>}
                    </div>
                    Set as Default End Time
                  </label>
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="form-field-wrapper flex">
          {this.renderRadio('flex', 'Flex?', radioOptions, true)}
          {!!showWiggleTime && (
            <React.Fragment>
              {this.renderSelect('flex_room', 'Time', wiggleOptions, true)}
            </React.Fragment>
          )}
        </div>
        <div className="form-field-wrapper driving">
          {this.renderRadio('driving_needed', 'Driving?', radioOptions, true)}
          {!!showDrivingDistance && (
            <React.Fragment>
              {this.renderInput('driving_distance', 'Miles', 'number', true)}
            </React.Fragment>
          )}
        </div>
        <div className="form-field-wrapper children">
          {this.renderRadio(
            'all_children',
            'All recipients?',
            radioOptions,
            true
          )}
          {!all_children && (
            <React.Fragment>
              {this.renderChildCheckbox(
                'selected_children',
                'Care Recipients',
                children,
                true
              )}
            </React.Fragment>
          )}
        </div>
        <div className="form-field-wrapper provider">
          {this.renderAsyncSelect(
            'selected_sitters',
            'Provider',
            this.debounceLoadSitters,
            true
          )}
          {idx === 0 && (
            <div className="primary-provider">
              <div className={`form-field boolean checkbox primary_provider`}>
                <span>
                  <input
                    name="primary_provider"
                    id="primary_provider"
                    type="checkbox"
                    checked={!!sameProvider ? true : false}
                    className="checkbox"
                    onChange={this.togglePrimaryProvider}
                  />
                  <label htmlFor="primary_provider">
                    <div className="checkbox">
                      {!!sameProvider && <i className="far fa-check"></i>}
                    </div>
                    Set as Primary Provider
                  </label>
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="form-field-wrapper cancel">
          <a className="cancel-btn" onClick={this.toggleRemoveAppointment}>
            <span>X</span>
          </a>
        </div>
        <Modal
          title="Confirm Removal"
          isOpen={showingRemoveAppt}
          closeModal={this.toggleRemoveAppointment}
          content={
            'Are you sure you want to remove this appointment? It has not been saved yet. This action CANNOT be undone.'
          }
          primaryButton={'Remove'}
          secondaryButton={'Close'}
          handleClick={this.removeAppointment}
        />
      </form>
    );
  }
}

export default AppointmentRow;
