import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { mapAuthState } from '../../../helpers/mapAuthState';
import { FormStepTracker } from '../../shared';
import trackerSteps from './trackerSteps';
class Complete extends React.Component {
    componentDidMount() {
        const { user } = this.props.auth;
        if (!user) {
            this.sendUserBack();
        }
    }
    sendUserBack() {
        alert('You must sign up or log in to view this page.');
        window.open('/', '_self');
    }
    advanceStep = async (event) => {
        if (event) {
            event.preventDefault();
        }
        try {
            const { id } = this.props.auth.user;
            await this.props.updateUserStep('care-provider-application-finished', id);
        }
        catch (error) {
            console.error(error);
        }
    };
    render() {
        return (React.createElement("div", { className: "registration family step-7 completion-page" },
            React.createElement("div", { className: "form" },
                React.createElement("div", { className: "inner" },
                    React.createElement(FormStepTracker, { steps: trackerSteps, currentStep: 4 }),
                    React.createElement("div", { className: "form-header" },
                        React.createElement("h2", null, "Registration Success"),
                        React.createElement("p", null, "Thank you and congratulations. You have successfully applied and have been registered as an STL Care Provider. Welcome to the family!")),
                    React.createElement("div", { className: "form-body" },
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                React.createElement("p", null,
                                    React.createElement("span", { className: "form-step-circle" },
                                        React.createElement("i", { className: "far fa-check" })),
                                    "Application Accepted")),
                            React.createElement("li", null,
                                React.createElement("p", null,
                                    React.createElement("span", { className: "form-step-circle" },
                                        React.createElement("i", { className: "far fa-check" })),
                                    "Terms Accepted")),
                            React.createElement("li", null,
                                React.createElement("p", null,
                                    React.createElement("span", { className: "form-step-circle" },
                                        React.createElement("i", { className: "far fa-check" })),
                                    "Application Approved")),
                            React.createElement("li", null,
                                React.createElement("p", null,
                                    React.createElement("span", { className: "form-step-circle" },
                                        React.createElement("i", { className: "far fa-check" })),
                                    "Private Profile Completed")),
                            React.createElement("li", null,
                                React.createElement("p", null,
                                    React.createElement("span", { className: "form-step-circle" },
                                        React.createElement("i", { className: "far fa-check" })),
                                    "Public Profile Completed")),
                            React.createElement("li", null,
                                React.createElement("p", null,
                                    React.createElement("span", { className: "form-step-circle" },
                                        React.createElement("i", { className: "far fa-check" })),
                                    "Availability Set")))),
                    React.createElement("button", { className: "theme-btn blue tall", onClick: this.advanceStep }, "Go to your dashboard")))));
    }
}
const actionCreators = {
    updateUserStep: authActions.updateUserStep,
};
export default connect(mapAuthState, actionCreators)(Complete);
