import { debounce } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { authActions } from '../../actions/authActions';
import { displayProfileColor, userHasCompletedRegistration, userHasRole, } from '../../helpers';
import searchService from '../../services/searchService';
import Avatar from './Avatar';
import { Notifications } from './Notifications';
class Header extends React.Component {
    triggerSearch = debounce(() => {
        this.doSearch();
    }, 1000);
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            searchOpen: false,
            appointments: [],
            members: [],
            providers: [],
            families: [],
        };
    }
    componentDidMount = () => {
        //
    };
    onAdminSearchChange = (event) => {
        const { value } = event.target;
        this.setState({
            search: value,
        }, this.triggerSearch);
    };
    doSearch = async () => {
        try {
            const data = {
                search: this.state.search,
            };
            const response = await searchService.search(data);
            if (response.status === 200) {
                const { appointments, members, providers, families } = response.data.data;
                this.setState({
                    appointments: appointments.splice(0, 3),
                    members: members.splice(0, 3),
                    providers: providers.splice(0, 3),
                    families: families.splice(0, 3),
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    onSearchFocus = () => {
        this.setState({
            searchOpen: true,
        });
    };
    onSearchBlur = () => {
        const blur = debounce(() => {
            this.setState({
                searchOpen: false,
            });
        }, 250);
        blur();
    };
    render() {
        const { type, auth, toggleMenu, logout, className, links, coverRequestsRemaining, } = this.props;
        const { balance } = this.props.balance;
        const parsedBalance = Number.parseFloat(balance);
        const { search, appointments, members, providers, families, searchOpen } = this.state;
        const { user } = auth;
        const menuClasses = ['menu'];
        if (!!className) {
            menuClasses.push(className);
        }
        if (user) {
            menuClasses.push(displayProfileColor(user.personal_info.profile_color, 'gradient'));
        }
        const hasResults = searchOpen &&
            (appointments.length > 0 ||
                members.length > 0 ||
                families.length > 0 ||
                providers.length > 0);
        const hasUserAndCompletedRegistration = user
            ? userHasRole(user, 'Admin') ||
                userHasRole(user, 'Super User') ||
                userHasCompletedRegistration(user)
            : false;
        return (React.createElement("header", { id: "app-header", className: hasUserAndCompletedRegistration ? 'logged-in' : 'public' },
            React.createElement("div", { className: "logo" },
                hasUserAndCompletedRegistration && (React.createElement(Link, { to: "/dashboard", "aria-label": "Return to Dashboard" },
                    React.createElement("img", { src: "/assets/images/logo.png" }))),
                !hasUserAndCompletedRegistration && (React.createElement("img", { src: "/assets/images/logo-white.png" }))),
            hasUserAndCompletedRegistration && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "menus" },
                    type === 'admin' && (React.createElement("div", { className: "admin-search-bar" },
                        React.createElement("div", { className: "field-wrapper" },
                            React.createElement("div", { className: "icon" },
                                React.createElement("i", { className: "far fa-search" })),
                            React.createElement("input", { className: `${hasResults ? 'active' : ''}`, type: "text", name: "admin_search", id: "admin_search", value: search, onChange: this.onAdminSearchChange, placeholder: "Search Family, Provider, or Appt ID", onBlur: this.onSearchBlur, onFocus: this.onSearchFocus }),
                            !!searchOpen && (React.createElement("div", { className: "results-wrapper" },
                                appointments.length > 0 && (React.createElement(React.Fragment, null, appointments.map((appointment) => (React.createElement(Link, { className: "result appointment", key: `appt-${appointment.id}`, to: `/appointments/view/${appointment.id}` },
                                    React.createElement("div", { className: "type" },
                                        React.createElement("span", null, "Appt")),
                                    React.createElement("div", { className: "name" },
                                        React.createElement("p", null,
                                            appointment.family.name,
                                            " Family /",
                                            ' ',
                                            moment(appointment.start_time).format('M/D [@] h:mma')))))))),
                                providers.length > 0 && (React.createElement(React.Fragment, null, providers.map((provider) => (React.createElement(Link, { className: "result provider", key: `appt-${provider.id}`, to: `/providers/edit/${provider.id}` },
                                    React.createElement("div", { className: "type" },
                                        React.createElement("span", null, "Provider")),
                                    React.createElement("div", { className: "name" },
                                        React.createElement("p", null,
                                            provider.firstname,
                                            " ",
                                            provider.lastname))))))),
                                members.length > 0 && (React.createElement(React.Fragment, null, members.map((member) => (React.createElement(Link, { className: "result member", key: `appt-${member.id}`, to: `/families/members/edit/${member.id}` },
                                    React.createElement("div", { className: "type" },
                                        React.createElement("span", null, "Member")),
                                    React.createElement("div", { className: "name" },
                                        React.createElement("p", null,
                                            member.firstname,
                                            " ",
                                            member.lastname))))))),
                                families.length > 0 && (React.createElement(React.Fragment, null, families.map((family) => (React.createElement(Link, { className: "result family", key: `appt-${family.id}`, to: `/families/members/${family.id}` },
                                    React.createElement("div", { className: "type" },
                                        React.createElement("span", null, "Family")),
                                    React.createElement("div", { className: "name" },
                                        React.createElement("p", null, family.name)))))))))))),
                    type === 'admin' && (React.createElement("div", { className: "menu-links admin" }, links &&
                        links.map((linkGroup, gIdx) => (React.createElement("div", { className: "menu-link-group", key: `group-${gIdx}` },
                            React.createElement("div", { className: "menu-link-group-title" },
                                React.createElement("h4", { className: "section-label" },
                                    React.createElement(Link, { to: linkGroup.group_link, title: linkGroup.group_link }, linkGroup.group_name))),
                            linkGroup.group_links.length > 0 && (React.createElement("div", { className: "menu-link-group-hover" }, linkGroup.group_links.map((link, lIdx) => (React.createElement("p", { className: "section-label", key: `link-${gIdx}-l-${lIdx}` },
                                React.createElement(Link, { to: link.href, title: link.title }, link.title))))))))))),
                    type === 'family' && (React.createElement("div", { className: "menu-links family" },
                        React.createElement("div", { className: "menu-link-group" },
                            React.createElement("div", { className: "menu-link-group-title" },
                                this.props.location.pathname ===
                                    '/appointments/book' && (React.createElement("div", { className: "theme-btn blue disabled" }, "Book Appointment")),
                                this.props.location.pathname === '/program/book' && (React.createElement("div", { className: "theme-btn blue disabled" }, "Book Appointment")),
                                this.props.location.pathname !== '/appointments/book' &&
                                    this.props.location.pathname !== '/program/book' && (React.createElement(Link, { className: "theme-btn blue", to: "/appointments" }, "Book Appointment")))))),
                    React.createElement(Notifications, null),
                    React.createElement("button", { onClick: (e) => {
                            e.preventDefault();
                            toggleMenu();
                        }, className: "menu-opener plain-link" },
                        React.createElement(Avatar, { imageSrc: user.personal_info.avatar_filename, profileColor: user.personal_info.profile_color }),
                        React.createElement("div", { className: "welcome" },
                            React.createElement("h2", null,
                                React.createElement("span", null, "Welcome,"),
                                React.createElement("br", null),
                                React.createElement("span", { className: "name" }, user.personal_info.firstname)),
                            type === 'family' && parsedBalance !== 0 && (React.createElement("p", { className: `${parsedBalance > 0 ? 'credit' : 'balance'}` },
                                parsedBalance < 0 ? '-' : '',
                                "$",
                                Math.abs(parsedBalance),
                                "\u00A0",
                                parsedBalance > 0 ? 'credit' : 'balance')),
                            type === 'sitter' &&
                                typeof coverRequestsRemaining === 'number' && (React.createElement("p", { className: "balance" }, `${coverRequestsRemaining} cover${coverRequestsRemaining > 1 ? 's' : ''}`))),
                        React.createElement("div", { className: "hamburger-wrapper" },
                            React.createElement("div", { className: "hamburger" },
                                React.createElement("svg", { width: "18", height: "15", viewBox: "0 0 18 15", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                                    React.createElement("rect", { width: "18", height: "3", rx: "1.5", fill: "#1B5687" }),
                                    React.createElement("rect", { y: "6", width: "14.4", height: "3", rx: "1.5", fill: "#1B5687" }),
                                    React.createElement("rect", { y: "12", width: "15.84", height: "3", rx: "1.5", fill: "#1B5687" })))))),
                React.createElement("div", { className: menuClasses.join(' ') },
                    React.createElement("div", { className: "close-menu" },
                        React.createElement("button", { type: "button", onClick: toggleMenu, className: "plain-link", "aria-label": "Close Menu" },
                            React.createElement("i", { className: "fas fa-arrow-left" }))),
                    React.createElement("div", { className: "menu-content" },
                        React.createElement(Avatar, { imageSrc: user.personal_info.avatar_filename, profileColor: user.personal_info.profile_color }),
                        React.createElement("h3", null, user.personal_info.firstname),
                        !!links &&
                            links.map((linkGroup, gIdx) => (React.createElement("div", { className: "link-group", key: `group-${gIdx}` },
                                !!linkGroup.group_name && (React.createElement("h4", null, linkGroup.group_name)),
                                linkGroup.group_links.map((link, lIdx) => (React.createElement("p", { key: `link-${gIdx}-l-${lIdx}` },
                                    !!link.external && (React.createElement("a", { href: link.href, target: "_blank", rel: "noreferrer" }, link.title)),
                                    !link.external && (React.createElement(Link, { to: link.href, title: link.title }, link.title)))))))),
                        React.createElement("div", { className: "logout" },
                            React.createElement("button", { className: "theme-btn black", onClick: logout }, "Logout"))))))));
    }
}
function mapState(state) {
    return {
        auth: state.auth,
        balance: state.balance,
    };
}
const actionCreators = {
    logout: authActions.logout,
};
export default withRouter(connect(mapState, actionCreators)(Header));
