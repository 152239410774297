import Joi from 'joi';
import React from 'react';
import { profileCareContactService } from '../../services';
import Form from '../shared/Form';
const freshSchema = {
    avatar_filename: '',
    firstname: '',
    lastname: '',
    phone1: '',
    phone2: '',
    relationship: '',
    authorized_contact: 0,
    profile_color: 'primary-coral',
};
class CareContactForm extends Form {
    schema = Joi.object({
        avatar_filename: Joi.alternatives(Joi.string(), Joi.object())
            .optional()
            .allow(null)
            .allow('')
            .label('Avatar'),
        profile_color: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Profile Color'),
        firstname: Joi.string().required().label('First Name'),
        lastname: Joi.string().required().label('Last Name'),
        relationship: Joi.string().required().label('Relationship'),
        phone1: Joi.number().required().label('Mobile Phone'),
        phone2: Joi.number().required().label('Secondary Phone (Work)'),
        authorized_contact: Joi.boolean()
            .truthy(1)
            .falsy(0)
            .label('Authorized contact'),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            creating: false,
            data: { ...freshSchema },
            freshSchema,
            allowDelete: false,
            family_id: null,
        };
    }
    submitFormData = async () => {
        const apiData = { ...this.state.data };
        const { family_id, creating, id } = this.state;
        apiData.family_id = family_id;
        const data = new FormData();
        const headers = { headers: { 'content-type': 'multipart/form-data' } };
        data.append('_method', 'post');
        for (const key of Object.keys(apiData)) {
            if (typeof apiData[key] === 'object') {
                if (key === 'avatar_filename') {
                    if (apiData[key] !== null) {
                        data.append(key, apiData[key]);
                    }
                }
                else {
                    data.append(key, JSON.stringify(apiData[key]));
                }
            }
            else {
                data.append(key, apiData[key]);
            }
        }
        return creating
            ? profileCareContactService.store(data, headers)
            : profileCareContactService.update(id, data, headers);
    };
    renderForm() {
        return (React.createElement("div", { className: "form-grid" },
            this.renderInput('firstname', 'First Name', 'text', true),
            this.renderInput('lastname', 'Last Name', 'text', true),
            this.renderInput('phone1', 'Mobile Phone', 'number', true),
            this.renderInput('phone2', 'Secondary Phone (Work)', 'number', true),
            this.renderInput('relationship', 'Relationship', 'text', true),
            this.renderBoolean('authorized_contact', 'Add contact as approved to request information on recipients of care.')));
    }
}
export default CareContactForm;
