import { find, findIndex } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { booleanRadioOptions, wiggleOptions } from '../../../../data';
import { makeTimeIncrements } from '../../../../helpers';
import Form from '../../../shared/Form';
import NumNights from '../../../shared/Form/NumNights';
import BookingTimesRow from './BookingTimesRow';
import Joi from 'joi';

class BookingTimes extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        start_time: '09:00',
        end_time: '12:00',
        flex: 0,
        flex_room: null,
        overnight: 0,
        num_nights: 0,
      },
      showWiggleTime: false,
      showOvernight: false,
      currentAppointment: null,
      times: makeTimeIncrements(0),
      startTimes: makeTimeIncrements(0, 21),
      endTimes: makeTimeIncrements(0, 21),
      errors: {},
    };
  }
  schema = Joi.object({
    start_time: Joi.string().required().label('Start Time'),
    end_time: Joi.string().required().label('End Time'),
    flex: Joi.boolean().truthy(1).falsy(0).required().label('Flex'),
    flex_room: Joi.string()
      .when('flex', {
        is: 1,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      })
      .label('Flex Time'),
    overnight: Joi.boolean().truthy(1).falsy(0).required().label('Overnight'),
    num_nights: Joi.string()
      .when('overnight', {
        is: 1,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      })
      .label('Nights'),
  });
  componentDidMount() {
    const { data } = this.state;
    let newEndTimes = this.createNewEndTimes(data['start_time']);
    data['end_time'] = newEndTimes[0].value;
    this.setState({
      endTimes: newEndTimes,
      data,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const prevData = prevState.data;
    const data = { ...this.state.data };
    const { sameTimes } = this.props;

    if (
      data['start_time'] !== null &&
      data['start_time'] !== prevData['start_time'] &&
      !!sameTimes
    ) {
      // If start_time has changed, AND sameTimes is set, we need to broadcast this.
      const { start_time } = data;
      this.props.setSameProp('start_time', start_time);
    }

    if (
      data['end_time'] !== null &&
      data['end_time'] !== prevData['end_time'] &&
      !!sameTimes
    ) {
      // Same here with end_time
      const { end_time } = data;
      this.props.setSameProp('end_time', end_time);
    }

    if (
      data['overnight'] !== null &&
      data['overnight'] !== prevData['overnight'] &&
      !!sameTimes
    ) {
      // Same here with overnight
      const { overnight } = data;
      let newEndTimes = this.createNewEndTimes(data['start_time'], overnight);
      let timeStillExists = find(newEndTimes, (time) => {
        return time.value == data['end_time'];
      });
      if (!timeStillExists) {
        data['end_time'] = newEndTimes[0].value;
      }
      (data['num_nights'] = data['overnight'] ? 1 : null),
        this.setState(
          {
            endTimes: newEndTimes,
            data,
          },
          () => {
            this.props.setBulkSameProp({
              overnight: data['overnight'],
              num_nights: data['overnight'] ? 1 : null,
              end_time: data['end_time'],
            });
          }
        );
    }

    if (
      data['num_nights'] !== null &&
      data['num_nights'] !== prevData['num_nights'] &&
      !!sameTimes
    ) {
      // And here with num_nights
      const { num_nights } = data;
      this.props.setSameProp('num_nights', num_nights, 'number');
    }

    if (
      data['flex'] !== null &&
      data['flex'] !== prevData['flex'] &&
      !!sameTimes
    ) {
      // And here with flex
      const { flex } = data;
      this.props.setSameProp('flex', flex);
    }

    if (
      data['flex_room'] !== null &&
      data['flex_room'] !== prevData['flex_room'] &&
      !!sameTimes
    ) {
      // And here with flex_room
      const { flex_room } = data;
      this.props.setSameProp('flex_room', flex_room, 'number');
    }

    if (
      data['start_time'] !== null &&
      prevData['start_time'] !== data['start_time']
    ) {
      let newEndTimes = this.createNewEndTimes(
        data['start_time'],
        data['overnight']
      );
      let timeStillExists = find(newEndTimes, (time) => {
        return time.value == data['end_time'];
      });
      if (!timeStillExists) {
        data['end_time'] = newEndTimes[0].value;
      }
      this.setState(
        {
          endTimes: newEndTimes,
          data,
        },
        () => {
          this.props.setSameProp('end_time', data['end_time']);
        }
      );
    }

    if (data['flex'] === 1 && prevData['flex'] !== 1) {
      const { data } = this.state;
      data['flex_room'] = `${wiggleOptions[0].id}`; // num to string conversion hack
      this.setState(
        {
          showWiggleTime: true,
          data,
        },
        () => {
          if (!!sameTimes)
            this.props.setSameProp(
              'flex_room',
              `${wiggleOptions[0].id}`,
              'number'
            );
        }
      );
    } else if (data['flex'] === 0 && prevData['flex'] !== 0) {
      const { data } = this.state;
      data['flex_room'] = null;
      this.setState(
        {
          showWiggleTime: false,
          data,
        },
        () => {
          if (!!sameTimes) this.props.setSameProp('flex_room', null);
        }
      );
    }

    if (data['overnight'] === 1 && prevData['overnight'] !== 1) {
      const { data } = this.state;
      data['num_nights'] = 1; // num to string conversion hack
      this.setState({
        showOvernight: true,
        data,
      });
    } else if (data['overnight'] === 0 && prevData['overnight'] !== 0) {
      const { data } = this.state;
      data['num_nights'] = null;
      this.setState({
        showOvernight: false,
        data,
      });
    }
  }
  createNewEndTimes = (start_time, overnight) => {
    let newEndTimes = [...this.state.times];
    newEndTimes.splice(0, 1);
    if (!overnight) {
      const idx = findIndex(newEndTimes, (time) => {
        return time.value == start_time;
      });
      const length = idx + 12;
      if (length === newEndTimes.length) {
        // if they select the very last time slot (11:45pm), then there are 0 options left
        // So we force there to be only 1 item
        newEndTimes.splice(0, length - 1);
      } else {
        newEndTimes.splice(0, length);
      }
    }
    return newEndTimes;
  };
  setCurrentAppointment = (idx) => {
    this.setState({
      currentAppointment: idx,
    });
  };
  handleNumNightsChange = (key, value) => {
    const data = { ...this.state.data };
    data[key] = value;
    this.setState({ data });
  };
  render() {
    const {
      data,
      times,
      startTimes,
      endTimes,
      showWiggleTime,
      showOvernight,
      currentAppointment,
      errors,
    } = this.state;
    const { sameTimes } = this.props;
    const appointments = [...this.props.appointments];
    const endTimeDisabled = !this.state.data.start_time;
    const canAdvanceStep = this.props.isFormDone();
    return (
      <div className="booking-form times">
        <div className="booking-template">
          <div className={`form-field radio checkbox same_times`}>
            <label className="" htmlFor="same_times">
              Would you like to set all appointments with the same Start and End
              Time Options?
              <span className="required">&nbsp;*</span>
            </label>
            {booleanRadioOptions.map((option, idx) => (
              <div className="radio-group" key={`same-times-opt-${idx}`}>
                <input
                  value={option.value}
                  name="same_times"
                  id={`same-times-${idx}`}
                  type="radio"
                  onChange={() => {
                    this.props.toggleSameTimes(option.value);
                  }}
                  className={sameTimes === option.value ? 'active' : 'inactive'}
                />
                <label className="" htmlFor={`same-times-${idx}`}>
                  <span>
                    <div className="radio"></div>
                    {option.label}
                  </span>
                </label>
              </div>
            ))}
          </div>
          <hr />
          {!!sameTimes && (
            <div className="booking-template-details">
              <p>
                <b>All times will be set to the following:</b>
              </p>
              <div className="form-group times">
                {this.renderSelect(
                  'start_time',
                  'Start Time',
                  startTimes,
                  true
                )}
                {this.renderSelect('end_time', 'End Time', endTimes, true, {
                  disabled: endTimeDisabled,
                })}
              </div>
              <div className="form-group flex">
                {this.renderRadio(
                  'flex',
                  'Wiggle Room:',
                  booleanRadioOptions,
                  true
                )}
                {!!showWiggleTime && (
                  <React.Fragment>
                    {this.renderSelect(
                      'flex_room',
                      'Time',
                      wiggleOptions,
                      true
                    )}
                  </React.Fragment>
                )}
              </div>
              <div className="form-group overnight">
                {this.renderRadio(
                  'overnight',
                  'Overnight:',
                  booleanRadioOptions,
                  true
                )}
                {!!showOvernight && (
                  <NumNights
                    className="num_nights"
                    name={`num_nights`}
                    label="Nights:"
                    value={data['num_nights']}
                    onChange={this.handleNumNightsChange}
                    required={true}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div className="booking-info-wrapper">
          {appointments.map((appointment, idx) => (
            <div
              className="booking-info"
              key={`appt-${appointment.rid}`}
              onClick={() => {
                this.setCurrentAppointment(idx);
              }}
            >
              {!!appointment.overnight && !!appointment.num_nights && (
                <div className="date">
                  <h3>
                    {moment(appointment.date).format('MMM D')}
                    &nbsp;-&nbsp;
                    <span>
                      {moment(appointment.date)
                        .add(appointment.num_nights, 'days')
                        .format('MMM D')}
                    </span>
                  </h3>
                  <h3 className="weekday">
                    {moment(appointment.date).format('ddd')}
                    &nbsp;-&nbsp;
                    <span>
                      {moment(appointment.date)
                        .add(appointment.num_nights, 'days')
                        .format('ddd')}
                    </span>
                  </h3>
                </div>
              )}
              {(!appointment.overnight || !appointment.num_nights) && (
                <div className="date">
                  <h3>{moment(appointment.date).format('MMM D')}</h3>
                  <h3 className="weekday">
                    {moment(appointment.date).format('dddd')}
                  </h3>
                </div>
              )}
              <div
                className="remove"
                onClick={() => {
                  this.props.removeAppointment(idx);
                }}
              >
                <button className="theme-btn small error-red">
                  <i className="fas fa-trash-alt"></i>
                  &nbsp;Remove
                </button>
              </div>
              <BookingTimesRow
                key={`appt-${idx}`}
                idx={idx}
                appointment={appointment}
                handleApptChange={this.props.handleApptChange}
                handleBulkApptChange={this.props.handleBulkApptChange}
              />
            </div>
          ))}
        </div>
        <div className="booking-footer">
          {canAdvanceStep && (
            <button
              className={`theme-btn blue`}
              onClick={this.props.onFormComplete}
            >
              Next Step - Add Details
            </button>
          )}
          {!canAdvanceStep && (
            <button
              className={`theme-btn blue disabled`}
              onClick={(e) => e.preventDefault()}
            >
              Next Step - Add Details
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default BookingTimes;
