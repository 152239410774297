import Joi from 'joi';
import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { publicSiteUrl } from '../../../config';
import { joiWordCountHelper } from '../../../helpers';
import { mapAuthState } from '../../../helpers/mapAuthState';
import { sitterService } from '../../../services';
import httpService from '../../../services/httpService';
import { FormStepTracker } from '../../shared';
import Form from '../../shared/Form';
import trackerSteps from './trackerSteps';
const AvatarDisplayComponent = ({ value, }) => {
    return (React.createElement("div", { className: "file-field-instructions" },
        React.createElement("div", { className: "avatar" },
            React.createElement("div", { className: "avatar-wrapper" },
                value && React.createElement("img", { src: value }),
                !value && React.createElement("i", { className: "far fa-camera" }))),
        React.createElement("div", { className: "instructions" },
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement("p", null, "1. Make sure the photo is recent")),
                React.createElement("li", null,
                    React.createElement("p", null, "2. Make sure the photo only includes yourself")),
                React.createElement("li", null,
                    React.createElement("p", null, "3. Make sure the lighting and quality are good"))))));
};
class PublicProfile extends Form {
    schema = Joi.object({
        avatar_filename: Joi.alternatives(Joi.string(), Joi.object())
            .required()
            .label('Avatar'),
        intro: Joi.string()
            .custom((value, helpers) => {
            return joiWordCountHelper(value, helpers, 50);
        }, 'custom validation')
            .required()
            .messages({
            'length.exceeds': 'Your Intro cannot be more than 50 words.',
        })
            .label('Intro'),
        bio: Joi.string()
            .custom((value, helpers) => {
            return joiWordCountHelper(value, helpers, 200);
        }, 'custom validation')
            .required()
            .messages({
            'length.exceeds': 'Your Bio cannot be more than 200 words.',
        })
            .label('Bio'),
        vehicle_year: Joi.string().required().label('Vehicle Year'),
        vehicle_make: Joi.string().required().label('Vehicle Make'),
        vehicle_model: Joi.string().required().label('Vehicle Model'),
        vehicle_seatbelts: Joi.number().required().label('Vehicle Seatbelts'),
        selected_recipient_needs: Joi.array()
            .required()
            .min(1)
            .messages({
            'array.min': 'You must select at least 1 companion need',
        })
            .label('Selected Activities'),
        accepted_terms: Joi.number()
            .integer()
            .min(1)
            .max(1)
            .required()
            .messages({
            'number.greater': 'You must accept the terms to continue.',
            'number.lesser': 'You must accept the terms to continue.',
            'number.min': 'You must accept the terms to continue.',
            'number.max': 'You must accept the terms to continue.',
        })
            .label('Accepted Terms'),
    });
    state = {
        data: {
            avatar_filename: '',
            intro: '',
            bio: '',
            selected_recipient_needs: [],
            vehicle_make: '',
            vehicle_model: '',
            vehicle_year: '',
            vehicle_seatbelts: '',
            accepted_terms: 0,
        },
        submitting: false,
        careRecipientNeeds: [],
        errors: {},
        user: null,
        loadingData: true,
    };
    componentDidMount() {
        const { user } = this.props.auth;
        if (!user) {
            this.sendUserBack();
        }
        else {
            this.getCareRecipientNeeds();
            this.getSitterProfile(user.personal_info.id);
        }
    }
    getCareRecipientNeeds = async () => {
        try {
            const response = await httpService.get('/api/careRecipientNeeds');
            if (response.status === 200) {
                const { careRecipientNeeds } = response.data.data;
                this.setState({
                    careRecipientNeeds: careRecipientNeeds.map((item) => ({
                        value: item.id,
                        label: item.name,
                    })),
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    getSitterProfile = async (id) => {
        try {
            const response = await sitterService.get(id);
            if (response.status === 200) {
                const { data: { profileSitter }, } = response.data;
                this.setState((prevState) => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        vehicle_make: profileSitter.vehicle_make,
                        vehicle_model: profileSitter.vehicle_model,
                        vehicle_year: profileSitter.vehicle_year,
                        vehicle_seatbelts: profileSitter.vehicle_seatbelts,
                    },
                    loadingData: false,
                }));
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    sendUserBack = () => {
        alert('You must sign up or log in to view this page.');
        window.open('/', '_self');
    };
    advanceStep = async (event) => {
        if (!!event) {
            event.preventDefault();
        }
        try {
            const { id } = this.props.auth.user;
            await this.props.updateUserStep('care-provider-public-profile', id);
        }
        catch (error) {
            console.error(error);
        }
    };
    doSubmit = async (callback) => {
        try {
            const apiData = { ...this.state.data };
            const { id } = this.props.auth.user.personal_info;
            const data = new FormData();
            const headers = {
                headers: { 'content-type': 'multipart/form-data' },
            };
            data.append('_method', 'post');
            for (const key of Object.keys(apiData)) {
                if (typeof apiData[key] === 'object') {
                    if (key === 'avatar_filename') {
                        data.append(key, apiData[key]);
                    }
                    else {
                        data.append(key, JSON.stringify(apiData[key]));
                    }
                }
                else {
                    data.append(key, apiData[key]);
                }
            }
            const response = await this.props.updateUser(`/profileSitter/update/${id}`, data, headers);
            if (response.status === 200) {
                this.advanceStep();
            }
            else {
                callback();
            }
        }
        catch (error) {
            console.error(error);
            callback();
        }
    };
    render() {
        const { data, errors, careRecipientNeeds, loadingData } = this.state;
        return (React.createElement("div", { className: "registration sitter step-5 public-profile" },
            React.createElement("div", { className: "form" },
                React.createElement("div", { className: "inner" },
                    React.createElement(FormStepTracker, { steps: trackerSteps, currentStep: 2 }),
                    React.createElement("div", { className: "form-notice" },
                        React.createElement("h3", null, "Important Note"),
                        React.createElement("p", null, "All information collected in this section will be made publicly available to the care recipients. Therefore, make your best effort to provide accurate and clear communications. Please pay attention to spelling and promote yourself positively in our community.")),
                    React.createElement("div", { className: "form-header" },
                        React.createElement("h2", null, "Public Profile")),
                    loadingData && 'Loading form...',
                    !loadingData && (React.createElement("form", { onSubmit: this.handleSubmit },
                        React.createElement("div", { className: "form-group justify-space-between" },
                            this.renderFile('avatar_filename', 'Upload Your Public Profile Image', 'image/jpg, image/jpeg, image/png', true, AvatarDisplayComponent),
                            this.renderInput('intro', 'Introduce yourself to our companions.', 'text', true, { wordCount: 50 }),
                            this.renderTextarea('bio', 'Write a short bio about yourself and your companion care history.', true, { wordCount: 200 }),
                            this.renderCheckbox('selected_recipient_needs', 'Which companion needs are you comfortable with? *', careRecipientNeeds),
                            React.createElement("hr", { className: "divider" }),
                            this.renderInput('vehicle_year', 'Vehicle Year', 'number', true),
                            this.renderInput('vehicle_make', 'Vehicle Make', 'text', true),
                            this.renderInput('vehicle_model', 'Vehicle Model', 'text', true),
                            this.renderInput('vehicle_seatbelts', 'Number of back seats with seatbelts', 'number', true),
                            React.createElement("hr", { className: "divider" }),
                            React.createElement("div", { className: "form-field boolean checkbox accepted_terms" },
                                React.createElement("span", null,
                                    React.createElement("input", { name: "accepted_terms", id: "accepted_terms", type: "checkbox", checked: data.accepted_terms > 0, className: "checkbox", onChange: this.handleBooleanChange }),
                                    React.createElement("label", { htmlFor: "accepted_terms" },
                                        React.createElement("div", { className: "checkbox" }, !!data.accepted_terms && (React.createElement("i", { className: "far fa-check" }))),
                                        "By completing your application and profile registration you are agreeing to STL Care",
                                        ' ',
                                        React.createElement("a", { href: `${publicSiteUrl}/terms-of-use-for-providers`, target: "_blank", rel: "noreferrer" }, "Terms of Service"),
                                        ' ',
                                        "and",
                                        ' ',
                                        React.createElement("a", { href: `${publicSiteUrl}/privacy-policy`, target: "_blank", rel: "noreferrer" }, "Privacy Policy"),
                                        ".",
                                        React.createElement("span", { className: "required" }, "\u00A0*"))),
                                !!errors.accepted_terms && (React.createElement("span", { className: "error", role: "alert" },
                                    React.createElement("strong", null, errors.accepted_terms))))),
                        this.renderButton('Next', 'Submitting...', 'theme-btn blue')))))));
    }
}
const actionCreators = {
    updateUserStep: authActions.updateUserStep,
    updateUser: authActions.updateUser,
};
export default connect(mapAuthState, actionCreators)(PublicProfile);
