import React from 'react';
import { connect } from 'react-redux';
import { additionalServicesOptions } from '../../../data';
import { mapAuthState } from '../../../helpers/mapAuthState';
import ActivitiesSelector from '../../shared/Form/ActivitiesSelector';
import Checkbox from '../../shared/Form/Checkbox';
import Input from '../../shared/Form/Input';
import Textarea from '../../shared/Form/Textarea';
class BulkDetails extends React.Component {
    state = {
        showAddOwnService: false,
    };
    componentDidUpdate(prevProps) {
        const { data } = this.props;
        const prevData = prevProps.data;
        if (data.program_additional_services.length !==
            prevData.program_additional_services.length) {
            if (data.program_additional_services.includes('1000') &&
                !this.state.showAddOwnService) {
                this.setState({ showAddOwnService: true });
            }
            else if (data.program_additional_services.includes('1000') === false &&
                this.state.showAddOwnService) {
                this.setState({ showAddOwnService: false });
            }
        }
    }
    handleCheckboxChange = ({ currentTarget: input, }) => {
        const data = { ...this.props.data };
        const name = input.name;
        let list = [...data[name]];
        if (input.checked) {
            // add to array
            if (list === undefined) {
                list = [input.value];
            }
            else {
                list.push(input.value);
            }
        }
        else {
            // remove from array
            list = list.filter(function (item) {
                return item !== input.value;
            });
        }
        data[name] = list;
        this.props.handleChange(name, list);
    };
    canAdvanceStep = () => {
        const { data } = this.props;
        if (data.program_additional_services.includes('1000') &&
            !data.program_additional_services_notes) {
            return false;
        }
        return true;
    };
    render() {
        const { data, errors, careRecipientNeeds, onFormComplete } = this.props;
        const { showAddOwnService } = this.state;
        const canAdvanceStep = this.canAdvanceStep();
        return (React.createElement("div", { className: "bulk-booking details" },
            React.createElement("div", { className: "bulk-notice blue" },
                React.createElement("p", null, "Tell us more! Please include any additional information about your household and your request. This will help us curate a proposal that meets your expectations.")),
            React.createElement("div", { className: "details-form" },
                React.createElement("div", { className: "details-form-row" },
                    React.createElement(Textarea, { className: "program_notes", name: "program_notes", label: 'Any notes about your bulk booking request', value: data.program_notes, error: errors.program_notes, onChange: (e) => {
                            this.props.handleChange('program_notes', e.target.value);
                        }, required: true })),
                React.createElement("div", { className: "details-form-row" },
                    React.createElement("p", null, "If you don't have a specific provider(s) you would like us to reach out to for your request, please select or enter any attributes, education, or experience that would be beneficial for your potential match. Please note: Every STL Care Provider is interviewed and trained in house; background & driving record checked; insured and licensed to drive; and CPR certified!"),
                    React.createElement(Input, { className: "program_requested_sitter", name: "program_requested_sitter", label: "Add Provider to Request", value: data.program_requested_sitter, error: errors.program_requested_sitter, type: "text", onChange: (e) => {
                            this.props.handleChange('program_requested_sitter', e.target.value);
                        }, required: false })),
                React.createElement("div", { className: "details-form-row" },
                    React.createElement(Checkbox, { className: "program_additional_services", name: "program_additional_services", label: "Select as many additional services (or none at all) that you would like included in your bulk booking request.", value: data.program_additional_services, error: errors.program_additional_services, options: additionalServicesOptions, onChange: this.handleCheckboxChange, required: false }),
                    showAddOwnService && (React.createElement("div", { className: "additional-services-notes" },
                        React.createElement(Textarea, { className: "program_additional_services_notes", name: "program_additional_services_notes", label: "Additional Details About Potential Provider", value: data.program_additional_services_notes, error: errors.program_additional_services_notes, onChange: (e) => {
                                this.props.handleChange('program_additional_services_notes', e.target.value);
                            }, required: true })))),
                React.createElement("div", { className: "details-form-row" },
                    React.createElement(ActivitiesSelector, { className: "program_selected_activities", name: "program_selected_recipient_needs", label: "Select as many care need requirements you have to help our team find your perfect match!", options: careRecipientNeeds, value: data.program_selected_recipient_needs, error: errors.program_selected_recipient_needs, onChange: this.handleCheckboxChange, required: false }))),
            React.createElement("div", { className: "booking-footer" },
                canAdvanceStep && (React.createElement("button", { className: "theme-btn blue", onClick: onFormComplete }, "Next")),
                !canAdvanceStep && (React.createElement("button", { className: "theme-btn blue disabled", onClick: (e) => e.preventDefault() }, "Next")))));
    }
}
export default connect(mapAuthState)(BulkDetails);
