import React from 'react';
import { connect } from 'react-redux';
import { mapAuthState } from '../../helpers/mapAuthState';
import { AppointmentListing } from '../shared';

class OpenCoverRequests extends React.Component {
  render() {
    const { 
      windowWidth, 
      appointmentStatus, 
      changeAppointmentStatus,
      coverRequestAppointments
    } = this.props;

    return (
      <main className="dashboard sitter cover-requests-listing">
        <div className="list-view">
          <div className="cover-requests-title">
            <h3>Available Casual Care Appointments</h3>
            <p>
              The appointments below are still searching for a provider OR a
              confirmed provider looking for a sub. By signing up for any of the
              below dates, you are volunteering to cover the appointment. You
              will not be confirmed for the sit until a manager reviews your
              sign-up. You will receive notice if your sign-up has been
              approved, and the appointment will be auto-confirmed in your
              account.
            </p>
          </div>
          <AppointmentListing
            appointments={coverRequestAppointments}
            type="sitter"
            appointmentStatus={appointmentStatus}
            changeAppointmentStatus={changeAppointmentStatus}
            windowWidth={windowWidth}
          />
        </div>
      </main>
    );
  }
}

export default connect(mapAuthState)(OpenCoverRequests);
