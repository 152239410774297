import Joi from 'joi';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { displayAddress, displayVehicleInfo, findLinkGroupByTitle, } from '../../../helpers';
import sitterService from '../../../services/sitterService';
import { Form } from '../../shared';
import Sidebar from '../Sidebar';
class ApplicationAcceptanceDetail extends Form {
    schema = Joi.object({
        admin_notes: Joi.string()
            .optional()
            .allow('')
            .allow(null)
            .label('Admin Notes'),
    });
    constructor(props) {
        super(props);
        this.state = {
            data: {
                admin_notes: '',
            },
            errors: {},
            languages: [],
            selectedRecipientNeeds: [],
            medicalInfo: [],
            profileSitter: {},
            personalInfo: {},
            user: {},
            loading: true,
            linkGroup: findLinkGroupByTitle('Providers'),
            accept: false,
            submitting: false,
        };
    }
    componentDidMount = () => {
        this.getSitter();
    };
    getSitter = async () => {
        try {
            const { id } = this.props.match.params;
            const response = await sitterService.get(id);
            if (response.status === 200) {
                const { languages, personalInfo, profileSitter, user, selectedRecipientNeeds, medicalInfo, } = response.data.data;
                const { admin_notes } = profileSitter;
                this.setState({
                    languages,
                    personalInfo,
                    profileSitter,
                    user,
                    selectedRecipientNeeds,
                    medicalInfo,
                    loading: false,
                    data: {
                        admin_notes,
                    },
                });
            }
        }
        catch (error) {
            console.error(error);
            this.setState({
                loading: false,
            });
        }
    };
    displayArray = (arrayName, field) => {
        const data = this.state[arrayName];
        const items = data.map((item) => item[field]);
        return items.join(', ');
    };
    doSubmit = async (callback) => {
        try {
            const apiData = { ...this.state.data };
            const { accept, personalInfo, user } = this.state;
            apiData.accept = accept;
            const { id } = personalInfo;
            const { step } = user;
            const response = await sitterService.updateApplicationStatus(id, apiData, step.slug);
            if (response.status === 200) {
                const { profileSitter, user } = response.data.data;
                const { admin_notes } = profileSitter;
                this.setState({
                    profileSitter,
                    user,
                    data: {
                        admin_notes,
                    },
                });
                if (accept) {
                    toast.success('Hooray! The Provider has been updated!');
                }
                else {
                    toast.success("The Provider's application has been rejected.");
                }
            }
            callback();
        }
        catch (error) {
            console.error(error);
            callback();
        }
    };
    saveSitter = async () => {
        try {
            const { id } = this.state.personalInfo;
            const apiData = { ...this.state.data };
            const response = await sitterService.update(id, apiData);
            if (response.status === 200) {
                toast.success('Hooray! The Provider has been updated!');
            }
        }
        catch (error) {
            console.error(error);
            toast.error('Uh oh! Something went wrong!');
        }
    };
    handleAcceptSubmit = () => {
        this.setState({
            accept: true,
        }, () => this.handleSubmit());
    };
    handleRejectSubmit = () => {
        this.setState({
            accept: false,
        }, () => this.handleSubmit());
    };
    render() {
        const { props, state, handleSubmit, handleAcceptSubmit, saveSitter, handleRejectSubmit, } = this;
        const { loading, accept, personalInfo, profileSitter, user, languages, linkGroup, submitting, medicalInfo, selectedRecipientNeeds, } = state;
        if (loading) {
            return (React.createElement("main", { className: "admin admin-detail with-sidebar providers application-acceptance" },
                React.createElement("div", { className: "sidebar-wrapper" },
                    React.createElement(Sidebar, { ...props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
                React.createElement("div", { className: "content" },
                    React.createElement("h3", null, "Loading Provider Details..."))));
        }
        return (React.createElement("main", { className: "admin admin-detail with-sidebar providers application-acceptance" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content" },
                React.createElement("h3", null,
                    "Provider: ",
                    personalInfo.firstname,
                    "\u00A0",
                    personalInfo.lastname),
                React.createElement("div", { className: "detail-table" },
                    React.createElement("h4", { className: "section-label" }, "Details"),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Email")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, user.email))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Date of Birth")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, personalInfo.DOB))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Gender")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, personalInfo.gender))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Have Children?")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, !!profileSitter.ownChildren ? 'Yes' : 'No'))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Mobile Number")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, personalInfo.phone1))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Address")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, displayAddress(personalInfo)))),
                    React.createElement("h4", { className: "section-label" }, "Transportation"),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Have Vehicle?")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, !!profileSitter.vehicle ? 'Yes' : 'No'))),
                    !!profileSitter.vehicle && (React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Vehicle Info")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, displayVehicleInfo(profileSitter))))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Valid License?")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, !!profileSitter.valid_drivers_license ? 'Yes' : 'No'))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Willing to transport children?")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, !!profileSitter.willing_transport_children ? 'Yes' : 'No'))),
                    React.createElement("h4", { className: "section-label" }, "Education"),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "High School attended")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.high_school_attended))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Currently enrolled in college?")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, !!profileSitter.college ? 'Yes' : 'No'))),
                    !!profileSitter.college && (React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "College Name")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.college_name)))),
                    !!profileSitter.college && (React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "College Student Status")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.college_status)))),
                    !!profileSitter.college && (React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Field of Study")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.college_field)))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "First Aid certified?")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, !!profileSitter.first_aid_certified ? 'Yes' : 'No'))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "CPR certified?")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, !!profileSitter.cpr_certified ? 'Yes' : 'No'))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Languages")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, languages.length > 0
                                ? this.displayArray('languages', 'language')
                                : 'None'))),
                    React.createElement("h4", { className: "section-label" }, "Background"),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "How did they hear about us?")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.hear_about_us))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "How long have they lived in the area?")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.area_length_time))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Recent backround check")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, !!profileSitter.recent_background_check ? 'Yes' : 'No'))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Recent arrest")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, !!profileSitter.arrested ? 'Yes' : 'No'))),
                    !!profileSitter.arrested && (React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Arrest Info")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.arrested_info)))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Convicted of felony / misdemeanor?")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, !!profileSitter.felony_misdemeanor ? 'Yes' : 'No'))),
                    !!profileSitter.felony_misdemeanor && (React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Felony / Misdemeanor Info")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.felony_misdemeanor_info)))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Conviced of DUI?")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, !!profileSitter.dui ? 'Yes' : 'No'))),
                    !!profileSitter.dui && (React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "DUI Info")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.dui_info)))),
                    React.createElement("h4", { className: "section-label" }, "Employment Info"),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Employed?")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, !!personalInfo.employed ? 'Yes' : 'No'))),
                    !!personalInfo.employed && (React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Employer Name")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, personalInfo.employer)))),
                    !!personalInfo.employed && (React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Employment Status")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, personalInfo.employment_status)))),
                    !!personalInfo.employed && (React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Employment Notes")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, personalInfo.employment_notes)))),
                    React.createElement("h4", { className: "section-label" }, "Final Thoughts"),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Why they want to work for us")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.why_work))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Other Notes")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.other_info))),
                    React.createElement("h4", { className: "section-label" }, "Private Profile"),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Emergency Contact Name")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.emergency_contact_name))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Emergency Contact Phone")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.emergency_contact_phone))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Marital Status")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.marital_status))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Pets?")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, !!profileSitter.pets ? 'Yes' : 'No'))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Smoke?")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, !!profileSitter.smoke ? 'Yes' : 'No'))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Medical Info")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, medicalInfo.length > 0
                                ? this.displayArray('medicalInfo', 'name')
                                : 'None'))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Medical Notes")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, !!profileSitter.allergy_info
                                ? profileSitter.allergy_info
                                : 'None'))),
                    React.createElement("h4", { className: "section-label" }, "Public Profile"),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Avatar")),
                        React.createElement("div", { className: "table-cell value" },
                            !!personalInfo.avatar_filename && (React.createElement("img", { style: { maxWidth: '200px' }, src: personalInfo.avatar_filename })),
                            !personalInfo.avatar_filename && React.createElement("p", null, "None"))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Intro")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.intro))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Bio")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, profileSitter.bio))),
                    React.createElement("div", { className: "table-row" },
                        React.createElement("div", { className: "table-cell header" },
                            React.createElement("p", null, "Preferred Companion Needs")),
                        React.createElement("div", { className: "table-cell value" },
                            React.createElement("p", null, selectedRecipientNeeds.length > 0
                                ? this.displayArray('selectedRecipientNeeds', 'name')
                                : 'None')))),
                React.createElement("div", { className: "form" },
                    React.createElement("form", { onSubmit: handleSubmit }, this.renderTextarea('admin_notes', 'Admin Notes'))),
                user.step.slug === 3 && (React.createElement("p", null, "This Provider has been sent an interview request. Accepting their application will allow them to complete their profile.")),
                user.step.slug !== 'care-provider-application-finished' &&
                    user.step.slug !== 'care-provider-application-denied' && (React.createElement("div", { className: "actions" },
                    user.step.slug === 'care-provider-application-questions' && (React.createElement("button", { className: "theme-btn blue", disabled: submitting, onClick: handleAcceptSubmit },
                        submitting && accept && (React.createElement("i", { className: "fas fa-spinner fa-spin" })),
                        submitting && accept
                            ? 'Sending...'
                            : 'Send Interview Request')),
                    user.step.slug === 'care-provider-application-pending' && (React.createElement("button", { className: "theme-btn blue", disabled: submitting, onClick: handleAcceptSubmit },
                        submitting && accept && (React.createElement("i", { className: "fas fa-spinner fa-spin" })),
                        submitting && accept
                            ? 'Approving...'
                            : 'Allow Provider to finish Profile')),
                    user.step.slug === 'care-provider-availability' && (React.createElement("button", { className: "theme-btn blue", disabled: submitting, onClick: handleAcceptSubmit },
                        submitting && accept && (React.createElement("i", { className: "fas fa-spinner fa-spin" })),
                        submitting && accept
                            ? 'Approving...'
                            : 'Give Provider dashboard access')),
                    user.step.slug === 'care-provider-awaiting-admin-approval' && (React.createElement("button", { className: "theme-btn blue", disabled: submitting, onClick: handleAcceptSubmit },
                        submitting && accept && (React.createElement("i", { className: "fas fa-spinner fa-spin" })),
                        submitting && accept
                            ? 'Approving...'
                            : 'Fully Approve Provider')),
                    React.createElement("button", { className: "theme-btn blue", disabled: submitting, onClick: saveSitter },
                        submitting && accept && (React.createElement("i", { className: "fas fa-spinner fa-spin" })),
                        submitting && accept ? 'Saving...' : 'Save Admin Notes'),
                    React.createElement("button", { className: "theme-btn primary-coral", disabled: submitting, onClick: handleRejectSubmit },
                        submitting && !accept && (React.createElement("i", { className: "fas fa-spinner fa-spin" })),
                        submitting && !accept ? 'Rejecting...' : 'Reject'))),
                React.createElement("br", null),
                user.step.slug === 'care-provider-application-approved' && (React.createElement("p", null, "Great! This Provider has been accepted! They will receive an email informing them that they can now continue their profile.")),
                user.step.slug === 'care-provider-awaiting-admin-approval' && (React.createElement("p", null, "This Provider has been given access to their dashboard but is still awaiting Admin approval before they can begin sitting.")),
                user.step.slug === 'care-provider-application-finished' && (React.createElement("p", null, "This Provider\u2019s application has been approved by an admin. They are now available for sitting.")),
                user.step.slug === 'care-provider-application-denied' && (React.createElement("p", null, "This Provider\u2019s application has been rejected.")),
                React.createElement("div", { className: "back" },
                    React.createElement(Link, { className: "theme-btn small slate-blue", to: "/providers/applications" }, "Back")))));
    }
}
export default ApplicationAcceptanceDetail;
