import { find } from 'lodash-es';
import React from 'react';
import coverRequestService from '../../../services/coverRequestService';
import { Form, Modal } from '../../shared';
import Joi from 'joi';

/*
	Cover request "steps" are as follows:
	10: "No cover requests remaining" screen
	20: "Reason for cover request" screen
	25: "Within 24 hours" screen
	40: "legally obligated until filled" screen
*/

const COVER_REQUEST_STEP_NONE_REMAINING = 10;
const COVER_REQUEST_STEP_REASON_FOR_COVER = 20;
const COVER_REQUEST_STEP_EMERGENCY_COVER = 25;
const COVER_REQUEST_STEP_FINAL_REMINDER = 40;

const emergencyCoverOptions = [
  {
    label:
      "I am sick and will provide a doctor's note to the STL Care Office within 24 hours.",
    value: '10',
  },
  {
    label:
      'I have a family emergency and will provide documentation to the STL Care Office within 24 hours.',
    value: '20',
  },
];

class AppointmentCoverRequest extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        sitter_reason: '',
        accepted_terms: 0,
      },
      errors: {},
      loading: false,
      submitting: false,
      coverRequestsRemaining: 0,
      coverRequestStep: 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.props;
    const prevOpen = prevProps.isOpen;

    if (isOpen !== prevOpen && isOpen === true) {
      this.getRemainingCoverRequests();
    }
  }
  getRemainingCoverRequests = async () => {
    const data = { ...this.state.data };
    data['sitter_reason'] = '';
    this.setState({
      loading: true,
      coverRequestStep: 0,
      data,
    });
    try {
      console.log('Getting # cover requests...');
      const { sitterPiid, hoursUntilAppt } = this.props;
      const response = await coverRequestService.getRequestsRemaining(
        sitterPiid
      );
      if (response.status === 200) {
        const { count } = response.data.data;
        // If no requests remaining, show that screen first
        // if there are requests, show the 24 hour screen if appropriate
        let newStep =
          count === 0
            ? COVER_REQUEST_STEP_NONE_REMAINING
            : hoursUntilAppt < 24
            ? COVER_REQUEST_STEP_EMERGENCY_COVER
            : COVER_REQUEST_STEP_REASON_FOR_COVER;
        this.setState({
          coverRequestsRemaining: count,
          coverRequestStep: newStep,
          loading: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  advanceStep = () => {
    const oldStep = this.state.coverRequestStep;
    let newStep = false;
    if (oldStep === COVER_REQUEST_STEP_NONE_REMAINING) {
      const { hoursUntilAppt } = this.props;
      if (hoursUntilAppt < 24) {
        newStep = COVER_REQUEST_STEP_EMERGENCY_COVER;
      } else {
        newStep = COVER_REQUEST_STEP_REASON_FOR_COVER;
      }
    } else if (
      oldStep === COVER_REQUEST_STEP_EMERGENCY_COVER ||
      oldStep === COVER_REQUEST_STEP_REASON_FOR_COVER
    ) {
      if (!!this.state.data.sitter_reason) {
        newStep = COVER_REQUEST_STEP_FINAL_REMINDER;
      }
    } else if (oldStep === COVER_REQUEST_STEP_FINAL_REMINDER) {
      if (!!this.state.data.accepted_terms) {
        this.submitCoverRequest();
      }
    }
    if (newStep !== false) {
      this.setState({
        coverRequestStep: newStep,
      });
    }
  };
  submitCoverRequest = async () => {
    this.setState({ submitting: true });
    try {
      const data = {
        appointment_id: this.props.apptId,
        sitter_piid: this.props.sitterPiid,
        sitter_reason: this.state.data.sitter_reason,
      };
      if (data['sitter_reason'] == Number(data['sitter_reason'])) {
        const sitterReason = find(emergencyCoverOptions, (option) => {
          return option.value == data['sitter_reason'];
        });
        data['sitter_reason'] = sitterReason.label;
      }
      const response = await coverRequestService.storeCoverRequest(data);
      if (response.status === 201) {
        this.props.onCoverRequestSuccess();
      } else {
        this.setState({ submitting: false });
      }
    } catch (e) {
      this.setState({ submitting: false });
      console.log(e);
    }
  };
  render() {
    const { closeModal, isOpen } = this.props;
    const { coverRequestStep } = this.state;
    return (
      <div className="sitter-cover-request-modal-wrapper">
        <Modal
          title="Sub Provider Request"
          isOpen={isOpen}
          closeModal={closeModal}
          content={
            <div className="sitter-cover-request-modal">
              {coverRequestStep === 0 && (
                <div className="cover-request-step">
                  <p>
                    <i className="fas fa-spinner fa-spin"></i>
                    &nbsp;Loading your cover request history...
                  </p>
                </div>
              )}
              {coverRequestStep === COVER_REQUEST_STEP_NONE_REMAINING && (
                <div className="cover-request-step">
                  <p>
                    Uh oh - it looks like you have no sub provider requests
                    remaining. All Providers are allotted 1 Sub Provider Request
                    per 30 days to remain in good standing. Our office
                    understands that unexpected circumstances can arise;
                    therefore, if this is a true emergency, please proceed with
                    submission and get in touch with our office and ourteam will
                    work to find an alternate provider.
                  </p>
                  <button
                    className="theme-btn primary-green"
                    onClick={this.advanceStep}
                  >
                    <i className="fas fa-arrow-right"></i>
                    &nbsp;Proceed with Request
                  </button>
                </div>
              )}
              {coverRequestStep === COVER_REQUEST_STEP_EMERGENCY_COVER && (
                <div className="cover-request-step">
                  {this.renderRadio(
                    'sitter_reason',
                    'Sub Provider requests within 24 hours of a confirmed appointment are only approved in the case of sickness or a family emergency with manager-approved documentation.',
                    emergencyCoverOptions,
                    true
                  )}
                  <button
                    className="theme-btn primary-green"
                    onClick={this.advanceStep}
                  >
                    <i className="fas fa-arrow-right"></i>
                    &nbsp;Next
                  </button>
                </div>
              )}
              {coverRequestStep === COVER_REQUEST_STEP_REASON_FOR_COVER && (
                <div className="cover-request-step">
                  <p>
                    Please provide any and all details pertaining to the reason
                    for submitting your sub request. We will not divulge any
                    personal information provided in your response. One-two word
                    explanations will result in your sub request being declined.
                    Thank you for your cooperation!
                  </p>
                  <br />
                  {this.renderTextarea(
                    'sitter_reason',
                    'Reason for Sub Provider Request',
                    true
                  )}
                  <button
                    className="theme-btn primary-green"
                    onClick={this.advanceStep}
                  >
                    <i className="fas fa-arrow-right"></i>
                    &nbsp;Next
                  </button>
                </div>
              )}
              {coverRequestStep === COVER_REQUEST_STEP_FINAL_REMINDER && (
                <div className="cover-request-step">
                  <p>
                    Providers are legally obligated to attend all confirmed
                    appointments unless a sub provider request has been approved
                    online and the appointment is confirmed with another
                    sitter.Providers who no-show a confirmed appointment will be
                    immediately terminated and directed to our legal team to
                    assess the fine that will be owed to our office team and
                    member.
                  </p>
                  {this.renderBoolean(
                    'accepted_terms',
                    'I understand that by submitting this Sub Request, it is only a request; and I am still expected to attend my confirmed appointment until my Sub Provider Request is approved by the STL Care Team.',
                    true
                  )}
                  <button
                    className="theme-btn primary-green"
                    onClick={this.advanceStep}
                  >
                    <i className="fas fa-arrow-right"></i>
                    &nbsp;Next
                  </button>
                </div>
              )}
            </div>
          }
          secondaryButton={'Close'}
        />
      </div>
    );
  }
}

export default AppointmentCoverRequest;
