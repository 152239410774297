import React from 'react';
export const AppointmentPendingReviewsInfo = (props) => {
    const { count, onClick } = props;
    if (count === 0) {
        return null;
    }
    return (React.createElement("div", { className: "pending-reviews-info" },
        React.createElement("div", null,
            React.createElement("h3", { className: "pending-reviews-info__title" }, "Provider Reviews"),
            React.createElement("p", null,
                "You have ",
                count,
                " pending provider review",
                count > 1 ? 's' : '',
                ".")),
        React.createElement("div", null,
            React.createElement("button", { type: "button", onClick: onClick, "aria-label": "Go to completed appointments" },
                React.createElement("span", { className: "fas fa-arrow-right" })))));
};
