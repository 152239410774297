import clsx from 'clsx';
import React from 'react';
class FileInput extends React.Component {
    constructor(props) {
        super(props);
        const { value } = props;
        if (value && typeof value !== 'string') {
            this.getFileUrl(value);
        }
        else {
            this.state = {
                fileUrl: value,
            };
        }
    }
    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if (value && value !== prevProps.value) {
            if (typeof value === 'string') {
                this.setState({
                    fileUrl: value,
                });
            }
            else {
                this.getFileUrl(value);
            }
        }
        else if (!value && value !== prevProps.value) {
            this.setState({
                fileUrl: '',
            });
        }
    }
    getFileUrl = (value) => {
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            this.setState({
                fileUrl: event.target.result,
            });
        });
        reader.readAsDataURL(value);
    };
    render() {
        const { name, label, error, value, required, DisplayComponent, ...rest } = this.props;
        const { fileUrl } = this.state;
        return (React.createElement("div", { className: clsx('form-field', 'file-upload', name) },
            React.createElement("label", { htmlFor: name },
                label,
                required && React.createElement("span", { className: "required" }, "\u00A0*"),
                React.createElement("input", { hidden: true, name: name, id: name, type: "file", className: value ? 'active' : 'inactive', ...rest }),
                DisplayComponent && React.createElement(DisplayComponent, { value: fileUrl })),
            error && (React.createElement("span", { className: "error", role: "alert" },
                React.createElement("strong", null, error)))));
    }
}
export default FileInput;
