import { each } from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { adminEmail, adminPhone } from '../../../config';
import { states } from '../../../data';
import { joiPasswordRulesHelper, updateRegisterStep } from '../../../helpers';
import httpService from '../../../services/httpService';
import { FormStepTracker } from '../../shared';
import Form from '../../shared/Form';
import trackerSteps from './trackerSteps';
import Joi from 'joi';
import { mapAuthState } from '../../../helpers/mapAuthState';

const radioOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

const sitterRadioOptions = [
  { label: 'Any', value: 'any' },
  { label: 'Female', value: 'female' },
];

class HouseholdInfo extends Form {
  schema = Joi.object({
    address1: Joi.number(),
    address1: Joi.string().required().label('Household Address (line 1)'),
    address2: Joi.string()
      .optional()
      .allow(null)
      .allow('')
      .label('Address (line 2)'),
    city: Joi.string().required().label('City'),
    state: Joi.string().required().label('State'),
    zip: Joi.string().required().label('ZIP code'),
    phone1: Joi.string().required().label('Household Number'),
    employer: Joi.string()
      .optional()
      .allow(null)
      .allow('')
      .label('Place of Employment'),
    sexual_criminal_offense: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .required()
      .label('Sexual / Criminal Offense'),
    smoke: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .required()
      .label('Household Smokers'),
  });
  constructor(props) {
    super(props);
    this.state = {
      data: {
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        phone1: null,
        employer: null,
        sexual_criminal_offense: null,
        smoke: null,
      },
      errors: {},
      user: null,
    };
  }
  componentDidMount() {
    const { user } = this.props.auth;
    if (!user) {
      this.sendUserBack();
    }
  }
  sendUserBack = () => {
    alert(
      "You must complete the previous form before filling out your profile. Please go back and fill out the initial registration form, then click on the link in your inbox once you've received the sign-up email."
    );
    window.open('/register/companion-care', '_self');
  };
  updateStep = async (step) => {
    const { id } = this.state.user;
    const response = await updateRegisterStep(id, step);
  };
  doSubmit = async (callback) => {
    try {
      const { data } = this.state;
      data['piid'] = this.props.auth.user.personal_info;
      const response = await httpService.post('/api/profileFamily/update', data);
      callback();
      if (response.status === 200) {
        this.advanceStep();
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  advanceStep = async () => {
    try {
      const { id } = this.props.auth.user;
      const response = await this.props.updateUserStep('companion-care-household-info', id);
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    const { activityOptions } = this.state;

    return (
      <div className="registration family step-2 profile">
        <div className="form">
          <div className="inner">
            <FormStepTracker steps={trackerSteps} currentStep={0} />
            <div className="form-header">
              <h2>Household Info</h2>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group left-align">
                <h3>Household Contact And Address Information</h3>
                {this.renderInput('phone1', 'Household Phone', 'number', true)}
                {this.renderInput('address1', 'Household Address', 'text', true)}
                {this.renderInput('address2', 'Apt/Suite', 'text')}
                {this.renderInput('city', 'City', 'text', true)}
                {this.renderSelect('state', 'State', states, true)}
                {this.renderInput('zip', 'ZIP', 'number', true)}
              </div>
              <div className="form-group left-align">
                <h3>History Information</h3>
                {this.renderInput('employer', 'Place of Employment (optional)')}
                {this.renderRadio(
                  'sexual_criminal_offense',
                  'Has anyone in the home been convicted of any sexual or criminal offenses?',
                  radioOptions,
                  true
                )}
                {this.renderRadio(
                  'smoke',
                  'Does anyone in your household smoke?',
                  radioOptions,
                  true
                )}
              </div>
              {this.renderButton('Next', 'Submitting...', 'theme-btn blue')}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const actionCreators = {
  updateUserStep: authActions.updateUserStep,
};

export default connect(mapAuthState, actionCreators)(HouseholdInfo);
