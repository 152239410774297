import Joi from 'joi';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { states } from '../../../data';
import { joiPasswordRulesHelper, updateRegisterStep } from '../../../helpers';
import { mapAuthState } from '../../../helpers/mapAuthState';
import httpService from '../../../services/httpService';
import { FormStepTracker } from '../../shared';
import Form from '../../shared/Form';
import trackerSteps from './trackerSteps';
const genderRadioOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
];
const radioOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
];
const statusRadioOptions = [
    { label: 'Full Time', value: 'Full Time' },
    { label: 'Part Time', value: 'Part Time' },
];
const inactiveSchema = Joi.object({
    firstname: Joi.string().required().label('First Name'),
    lastname: Joi.string().required().label('Last Name'),
    dob_year: Joi.number().integer().min(1900).required().label('DOB - Year'),
    dob_month: Joi.number()
        .integer()
        .min(1)
        .max(12)
        .required()
        .label('DOB - Month'),
    dob_day: Joi.number().integer().min(1).max(31).required().label('DOB - Day'),
    gender: Joi.string().required().label('Gender'),
    own_children: Joi.boolean().truthy(1).falsy(0).label('Children'),
    email: Joi.string().required().label('Email'),
    password: Joi.string()
        .custom(joiPasswordRulesHelper, 'custom validation')
        .required()
        .messages({
        'password.lowercase': 'Password must contain at least 1 lowercase character.',
        'password.uppercase': 'Password must contain at least 1 uppercase character.',
        'password.number': 'Password must contain at least 1 number',
        'password.special_character': 'Password must contain at least 1 special character.',
        'password.length': 'Password must be at least 8 characters long.',
    })
        .label('Password'),
    phone1: Joi.string().required().label('Mobile Number'),
    address1: Joi.string().required().label('Address (line 1)'),
    address2: Joi.string()
        .optional()
        .allow(null)
        .allow('')
        .label('Address (line 2)'),
    city: Joi.string().required().label('City'),
    state: Joi.string().required().label('State'),
    zip: Joi.string().required().label('ZIP code'),
    vehicle: Joi.boolean().truthy(1).falsy(0).label('Vehicle'),
    valid_drivers_license: Joi.boolean()
        .truthy(1)
        .falsy(0)
        .label("Valid Driver's License"),
    willing_transport_children: Joi.boolean()
        .truthy(1)
        .falsy(0)
        .when('vehicle', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
    })
        .label('Willing to Transport Care Recipients'),
    vehicle_year: Joi.string()
        .when('vehicle', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
    })
        .label('Vehicle Year'),
    vehicle_make: Joi.string()
        .when('vehicle', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
    })
        .label('Vehicle Make'),
    vehicle_model: Joi.string()
        .when('vehicle', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
    })
        .label('Vehicle Model'),
    vehicle_seatbelts: Joi.number()
        .when('vehicle', {
        is: true,
        then: Joi.number().min(0).required(),
        otherwise: Joi.optional().allow('').allow(null),
    })
        .label('Vehicle Seatbelts'),
    high_school_attended: Joi.string().required().label('High School Attended'),
    college: Joi.boolean()
        .truthy(1)
        .falsy(0)
        .label('Currently Enrolled in College'),
    college_name: Joi.string()
        .when('college', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
    })
        .label('College Name'),
    college_status: Joi.string()
        .when('college', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
    })
        .label('College Status'),
    college_field: Joi.string()
        .when('college', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
    })
        .label('College Field of Study'),
    first_aid_certified: Joi.boolean()
        .truthy(1)
        .falsy(0)
        .label('First Aid Certified'),
    cpr_certified: Joi.boolean().truthy(1).falsy(0).label('CPR Certified'),
    languages: Joi.array().optional().label('Conversational Languages'),
    hear_about_us: Joi.string().required().label('How Did You Hear About Us'),
    area_length_time: Joi.string()
        .required()
        .label('How long have you lived in the area'),
    recent_background_check: Joi.boolean()
        .truthy(1)
        .falsy(0)
        .label('Recent Background Check'),
    arrested: Joi.boolean().truthy(1).falsy(0).label('Arrested'),
    arrested_info: Joi.string()
        .when('arrested', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
    })
        .label('Arrest Info'),
    felony_misdemeanor: Joi.boolean()
        .truthy(1)
        .falsy(0)
        .label('Convicted of Felony / Misdemeanor'),
    felony_misdemeanor_info: Joi.string()
        .when('felony_misdemeanor', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
    })
        .label('Felony / Misdemeanor Info'),
    dui: Joi.boolean().truthy(1).falsy(0).label('Convicted of a DUI'),
    dui_info: Joi.string()
        .when('dui', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
    })
        .label('DUI Info'),
    employed: Joi.boolean().truthy(1).falsy(0).label('Employed'),
    employer: Joi.string()
        .when('employed', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
    })
        .label('Place of Employment'),
    employment_status: Joi.string()
        .when('employed', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
    })
        .label('Employment Status'),
    employment_notes: Joi.string()
        .optional()
        .allow(null)
        .allow('')
        .label('Employment Notes'),
    why_work: Joi.string().required().label('Why You Want To Work For Us'),
    other_info: Joi.string()
        .optional()
        .allow('')
        .allow(null)
        .label('Additional Notes'),
});
const inactiveData = {
    firstname: '',
    lastname: '',
    dob_year: '',
    dob_month: '',
    dob_day: '',
    gender: '',
    own_children: '',
    email: '',
    password: '',
    phone1: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    vehicle: '',
    valid_drivers_license: '',
    willing_transport_children: '',
    vehicle_make: '',
    vehicle_model: '',
    vehicle_year: '',
    vehicle_seatbelts: '',
    high_school_attended: '',
    college: '',
    college_name: '',
    college_status: '',
    college_field: '',
    first_aid_certified: '',
    cpr_certified: '',
    languages: [],
    hear_about_us: '',
    area_length_time: '',
    recent_background_check: '',
    arrested: '',
    arrested_info: '',
    felony_misdemeanor: '',
    felony_misdemeanor_info: '',
    dui: '',
    dui_info: '',
    employed: '',
    employer: '',
    employment_status: '',
    employment_notes: '',
    why_work: '',
    other_info: '',
};
const activeSchema = Joi.object({
    firstname: Joi.string().required().label('First Name'),
    lastname: Joi.string().required().label('Last Name'),
    dob_year: Joi.number().integer().min(1900).required().label('DOB - Year'),
    dob_month: Joi.number()
        .integer()
        .min(1)
        .max(12)
        .required()
        .label('DOB - Month'),
    dob_day: Joi.number().integer().min(1).max(31).required().label('DOB - Day'),
    gender: Joi.string().required().label('Gender'),
    own_children: Joi.boolean().truthy(1).falsy(0).label('Children'),
    email: Joi.string().required().label('Email'),
    password: Joi.string()
        .custom(joiPasswordRulesHelper, 'custom validation')
        .required()
        .messages({
        'password.lowercase': 'Password must contain at least 1 lowercase character.',
        'password.uppercase': 'Password must contain at least 1 uppercase character.',
        'password.number': 'Password must contain at least 1 number',
        'password.special_character': 'Password must contain at least 1 special character.',
        'password.length': 'Password must be at least 8 characters long.',
    })
        .label('Password'),
    phone1: Joi.string().required().label('Mobile Number'),
    address1: Joi.string().required().label('Address (line 1)'),
    address2: Joi.string()
        .optional()
        .allow(null)
        .allow('')
        .label('Address (line 2)'),
    city: Joi.string().required().label('City'),
    state: Joi.string().required().label('State'),
    zip: Joi.string().required().label('ZIP code'),
});
class Application extends Form {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                firstname: '',
                lastname: '',
                dob_year: '',
                dob_month: '',
                dob_day: '',
                gender: '',
                own_children: '',
                email: '',
                password: '',
                phone1: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
            },
            errors: {},
            user: null,
            availableLanguages: [],
            submitting: false,
            showVehicleFields: false,
            showEducationFields: false,
            showArrestFields: false,
            showFelonyMisdemeanorFields: false,
            showDuiFields: false,
            showEmploymentFields: false,
        };
    }
    componentDidMount() {
        this.verifyHash();
    }
    componentDidUpdate(prevProps, prevState) {
        const prevData = prevState.data;
        const { data } = this.state;
        if (data.vehicle === 1 && prevData.vehicle !== 1) {
            this.setState({
                showVehicleFields: true,
            });
        }
        else if (data.vehicle === 0 && prevData.vehicle !== 0) {
            this.setState({
                showVehicleFields: false,
            });
        }
        if (data.employed === 1 && prevData.employed !== 1) {
            this.setState({
                showEmploymentFields: true,
            });
        }
        else if (data.employed === 0 && prevData.employed !== 0) {
            this.setState({
                showEmploymentFields: false,
            });
        }
        if (data.arrested === 1 && prevData.arrested !== 1) {
            this.setState({
                showArrestFields: true,
            });
        }
        else if (data.arrested === 0 && prevData.arrested !== 0) {
            this.setState({
                showArrestFields: false,
            });
        }
        if (data.felony_misdemeanor === 1 && prevData.felony_misdemeanor !== 1) {
            this.setState({
                showFelonyMisdemeanorFields: true,
            });
        }
        else if (data.felony_misdemeanor === 0 &&
            prevData.felony_misdemeanor !== 0) {
            this.setState({
                showFelonyMisdemeanorFields: false,
            });
        }
        if (data.dui === 1 && prevData.dui !== 1) {
            this.setState({
                showDuiFields: true,
            });
        }
        else if (data.dui === 0 && prevData.dui !== 0) {
            this.setState({
                showDuiFields: false,
            });
        }
        if (data.college === 1 && prevData.college !== 1) {
            this.setState({
                showEducationFields: true,
            });
        }
        else if (data.college === 0 && prevData.college !== 0) {
            this.setState({
                showEducationFields: false,
            });
        }
    }
    verifyHash = async () => {
        const { user } = this.props.auth;
        const params = new URLSearchParams(window.location.search);
        let loginHash = params.get('login_hash');
        let email = params.get('email');
        if ((!!email && !!loginHash) || !!user) {
            if (!!user) {
                loginHash = user.login_hash;
                email = user.email;
            }
            else {
                email = email.replace(/ /g, '+');
            }
            const data = {
                email,
                login_hash: loginHash,
            };
            try {
                const response = await httpService.post('/api/verify/email', data);
                if (response.status === 200) {
                    const { user } = response.data.data;
                    let { data } = this.state;
                    if (!!user.active) {
                        this.schema = activeSchema;
                    }
                    else {
                        this.schema = inactiveSchema;
                        data = inactiveData;
                    }
                    const { email, personal_info } = user;
                    const { firstname, lastname, phone1 } = personal_info;
                    data.firstname = firstname;
                    data.lastname = lastname;
                    if (!!phone1) {
                        data.phone1 = phone1;
                    }
                    data.email = email;
                    this.setState({ data, user }, () => {
                        this.updateStep('care-provider-signup');
                        this.getAvailableLanguages();
                    });
                }
                else {
                    this.sendUserBack();
                }
            }
            catch (error) {
                console.error(error);
                this.sendUserBack();
            }
        }
        else {
            this.sendUserBack();
        }
    };
    sendUserBack = () => {
        alert("You must complete the previous form before filling out your profile. Please go back and fill out the initial registration form, then click on the link in your inbox once you've received the sign-up email.");
        window.open('/register/care-provider', '_self');
    };
    getAvailableLanguages = async () => {
        const response = await httpService.get('/api/languages');
        if (response.status === 200) {
            const { languages } = response.data.data;
            this.setState({
                availableLanguages: languages.map((language) => ({
                    value: language.id,
                    label: language.language,
                })),
            });
        }
    };
    updateStep = async (step) => {
        const { id } = this.state.user;
        try {
            await updateRegisterStep(id, step);
        }
        catch (error) {
            console.error(error);
        }
    };
    doSubmit = async (callback) => {
        try {
            const apiData = { ...this.state.data };
            const { dob_day, dob_year, dob_month } = apiData;
            apiData.DOB = moment(`${dob_year}-${dob_month}-${dob_day}`, 'YYYY-M-D').format('YYYY-MM-DD');
            delete apiData.dob_day;
            delete apiData.dob_year;
            delete apiData.dob_month;
            await this.props.register(apiData, 'care-provider');
            callback();
        }
        catch (error) {
            console.error(error);
            callback();
        }
    };
    render() {
        const { showVehicleFields, showEmploymentFields, showDuiFields, showArrestFields, showFelonyMisdemeanorFields, showEducationFields, availableLanguages, user, } = this.state;
        return (React.createElement("div", { className: "registration sitter step-2 profile" },
            React.createElement("div", { className: "form" },
                React.createElement("div", { className: "inner" },
                    React.createElement(FormStepTracker, { steps: trackerSteps, currentStep: 0 }),
                    React.createElement("form", { onSubmit: this.handleSubmit },
                        React.createElement("div", { className: "form-header" },
                            React.createElement("h2", null, "Care Provider Credentials")),
                        React.createElement("div", { className: "form-group left-align" },
                            this.renderInput('firstname', 'First Name', 'text', true, {
                                disabled: true,
                            }),
                            this.renderInput('lastname', 'Last Name', 'text', true, {
                                disabled: true,
                            }),
                            this.renderTextDatePicker('dob', 'Date of Birth (MM/DD/YYYY)', true),
                            this.renderRadio('gender', 'Gender', genderRadioOptions, true),
                            this.renderRadio('own_children', 'Do you have any children?', radioOptions, true),
                            this.renderInput('email', 'Email', 'email', true, {
                                disabled: true,
                            }),
                            this.renderPasswordWithRules('password', 'Password'),
                            this.renderInput('phone1', 'Mobile Phone', 'number', true),
                            this.renderInput('address1', 'Address', 'text', true),
                            this.renderInput('address2', 'Apt/Suite', 'text'),
                            this.renderInput('city', 'City', 'text', true),
                            this.renderSelect('state', 'State', states, true),
                            this.renderInput('zip', 'ZIP', 'number', true)),
                        !!user && !user.active && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "form-header" },
                                React.createElement("h2", null, "Application Questions")),
                            React.createElement("div", { className: "form-group left-align" },
                                React.createElement("h3", null, "Transportation"),
                                this.renderRadio('vehicle', 'Do you have a vehicle to get to work?', radioOptions, true),
                                this.renderRadio('valid_drivers_license', "Do you have a valid driver's license?", radioOptions, true),
                                !!showVehicleFields && (React.createElement(React.Fragment, null,
                                    this.renderRadio('willing_transport_children', 'Are you wiling to transport companions in your vehicle?', radioOptions, true),
                                    this.renderInput('vehicle_year', 'Vehicle Year', 'number', true),
                                    this.renderInput('vehicle_make', 'Vehicle Make', 'text', true),
                                    this.renderInput('vehicle_model', 'Vehicle Model', 'text', true),
                                    this.renderInput('vehicle_seatbelts', 'Number of back seats with seatbelts', 'number', true)))),
                            React.createElement("div", { className: "form-group left-align" },
                                React.createElement("h3", null, "Education"),
                                this.renderInput('high_school_attended', 'What high school did you attend?', 'text', true),
                                this.renderRadio('college', 'Are you enrolled or currently in college?', radioOptions, true),
                                !!showEducationFields && (React.createElement(React.Fragment, null,
                                    this.renderInput('college_name', 'What is the name of the College?', 'text', true),
                                    this.renderRadio('college_status', 'What is your college student status?', statusRadioOptions, true),
                                    this.renderInput('college_field', 'What is your field of Study?', 'text', true))),
                                this.renderRadio('first_aid_certified', 'Are you currently First Aid Certified?', radioOptions, true),
                                this.renderRadio('cpr_certified', 'Are you currently CPR Certified?', radioOptions, true),
                                this.renderCheckbox('languages', 'In addition to English, I can also speak conversational:', availableLanguages)),
                            React.createElement("div", { className: "form-group left-align" },
                                React.createElement("h3", null, "Background"),
                                this.renderInput('hear_about_us', 'How did you hear about us?', 'text', true),
                                this.renderInput('area_length_time', 'How long have you lived in Missouri?', 'text', true),
                                this.renderRadio('recent_background_check', 'Have you had a background check within 3 months?', radioOptions, true),
                                this.renderRadio('arrested', 'Have you ever been arrested?', radioOptions, true),
                                !!showArrestFields && (React.createElement(React.Fragment, null, this.renderTextarea('arrested_info', 'Please provide arrest details', true, {
                                    tooltip: 'Care providers are required to have a clean background check and driving record on file in order to be eligible to provide services to our members.',
                                }))),
                                this.renderRadio('felony_misdemeanor', 'Have you ever been convicted for a felony or misdemeanor?', radioOptions, true),
                                !!showFelonyMisdemeanorFields && (React.createElement(React.Fragment, null, this.renderTextarea('felony_misdemeanor_info', 'Please provide felony / misdemeanor details', true, {
                                    tooltip: 'Care providers are required to have a clean background check and driving record on file in order to be eligible to provide services to our members.',
                                }))),
                                this.renderRadio('dui', 'Have you ever been convicted of Driving Under the Influence of Drugs or Alcohol (DUI) or any other drug / alcohol related activity? Including underage drinking.', radioOptions, true),
                                !!showDuiFields && (React.createElement(React.Fragment, null, this.renderTextarea('dui_info', 'Please provide conviction details', true, {
                                    tooltip: 'Care providers are required to have a clean background check and driving record on file in order to be eligible to provide services to our members.',
                                })))),
                            React.createElement("div", { className: "form-group left-align" },
                                React.createElement("h3", null, "Employment"),
                                this.renderRadio('employed', 'Are you currently employed?', radioOptions, true),
                                !!showEmploymentFields && (React.createElement(React.Fragment, null,
                                    this.renderInput('employer', 'Who is your current employer', 'text', true),
                                    this.renderRadio('employment_status', 'What is your employment status?', statusRadioOptions, true))),
                                this.renderTextarea('employment_notes', 'Please tell us about any employment history (specifically companion care if available).', false, {
                                    tooltip: 'STL Care requires any applicant to have substantial companion care experience and/or background in either a personal or professional environment.',
                                })),
                            React.createElement("div", { className: "form-group left-align" },
                                React.createElement("h3", null, "Final Thoughts"),
                                this.renderTextarea('why_work', 'Why do you want to work for STL Care?', true, {
                                    tooltip: 'Share your experience in companion care and what brought you to us!',
                                }),
                                this.renderTextarea('other_info', 'Any other information you would like to share?', false, {
                                    tooltip: 'At STL Care, we take pride in our relationships with each team member - so tell us more about you!',
                                })))),
                        this.renderButton('Next', 'Submitting...', 'theme-btn blue'))))));
    }
}
const actionCreators = {
    register: authActions.register,
};
export default connect(mapAuthState, actionCreators)(Application);
