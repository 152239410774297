import { buildApiUrl } from '../helpers/buildApiUrl';
import httpService from './httpService';
const get = (id) => {
    const apiUrl = buildApiUrl(`/profileCareContact/details/${id}`);
    return httpService.get(apiUrl);
};
const store = (data, headers) => {
    const apiUrl = buildApiUrl('/profileCareContact/store');
    return httpService.post(apiUrl, data, headers);
};
const update = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/profileCareContact/update/${id}`);
    return httpService.post(apiUrl, data, headers);
};
const deleteCareContact = (id) => {
    const apiUrl = buildApiUrl(`/profileCareContact/delete/${id}`);
    return httpService.delete(apiUrl);
};
export default {
    get,
    store,
    update,
    delete: deleteCareContact,
};
