import React from 'react';
import { Link } from 'react-router-dom';

class PublicLanding extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main className="public-landing">
        <h2>STLCare</h2>
        <p>Please log in or register to view the site.</p>
        <p>
          <Link to="/login">Log In</Link>
        </p>
        <p>
          <Link to="/register/companion-care">Companion Care Register</Link>
        </p>
        <p>
          <Link to="/register/care-provider">Care Provider Register</Link>
        </p>
      </main>
    );
  }
}

export default PublicLanding;
