import React from 'react';
import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { stripeKey } from '../../../config';
import { mapAuthState } from '../../../helpers/mapAuthState';
import { FormStepTracker } from '../../shared';
import PaymentForm from './PaymentForm';
import trackerSteps from './trackerSteps';
const fontsObj = [
    {
        cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans&display=swap',
    },
];
class Payment extends React.Component {
    componentDidMount() {
        const { user } = this.props.auth;
        if (!user) {
            this.sendUserBack();
        }
    }
    sendUserBack() {
        alert('You must sign up or log in to view this page.');
        window.open('/', '_self');
    }
    render() {
        return (React.createElement("div", { className: "registration family step-5 payment" },
            React.createElement("div", { className: "form" },
                React.createElement("div", { className: "inner" },
                    React.createElement(FormStepTracker, { steps: trackerSteps, currentStep: 4 }),
                    React.createElement("div", { className: "form-header" },
                        React.createElement("h2", null, "Payment")),
                    React.createElement(StripeProvider, { apiKey: stripeKey },
                        React.createElement(Elements, { fonts: fontsObj },
                            React.createElement(PaymentForm, null)))))));
    }
}
export default connect(mapAuthState)(Payment);
