import React from 'react';
class SitterOnboarding extends React.Component {
    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
        script.setAttribute('id', 'calendly-widget-script');
        head.append(script);
    }
    componentWillUnmount() {
        document.querySelector('#calendly-widget-script')?.remove();
    }
    render() {
        return (React.createElement("main", { className: "schedule-interview-page" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("div", { className: "calendly-inline-widget", "data-url": "https://calendly.com/stlcare/stlcare-provider-onboarding", style: { minWidth: '320px', height: '650px' } })))));
    }
}
export default SitterOnboarding;
