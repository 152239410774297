import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { displayProfileColor } from '@ss/helpers';
import { mapAuthState } from '@ss/helpers/mapAuthState';
import { httpService } from '@ss/services';
import sitterService from '@ss/services/sitterService';
import SitterPublicProfileForm from '../../Forms/SitterPublicProfileForm';
import { ProfileColorPicker } from '../../shared';
const AvatarDisplayComponent = (props) => {
    return (React.createElement("div", { className: "avatar" },
        React.createElement("div", { className: "avatar-wrapper" },
            !!props.value && React.createElement("img", { src: props.value }),
            !props.value && React.createElement("i", { className: "far fa-camera" }))));
};
class PublicProfile extends SitterPublicProfileForm {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            loading: true,
            id: null,
        };
    }
    componentDidMount = () => {
        this.getCareRecipientNeeds();
        this.getAvailableLanguages();
        this.getSitter();
    };
    getCareRecipientNeeds = async () => {
        try {
            const response = await httpService.get('/api/careRecipientNeeds');
            if (response.status === 200) {
                const { careRecipientNeeds } = response.data.data;
                this.setState({
                    careRecipientNeeds: careRecipientNeeds.map((item) => ({
                        value: item.id,
                        label: item.name,
                    })),
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    getAvailableLanguages = async () => {
        const response = await httpService.get('/api/languages');
        if (response.status === 200) {
            const languages = response.data.data.languages.map((info) => ({
                ...info,
                value: info.id,
                label: info.language,
            }));
            this.setState({
                availableLanguages: languages,
            });
        }
    };
    handleProfileColorChange = (colorClass) => {
        this.setState((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                profile_color: colorClass,
            },
        }));
    };
    getSitter = async () => {
        try {
            const { id } = this.props.auth.user.personal_info;
            const response = await sitterService.get(id);
            this.formatData(response.data.data);
        }
        catch (error) {
            console.error(error);
        }
    };
    formatData = ({ languages, personalInfo, profileSitter, selectedRecipientNeeds, }) => {
        const { data } = this.state;
        const { avatar_filename, profile_color, id } = personalInfo;
        const { vehicle_year, vehicle_make, vehicle_model, vehicle_seatbelts, intro, bio, } = profileSitter;
        data.avatar_filename = avatar_filename;
        data.vehicle_year = vehicle_year;
        data.vehicle_make = vehicle_make;
        data.vehicle_model = vehicle_model;
        data.vehicle_seatbelts = vehicle_seatbelts;
        data.intro = intro;
        data.bio = bio;
        data.profile_color =
            profile_color !== null ? profile_color : 'primary-coral';
        const selected_languages = languages.map((lang) => String(lang.id));
        const selected_recipient_needs = selectedRecipientNeeds.map((need) => String(need.id));
        data.languages = selected_languages;
        data.selected_recipient_needs = selected_recipient_needs;
        this.setState({
            data,
            personalInfo,
            profileSitter,
            id,
        });
    };
    doSubmit = async (callback) => {
        try {
            const response = await this.submitFormData();
            callback();
            if (response.status === 200) {
                toast.success('Hooray! Your changes are saved.');
            }
        }
        catch (error) {
            console.error(error);
            callback();
        }
    };
    render() {
        const { data } = this.state;
        const { user } = this.props.auth;
        return (React.createElement("main", { className: "account profile sitter-public" },
            React.createElement("form", { onSubmit: this.handleSubmit },
                React.createElement("div", { className: `profile-header ${displayProfileColor(data.profile_color, 'gradient')}` },
                    React.createElement("div", { className: "inner" },
                        this.renderFile('avatar_filename', 'Upload Your Public Profile Image', 'image/jpg, image/png', false, AvatarDisplayComponent),
                        React.createElement("h2", { className: "theme-h2" },
                            user.personal_info.firstname,
                            "\u00A0",
                            user.personal_info.lastname))),
                React.createElement("div", { className: "profile-body" },
                    React.createElement("h2", null, "Public Profile"),
                    React.createElement(ProfileColorPicker, { value: data.profile_color, onProfileColorChange: this.handleProfileColorChange }),
                    this.renderForm(),
                    this.renderButton('Save Public Profile', 'Saving...', 'theme-btn blue'),
                    React.createElement("div", { className: "cancel-btn" },
                        React.createElement(Link, { className: "theme-btn small slate-blue", to: "/profile" }, "Cancel"))))));
    }
}
export default connect(mapAuthState)(PublicProfile);
