export default [
    {
        id: 1,
        slug: 'care-provider-signup',
        step: 'Care Provider Signup',
    },
    {
        id: 2,
        slug: 'care-provider-application-questions',
        step: 'Care Provider Application Questions',
    },
    {
        id: 3,
        slug: 'care-provider-application-pending',
        step: 'Care Provider Application Pending',
    },
    {
        id: 4,
        slug: 'care-provider-application-approved',
        step: 'Care Provider Application Approved',
    },
    {
        id: 5,
        slug: 'care-provider-private-profile',
        step: 'Care Provider Private Profile',
    },
    {
        id: 6,
        slug: 'care-provider-public-profile',
        step: 'Care Provider Public Profile',
    },
    {
        id: 7,
        slug: 'care-provider-availability',
        step: 'Care Provider Availability',
    },
    {
        id: 16,
        slug: 'care-provider-onboarding',
        step: 'Care Provider Onboarding',
    },
    {
        id: 17,
        slug: 'care-provider-awaiting-admin-approval',
        step: 'Care Provider Awaiting Admin Approval',
    },
    {
        id: 8,
        slug: 'care-provider-application-finished',
        step: 'Care Provider Application Finished',
    },
    {
        id: 9,
        slug: 'care-provider-application-denied',
        step: 'Care Provider Application Denied',
    },
    {
        id: 10,
        slug: 'companion-care-signup',
        step: 'Companion Care Signup',
    },
    {
        id: 11,
        slug: 'companion-care-profile',
        step: 'Companion Care Profile',
    },
    {
        id: 12,
        slug: 'companion-care-children',
        step: 'Companion Care Children',
    },
    {
        id: 13,
        slug: 'companion-care-pets',
        step: 'Companion Care Pets',
    },
    {
        id: 14,
        slug: 'companion-care-payment',
        step: 'Companion Care Payment',
    },
    {
        id: 15,
        slug: 'companion-care-application-finished',
        step: 'Companion Care Application Finished',
    },
];
