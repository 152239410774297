import Joi from 'joi';
import moment from 'moment-timezone';
import React from 'react';
import { toast } from 'react-toastify';
import { booleanRadioOptions as radioOptions, wiggleOptions } from '@ss/data';
import { makeTimeIncrements } from '@ss/helpers';
import appointmentService from '@ss/services/appointmentService';
import Form from '../../shared/Form';
const statusOptions = [
    { name: 'Pending', id: 'pending' },
    { name: 'Submitted', id: 'submitted' },
    { name: 'Accepted', id: 'accepted' },
    { name: 'Completed', id: 'completed' },
    { name: 'Cancelled', id: 'cancelled' },
];
class AppointmentsEdit extends Form {
    schema = Joi.object({
        date: Joi.string().required().label('Date'),
        start_time: Joi.string().required().label('Start Time'),
        end_time: Joi.string().required().label('End Time'),
        flex: Joi.string().required().label('Flex'),
        driving: Joi.string().required().label('Driving'),
        admin_notes: Joi.string()
            .optional()
            .allow('')
            .allow(null)
            .label('Admin-Only Notes'),
        sitter_notes: Joi.string()
            .optional()
            .allow('')
            .allow(null)
            .label('Admin Notes To Providers'),
    });
    constructor(props) {
        super(props);
        this.state = {
            data: {
                id: null,
                date: '',
                start_time: '',
                end_time: '',
                flex: false,
                flex_room: '',
                driving: false,
                driving_distance: '',
                admin_notes: '',
                sitter_notes: '',
                status: '',
                paid: 0,
            },
            start_times: makeTimeIncrements(0, 21),
            end_times: makeTimeIncrements(0),
            errors: {},
            submitting: false,
        };
    }
    componentDidMount() {
        this.loadData();
    }
    loadData = () => {
        const { appointment } = this.props;
        const startTime = moment(appointment.start_time).format('kk:mm');
        const endTime = moment(appointment.end_time).format('kk:mm');
        const stateData = {
            status: appointment.status,
            start_time: startTime,
            end_time: endTime,
            date: new Date(appointment.start_time),
            flex: appointment.flex,
            flex_room: appointment.flex_room,
            driving_needed: appointment.driving_needed,
            driving_distance: appointment.driving_distance,
            id: appointment.id,
            admin_notes: appointment.admin_notes,
            sitter_notes: appointment.sitter_notes,
            paid: appointment.paid,
        };
        this.setState({
            data: stateData,
        });
    };
    submitFormData = async () => {
        this.setState({
            submitting: true,
        });
        const apiData = { ...this.state.data };
        apiData.start_time = moment(`${moment(apiData.date).format('YYYY-MM-DD')} ${apiData.start_time}`, 'YYYY-MM-DD HH:mm:ss').format();
        apiData.end_time = moment(`${moment(apiData.date).format('YYYY-MM-DD')} ${apiData.end_time}`, 'YYYY-MM-DD HH:mm:ss').format();
        const fd = new FormData();
        const headers = { headers: { 'content-type': 'multipart/form-data' } };
        fd.append('_method', 'post');
        for (const key of Object.keys(apiData)) {
            if (typeof apiData[key] === 'object') {
                if (key === 'avatar_filename') {
                    if (apiData[key] !== null) {
                        fd.append(key, apiData[key]);
                    }
                }
                else {
                    fd.append(key, JSON.stringify(apiData[key]));
                }
            }
            else {
                fd.append(key, apiData[key]);
            }
        }
        try {
            const response = await appointmentService.update(apiData.id, fd, headers);
            if (response.status === 200 || response.status === 201) {
                toast.success('Hooray! Your changes are saved.');
                window.location.reload();
            }
            else {
                toast.error('Oops, there were issues with saving');
            }
        }
        catch (error) {
            toast.error('Oops, there were issues with saving');
            return error;
        }
        this.setState({
            submitting: false,
        });
    };
    render() {
        const { start_times, end_times, data, submitting } = this.state;
        const { appointment, cancelledHours } = this.props;
        return (React.createElement("div", { className: "account-settings-form appointment" },
            this.renderSelect('status', 'Status', statusOptions),
            this.renderDatePicker('date', 'Date', true),
            this.renderSelect('start_time', 'Start Time', start_times, true),
            this.renderSelect('end_time', 'End Time', end_times, true),
            appointment.status === 'cancelled' && cancelledHours <= 3 && (React.createElement(React.Fragment, null, this.renderRadio('paid', 'Provider Paid Out?', radioOptions, true))),
            this.renderRadio('flex', 'Flex', radioOptions, true),
            !!data.flex &&
                this.renderSelect('flex_room', 'Time', wiggleOptions, true),
            this.renderRadio('driving_needed', 'Driving', radioOptions, true),
            this.renderTextarea('admin_notes', 'Admin-Only Notes'),
            this.renderTextarea('sitter_notes', 'Admin Notes To Provider'),
            !!data.driving_needed &&
                this.renderInput('driving_distance', 'Driving Distance (miles)', 'number'),
            React.createElement("div", { className: "button-row" },
                React.createElement("button", { onClick: this.submitFormData, className: "theme-btn primary-green" },
                    !submitting && (React.createElement(React.Fragment, null,
                        React.createElement("i", { className: "fas fa-save" }),
                        "\u00A0Save")),
                    submitting && React.createElement(React.Fragment, null, "...Saving")))));
    }
}
export default AppointmentsEdit;
