import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Joi from 'joi';
import couponService from '@ss/services/couponService';
import { findLinkGroupByTitle } from '@ss/helpers';
import { Form } from '../../shared';
import Sidebar from '../Sidebar';
const couponModelTypes = [
    { name: '', id: '' },
    { name: 'Everywhere', id: 'all' },
    { name: 'Bookings (Casual & Consistent Care)', id: 'booking' },
    { name: 'Subscriptions', id: 'subscription' },
    { name: 'Family Onboarding', id: 'onboarding' },
    { name: 'Booking Cancellation Fee', id: 'booking-cancellation' },
    { name: 'Balance Repayment', id: 'balance' },
    {
        name: 'Family Consistent Care Proposal Acceptance Fee',
        id: 'program-proposal-accept',
    },
];
const couponDiscountTypes = [
    { name: '', id: '' },
    { name: 'Flat Amount', id: 'amount' },
    { name: 'Percentage', id: 'percentage' },
];
const couponNumUsesTypes = [
    { name: '', id: '' },
    { name: 'Unlimited', id: 'unlimited' },
    { name: 'Uses Per User', id: 'uses_per_user' },
    { name: 'Uses Per User Per Year', id: 'uses_per_user_year' },
];
class CouponsEdit extends Form {
    schema = Joi.object({
        model_type: Joi.string().required().label('Applicable To'),
        code: Joi.string().required().label('Coupon Code'),
        type: Joi.string().required().label('Discount Type'),
        amount: Joi.string().required().label('Discount Amount'),
        num_uses_type: Joi.string().required().label('Num. Uses Type'),
        num_uses: Joi.number().required().label('Num. Uses'),
    });
    constructor(props) {
        super(props);
        this.state = {
            data: {
                model_type: '',
                code: '',
                type: '',
                amount: '',
                num_uses: 0,
                num_uses_type: '',
            },
            errors: {},
            coupon: null,
            creating: false,
            submitting: false,
            loading: true,
            linkGroup: findLinkGroupByTitle('Settings'),
            id: null,
        };
    }
    componentDidMount() {
        if (!this.props.match.params.id) {
            this.setState({
                creating: true,
                loading: false,
            });
        }
        else {
            this.getCoupon();
        }
    }
    getCoupon = async () => {
        try {
            const data = { ...this.state.data };
            const { id } = this.props.match.params;
            const response = await couponService.get(id);
            if (response.status === 200) {
                const { coupon } = response.data.data;
                data.model_type = coupon.model_type;
                data.code = coupon.code;
                data.type = coupon.type;
                data.amount =
                    data.type === 'percentage'
                        ? (Number.parseFloat(coupon.amount) * 100).toString()
                        : coupon.amount;
                data.num_uses = coupon.num_uses;
                data.num_uses_type = coupon.num_uses_type;
                this.setState({
                    coupon,
                    data,
                    loading: false,
                    creating: false,
                });
            }
        }
        catch (error) {
            console.error(error);
            toast.error('Uh oh! Something went wrong!');
        }
    };
    doSubmit = async (callback) => {
        try {
            if (this.state.creating) {
                const data = { ...this.state.data };
                const response = await couponService.store(data);
                callback();
                if (response.status === 201) {
                    const { coupon } = response.data.data;
                    this.props.history.replace(`/settings/coupons/edit/${coupon.id}`);
                    this.getCoupon();
                    toast.success('Hooray! This Coupon has been created!');
                }
            }
            else {
                const data = { ...this.state.data };
                const { id } = this.state.coupon;
                const response = await couponService.update(id, data);
                callback();
                if (response.status === 201) {
                    const { coupon } = response.data.data;
                    this.props.history.replace(`/settings/coupons/edit/${coupon.id}`);
                    this.getCoupon();
                    toast.success('Hooray! This Coupon has been updated!');
                }
            }
        }
        catch (error) {
            console.error(error);
            callback();
            toast.error('Uh oh! Something went wrong!');
        }
    };
    deleteCoupon = async () => {
        this.setState({
            deleting: true,
        });
        try {
            const data = { ...this.state.data };
            const { id } = this.state.coupon;
            const response = await couponService.delete(id, data);
            if (response.status === 204) {
                toast.success('Hooray! This Coupon has been deleted!');
                this.props.history.push('/settings/coupons');
            }
        }
        catch (error) {
            console.error(error);
            toast.success('Uh oh! Something went wrong!');
            this.setState({
                deleting: false,
            });
        }
    };
    goBack = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.props.history.goBack();
    };
    render() {
        const { submitting, creating, coupon, loading, linkGroup, data, deleting } = this.state;
        if (!!loading) {
            return (React.createElement("main", { className: "dashboard admin manage" },
                React.createElement("div", { className: "sidebar-wrapper" },
                    React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
                React.createElement("div", { className: "content" },
                    React.createElement("div", { className: "coupons" },
                        React.createElement("h2", null,
                            React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                            "\u00A0Loading Coupon...")))));
        }
        return (React.createElement("main", { className: "dashboard admin manage" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content" },
                React.createElement("div", { className: "coupons" },
                    React.createElement("h2", null, !!creating ? 'Create Coupon' : `Editing Coupon #${coupon.id}`),
                    React.createElement("form", { onSubmit: this.handleSubmit, className: "coupon-form form" },
                        this.renderInput('code', 'Coupon Code', 'text', true),
                        this.renderSelect('model_type', 'Applicable To', couponModelTypes, true),
                        this.renderSelect('type', 'Discount Type', couponDiscountTypes, true),
                        this.renderInput('amount', 'Discount Amount', 'number', true),
                        this.renderSelect('num_uses_type', 'Number of Uses Type', couponNumUsesTypes, true),
                        data.num_uses_type !== 'unlimited' && (React.createElement(React.Fragment, null, this.renderInput('num_uses', 'Number of Uses', 'number', true))),
                        React.createElement("div", { className: "divider" }),
                        React.createElement("div", { className: "align_center row" },
                            !!submitting && (React.createElement("button", { type: "button", className: "theme-btn blue large save-btn", disabled: true, onClick: (e) => e.preventDefault() },
                                React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                "\u00A0Saving...")),
                            !submitting && (React.createElement("button", { type: "button", className: "theme-btn blue large save-btn", onClick: this.handleSubmit },
                                React.createElement("i", { className: "fas fa-save" }),
                                "\u00A0Save Coupon"))),
                        React.createElement("br", null),
                        React.createElement("div", { className: "align_center row" },
                            !!deleting && (React.createElement("button", { type: "button", className: "theme-btn error-red small", disabled: true, onClick: (e) => e.preventDefault() },
                                React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                "\u00A0Deleting...")),
                            !deleting && (React.createElement("button", { type: "button", className: "theme-btn error-red small", onClick: this.deleteCoupon },
                                React.createElement("i", { className: "fas fa-times-circle" }),
                                "\u00A0Delete"))),
                        React.createElement("br", null),
                        React.createElement("div", { className: "align_center row" },
                            React.createElement(Link, { to: "/settings/coupons", className: "theme-btn slate-blue small" }, "Back")))))));
    }
}
export default CouponsEdit;
