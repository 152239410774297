export function getThemeFromGroup(group) {
    switch (group) {
        case 'negative':
        case 'cancel':
            return 'red';
        case 'positive':
        case 'refund':
            return 'green';
        case 'neutral':
        case 'reviews':
            return 'blue';
        case 'billing':
        case 'account':
        default:
            return 'gray';
    }
}
export function getNotificationDisplayProperties(notification) {
    switch (notification.action) {
        case 'AppointmentReview':
            return {
                title: 'Sitter Review Pending',
                actionLabel: 'Complete Sitter Feedback',
                theme: getThemeFromGroup('reviews'),
            };
        default:
            return {
                title: null,
                actionLabel: null,
                theme: getThemeFromGroup(),
            };
    }
}
