import React from 'react';
import Form from '../../shared/Form';
import Joi from 'joi';

import httpService from '../../../services/httpService';

// The way this data is structured is super weird, because you can sign up as either
// a recipient of care or an emergency contact for a recipient of care.
// Because of that, I've basically just hard-coded the data structure, and we process it on the backend
// based on the signing_up_for value.
class Signup extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        firstname: null,
        lastname: null,
        email: null,
        phone1: null,
        phone2: null,
        terms_accepted: false,
        signing_up_for: null,
        recipient_1_firstname: null,
        recipient_1_lastname: null,
        recipient_1_relationship: null,
        recipient_2_firstname: null,
        recipient_2_lastname: null,
        recipient_2_relationship: null,
        recipient_3_firstname: null,
        recipient_3_lastname: null,
        recipient_3_lastname: null,
        show_recipient_1: false,
        show_recipient_2: false,
        show_recipient_3: false,
      },
      errors: {},
      recaptchaAccepted: false,
      formSubmitted: false,
      // Overall onboarding step
      stepName: 'Companion Care Signup',
      // Internal step value (since this is composed of a few "pages")
      step: 1,
      signingUpFor: null,
    };
  }
  schema = Joi.object({
    firstname: Joi.string().required().label('First Name'),
    lastname: Joi.string().required().label('Last Name'),
    email: Joi.string().required().label('Email'),
    phone1: Joi.string()
      .when('signing_up_for', {
        is: 'other',
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      }).label('Mobile Phone'),
    phone2: Joi.string()
      .when('signing_up_for', {
        is: 'other',
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      }).label('Work Phone'),
    terms_accepted: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .required(),
    show_recipient_1: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .required(),
    show_recipient_2: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .required(),
    show_recipient_3: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .required(),
    signing_up_for: Joi.string().required().label('Signing up for'),
    recipient_1_firstname: Joi.string()
      .when('signing_up_for', {
        is: 'other',
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      }).label('Recipient 1 First Name'),
    recipient_1_lastname: Joi.string()
      .when('signing_up_for', {
        is: 'other',
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      }).label('Relationship 1 Last Name'),
    recipient_1_relationship: Joi.string()
      .when('signing_up_for', {
        is: 'other',
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      }).label('Recipient 1 Relationship'),
    recipient_2_firstname: Joi.string()
      .when('show_recipient_2', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      }).label('Recipient 2 First Name'),
    recipient_2_lastname: Joi.string()
      .when('show_recipient_2', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      }).label('Recipient 2 Last Name'),
    recipient_2_relationship: Joi.string()
      .when('show_recipient_2', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      }).label('Recipient 2 Relationship'),
    recipient_3_firstname: Joi.string()
      .when('show_recipient_3', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      }).label('Recipient 3 First Name'),
    recipient_3_lastname: Joi.string()
      .when('show_recipient_3', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      }).label('Recipient 3 Last Name'),
    recipient_3_relationship: Joi.string()
      .when('show_recipient_3', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
    }).label('Recipient 3 Relationship'),
  });
  doSubmit = async (callback) => {
    if (!this.state.recaptchaAccepted) {
      alert('You must accept the reCaptcha before proceeding.');
      return;
    }
    try {
      const { data } = this.state;
      console.log(data);
      const response = await httpService.post('/api/signup/companion-care', data);
      console.log(response);
      if (response.status === 200 && response.data.message === 'OK') {
        this.setState({
          formSubmitted: true,
        });
      }
      callback();
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  onChange = () => {
    this.setState({
      recaptchaAccepted: true,
    });
  };
  setSigningUpForValue = (value) => {
    const data = { ...this.state.data };
    data['signing_up_for'] = value;
    data['show_recipient_1'] = !!(value === 'other')
    this.setState({
      data,
    });
  };
  nextStep = () => {
    this.setState({
      step: this.state.step += 1,
    })
  };
  addNewRecipientOfCare = (e) => {
    e.preventDefault();
    // Because the data structure is flat, we have to do a little bit of jank to "add" a recipient.
    const data = { ...this.state.data };
    let {
      show_recipient_1,
      show_recipient_2,
      show_recipient_3,
      signing_up_for,
    } = data;
    if (show_recipient_2 && signing_up_for !== 'self') {
      // Limit of 3 recipients - self is one, so if self is selected, we can only add 2
      show_recipient_3 = true;
    };
    if (show_recipient_1) {
      show_recipient_2 = true;
    };
    show_recipient_1 = true;
    data['show_recipient_1'] = show_recipient_1;
    data['show_recipient_2'] = show_recipient_2;
    data['show_recipient_3'] = show_recipient_3;
    this.setState({
      data
    });
  };
  render() {
    const {
      step,
      data: {
        signing_up_for,
        terms_accepted,
        show_recipient_1,
        show_recipient_2,
        show_recipient_3,
      }
    } = this.state;
    return (
      <div className="registration family step-1 signup">
        <div className="form">
          {!this.state.formSubmitted && (
            <div className="inner">
              { step === 1 && (
                <div className="form-header">
                  <h2>Sign up for STLCare</h2>
                  <p>
                  Please select whether you are signing up for companion services for yourself or another person.
                  </p>
                  <br/>
                  <button className={`onboarding-buttons ${signing_up_for === 'self' ? 'active' : ''}`} onClick={()=>{this.setSigningUpForValue('self')}}>
                    <div className="onboarding-button-checkbox">
                      <i className="fas fa-check"></i>
                    </div>
                    <p>I am signing up for myself.</p>
                  </button>
                  <button className={`onboarding-buttons ${signing_up_for === 'other' ? 'active' : ''}`} onClick={()=>{this.setSigningUpForValue('other')}}>
                    <div className="onboarding-button-checkbox">
                      <i className="fas fa-check"></i>
                    </div>
                    <p>I am signing up for someone else.</p>
                  </button>
                  <button className="theme-btn blue submit-btn" disabled={!signing_up_for} onClick={this.nextStep}>Next</button>
                </div>
              )}
              { step === 2 && (
                <div className="form-header">
                  <h2>Sign up for STLCare</h2>
                  <p>Please note, at STL Care the Providers offer soley Companion Care. The information below should help decide if our level of care meets your needs!</p>
                  <div className="signup-section allowed">
                    <h3>Companion Care</h3>
                    <p>Companion Care includes the follow offerings:</p>
                    <ul>
                      <li><i className="fas fa-check"></i>&nbsp;Lifestyle Support</li>
                      <li><i className="fas fa-check"></i>&nbsp;Light Housekeeping</li>
                      <li><i className="fas fa-check"></i>&nbsp;Meal Prep / Nutrition</li>
                      <li><i className="fas fa-check"></i>&nbsp;Transportation</li>
                      <li><i className="fas fa-check"></i>&nbsp;Socialization / Tech Learning</li>
                    </ul>
                  </div>
                  <div className="signup-section prohibited">
                    <h3>Assisted Living</h3>
                    <p>STL Care does NOT offer these common Assisted Living Needs:</p>
                    <ul>
                      <li><i className="fas fa-times"></i>&nbsp;Assistance walking &amp; transferring from bed or chair</li>
                      <li><i className="fas fa-times"></i>&nbsp;Bathing, dressing, grooming</li>
                    </ul>
                  </div>
                  <div className="signup-section prohibited">
                    <h3>Skilled Nuring Facility (SNF)</h3>
                    <p>STL Care does NOT offer these common Skilled Nursing needs:</p>
                    <ul>
                      <li><i className="fas fa-times"></i>&nbsp;Care offered in nursing homes, hospitals, and assisted living communities</li>
                      <li><i className="fas fa-times"></i>&nbsp;Rehabilitation for surgeries, strokes, and/or extensive treatment for kidney, heart or respiratory conditions</li>
                    </ul>
                  </div>
                  {this.renderBoolean(
                    'terms_accepted',
                    'I have read and understand what STL Companion Care entails.'
                  )}
                  <button className="theme-btn blue submit-btn" disabled={!terms_accepted} onClick={this.nextStep}>Next</button>
                </div>
              )}
              { step === 3 && (
                <div className="form-group left-align">
                  <h3>Your Information</h3>
                  <form onSubmit={this.handleSubmit}>
                    {this.renderInput('firstname', 'First Name', 'text', true)}
                    {this.renderInput('lastname', 'Last Name', 'text', true)}
                    {this.renderInput('email', 'Email', 'email', true)}
                    { signing_up_for === 'other' && (
                      <>
                        {this.renderInput('phone1', 'Mobile Number', 'number', true)}
                        {this.renderInput('phone2', 'Secondary Number', 'number', true)}
                      </>
                    )}
                    { (signing_up_for === 'other' || show_recipient_1) && (
                      <div className="form-group">
                        <h3>Name of Person Needing Care</h3>
                        {this.renderInput('recipient_1_firstname', 'Recipient of Care\'s First Name', 'text', true)}
                        {this.renderInput('recipient_1_lastname', 'Recipient of Care\'s Last Name', 'text', true)}
                        {this.renderInput('recipient_1_relationship', 'Relationship to You', 'text', true)}
                      </div>
                    )}
                    { show_recipient_2 && (
                      <div className="form-group">
                        <h3>Name of Person Needing Care</h3>
                        {this.renderInput('recipient_2_firstname', 'Recipient of Care\'s First Name', 'text', true)}
                        {this.renderInput('recipient_2_lastname', 'Recipient of Care\'s Last Name', 'text', true)}
                        {this.renderInput('recipient_2_relationship', 'Relationship to You', 'text', true)}
                      </div>
                    )}
                    { show_recipient_3 && (
                      <div className="form-group">
                        <h3>Name of Person Needing Care</h3>
                        {this.renderInput('recipient_3_firstname', 'Recipient of Care\'s First Name', 'text', true)}
                        {this.renderInput('recipient_3_lastname', 'Recipient of Care\'s Last Name', 'text', true)}
                        {this.renderInput('recipient_3_relationship', 'Relationship to You', 'text', true)}
                      </div>
                    )}
                    { (!show_recipient_3 && !(show_recipient_2 && signing_up_for === 'self')) && (
                      <button className="theme-btn add-new-btn primary-green" onClick={this.addNewRecipientOfCare}>
                        <i className="far fa-plus-circle"></i>&nbsp;Add Another Recipient of Care
                      </button>
                    )}
                    {this.renderRecaptcha(this.onChange)}
                    {this.renderButton('Submit', 'Submitting...', 'theme-btn blue')}
                  </form>
                </div>
              )}
            </div>
          )}
          {this.state.formSubmitted && (
            <div className="inner">
              <div className="signup-completed">
                <h2>
                  Almost ready to
                  <br />
                  get started
                </h2>
                <p>
                  We just need to verify your email. Please check your inbox and
                  click the link within.
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="image"></div>
      </div>
    );
  }
}

export default Signup;
