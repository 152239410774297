import Joi from 'joi';
import React from 'react';
import { states } from '../../data';
import httpService from '../../services/httpService';
import sitterService from '../../services/sitterService';
import Form from '../shared/Form';
const radioOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
];
const maritalStatusOptions = [
    { label: 'Single', value: 'Single' },
    { label: 'Married', value: 'Married' },
    { label: 'Widowed', value: 'Widowed' },
    { label: 'Divorced', value: 'Divorced' },
];
const genderRadioOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
];
class SitterPrivateProfileForm extends Form {
    schema = Joi.object({
        phone1: Joi.number().required().label('Mobile Number'),
        emergency_contact_name: Joi.string()
            .required()
            .label('Emergency Contact Name'),
        emergency_contact_phone: Joi.number()
            .required()
            .label('Emergency Contact Phone Number'),
        address1: Joi.string().required().label('Address (line 1)'),
        address2: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Address (line 2)'),
        city: Joi.string().required().label('City'),
        state: Joi.string().required().label('State'),
        zip: Joi.string().required().label('ZIP code'),
        gender: Joi.string().required().label('Gender'),
        marital_status: Joi.string().required().label('Emergency Contact Name'),
        sms_optin: Joi.boolean()
            .truthy(1)
            .falsy(0)
            .required()
            .label('Texting Opt-in'),
        pets: Joi.boolean().truthy(1).falsy(0).required().label('Pets'),
        own_children: Joi.boolean()
            .truthy(1)
            .falsy(0)
            .required()
            .label('Own Children'),
        smoke: Joi.boolean()
            .truthy(1)
            .falsy(0)
            .required()
            .label('Household Smokers'),
        selected_medical_conditions: Joi.array()
            .optional()
            .label('Selected Medical Conditions'),
        allergy_info: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Medical Condition Info'),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            data: {
                phone1: '',
                emergency_contact_name: '',
                emergency_contact_phone: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                gender: '',
                marital_status: '',
                pets: '',
                smoke: '',
                selected_medical_conditions: [],
                allergy_info: '',
                sms_optin: '',
            },
            medicalConditions: [],
        };
    }
    getMedicalConditions = async () => {
        try {
            const response = await httpService.get('/api/medicalInfo');
            if (response.status === 200) {
                const medicalConditions = response.data.data.medical_info.map((info) => ({
                    ...info,
                    value: info.id,
                    label: info.name,
                }));
                this.setState({
                    medicalConditions,
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    submitFormData = async () => {
        try {
            const apiData = { ...this.state.data };
            const { id } = this.state;
            const data = new FormData();
            const headers = {
                headers: { 'content-type': 'multipart/form-data' },
            };
            data.append('_method', 'post');
            for (const key of Object.keys(apiData)) {
                if (typeof apiData[key] === 'object') {
                    if (key === 'avatar_filename') {
                        if (apiData[key] !== null) {
                            data.append(key, apiData[key]);
                        }
                    }
                    else {
                        data.append(key, JSON.stringify(apiData[key]));
                    }
                }
                else {
                    data.append(key, apiData[key]);
                }
            }
            return await sitterService.update(id, data, headers);
        }
        catch (error) {
            return error;
        }
    };
    renderForm = () => {
        const { medicalConditions } = this.state;
        return (React.createElement("div", { className: "sitter-private-profile-form" },
            this.renderInput('phone1', 'Mobile Number', 'number', true),
            this.renderInput('emergency_contact_name', 'Emergency Contact Full Name', 'text', true),
            this.renderInput('emergency_contact_phone', 'Emergency Contact Phone Number', 'number', true),
            this.renderInput('address1', 'Address', 'text', true),
            this.renderInput('address2', 'Apt/Suite', 'text'),
            this.renderInput('city', 'City', 'text', true),
            this.renderSelect('state', 'State', states, true),
            this.renderInput('zip', 'ZIP', 'number', true),
            this.renderRadio('sms_optin', 'Would you like to opt-in to text alerts?', radioOptions, true),
            this.renderRadio('gender', 'Gender', genderRadioOptions, true, true),
            this.renderRadio('own_children', 'Do you have any children?', radioOptions, true),
            this.renderRadio('marital_status', 'What is your marital status?', maritalStatusOptions, true),
            this.renderRadio('pets', 'Do you have any pets?', radioOptions, true),
            this.renderRadio('smoke', 'Do you smoke?', radioOptions, true),
            this.renderSelectSearch('selected_medical_conditions', 'Any allergies or medical conditions? (optional)', medicalConditions, false, {
                tooltip: 'Including your own allergies is incredibly important! By doing this, it allows our team a heads up for what sits you may not be applicable for, as well as, assists in an emergency.',
            }),
            this.renderTextarea('allergy_info', 'Notes on Allergies and Medical Conditions (optional)', false)));
    };
}
export default SitterPrivateProfileForm;
