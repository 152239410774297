// This file gets any necessary env variables, as well as sets any other necessary variables.
// Note: There is a test version of this file within /test - if you add a new variable here be sure to add it there as well
const stripeKey = process.env.MIX_STRIPE_KEY;
const baseUrl = process.env.MIX_APP_URL;
const calendlyKey = process.env.MIX_CALENDLY_API_KEY;
const calendlyUrl = 'https://calendly.com/api/v1';
const apiPrefix = '/api';
const rollbarToken = process.env.MIX_ROLLBAR_JS_TOKEN;
const appEnv = process.env.MIX_APP_ENV;
const recaptchaSiteKey = process.env.MIX_GOOGLE_RECAPTCHA_SITE_KEY;
// The url for the marketing site (for linking to privacy policy, etc)
const publicSiteUrl = process.env.MIX_MARKETING_SITE_URL;
// These are what are displayed throughout the app on the front-end
const adminPhone = '314.310.6010';
const adminEmail = 'email@stlcare.io';
const mobileBp = 992;
const config = {
    stripeKey,
    baseUrl,
    apiPrefix,
    calendlyKey,
    calendlyUrl,
    rollbarToken,
    appEnv,
    recaptchaSiteKey,
    adminPhone,
    adminEmail,
    publicSiteUrl,
};
export { stripeKey, baseUrl, apiPrefix, calendlyKey, calendlyUrl, rollbarToken, appEnv, mobileBp, recaptchaSiteKey, adminPhone, adminEmail, publicSiteUrl, };
export default config;
