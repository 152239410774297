import { ErrorBoundary, Provider } from '@rollbar/react';
import { appEnv, rollbarToken } from '@ss/config';
import React from 'react';
import { ErrorLayout } from '../ErrorLayout';
const FallbackUI = ({ error }) => (React.createElement(ErrorLayout, { title: "Something went really wrong...", errorCode: "500" },
    React.createElement("p", null,
        "We cannot display this section right now.",
        ' ',
        React.createElement("button", { className: "theme-btn blue ", onClick: () => window.location.reload() }, "Try refreshing the page"),
        ", or",
        ' ',
        React.createElement("button", { className: "theme-btn blue ", onClick: () => window.history.back() }, "going back"),
        ' ',
        "and repeating your last action. We have been notified about the issue."),
    error && React.createElement("div", { className: "error-message" }, error.message)));
const rollbarConfig = {
    captureUncaught: true,
    captureUnhandledRejections: true,
    accessToken: rollbarToken,
    environment: appEnv,
};
export const RollbarErrorReporting = ({ children, }) => {
    return (React.createElement(Provider, { config: rollbarConfig },
        React.createElement(ErrorBoundary, { fallbackUI: FallbackUI }, children)));
};
