import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { balanceActions } from '../../actions/balanceActions';
import authService from '../../services/authService';
class SelectFlow extends React.Component {
    componentDidMount() {
        this.getBalance();
        this.checkSub();
    }
    componentDidUpdate() {
        const balance = Number.parseFloat(this.props.balance.balance);
        if (balance < 0) {
            toast.error(`Uh oh! Looks like you have an outstanding balance of $${Math.abs(balance)}. You must pay this before you are allowed to book appointments.`);
            this.props.history.push('/settings/billing');
        }
    }
    getBalance = async () => {
        try {
            const { family_id } = this.props.auth.user.personal_info;
            await this.props.getBalance(family_id);
        }
        catch (error) {
            console.error(error);
        }
    };
    checkSub = async () => {
        try {
            const { id } = this.props.auth.user;
            const response = await authService.getSubscription(id);
            if (response.status === 200) {
                const { subscription } = response.data.data;
                if (!subscription) {
                    toast.error('Uh oh! Looks like your membership has expired. You must pay this before you are allowed to book appointments.');
                    this.props.history.push('/settings/billing');
                }
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    render() {
        return (React.createElement("main", { className: "booking select-flow" },
            React.createElement("div", { className: "booking-component" },
                React.createElement("div", { className: "booking-component-inner" },
                    React.createElement("div", { className: "booking-header" },
                        React.createElement("div", { className: "inner" },
                            React.createElement("h3", { className: "theme-h3" }, "Select Type of Care"))),
                    React.createElement("div", { className: "booking-body" },
                        React.createElement("div", { className: "select-flow-wrapper" },
                            React.createElement("div", { className: "flow" },
                                React.createElement("i", { className: "fas fa-calendar-star" }),
                                React.createElement("h2", null, "Casual Care"),
                                React.createElement("p", null,
                                    "Casual Care is best suited for Members looking for care on 1 or more dates, on an as-needed basis. Members may submit Casual requests with as little as a few hours of notice, or up to 60 days in advance!",
                                    React.createElement("br", null),
                                    "(Example: Transportation to a Doctors appointment, a day of petcare and errand running, or even grocery shopping. For a full list of services, visit ",
                                    React.createElement("a", { href: "stlcare.io", target: "_blank" }, "stlcare.io"),
                                    ")."),
                                React.createElement(Link, { to: "/appointments/book", className: "theme-btn slate-blue" }, "Select"),
                                React.createElement("p", { className: "small" }, "Note: All appointments require payment to the provider for the Full Amount scheduled + confirmed online. In the event a member does not utilize the full amount of time, providers are still owed for the time scheduled. All appointments require a 3-hour minimum. The Full Amount Owed policy extends to all appointments booked on our platform.")),
                            React.createElement("div", { className: "flow" },
                                React.createElement("i", { className: "fas fa-calendar-alt" }),
                                React.createElement("h2", null, "Consistent Care"),
                                React.createElement("p", null, "Consistent Care allows members to submit any appointments needed for an upcoming 6, 7, or 8-week period in which they would like our office to match them with 1-3 providers who can commit to their needs. The appointments do not have to be weekly or consistent, but typically the more consistent the schedule is, the more consistent coverage we can provide. This scheduling option is available on a rolling basis, meaning care recipients can submit their requests at any time, with whichever start date they prefer, as long as 2 weeks notice is given."),
                                React.createElement(Link, { to: "/program/book", className: "theme-btn slate-blue" }, "Select"),
                                React.createElement("p", { className: "small" }, "Note: We will need 2-Week advance notice to help recommend the best available care providers for your booked appointments."))))))));
    }
}
function mapState(state) {
    return {
        auth: state.auth,
        balance: state.balance,
    };
}
const actionCreators = {
    getBalance: balanceActions.getBalance,
};
export default connect(mapState, actionCreators)(SelectFlow);
