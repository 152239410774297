import { Grid, GridColumn, } from '@progress/kendo-react-grid';
import { findLinkGroupByTitle } from '@ss/helpers';
import logService from '@ss/services/logService';
import React from 'react';
import Sidebar from '../Sidebar';
import { ColumnMenu, DateCell } from './KendoComponents';
class LogsListing extends React.Component {
    state = {
        logs: [],
        result: [],
        loading: true,
        linkGroup: findLinkGroupByTitle('Settings'),
        dataState: {
            sort: [{ field: 'created_at', dir: 'desc' }],
            filter: {
                logic: 'and',
                filters: [],
            },
            filterOperators: {
                text: [
                    {
                        text: 'grid.filterContainsOperator',
                        operator: 'contains',
                    },
                    { text: 'grid.filterEqOperator', operator: 'eq' },
                ],
            },
            skip: 0,
            take: 50,
            total: 0,
        },
        filters: ['all', 'pending', 'submitted', 'completed', 'cancelled'],
        currentFilter: null,
    };
    componentDidMount = () => {
        const { filter } = this.props.match.params;
        this.setFilter(filter);
    };
    componentDidUpdate(_prevProps, prevState) {
        const prevCurrentFilter = prevState.currentFilter;
        const { currentFilter } = this.state;
        if (currentFilter !== prevCurrentFilter) {
            this.setState({
                loading: true,
                dataState: {
                    ...prevState.dataState,
                    sort: [{ field: 'created_at', dir: 'desc' }],
                    skip: 0,
                    take: 50,
                    total: 0,
                },
            }, () => {
                this.getLogs();
            });
        }
    }
    getLogs = async () => {
        try {
            const { currentFilter, dataState } = this.state;
            const response = await logService.get({
                status: currentFilter,
                skip: dataState.skip,
                take: dataState.take,
                sort: dataState.sort,
                filter: dataState.filter,
            });
            if (response.status === 200) {
                const { logs, count } = response.data.data;
                this.setState({
                    result: logs,
                    dataState: {
                        ...dataState,
                        total: count,
                    },
                    logs,
                    loading: false,
                });
            }
        }
        catch {
            this.setState({
                loading: false,
            });
        }
    };
    setFilter = (filter) => {
        this.setState({ currentFilter: filter ? filter : 'all' });
    };
    pageChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                skip: event.page.skip,
                take: event.page.take,
            },
        }, () => {
            this.getLogs();
        });
    };
    sortChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                sort: event.sort,
            },
        }, () => {
            this.getLogs();
        });
    };
    filterChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                filter: event.filter,
            },
        }, () => {
            this.getLogs();
        });
    };
    render() {
        const { loading, logs, linkGroup, dataState, result } = this.state;
        if (loading && logs.length === 0) {
            return (React.createElement("main", { className: "admin admin-listing with-sidebar logs" },
                React.createElement("div", { className: "sidebar-wrapper" },
                    React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-calendar-star", linkGroup: linkGroup })),
                React.createElement("div", { className: "content" },
                    React.createElement("h4", { className: "section-label" },
                        React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                        "\u00A0Loading Logs..."))));
        }
        return (React.createElement("main", { className: "admin admin-listing with-sidebar logs" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content" },
                React.createElement("h4", { className: "section-label" },
                    loading && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                    !loading && React.createElement("i", { className: "fas fa-calendar-star" }),
                    "\u00A0Viewing System Logs"),
                React.createElement("div", { className: "kendo-table" },
                    React.createElement(Grid, { data: result, pageable: true, sortable: true, ...dataState, onPageChange: this.pageChange, onSortChange: this.sortChange, onFilterChange: this.filterChange },
                        React.createElement(GridColumn, { title: "Time", field: "created_at", cell: DateCell, width: 140 }),
                        React.createElement(GridColumn, { title: "IP", field: "ip", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "User", field: "user", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Activity Type", field: "activity_type", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Action", field: "action", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Notation", field: "notation", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "ID", field: "id", width: 60 }))))));
    }
}
export default LogsListing;
