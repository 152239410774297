import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { Form } from '../../shared';
import { displayAddress } from '../../../helpers';

import { bulkBookingWeeks, states } from '../../../data';

import Radio from '../../shared/Form/Radio';
import DatePicker from '../../shared/Form/Datepicker';
import Input from '../../shared/Form/Input';
import Select from '../../shared/Form/Select';
import BooleanInput from '../../shared/Form/BooleanInput';
import ChildCheckbox from '../../shared/Form/ChildCheckbox';
import { mapAuthState } from '../../../helpers/mapAuthState';

class BulkNeeds extends Form {
  constructor(props) {
    super(props);
    this.state = {
      today: moment().format('MMMM Do'),
      minDateReadable: null,
      minDate: null,
      editingAddress: false,
      addressConfirmed: false,
    };
  }
  componentDidMount() {
    // here we need to calculate minDate - the user can only book for the first full
    // week that is at least 2 weeks out. so we calculate that here.
    // i.e. if today is Sunday, we can just go ahead 2 weeks, but if today is Monday,
    // we need to skip ahead 2 weeks and 6 days to the next Sunday.
    let rawDate = moment().add(14, 'days');
    let weekday = moment(rawDate).format('d');
    let minDate;
    if (weekday > 0) {
      minDate = moment(rawDate).add(1, 'week').startOf('week').toDate();
    } else {
      minDate = moment(rawDate).toDate();
    }
    let minDateReadable = moment(minDate).format('MMMM Do');
    this.setState({ minDate, minDateReadable });
  }
  renderWeekOutline = () => {
    const { data } = this.props;
    let elements = [];
    for (var i = 1; i <= data['program_num_weeks']; i++) {
      let startDate = moment(data['program_start_date']).add(i - 1, 'weeks');
      let startOfWeek = startDate.startOf('week').format('MMM D');
      let endOfWeek = startDate.endOf('week').format('MMM D');
      let element = (
        <div className="week" key={`week-outline-${i}`}>
          <p>
            Week {i}: {startOfWeek}&nbsp;-&nbsp;{endOfWeek}
          </p>
        </div>
      );
      elements.push(element);
    }
    return (
      <div className="week-outline">
        {elements}
        <p className="small-notice">
          Reminder: STL Care does not offer long-term care solutions due to the
          fluctuating schedules of busy college students! (See{' '}
          <a
            href="https://www.stlcare.io/members"
            target="_blank"
          >
            our FAQs page
          </a>{' '}
          for more info).
        </p>
        <p className="small-notice">
          *Although we can only guarantee a six to eight week commitment from
          our providers, families interested in extending their request for an
          additional six to eight weeks, will have the opportunity to request
          their favorite providers, and our team will coordinate based on
          availability.{' '}
        </p>
      </div>
    );
  };
  toggleEditAddress = (e) => {
    e.preventDefault();
    this.setState({
      editingAddress: !this.state.editingAddress,
    });
  };
  confirmAddress = () => {
    this.setState({
      addressConfirmed: !this.state.addressConfirmed,
    });
  };
  handleChildCheckboxChange = ({ currentTarget: input }) => {
    let children = [...this.props.data.program_selected_children];
    if (input.checked) {
      children.push(input.value);
    } else {
      //remove from array
      children = children.filter(function (item) {
        return item !== input.value;
      });
    }
    if (!children) children = [];
    this.props.handleChange('program_selected_children', children);
  };
  canAdvanceStep = () => {
    const { data } = this.props;
    const { addressConfirmed, editingAddress } = this.state;
    if (!data['program_num_weeks'] || !data['program_start_date']) return false;
    if (!!editingAddress) {
      if (
        !data['program_address1'] ||
        !data['program_city'] ||
        !data['program_state'] ||
        !data['program_zip']
      )
        return false;
    } else {
      if (!addressConfirmed) return false;
    }
    if (data['program_selected_children'].length === 0) return false;
    return true;
  };
  render() {
    const { data, errors, children } = this.props;
    const {
      today,
      minDateReadable,
      minDate,
      editingAddress,
      addressConfirmed,
    } = this.state;
    const canAdvanceStep = this.canAdvanceStep();
    return (
      <div className="bulk-booking needs">
        <div className="bulk-notice red">
          <p>
            Members can submit Consistent Care requests for any upcoming 6-8
            week period they would like to work with the same provider(s)! The
            STL Care Office requires at least two weeks advance notice to
            prepare a provider proposal and coordinate appointment logistics.
          </p>
          <div className="tooltip-wrapper">
            <div className="inner">
              <i className="fas fa-info-circle"></i>
              <div className="tooltip-item">
                <p>
                  What is a provider proposal? Once the STL Care Office compiles
                  all providers available for your request, we will outline the
                  coverage details in a proposal for your final review. This
                  proposal will also include background on the provider(s)
                  available and what the next steps are for you. *Proposals are
                  sent within 7-10 business days, or sooner!
                </p>
              </div>
            </div>
          </div>
        </div>
        {!!minDate && (
          <React.Fragment>
            <div className="needs-header">
              <p>
                Today's Date:
                <br />
                <b>{today}</b>
              </p>
              <p>
                Earliest Available Start Date:
                <br />
                <b>{minDateReadable}</b>
              </p>
            </div>
            <DatePicker
              className="program_start_date"
              name={`program_start_date`}
              label={`Desired Start Date`}
              value={data['program_start_date']}
              error={errors['program_start_date']}
              required={true}
              dateFormat={'MMMM do, yyyy'}
              onChange={(date) => {
                this.props.handleChange('program_start_date', date);
              }}
              minDate={minDate}
            />
            <Radio
              className="program_num_weeks"
              name={`program_num_weeks`}
              label="What is the duration of your Consistent Care request?"
              options={bulkBookingWeeks}
              value={data['program_num_weeks']}
              error={errors['program_num_weeks']}
              onChange={(e) => {
                this.props.handleChange(
                  'program_num_weeks',
                  Number(e.target.value)
                );
              }}
              required={true}
            />
          </React.Fragment>
        )}
        {data['program_start_date'] &&
          data['program_num_weeks'] &&
          this.renderWeekOutline()}
        <div className="program-details">
          <p>
            <b>Details:</b>
          </p>
          {editingAddress && (
            <div className="form-group address">
              <Input
                className="program_address1"
                name={`program_address1`}
                label="Address"
                type="text"
                value={data['program_address1']}
                error={errors['program_address1']}
                onChange={(e) => {
                  this.props.handleChange('program_address1', e.target.value);
                }}
                required={true}
              />
              <Input
                className="program_address2"
                name={`program_address2`}
                label="Apt/Suite"
                type="text"
                value={data['program_address2']}
                error={errors['program_address2']}
                onChange={(e) => {
                  this.props.handleChange('program_address2', e.target.value);
                }}
                required={false}
              />
              <Input
                className="program_city"
                name={`program_city`}
                label="City"
                type="text"
                value={data['program_city']}
                error={errors['program_city']}
                onChange={(e) => {
                  this.props.handleChange('program_city', e.target.value);
                }}
                required={true}
              />
              <Select
                className="program_state"
                name={`program_state`}
                label="State"
                options={states}
                value={data['program_state']}
                error={errors['program_state']}
                onChange={(e) => {
                  this.props.handleChange('program_state', e.target.value);
                }}
                required={true}
              />
              <Input
                className="program_zip"
                name={`program_zip`}
                label="ZIP"
                type="text"
                value={data['program_zip']}
                error={errors['program_zip']}
                onChange={(e) => {
                  this.props.handleChange('program_zip', e.target.value);
                }}
                required={true}
              />
            </div>
          )}
          {!editingAddress && (
            <div className="form-group address">
              <Input
                className="address"
                name={`program_address`}
                label="Address"
                type="text"
                value={displayAddress(this.props.auth.user.personal_info)}
                error={errors['driving_distance']}
                onChange={(e) => e.preventDefault()}
                required={true}
                disabled={true}
              />
              <div className="confirm-address">
                <BooleanInput
                  className="confirm_address"
                  name={`program_confirm_address`}
                  label="Confirm Address"
                  type="text"
                  value={addressConfirmed}
                  error={errors['driving_distance']}
                  onChange={this.confirmAddress}
                  required={true}
                />
                <a href="#" onClick={this.toggleEditAddress}>
                  Edit Address
                </a>
              </div>
            </div>
          )}
          <ChildCheckbox
            className="program_selected_children"
            name={`program_selected_children`}
            label="Select care recipients for this appointment"
            options={children}
            value={data['program_selected_children']}
            error={errors['program_selected_children']}
            onChange={this.handleChildCheckboxChange}
            required={true}
          />
        </div>
        <div className="booking-footer">
          {canAdvanceStep && (
            <button
              className={`theme-btn blue`}
              onClick={this.props.onFormComplete}
            >
              Next
            </button>
          )}
          {!canAdvanceStep && (
            <button
              className={`theme-btn blue disabled`}
              onClick={(e) => e.preventDefault()}
            >
              Next
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapAuthState)(BulkNeeds);
