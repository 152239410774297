export default [
    {
        name: 'Household Info',
        url: '/register/companion-care/household-info',
    },
    {
        name: 'Care Profiles',
        url: '/register/companion-care/household-info',
    },
    {
        name: 'Emergency Contacts',
        url: '/register/companion-care/emergency-contacts',
    },
    {
        name: 'Pets',
        url: '/register/companion-care/pets',
    },
    {
        name: 'Payments',
        url: '/register/companion-care/payment',
    },
];
