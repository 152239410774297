import React from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { updateRegisterStep } from '../../../helpers';
import familyService from '../../../services/familyService';
import profileCareContactService from '../../../services/profileCareContactService';
import { FormStepTracker } from '../../shared';
import trackerSteps from './trackerSteps';
import { mapAuthState } from '../../../helpers/mapAuthState';
import CareContactForm from './CareContactForm';

class CareContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: false,
      contacts: [],
      careRecipientNeeds: [],
      activeContact: null,
      creating: false,
      family_id: 0,
    };
  }
  componentDidMount() {
    const { user } = this.props.auth;
    if (!user) {
      this.sendUserBack();
    } else {
      this.getCareContacts();
      const { family_id } = user.personal_info;
      this.setState({
        family_id,
      });
    }
  }
  sendUserBack = () => {
    alert(
      "You must complete the previous form before filling out your profile. Please go back and fill out the initial registration form, then click on the link in your inbox once you've received the sign-up email."
    );
    window.open('/register/companion-care', '_self');
  };
  getCareContacts = async () => {
    this.setState({
      loading: true,
    });
    try {
      const response = await familyService.getCareContacts();

      this.setState({
        loading: true,
      });
      if (response.status === 200) {
        const { contacts } = response.data.data;
        this.setState({
          loading: false,
          contacts,
        });
      }
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };
  updateStep = async (step) => {
    const { id } = this.state.user;
    const response = await updateRegisterStep(id, step);
  };
  doSubmit = async (callback) => {
    try {
      const { data } = this.state;
      console.log(data);
      //   const response = await httpService.post('/api/profileFamily/update', data);
      callback();
      if (response.status === 200) {
        this.advanceStep();
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  advanceStep = async () => {
    try {
      const { id } = this.props.auth.user;
      const response = await this.props.updateUserStep(
        'companion-care-emergency-contacts',
        id
      );
    } catch (e) {
      console.log(e);
    }
  };
  setActiveContact = (contact) => {
    this.setState({
      activeContact: contact,
    });
  };
  onProfileComplete = () => {
    this.setState(
      {
        activeContact: null,
      },
      this.getCareContacts
    );
  };
  toggleCreatingContact = () => {
    this.setState(
      {
        creating: !this.state.creating,
      },
      () => {
        if (!this.state.creating) {
          this.getCareContacts();
        }
      }
    );
  };
  handleCancel = () => {
    this.setState({
      activeContact: null,
      creating: false,
    });
  };
  removeContact = async (contact) => {
    console.log(contact);
    try {
      const response = await profileCareContactService.delete(contact.profile_care_contact.id);
      if(response.status === 204) {
        this.getCareContacts();
        toast.success('Care contact has been removed');
      };
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    const { contacts, loading, activeContact, creating } = this.state;

    const canProceed = contacts.length > 0;

    const { user } = this.props.auth;

    return (
      <div className="registration family step-2 profile">
        <div className="form">
          <div className="inner">
            <FormStepTracker steps={trackerSteps} currentStep={2} />
            {!!activeContact && !creating && (
              <CareContactForm
                contact={activeContact}
                onProfileComplete={this.onProfileComplete}
                handleCancel={this.handleCancel}
              />
            )}
            {creating && (
              <CareContactForm onProfileComplete={this.toggleCreatingContact} handleCancel={this.handleCancel} />
            )}
            {!activeContact && !creating && (
              <>
                <div className="form-header">
                  <h2>Emergency Contacts</h2>
                  <p>
                    In case of an emergency, who should we contact? One contact
                    is required.
                  </p>
                </div>
                <div className="care-contact-wrapper">
                  {contacts.length === 0 && !!loading && (
                    <p>Loading emergency contacts...</p>
                  )}
                  {contacts.length === 0 && !loading && (
                    <p>No emergency contacts added yet.</p>
                  )}
                  {contacts.map((contact) => (
                    <div
                      className="care-contact-onboarding"
                      key={`contact-${contact.id}`}
                    >
                      <p><b>{contact.firstname}&nbsp;{contact.lastname}</b></p>
                      <p>{contact.phone1}&nbsp;(mobile)</p>
                      <p>{contact.phone2}&nbsp;(secondary)</p>
                      <p>{contact.profile_care_contact.relationship}</p>
                      <div className="actions">
                      <a href="#" className="edit" onClick={(e) => {e.preventDefault();this.setActiveContact(contact);}}>edit</a>
                        { user.personal_info.id !== contact.id && (
                          <a href="#" className="remove" onClick={(e) => {e.preventDefault();this.removeContact(contact)}}>remove</a>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                {contacts.length < 3 && (
                  <button
                    className="theme-btn primary-green add-new-btn"
                    onClick={this.toggleCreatingContact}
                  >
                    <i className="far fa-plus-circle"></i>&nbsp;Add Emergency
                    Contact
                  </button>
                )}
                <div className="form-header">
                  <h2>Authorized Contacts</h2>
                  <p>
                    Who is allowed to contact the office requesting information
                    on the companion?
                  </p>
                </div>
                <div className="care-contact-wrapper">
                  {contacts
                    .filter((c) => !!c.profile_care_contact.authorized_contact)
                    .map((contact) => (
                      <div
                        className="care-contact-onboarding"
                        key={`contact-${contact.id}`}
                      >
                        <p><b>{contact.firstname}&nbsp;{contact.lastname}</b></p>
                        <p>{contact.phone1}&nbsp;(mobile)</p>
                        <p>{contact.phone2}&nbsp;(secondary)</p>
                        <p>{contact.profile_care_contact.relationship}</p>
                        <div className="actions">
                          <a href="#" className="edit" onClick={(e) => {e.preventDefault();this.setActiveContact(contact);}}>edit</a>
                          { user.personal_info.id !== contact.id && (
                            <a href="#" className="remove" onClick={(e) => {e.preventDefault();this.removeContact(contact);}}>remove</a>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="care-contact-btn">
                  <button
                    className="theme-btn blue tall submit-btn"
                    disabled={!canProceed}
                    onClick={this.advanceStep}
                  >
                    Next
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const actionCreators = {
  updateUserStep: authActions.updateUserStep,
};

export default connect(mapAuthState, actionCreators)(CareContacts);
