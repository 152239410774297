import $ from 'jquery';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import httpService from '../../../services/httpService';
import Form from '../../shared/Form';

import Joi from 'joi';
import { mapAuthState } from '../../../helpers/mapAuthState';

class CareContactForm extends Form {
  schema = Joi.object({
    firstname: Joi.string().required().label('First Name'),
    lastname: Joi.string().required().label('Last Name'),
    phone1: Joi.string().required().label('Mobile Number'),
    phone2: Joi.string().required().label('Secondary Number'),
    relationship: Joi.string().required().label('Relationship'),
    authorized_contact: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .required()
      .label('Authorized'),
  });
  constructor(props) {
    super(props);
    this.state = {
      data: {
        firstname: '',
        lastname: '',
        phone1: '',
        phone2: '',
        relationship: '',
        authorized_contact: false,
      },
      errors: {},
    };
  }
  componentDidMount() {
    const { user } = this.props.auth;
    if (!user) {
      this.sendUserBack();
    } else {
      this.mergeData();
    }
  }
  mergeData = () => {
    const { contact } = this.props;
    const data = { ...this.state.data };
    if (!!contact) {
      const { firstname, lastname, phone1, phone2, profile_care_contact } = contact;
      const { relationship, authorized_contact } = profile_care_contact;

      data['firstname'] = firstname;
      data['lastname'] = lastname;
      if (!!phone1) data['phone1'] = phone1;
      if (!!phone2) data['phone2'] = phone2;
      if (!!relationship) data['relationship'] = relationship;
      if (!!authorized_contact) data['authorized_contact'] = authorized_contact;

      this.setState({ data });
    }
  };
  sendUserBack() {
    alert('You must sign up or log in to view this page.');
    window.open('/', '_self');
  }
  doSubmit = async (callback) => {
    let apiData = { ...this.state.data };
    const { family_id } = this.props.auth.user.personal_info;
    apiData['family_id'] = family_id;

    const url = !!this.props.contact
      ? `/api/profileCareContact/update/${this.props.contact.profile_care_contact.id}`
      : '/api/profileCareContact/store';

    try {
      const response = await httpService.post(url, apiData);
      console.log(response);
      callback();
      // "add child" has response 201
      // "update child" has response 200
      if (response.status === 200 || response.status === 201) {
        this.props.onProfileComplete();
      } else {
        $('.registration').animate({ scrollTop: 0 });
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  handleCancel = (e) => {
    e.preventDefault();
    this.props.handleCancel();
  };
  render() {
    const { submitting } = this.state;
    return (
      <div className="care-profile-form">
        <div className="form-header">
          <h2>Emergency Contacts</h2>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group justify-space-between">
            <h3>Add Emergency Contact</h3>
            {this.renderInput('firstname', 'First Name', 'text', true)}
            {this.renderInput('lastname', 'Last Name', 'text', true)}
            {this.renderInput('phone1', 'Mobile Phone', 'number', true)}
            {this.renderInput(
              'phone2',
              'Secondary Phone (Work)',
              'number',
              true
            )}
            {this.renderInput('relationship', 'Relationship', 'text', true)}
            {this.renderBoolean(
              'authorized_contact',
              'Add contact as approved to request information on recipients of care.'
            )}
          </div>
          <div className="form-field button next-step">
            {!!submitting && (
              <button
                type="submit"
                className="theme-btn blue"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <i className="fas fa-spinner fa-spin"></i> Saving...
              </button>
            )}
            {!submitting && (
              <button
                type="submit"
                className="theme-btn blue"
                onClick={this.handleSubmit}
              >
                Save
              </button>
            )}
            <br/>
            <br/>
            <button
              className="theme-btn slate-blue small"
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const actionCreators = {
  updateUserStep: authActions.updateUserStep,
};

export default connect(mapAuthState, actionCreators)(CareContactForm);
