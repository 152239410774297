import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

import communicationsService from '../../../services/communicationsService';

import Textarea from '../../shared/Form/Textarea';
import Select from '../../shared/Form/Select';
import { upperCaseFirst } from '../../../helpers';
import { mapAuthState } from '../../../helpers/mapAuthState';

class ProgramCommunications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      communications: [],
      communication: '',
      loading: true,
      selectedSitter: null,
    };
  }
  componentDidMount() {
    if (this.props.type === 'family') {
      this.getCommunications();
    } else {
      const { sitters } = this.props;
      if (sitters.length > 0) {
        this.setState(
          {
            selectedSitter: `${sitters[0].id}`,
          },
          this.getCommunications
        );
      }
    }
  }
  getCommunications = async () => {
    const { type, bookingId } = this.props;
    const { selectedSitter } = this.state;
    try {
      let sitterPiid = type === 'sitter' ? selectedSitter : null;
      const response = await communicationsService.get(
        'booking',
        bookingId,
        type,
        sitterPiid
      );

      if (response.status === 200) {
        const { communications } = response.data.data;
        this.setState({
          communications,
          loading: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  sendNewMessage = async () => {
    const { type, bookingId, auth } = this.props;
    const from_piid = auth.user.personal_info.id;
    const { communication, selectedSitter } = this.state;
    if (!!communication) {
      try {
        const data = {
          model_id: bookingId,
          from_piid: from_piid,
          model_type: 'booking',
          comm_type: type,
          from_type: 'admin',
          to_type: type,
          message: communication,
        };
        if (type === 'sitter') {
          data['to_piid'] = selectedSitter;
        }
        const response = await communicationsService.store(data);
        if (response.status === 201) {
          this.setState({ communication: '' });
          toast.success(
            `Hooray! Your Communication has been sent to the ${type}.`
          );
          this.getCommunications();
        }
      } catch (e) {
        console.log(e);
        toast.error('Uh oh! Something went wrong!');
      }
    } else {
      toast.error('You must enter a message before sending it.');
    }
  };
  handleChange = (e) => {
    const value = e.target.value;
    this.setState({
      communication: value,
    });
  };
  selectSitter = (e) => {
    const value = e.target.value;
    this.setState(
      {
        selectedSitter: value,
      },
      () => {
        if (!!value) this.getCommunications();
      }
    );
  };
  render() {
    const { type, sitters } = this.props;
    const { communication, communications, selectedSitter, loading } =
      this.state;
    return (
      <div className={`admin-communications ${type}`}>
        <div className="communications-section">
          <h5>Send Message to {type === 'sitter' ? 'Provider' : 'Family'}</h5>
          {type === 'sitter' && (
            <Select
              className="program_sitter_comm_selection"
              name={`program_sitter_comm_selection`}
              label="Select Provider"
              options={sitters}
              value={selectedSitter}
              error={false}
              onChange={this.selectSitter}
              required={true}
              tooltip={`Only sitters who signed up can be messaged`}
            />
          )}
          {((type === 'sitter' && !!selectedSitter) || type === 'family') && (
            <React.Fragment>
              <Textarea
                className="communication"
                name={`program-${type}-communication`}
                label={'Message'}
                value={communication}
                onChange={this.handleChange}
                required={false}
              />
              <button
                className="theme-btn small slate-blue"
                onClick={this.sendNewMessage}
              >
                Send
              </button>
            </React.Fragment>
          )}
        </div>
        <div className="communications-section">
          <h5>History</h5>
          {!!loading && type === 'sitter' && (
            <p>Please select a provider to load Communications.</p>
          )}
          {!!loading && type === 'family' && <p>Loading Communications...</p>}
          {!loading && communications.length === 0 && (
            <p>No communications yet.</p>
          )}
          {communications.map((communication) => (
            <div
              className="communication-item"
              key={`comm-${communication.id}`}
            >
              <h4>From {upperCaseFirst(communication.from_type)}</h4>
              <p>{communication.message}</p>
              <p className="info">
                Note By {communication.firstname}&nbsp;
                {communication.lastname}
              </p>
              <p className="info">
                {moment(communication.created_at).format(
                  'dddd M/D/YYYY [at] h:mm a'
                )}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(mapAuthState)(ProgramCommunications);
