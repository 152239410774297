import Joi from 'joi';
import $ from 'jquery';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from 'react-stripe-elements';
import { toast } from 'react-toastify';
import { authActions } from '../../../actions/authActions';
import { publicSiteUrl } from '../../../config';
import { states } from '../../../data';
import { mapAuthState } from '../../../helpers/mapAuthState';
import stripeService from '../../../services/stripeService';
import { CouponChecker, PaymentHelper } from '../../shared';

const fieldStyles = {
  fontFamily: "'Open Sans', sans-serif",
  fontSize: '16px',
  color: '#252525',
  '::placeholder': {
    color: '#FFF',
  },
};

class PaymentForm extends PaymentHelper {
  schema = Joi.object({
    firstname: Joi.string().required().label('First Name'),
    lastname: Joi.string().required().label('Last Name'),
    address1: Joi.string().required().label('Address (line 1)'),
    address2: Joi.string()
      .optional()
      .allow(null)
      .allow('')
      .label('Address (line 2)'),
    city: Joi.string().required().label('City'),
    state: Joi.string().required().label('State'),
    zip: Joi.string().required().label('ZIP code'),
    hear_about_us: Joi.string().required().label('Hear About Us'),
    read_member_agreement: Joi.number()
      .integer()
      .min(1)
      .max(1)
      .required()
      .messages({
        'number.greater':
          'You must acknowledge that you have read the member agreement to continue.',
        'number.lesser':
          'You must acknowledge that you have read the member agreement to continue.',
        'number.min':
          'You must acknowledge that you have read the member agreement to continue.',
        'number.max':
          'You must acknowledge that you have read the member agreement to continue.',
      })
      .label('Member Agreement'),
    read_privacy_policy: Joi.number()
      .integer()
      .min(1)
      .max(1)
      .required()
      .messages({
        'number.greater':
          'You must acknowledge that you have read the privacy policy to continue.',
        'number.lesser':
          'You must acknowledge that you have read the privacy policy to continue.',
        'number.min':
          'You must acknowledge that you have read the privacy policy to continue.',
        'number.max':
          'You must acknowledge that you have read the privacy policy to continue.',
      })
      .label('Privacy Policy'),
    initials: Joi.string().required().label('Initials'),
  });

  constructor(props) {
    super(props);
    this.state = {
      data: {
        firstname: '',
        lastname: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        hear_about_us: '',
        initials: '',
      },
      totalFee: 120,
      actualFee: 120,
      editBillingAddress: true,
      coupon: false,
      errors: {},
    };
  }

  componentDidMount() {
    const { user } = this.props.auth;
    if (!user) {
      this.sendUserBack();
    } else {
      const { firstname, lastname, address1, address2, city, state, zip } =
        user.personal_info;
      const { data } = this.state;
      data['firstname'] = firstname;
      data['lastname'] = lastname;
      data['address1'] = address1;
      data['address2'] = address2;
      data['city'] = city;
      data['state'] = state;
      data['zip'] = zip;
      this.setState({ data });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    //
  }
  sendUserBack() {
    alert('You must sign up or log in to view this page.');
    window.open('/', '_self');
  }
  doSubmit = async (callback) => {
    console.log('Good to go');
    let display_error = false;
    try {
      // convert amount to cents
      const { actualFee, coupon } = this.state;
      const { firstname, lastname, address1, address2, city, state, zip } =
        this.state.data;

      const cardNumber = this.props.elements.getElement('cardNumber');

      let { paymentMethod, error } =
        await this.props.stripe.createPaymentMethod({
          type: 'card',
          card: cardNumber,
          billing_details: {
            name: firstname + ' ' + lastname,
            address: {
              line1: address1,
              line2: address2,
              city,
              postal_code: zip,
              state,
            },
          },
        });
      if (error) {
        this.handleStripeError(error);
        callback();
      } else {
        const paymentData = {
          paymentMethod: paymentMethod,
          amount: actualFee,
        };

        if (coupon !== false) {
          paymentData['coupon'] = coupon;
        }

        const response = await stripeService.chargeCustomer(
          '/payment/family',
          paymentData
        );

        if (response.status === 200 && response.data.payment_accepted == true) {
          this.advanceStep();
        } else {
          toast.error(
            'Your card was declined. Please check your information and try again.'
          );
          callback();
        }

        callback();
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  handleStripeError = (errorObj) => {
    const { code } = errorObj;
    if (code === 'incomplete_number') {
      //
    }
    if (code === 'incomplete_expiry') {
      //
    }
    if (code === 'incomplete_cvc') {
      //
    }
    $('.registration').animate({ scrollTop: 0 });
  };
  advanceStep = async (e) => {
    if (!!e) {
      e.preventDefault();
    }
    try {
      const { id } = this.props.auth.user;
      const response = await this.props.updateUserStep(
        'companion-care-payment',
        id
      );
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };
  toggleEditBilling = () => {
    this.setState({
      editBillingAddress: !this.state.editBillingAddress,
    });
  };
  applyCouponCode = (coupon) => {
    const { type, amount } = coupon;
    const { totalFee } = this.state;
    let actualFee = totalFee;
    if (type === 'percentage') {
      actualFee = Math.max(0, totalFee - totalFee * amount);
    } else if (type === 'amount') {
      actualFee = Math.max(0, totalFee - amount);
    }
    this.setState({
      coupon,
      actualFee,
    });
  };
  displayRenewDate = () => {
    return moment().add(1, 'year').format('MMMM, DD YYYY');
  };
  render() {
    const { coupon, editBillingAddress, totalFee, actualFee, data, errors } =
      this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group left-align">
          <h3>Billing Address</h3>
          {!editBillingAddress && (
            <div className="billing-address">
              <p>
                {data.firstname} {data.lastname}
                <br />
                {data.address1} {data.address2}
                <br />
                {data.city}, {data.state} {data.zip}
              </p>
              <button
                className="theme-btn small blue"
                onClick={this.toggleEditBilling}
              >
                Change
              </button>
            </div>
          )}
          {!!editBillingAddress && (
            <React.Fragment>
              {this.renderInput('firstname', 'First Name', 'text', true)}
              {this.renderInput('lastname', 'Last Name', 'text', true)}
              {this.renderInput('address1', 'Address', 'text', true)}
              {this.renderInput('address2', 'Apt/Suite', 'text')}
              {this.renderInput('city', 'City', 'text', true)}
              {this.renderSelect('state', 'State', states, true)}
              {this.renderInput('zip', 'ZIP', 'number', true)}
            </React.Fragment>
          )}
        </div>
        <div className="form-group justify-space-between">
          <h3>Payment Info</h3>
          <div className="payment-fields">
            <div className="payment-field card-number">
              <CardNumberElement
                className="card-number"
                style={{ base: fieldStyles }}
              />
              <label>
                Card Number
                <span className="required">&nbsp;*</span>
              </label>
            </div>
            <div className="payment-field card-expiry">
              <CardExpiryElement
                className="card-expiry"
                style={{ base: fieldStyles }}
              />
              <label>
                Expiration Date
                <span className="required">&nbsp;*</span>
              </label>
            </div>
            <div className="payment-field card-cvc-num">
              <CardCvcElement
                className="card-cvc-num"
                style={{ base: fieldStyles }}
              />
              <label>
                CSV<span className="required">&nbsp;*</span>
              </label>
            </div>
          </div>
        </div>
        <div className="form-group justify-space-between">
          <h3>Coupons</h3>
          <CouponChecker
            className="onboarding"
            type="onboarding"
            required={false}
            applyCouponCode={this.applyCouponCode}
          />
          {this.renderInput(
            'hear_about_us',
            'How did you hear about us?',
            'text',
            true
          )}
        </div>
        <div className="form-group accept-terms">
          <h3>Terms of Service</h3>
          <p>
            Due to the highly sensitive nature of our service, we request each
            Member to review our policies and agree to the terms of our Member
            Agreement.
          </p>
          <p>Please acknowledge and initial the following:</p>
          <div className={`form-field boolean checkbox read_member_agreement`}>
            <span>
              <input
                name="read_member_agreement"
                id="read_member_agreement"
                type="checkbox"
                checked={data['read_member_agreement'] > 0 ? true : false}
                className="checkbox"
                onChange={this.handleBooleanChange}
              />
              <label htmlFor="read_member_agreement">
                <div className="checkbox">
                  {!!data['read_member_agreement'] && (
                    <i className="far fa-check"></i>
                  )}
                </div>
                I have read the{' '}
                <a
                  href={`${publicSiteUrl}/terms-conditions`}
                  target="_blank"
                >
                  Member Agreement
                </a>
                <span className="required">&nbsp;*</span>
              </label>
            </span>
            {!!errors['read_member_agreement'] && (
              <span className="error" role="alert">
                <strong>{errors['read_member_agreement']}</strong>
              </span>
            )}
          </div>
          <div className={`form-field boolean checkbox ${name}`}>
            <span>
              <input
                name="read_privacy_policy"
                id="read_privacy_policy"
                type="checkbox"
                checked={data['read_privacy_policy'] > 0 ? true : false}
                className="checkbox"
                onChange={this.handleBooleanChange}
              />
              <label htmlFor="read_privacy_policy">
                <div className="checkbox">
                  {!!data['read_privacy_policy'] && (
                    <i className="far fa-check"></i>
                  )}
                </div>
                I have read the{' '}
                <a href={`${publicSiteUrl}/privacy-policy`} target="_blank">
                  Privacy Policy
                </a>
                <span className="required">&nbsp;*</span>
              </label>
            </span>
            {!!errors['read_privacy_policy'] && (
              <span className="error" role="alert">
                <strong>{errors['read_privacy_policy']}</strong>
              </span>
            )}
          </div>
          {this.renderInput('initials', 'Initials', 'text', true)}
        </div>
        <div className="complete-registration">
          <h3>Total registration fee</h3>
          {coupon === false && (
            <h4>${parseFloat(actualFee).toFixed(2)} for One-Year Membership</h4>
          )}
          {coupon !== false && (
            <h4>
              <span>${parseFloat(totalFee).toFixed(2)}</span>
              &nbsp;${parseFloat(actualFee).toFixed(2)} (
              {coupon.type === 'percentage'
                ? coupon.amount * 100 + '%'
                : '$' + coupon.amount}{' '}
              off) for One-Year Membership
            </h4>
          )}
          <p>Will auto-renew on {this.displayRenewDate()}</p>
          {this.renderButton(
            'Pay And Start Membership',
            'Submitting...',
            'theme-btn blue',
            'far fa-check-circle'
          )}
        </div>
      </form>
    );
  }
}

const actionCreators = {
  updateUserStep: authActions.updateUserStep,
};

export default connect(mapAuthState, actionCreators)(injectStripe(PaymentForm));
