import { get } from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { displayProfileColor, mapAuthState } from '@ss/helpers';
import familyService from '@ss/services/familyService';
import FamilyAccountSettingsForm from '../../Forms/FamilyAccountSettingsForm';
import { ProfileColorPicker } from '../../shared';
const AvatarDisplayComponent = (props) => {
    return (React.createElement("div", { className: "avatar" },
        React.createElement("div", { className: "avatar-wrapper" },
            props.value && React.createElement("img", { src: props.value }),
            !props.value && React.createElement("i", { className: "far fa-camera" }))));
};
class AccountSettings extends FamilyAccountSettingsForm {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            loading: true,
            piid: null,
            creating: true,
            family_id: null,
            profileFamily: null,
            personalInfo: null,
            activityOptions: [],
        };
    }
    componentDidMount = () => {
        this.getFamilyDetails();
    };
    getFamilyDetails = async () => {
        try {
            const { family_id, id } = this.props.auth.user.personal_info;
            const response = await familyService.get(family_id, id);
            if (response.status === 200) {
                this.formatData(response.data.data);
            }
            this.setState({ loading: false });
        }
        catch (error) {
            console.error(error);
        }
    };
    formatData = ({ profileFamily, personalInfo, }) => {
        const { sexual_criminal_offense, smoke, secondary_email, house_rules, sitter_preference, sss_initiative, address1, address2, city, state, zip, phone1, secondary_phone, id: family_id, } = profileFamily;
        const { id: piid, avatar_filename, profile_color, employer } = personalInfo;
        this.setState({
            data: {
                avatar_filename,
                profile_color,
                address1,
                address2,
                city,
                state,
                zip,
                phone1,
                secondary_phone,
                employer,
                sexual_criminal_offense,
                smoke,
                secondary_email,
                house_rules,
                sitter_preference,
                sss_initiative,
            },
            profile_color: profile_color || 'primary-coral',
            personalInfo,
            profileFamily,
            piid,
            family_id,
        });
    };
    handleProfileColorChange = (colorClass) => {
        this.setState((prevState) => ({
            ...prevState,
            data: { ...prevState.data, profile_color: colorClass },
        }));
    };
    getIsUserPrimary = () => {
        const piid = this.props.auth.user.personal_info.id;
        const ownerPiid = get(this.state, 'profileFamily.owner.id', false);
        return piid === ownerPiid;
    };
    doSubmit = async (callback) => {
        try {
            await this.submitFormData();
            callback();
            toast.success('Hooray! Your changes are saved.');
            location.reload();
        }
        catch (error) {
            console.error(error);
            callback();
        }
    };
    render() {
        const { data, loading } = this.state;
        if (loading) {
            return null;
        }
        return (React.createElement("main", { className: "account profile settings" },
            React.createElement("form", { onSubmit: this.handleSubmit },
                React.createElement("div", { className: `profile-header ${displayProfileColor(data.profile_color, 'gradient')}` },
                    React.createElement("div", { className: "inner" },
                        this.renderFile('avatar_filename', 'Upload Your Public Profile Image', 'image/jpg, image/png', false, AvatarDisplayComponent),
                        React.createElement("h2", { className: "theme-h2" }, "My Household"))),
                React.createElement("div", { className: "profile-body" },
                    React.createElement("h2", null, "Account Settings"),
                    React.createElement(ProfileColorPicker, { value: data.profile_color, onProfileColorChange: this.handleProfileColorChange }),
                    this.renderForm(true, true),
                    this.renderButton('Save', 'Saving...', 'theme-btn blue'),
                    React.createElement("div", { className: "cancel-btn" },
                        React.createElement(Link, { className: "theme-btn small slate-blue", to: "/profile" }, "Cancel"))))));
    }
}
export default connect(mapAuthState)(AccountSettings);
