import { Grid, GridColumn, } from '@progress/kendo-react-grid';
import React from 'react';
import { findLinkGroupByTitle } from '@ss/helpers';
import appointmentService from '@ss/services/appointmentService';
import { ColumnMenu, DateCell, FamilyCell, } from '../Appointments/KendoComponents';
import Sidebar from '../Sidebar';
import ProgramManageBtn from './ProgramManageBtn';
import ProgramStatusCell from './ProgramStatusCell';
const INITIAL_DATA_STATE = {
    sort: [{ field: 'created_at', dir: 'desc' }],
    filter: {
        logic: 'and',
        filters: [],
    },
    filterOperators: {
        text: [
            { text: 'grid.filterContainsOperator', operator: 'contains' },
            { text: 'grid.filterEqOperator', operator: 'eq' },
        ],
    },
    skip: 0,
    take: 50,
    total: 0,
};
class ProgramsGrid extends React.Component {
    state = {
        bookings: [],
        result: [],
        loading: true,
        linkGroup: findLinkGroupByTitle('Programs'),
        dataState: { ...INITIAL_DATA_STATE },
        filters: [
            'all',
            'new',
            'assigning',
            'proposal-sent',
            'accepted',
            'rejected',
            'completed',
            'cancelled',
        ],
        currentFilter: 'all',
    };
    componentDidMount = () => {
        const { filter } = this.props.match.params;
        const { search } = this.props.history.location;
        if (search) {
            const urlParams = new URLSearchParams(search);
            const skip = urlParams.get('skip');
            const filterObj = JSON.parse(urlParams.get('filter'));
            const { dataState } = this.state;
            dataState.filter = filterObj;
            dataState.skip = Number(skip);
            const currentFilter = filter ? filter : 'all';
            this.setState({
                dataState,
                currentFilter,
            }, () => {
                this.getBookings();
            });
        }
        else {
            this.setFilter(filter);
        }
    };
    componentDidUpdate(prevProps) {
        const { filter } = this.props.match.params;
        const prevFilter = prevProps.match.params.filter;
        if (filter !== prevFilter) {
            this.setFilter(filter);
        }
    }
    getBookings = async () => {
        const { currentFilter, dataState } = this.state;
        try {
            const response = await appointmentService.getBookings('program', {
                status: currentFilter,
                skip: dataState.skip,
                take: dataState.take,
                sort: dataState.sort,
                filter: dataState.filter,
            });
            if (response.status === 200) {
                const { bookings, count } = response.data.data;
                this.setState({
                    result: bookings,
                    dataState: {
                        ...dataState,
                        total: count,
                    },
                    bookings,
                    loading: false,
                });
            }
        }
        catch (error) {
            console.error(error);
            this.setState({
                loading: false,
            });
        }
    };
    setFilter = (filter) => {
        const currentFilter = filter ? filter : 'all';
        this.setState({
            currentFilter,
            loading: true,
            dataState: { ...INITIAL_DATA_STATE },
        }, () => {
            this.getBookings();
        });
    };
    pageChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                skip: event.page.skip,
                take: event.page.take,
            },
        }, () => {
            this.getBookings();
        });
    };
    sortChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                sort: event.sort,
            },
        }, () => {
            this.getBookings();
        });
    };
    filterChange = (event) => {
        this.setState({
            dataState: {
                ...INITIAL_DATA_STATE,
                filter: event.filter,
                skip: 0,
            },
        }, () => {
            this.getBookings();
        });
    };
    render() {
        const { loading, currentFilter, bookings, linkGroup, dataState, result } = this.state;
        if (loading && bookings.length === 0) {
            return (React.createElement("main", { className: "admin admin-listing with-sidebar appointments" },
                React.createElement("div", { className: "sidebar-wrapper" },
                    React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-calendar-star", linkGroup: linkGroup })),
                React.createElement("div", { className: "content" },
                    React.createElement("h4", { className: "section-label" },
                        React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                        "\u00A0Loading Programs..."))));
        }
        return (React.createElement("main", { className: "admin admin-listing with-sidebar appointments" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content" },
                React.createElement("h4", { className: "section-label" },
                    loading && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                    !loading && React.createElement("i", { className: "fas fa-calendar-star" }),
                    "\u00A0Viewing",
                    ' ',
                    currentFilter === 'assigning' ? 'Open' : currentFilter,
                    " Program Bookings"),
                React.createElement("div", { className: "filter-btn-wrapper" },
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('all');
                        } }, "All"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('new');
                        } }, "New"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('assigning');
                        } }, "Open"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('proposal-sent');
                        } }, "Proposal Sent"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('accepted');
                        } }, "Accepted"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('rejected');
                        } }, "Rejected"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('completed');
                        } }, "Completed"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('cancelled');
                        } }, "Cancelled")),
                React.createElement("div", { className: "kendo-table" },
                    React.createElement(Grid, { data: result, pageable: true, sortable: true, ...dataState, onPageChange: this.pageChange, onSortChange: this.sortChange, onFilterChange: this.filterChange },
                        React.createElement(GridColumn, { title: "Status", field: "status", sortable: false, cell: ProgramStatusCell, width: 125 }),
                        React.createElement(GridColumn, { title: "Family", field: "family", cell: (props) => (React.createElement(FamilyCell, { ...props, ...this.props, var: "family" })), columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Start Date", field: "start_date", cell: DateCell, width: 100 }),
                        React.createElement(GridColumn, { title: "End Date", field: "end_date", cell: DateCell, width: 100 }),
                        React.createElement(GridColumn, { title: "Total Appts", field: "appointments", width: 125 }),
                        React.createElement(GridColumn, { title: "Date Submitted", field: "created_at", cell: DateCell, width: 150 }),
                        React.createElement(GridColumn, { title: "ID", field: "id", width: 60 }),
                        React.createElement(GridColumn, { title: " ", field: "id", width: 100, cell: (props) => (React.createElement(ProgramManageBtn, { ...props, ...this.props })) }))))));
    }
}
export default ProgramsGrid;
