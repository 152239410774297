import { each } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import { findLinkGroupByTitle, makeTimeIncrements } from '../../../helpers';
import appointmentService from '../../../services/appointmentService';
import familyService from '../../../services/familyService';
import { Avatar, Form } from '../../shared';
import Calendar from '../../shared/Appointments/Calendar';
import Sidebar from '../Sidebar';
import AppointmentRow from './AppointmentRow';
import Joi from 'joi';

class AdminBooking extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        booking_appointments: [],
      },
      freshSchema: {
        id: null,
        all_children: 1,
        start_date: new Date(),
        end_date: new Date(),
        start_time: null,
        end_time: null,
        flex: 0,
        flex_room: null,
        driving_needed: 0,
        driving_distance: null,
        selected_sitters: null,
        selected_children: [],
      },
      errors: {},
      children: [],
      profileFamily: {},
      owner: {},
      pets: [],
      sitters: [],
      appointments: [],
      loading: true,
      linkGroup: findLinkGroupByTitle('Families'),
      times: makeTimeIncrements(0),

      calendarDate: moment(),
      canDecreaseDate: false,
      months: [moment(), moment().add(1, 'months'), moment().add(2, 'months')],
      today: moment(),

      sameProvider: false,
      primaryProvider: null,
      sameStart: false,
      sameEnd: false,
      startTime: null,
      endTime: null,
    };
  }
  schema = Joi.object({
    booking_appointments: Joi.array()
      .items(
        Joi.object({
          id: Joi.number(), // id is not required and will be stripped out before it is sent along
          all_children: Joi.boolean()
            .truthy(1)
            .falsy(0)
            .label('All Recipients'),
          start_date: Joi.date().required().label('Start Date'),
          end_date: Joi.date().required().label('End Date'),
          start_time: Joi.string().required().label('Start Time'),
          end_time: Joi.string().required().label('End Time'),
          flex: Joi.boolean().truthy(1).falsy(0).label('Flex'),
          flex_room: Joi.number()
            .when('flex', {
              is: 1,
              then: Joi.required(),
              otherwise: Joi.optional().allow('').allow(null),
            })
            .label('Wiggle Room'),
          driving_needed: Joi.boolean().truthy(1).falsy(0).label('Driving?'),
          driving_distance: Joi.number()
            .when('driving_needed', {
              is: 1,
              then: Joi.required(),
              otherwise: Joi.optional().allow('').allow(null),
            })
            .label('Driving Distance'),
          selected_children: Joi
            // .array()
            .required()
            // .min(1)
            // .messages({
            // 	'array.min': "You must select at least 1 child for this sit.",
            // })
            .label('Selected Children'),
          selected_sitters: Joi.object().required().label('Selected Sitters'),
          created_at: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Created At'),
        })
      )
      .min(1)
      .messages({
        'array.min': 'You must select at least ',
      })
      .label('Booking Appointments'),
  });
  componentDidMount = () => {
    this.getFamily();
    this.getAppointments();
    window.onbeforeunload = () => {
      const { dirty } = this.state;
      return dirty
        ? 'Changes will be LOST if you navigate away from the page. Are you sure you want to proceed?'
        : null;
    };
  };
  componentWillUnmount = () => {
    window.onbeforeunload = () => {
      return null;
    };
  };
  getFamily = async () => {
    try {
      const { id, booking_id } = this.props.match.params;
      const response = await familyService.get(id);
      if (response.status === 200) {
        const { profileFamily, parents, children, pets, owner, appointments } =
          response.data.data;
        this.setState({
          profileFamily,
          parents,
          pets,
          children,
          owner,
          appointments: booking_id ? [] : appointments,
          loading: false,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        loading: false,
      });
    }
  };
  getAppointments = async () => {
    try {
      const { booking_id } = this.props.match.params;
      let { data } = this.state;
      if (booking_id) {
        const response = await appointmentService.getBooking(booking_id);
        if (response.status === 200) {
          const { programAppointments } = response.data.data;
          data['booking_appointments'] = programAppointments;
          this.setState({
            data,
            loading: false,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  addNewAppointment = (e) => {
    e.preventDefault();
    const { data } = this.state;
    const freshSchema = { ...this.state.freshSchema };
    // we assign it a random id here so that we can reference that instead of its index
    // using its index in the array causes problems with splicing
    freshSchema.id = Math.round(Math.random() * 10000000);
    const selected_children = [];
    each(this.state.children, (child) => {
      selected_children.push(child.id + '');
    });
    freshSchema.selected_children = selected_children;
    if (this.state.sameProvider) {
      freshSchema.selected_sitters = this.state.primaryProvider;
    }
    if (this.state.sameStart) {
      freshSchema.start_time = this.state.startTime;
    }
    if (this.state.sameEnd) {
      freshSchema.end_time = this.state.endTime;
    }
    data['booking_appointments'].push(freshSchema);
    this.setState({
      data,
      dirty: true,
    });
  };
  onAppointmentDataChange = (idx, apptData) => {
    const { data } = this.state;
    data['booking_appointments'][idx] = apptData;
    if (idx === 0 && this.state.sameProvider) {
      this.setState({
        data,
        primaryProvider: apptData.selected_sitters,
      });
    } else {
      this.setState({ data });
    }
  };
  removeAppointment = (idx) => {
    const { data } = this.state;
    const { booking_appointments } = data;
    booking_appointments.splice(idx, 1);
    data['booking_appointments'] = booking_appointments;
    let dirty = booking_appointments.length > 0;
    this.setState({ data, dirty });
  };
  setPrimaryProvider = (sitter = null) => {
    const sameProvider = !!sitter;
    this.setState(
      {
        sameProvider: sameProvider,
        primaryProvider: sitter,
      },
      () => {
        if (!!sameProvider) this.setFields('selected_sitters');
      }
    );
  };
  setTime = (key, time) => {
    const sameTime = !!time;
    if (key === 'start_time') {
      this.setState(
        {
          sameStart: sameTime,
          startTime: time,
        },
        () => {
          if (!!sameTime) this.setFields('start_time');
        }
      );
    } else {
      this.setState(
        {
          sameEnd: sameTime,
          endTime: time,
        },
        () => {
          if (!!sameTime) this.setFields('end_time');
        }
      );
    }
  };
  setFields = (key) => {
    const masterAppt = this.state.data.booking_appointments[0];
    const value = masterAppt[key];
    const { data } = this.state;
    const { booking_appointments } = data;
    each(booking_appointments, (appt, idx) => {
      if (!appt[key]) booking_appointments[idx][key] = value;
    });
    data['booking_appointments'] = booking_appointments;
    this.setState({ data });
  };
  validateForm = () => {
    this.setState({
      submitting: true,
    });
    const errors = this.validate();
    if (errors) {
      console.error(errors);
      toast.error('Oops! Looks like a few fields need some attention.');
      this.setState({
        errors: errors || {},
        submitting: false,
      });
      return;
    }
    this.doSubmit(this.postSubmit);
  };
  loadOldMonths = () => {
    if (this.state.canDecreaseDate) {
      let firstMonth = this.state.months[0];
      let earliestMonth = moment(firstMonth).subtract(3, 'months');
      let months = [
        earliestMonth,
        moment(firstMonth).subtract(2, 'months'),
        moment(firstMonth).subtract(1, 'months'),
      ];
      if (earliestMonth.isSameOrBefore(this.state.today)) {
        this.setState({
          months,
          canDecreaseDate: false,
        });
      } else {
        this.setState({
          months,
          canDecreaseDate: true,
        });
      }
    }
  };
  loadMoreMonths = () => {
    let lastMonth = this.state.months[this.state.months.length - 1];
    let months = [
      moment(lastMonth).add(1, 'months'),
      moment(lastMonth).add(2, 'months'),
      moment(lastMonth).add(3, 'months'),
    ];
    this.setState({
      months,
      canDecreaseDate: true,
    });
  };
  onSelectedDay = (currentDate) => {
    if (moment(currentDate).isSameOrAfter(this.state.today)) {
      const { data } = this.state;
      toast.dismiss();
      let date = currentDate.toDate();
      const freshSchema = { ...this.state.freshSchema };
      // we assign it a random id here so that we can reference that instead of its index
      // using its index in the array causes problems with splicing
      freshSchema.id = Math.round(Math.random() * 10000000);
      freshSchema.start_date = date;
      freshSchema.end_date = date;
      const selected_children = [];
      each(this.state.children, (child) => {
        selected_children.push(child.id + '');
      });
      freshSchema.selected_children = selected_children;
      if (this.state.sameProvider) {
        freshSchema.selected_sitters = this.state.primaryProvider;
      }
      if (this.state.sameStart) {
        freshSchema.start_time = this.state.startTime;
      }
      if (this.state.sameEnd) {
        freshSchema.end_time = this.state.endTime;
      }
      data['booking_appointments'].push(freshSchema);
      this.setState({ data });
    } else {
      toast.error('Uh oh! You must select a date after today.');
    }
  };
  doSubmit = async (callback) => {
    try {
      const piid = this.state.owner.id;
      const { booking_id } = this.props.match.params;
      let rawAppointments = [...this.state.data.booking_appointments];
      const { owner } = this.state;
      let appointments = [];
      each(rawAppointments, (rawAppointment) => {
        let appointment = { ...rawAppointment };
        appointment['start_time'] = moment(
          `${appointment['start_date']} ${appointment['start_time']}`,
          'YYYY-MM-DD HH:mm:ss'
        ).format();
        appointment['end_time'] = moment(
          `${appointment['end_date']} ${appointment['end_time']}`,
          'YYYY-MM-DD HH:mm:ss'
        ).format();
        // delete appointment['id'];
        delete appointment['all_children'];
        delete appointment['date'];
        appointment['address1'] = owner.address1;
        appointment['address2'] = owner.address2;
        appointment['city'] = owner.city;
        appointment['state'] = owner.state;
        appointment['zip'] = owner.zip;
        const sitterId =
          appointment.selected_sitters.piid !== undefined
            ? appointment.selected_sitters.piid
            : appointment.selected_sitters.id;
        if (typeof appointment.selected_children === 'string') {
          appointment['selected_children'] =
            appointment.selected_children.split(',');
        }
        appointment['selected_sitters'] = [sitterId];
        appointments.push(appointment);
      });
      const apiData = {
        booking_appointments: appointments,
        booking_id: booking_id ? booking_id : null,
        type: booking_id ? 'program' : 'regular',
      };
      const response = await appointmentService.create(piid, apiData);
      callback();
      if (response.status === 200) {
        let msg = `Hooray! Your appointment${
          appointments.length > 1 ? 's have' : ' has'
        } been saved.`;
        toast.success(msg);
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  render() {
    const { props } = this;
    const {
      loading,
      linkGroup,
      children,
      profileFamily,
      owner,
      sitters,
      data,
      times,
      canDecreaseDate,
      calendarDate,
      months,
      appointments,
      dirty,
    } = this.state;
    const { booking_appointments } = data;

    const { booking_id } = this.props.match.params;

    // this is for displaying on the calendar
    let consolidatedAppointments = [...appointments, ...booking_appointments];
    // console.log(consolidatedAppointments);
    if (!!loading) {
      return (
        <main className="admin admin-detail with-sidebar family-booking">
          <div className="sidebar-wrapper">
            <Sidebar
              {...props}
              iconClass="fas fa-id-card"
              linkGroup={linkGroup}
            />
          </div>
          <div className="content">
            <h4 className="section-label">Loading Family Details...</h4>
          </div>
        </main>
      );
    }

    return (
      <main className="admin admin-detail with-sidebar admin-family-booking">
        <Prompt
          when={dirty}
          message="Changes will be LOST if you navigate away from the page. Are you sure you want to proceed?"
        />
        <div className="sidebar-wrapper">
          <Sidebar
            {...props}
            iconClass="fas fa-id-card"
            linkGroup={linkGroup}
          />
        </div>
        <div className="content">
          <div className="appt-people">
            <div className="admin-avatar-section family">
              <h4 className="section-label">
                Regular Bookings
                {booking_id ? ' #' + booking_id : ''}
              </h4>
              <div className="wrapper">
                <Avatar
                  imageSrc={profileFamily.avatar_filename}
                  profileColor={owner.profile_color}
                />
                <div className="info">
                  <h1>{profileFamily.name}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="admin-booking-form" onSubmit={this.handleSubmit}>
            <div className="calendar-view">
              <div className="calendar-wrapper">
                <a
                  className={`arrow prev ${!canDecreaseDate ? 'disabled' : ''}`}
                  href="#"
                  onClick={this.loadOldMonths}
                >
                  <i className="fas fa-arrow-alt-left"></i>
                </a>
                <a
                  className={`arrow next`}
                  href="#"
                  onClick={this.loadMoreMonths}
                >
                  <i className="fas fa-arrow-alt-right"></i>
                </a>
                <div className="calendars">
                  {months.map((month, i) => {
                    return (
                      <Calendar
                        key={i}
                        appointments={consolidatedAppointments}
                        showAppointments={false}
                        date={month}
                        onSelectedDay={this.onSelectedDay}
                        type="booking"
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="appointment-fields">
              <div className="appointment-rows">
                {booking_appointments.map((appointment, idx) => (
                  <AppointmentRow
                    key={`appt-${appointment.id}`}
                    appointment={appointment}
                    idx={idx}
                    children={children}
                    sitters={sitters}
                    times={times}
                    onAppointmentDataChange={this.onAppointmentDataChange}
                    setPrimaryProvider={this.setPrimaryProvider}
                    setTime={this.setTime}
                    removeAppointment={this.removeAppointment}
                  />
                ))}
              </div>
            </div>
            <div className="btn-wrapper">
              <button
                onClick={this.addNewAppointment}
                className="theme-btn small slate-blue"
              >
                Add Day
              </button>
            </div>
            <div className="submit-wrapper">
              <div className="form-field button submit">
                <button
                  type="submit"
                  className="theme-btn primary-green auto-width"
                  disabled={this.state.submitting}
                  onClick={this.validateForm}
                >
                  {this.state.submitting && (
                    <i className="fas fa-spinner fa-spin"></i>
                  )}
                  {!this.state.submitting && <i className="fas fa-save"></i>}
                  {this.state.submitting ? 'Saving...' : 'Save'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default AdminBooking;
