import moment from 'moment-timezone';
import React from 'react';
import { toast } from 'react-toastify';
import { findLinkGroupByTitle } from '../../../helpers';
import { profileCareContactService } from '../../../services';
import familyService from '../../../services/familyService';
import personalInfoService from '../../../services/personalInfoService';
import CareContactForm from '../../Forms/CareContactForm';
import ReactModal from '../../shared/ReactModal';
import Sidebar from '../Sidebar';
class CareContactsEdit extends CareContactForm {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            saving: false,
            deleting: false,
            isOpen: false,
            linkGroup: findLinkGroupByTitle('Families'),
            type: '',
            loading: true,
            cancelling: false,
            showCancel: false,
            sending: false,
            id: null,
            creating: false,
            family_id: null,
        };
    }
    componentDidMount = () => {
        const { id, family_id } = this.props.match.params;
        const parsedId = Number.parseInt(id, 10);
        if (Number.isNaN(parsedId) === false) {
            // abusing a quirk of JS where "11" == 11 to ensure the ID is a number
            this.getProfileCareContact(parsedId);
        }
        else {
            this.setState({
                data: { ...this.state.freshSchema },
                creating: true,
                family_id,
            });
        }
    };
    getProfileCareContact = async (id) => {
        try {
            const response = await profileCareContactService.get(id);
            this.formatData(response.data.data);
        }
        catch (error) {
            console.error(error);
        }
    };
    formatData = ({ personalInfo, profileCareContact, user, subscription, }) => {
        const { data } = this.state;
        const { firstname, lastname, phone1, phone2, type, family_id } = personalInfo;
        const { id, relationship, authorized_contact } = profileCareContact;
        data.firstname = firstname;
        data.lastname = lastname;
        data.phone1 = phone1;
        data.phone2 = phone2;
        data.relationship = relationship;
        data.authorized_contact = authorized_contact;
        this.setState({
            data,
            type,
            user,
            subscription,
            id,
            family_id,
        });
    };
    doSubmit = async (callback) => {
        this.setState({ saving: true });
        try {
            await this.submitFormData();
            toast.success('Hooray! The care recipient was updated!');
            this.goBack();
            callback();
        }
        catch (error) {
            toast.error('Oops! Something went wrong. Please try again.');
            console.error(error);
        }
        this.setState({ saving: false });
    };
    closeModal = () => {
        this.setState({ isOpen: false, showCancel: false });
    };
    toggleCancelSubscription = () => {
        this.setState({
            showCancel: !this.state.showCancel,
        });
    };
    cancelSubscription = async () => {
        this.setState({
            cancelling: true,
        });
        try {
            const { id } = this.state.user;
            const response = await familyService.cancelSubscription(id);
            console.log(response);
            if (response.status === 200) {
                const { user } = response.data.data;
                toast.success("The user's subscription has been cancelled.");
                this.setState({
                    showCancel: false,
                    cancelling: false,
                    user,
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    deleteContact = async () => {
        const { id } = this.state;
        this.setState({ deleting: true });
        const data = new FormData();
        const headers = { headers: { 'content-type': 'multipart/form-data' } };
        data.append('_method', 'delete');
        const response = await personalInfoService.delete(id, data, headers);
        if (response.status === 204) {
            toast.success('Hooray! The care recipient was deleted.');
            this.goBack();
        }
        else {
            toast.error('Oops! Looks like something went wrong.');
        }
        this.setState({ deleting: false });
    };
    goBack = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.props.history.goBack();
    };
    render() {
        const { saving, deleting, linkGroup, isOpen, creating, cancelling, showCancel, user, subscription, } = this.state;
        return (React.createElement("main", { className: "dashboard admin manage" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content" },
                React.createElement("div", { className: "providers" },
                    React.createElement("h2", null,
                        creating === false ? 'Edit' : 'Create',
                        " Contact"),
                    React.createElement("div", { className: "providers-form form" },
                        this.renderForm(),
                        React.createElement("div", { className: "divider" }),
                        user && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "subscription-cancellation" },
                                !!user && !!user.requested_subscription_cancellation && (React.createElement("p", null, "This user has requested a subscription cancellation.")),
                                !subscription ||
                                    (subscription && subscription.ends_at === null && (React.createElement("button", { type: "button", className: "theme-btn blue large save-btn", disabled: cancelling, onClick: this.toggleCancelSubscription },
                                        cancelling && (React.createElement("i", { className: "fas fa-spinner fa-spin" })),
                                        cancelling
                                            ? ' Cancelling...'
                                            : 'Cancel Subscription'))),
                                subscription && subscription.ends_at !== null && (React.createElement("div", null,
                                    React.createElement("b", null, "Subscription ends on:"),
                                    ' ',
                                    moment(subscription.ends_at).format('MMMM Do, YYYY'))),
                                subscription &&
                                    subscription.ends_at === null &&
                                    subscription.renews_at !== null && (React.createElement("div", null,
                                    React.createElement("b", null, "Subscription renews on:"),
                                    ' ',
                                    moment(subscription.renews_at).format('MMMM Do, YYYY'))),
                                user.cancel_reason && (React.createElement("div", null,
                                    React.createElement("b", null, "Cancel Reason:"),
                                    " ",
                                    user.cancel_reason))))),
                        React.createElement("div", { className: "divider" }),
                        React.createElement("div", { className: "align_center row" },
                            React.createElement("button", { type: "button", className: "theme-btn blue large save-btn", disabled: saving, onClick: this.handleSubmit },
                                saving && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                saving ? ' Saving...' : 'Save'),
                            creating === false && (React.createElement("button", { type: "button", className: "theme-btn red large save-btn", disabled: deleting, onClick: () => this.setState({ isOpen: true }) },
                                deleting && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                deleting ? ' Deleting...' : 'Delete'))))),
                React.createElement(ReactModal, { title: "Confirm Delete", isOpen: isOpen, closeModal: this.closeModal, content: 'Are you sure you want to delete this contact?', primaryButton: deleting ? ' Deleting...' : 'Delete', secondaryButton: 'Close', handleClick: this.deleteContact }),
                React.createElement(ReactModal, { title: "Confirm Subscription Cancellation", isOpen: showCancel, closeModal: this.toggleCancelSubscription, content: "Are you sure you want to cancel this user's subscription?", primaryButton: cancelling ? ' Cancelling...' : 'Cancel Subscription', secondaryButton: 'Close', handleClick: this.cancelSubscription }))));
    }
}
export default CareContactsEdit;
