import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { displayAge, displayAppointmentDuration, displayAppointmentTime, firstInitial, slugify, } from '../../../helpers';
import { Avatar } from '../../shared';
const getMonthClass = (appointment) => {
    const startDate = moment(appointment.start_time, 'YYYY-MM-DD HH:mm:ss').format('MMM');
    return startDate.toLowerCase();
};
const displayChildAges = (children) => {
    return children.map((child) => displayAge(child.DOB, 'yo', 'mo')).join(' / ');
};
export const AppointmentListingRow = (props) => {
    const { appointment, type } = props;
    const selectedSitter = appointment.sitters?.[0];
    const showSitter = selectedSitter &&
        ['submitted', 'pending', 'pending-list', 'pending-list-approval'].includes(appointment.status) === false;
    return (React.createElement("div", { className: `appointment-list-item ${slugify(appointment.status)}` },
        React.createElement("div", { className: 'date-day ' + getMonthClass(appointment) },
            !!appointment.overnight && !!appointment.num_nights && (React.createElement("div", { className: "inner" },
                React.createElement("h3", null,
                    moment(appointment.start_time).format('MMM D'),
                    "\u00A0-\u00A0",
                    React.createElement("span", null, moment(appointment.end_time).format('MMM D'))),
                React.createElement("h3", { className: "weekday" },
                    moment(appointment.start_time).format('ddd'),
                    "\u00A0-\u00A0",
                    React.createElement("span", null, moment(appointment.end_time).format('ddd'))))),
            (!appointment.overnight || !appointment.num_nights) && (React.createElement("div", { className: "inner" },
                React.createElement("h3", null, moment(appointment.start_time).format('MMM D')),
                React.createElement("h3", { className: "weekday" }, moment(appointment.start_time).format('ddd'))))),
        React.createElement("div", { className: "time" },
            appointment.status === 'time change requested' && (React.createElement("p", { className: "time-change" },
                React.createElement("span", { className: "desktop" },
                    React.createElement("i", { className: "fas fa-exclamation-circle" }),
                    "\u00A0Change Pending",
                    React.createElement("br", null)),
                displayAppointmentTime(appointment, true),
                React.createElement("br", null),
                React.createElement("span", { className: "duration" },
                    "(",
                    displayAppointmentDuration(appointment, true),
                    ")"))),
            appointment.status !== 'time change requested' && (React.createElement("p", null,
                displayAppointmentTime(appointment),
                React.createElement("br", null),
                React.createElement("span", { className: "duration" },
                    "(",
                    displayAppointmentDuration(appointment),
                    ")")))),
        type === 'family' && (React.createElement("div", { className: "sitters" },
            showSitter && (React.createElement(React.Fragment, null, appointment.sitters.map((sitter) => (React.createElement("div", { className: "sitter", key: `sitter-${sitter.id}` },
                React.createElement(Avatar, { imageSrc: sitter.avatar_filename, profileColor: sitter.profileColor ?? sitter.profile_color }),
                React.createElement("div", { className: "name" },
                    React.createElement("p", null, `${sitter.firstname} ${firstInitial(sitter.lastname)}`))))))),
            !showSitter && (React.createElement("div", { className: "sitter none" },
                React.createElement("div", { className: "name" },
                    React.createElement("p", { className: "pending" },
                        React.createElement("i", { className: "far fa-clock" }),
                        "\u00A0Pending")))))),
        type === 'sitter' && (React.createElement("div", { className: "family-wrapper" },
            React.createElement("div", { className: "family" },
                React.createElement(Avatar, { imageSrc: appointment.family.avatar_filename }),
                React.createElement("div", { className: "name" },
                    React.createElement("p", null,
                        appointment.family.name,
                        React.createElement("br", null),
                        React.createElement("span", null, displayChildAges(appointment.children))))))),
        React.createElement("div", { className: "manage" },
            React.createElement(Link, { to: {
                    pathname: `/appointment/${appointment.id}`,
                    state: { appointmentView: true },
                } },
                React.createElement("i", { className: "fas fa-arrow-right" }))),
        appointment.status === 'time change requested' && (React.createElement("div", { className: "appointment-status-notice time-change-request" },
            React.createElement("p", { className: "time-change" },
                React.createElement("i", { className: "fas fa-exclamation-circle" }),
                "\u00A0Time Change Pending"))),
        (appointment.status === 'pending-cover' ||
            appointment.status === 'cover-acceptance-needed') && (React.createElement("div", { className: "appointment-status-notice cover-request always-visible" },
            React.createElement("p", { className: "pending-cover" },
                React.createElement("i", { className: "fas fa-exclamation-circle" }),
                "\u00A0Sub Requested"))),
        appointment.appointment_review?.status === 'pending' && (React.createElement("div", { className: "appointment-list-item__actions" },
            React.createElement(Link, { to: `/review/${appointment.appointment_review.id}`, className: "theme-btn small blue" }, "Complete Provider Feedback")))));
};
