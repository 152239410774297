import { sortBy } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import { stripeKey } from '../../../config';
import {
  displayAge,
  firstInitial,
  upperCaseFirst,
  slugify,
} from '../../../helpers';
import appointmentService from '../../../services/appointmentService';
import communicationsService from '../../../services/communicationsService';
import sitterService from '../../../services/sitterService';
import stripeService from '../../../services/stripeService';
import { Avatar, Modal, SitterProfile } from '../../shared';
import Calendar from '../../shared/Appointments/Calendar';
import Textarea from '../../shared/Form/Textarea';
import ProgramFeeForm from './ProgramFeeForm';

const fontsObj = [
  {
    cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans&display=swap',
  },
];

class ProgramDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      submitting: false,
      accepted: true, // we assume they want to accept the program, and do special things if they reject it.
      newProgramStatus: false,
      showCancelModal: false,

      cancel_reason: '',

      appointments: [],
      communications: [],
      communication: '',

      currentSitter: null,
      currentTab: 'calendar',

      family: null,
      children: [],
      booking: null,
      owner: null,

      canDecreaseDate: false,
      months: [moment(), moment().add(1, 'months'), moment().add(2, 'months')],

      programStartDate: false,
      programEndDate: false,

      showSubmitModal: false,
      hasStripeUser: false,
    };
  }
  componentDidMount() {
    this.getAppointments();
    this.getCommunications();

    if (this.props.windowWidth >= 1200 && this.state.numMonths != 3) {
      this.setState({
        numMonths: 3,
        months: [
          moment(),
          moment().add(1, 'months'),
          moment().add(2, 'months'),
        ],
      });
    } else if (this.props.windowWidth < 1200 && this.state.numMonths != 1) {
      this.setState({
        numMonths: 1,
        months: [moment()],
      });
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.windowWidth != prevProps.windowWidth) {
      if (this.props.windowWidth >= 1200 && this.state.numMonths != 3) {
        this.setState({
          numMonths: 3,
          months: [
            moment(),
            moment().add(1, 'months'),
            moment().add(2, 'months'),
          ],
        });
      } else if (this.props.windowWidth < 1200 && this.state.numMonths != 1) {
        this.setState({
          numMonths: 1,
          months: [moment()],
        });
      }
    }
  };
  getAppointments = async () => {
    try {
      const { id } = this.props.match.params;
      if (!!id) {
        const response = await appointmentService.getBooking(id);
        if (response.status === 200) {
          const { bookingAppointments, family, children, booking, owner } =
            response.data.data;
          let sortedAppts = sortBy(bookingAppointments, [
            'start_time',
            'end_time',
          ]);
          let programStartDate = moment(sortedAppts[0].start_time);
          let programEndDate = moment(
            sortedAppts[sortedAppts.length - 1].start_time
          );
          this.setState({
            loading: false,
            family,
            children,
            booking,
            owner,
            appointments: sortedAppts,
            programStartDate,
            programEndDate,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  getCommunications = async () => {
    try {
      const { id } = this.props.match.params;
      const response = await communicationsService.get('booking', id, 'family');
      console.log(response);
      if (response.status === 200) {
        const { communications } = response.data.data;
        this.setState({
          communications,
          communication: '',
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  displayChildrenAges = (children) => {
    const childAges = [];
    if (!!children) {
      children.map((child) => {
        const childAge = displayAge(child.DOB, 'yo', 'mo');
        childAges.push(childAge);
      });
      return childAges.join(' / ');
    }
    return '';
  };
  loadOldMonths = () => {
    if (this.state.canDecreaseDate) {
      let firstMonth = this.state.months[0];
      let earliestMonth = moment(firstMonth).subtract(
        this.state.numMonths,
        'months'
      );
      let months;
      if (this.state.numMonths === 3) {
        months = [
          earliestMonth,
          moment(firstMonth).subtract(2, 'months'),
          moment(firstMonth).subtract(1, 'months'),
        ];
      } else {
        months = [earliestMonth];
      }
      if (earliestMonth.isSameOrBefore(this.state.today, 'month')) {
        this.setState({
          months,
          canDecreaseDate: false,
        });
      } else {
        this.setState({
          months,
          canDecreaseDate: true,
        });
      }
    }
  };
  loadMoreMonths = () => {
    let lastMonth = this.state.months[this.state.months.length - 1];
    let months;
    if (this.state.numMonths === 3) {
      months = [
        moment(lastMonth).add(1, 'months'),
        moment(lastMonth).add(2, 'months'),
        moment(lastMonth).add(3, 'months'),
      ];
    } else {
      months = [moment(lastMonth).add(1, 'months')];
    }
    this.setState({
      months,
      canDecreaseDate: true,
    });
  };
  toggleSubmitModal = () => {
    this.setState({ showSubmitModal: !this.state.showSubmitModal });
  };
  displaySitter = (sitters = []) => {
    if (sitters.length > 0) {
      let sitter = sitters[0];
      return (
        <div className="program-col sitter">
          <div
            className="sitter-wrapper"
            onClick={() => {
              this.getSitterProfile(sitter.id);
            }}
          >
            <Avatar
              imageSrc={sitter.avatar_filename}
              profileColor={sitter.profile_color}
            />
            <div className="info">
              <h3>
                {sitter.firstname}&nbsp;
                {firstInitial(sitter.lastname)}
              </h3>
              <h4 className="section-label">
                <i className="fas fa-address-card"></i>
                &nbsp;View Profile
              </h4>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="program-col sitter">
        <h4>Provider</h4>
        <div className="sitter-wrapper pending">
          <p>
            <i className="far fa-clock"></i>&nbsp;Pending
          </p>
        </div>
      </div>
    );
  };
  getSitterProfile = async (sitterPiid) => {
    try {
      const response = await sitterService.get(sitterPiid);
      console.log(response);
      if (response.status === 200) {
        const { personalInfo, profileSitter } = response.data.data;
        const currentSitter = {
          ...personalInfo,
          profile_sitter: { ...profileSitter },
        };
        this.setState({
          currentSitter: currentSitter,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  closeCurrentSitter = () => {
    this.setState({
      currentSitter: null,
    });
  };
  getStripeCustomer = async () => {
    try {
      const { id } = this.props.auth.user;
      const response = await stripeService.get(id);
      if (response.status === 200) {
        const { user, stripeUser } = response.data.data;
        this.setState({
          stripeUser,
          user,
          hasStripeUser: true,
          changingPaymentInfo: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  updateProgramStatus = async () => {
    try {
      const piid = this.state.owner.id;
      const booking_id = this.props.match.params.id;
      const { appointments, accepted, newProgramStatus } = this.state;
      const apiData = {
        booking_id,
        type: 'program',
        //suppress: !accepted,
        status: newProgramStatus,
        booking_appointments: appointments,
      };
      if (newProgramStatus === 'cancelled') {
        apiData['cancel_reason'] = this.state.cancel_reason;
      }
      const response = await appointmentService.create(piid, apiData);
      if (response.status === 200) {
        this.getAppointments();
        let message = '';
        if (newProgramStatus === 'accepted')
          message =
            'Hooray! Your fee has been paid and your consistent care request has been booked!';
        else if (newProgramStatus === 'rejected')
          message =
            'This Program Proposal has been rejected. An Admin will be in contact shortly.';
        else if (newProgramStatus === 'cancelled') {
          this.setState({
            showCancelModal: false,
          });
          message = 'This Consistent Care Request has been cancelled.';
        }
        toast.success(message);
      } else {
        console.log('Error');
      }
      this.setState(
        {
          submitting: false,
          showSubmitModal: false,
        },
        this.getAppointments
      );
    } catch (e) {
      console.log(e);
      this.setState(
        {
          submitting: false,
          showSubmitModal: false,
        },
        this.getAppointments
      );
      toast.error('Uh oh! Something went wrong!');
    }
  };
  acceptProposal = () => {
    this.setState(
      {
        accepted: true,
        newProgramStatus: 'accepted',
      },
      this.updateProgramStatus
    );
  };
  rejectProposal = () => {
    this.setState(
      {
        accepted: false,
        newProgramStatus: 'rejected',
      },
      this.updateProgramStatus
    );
  };
  sendNewMessage = async () => {
    const { communication } = this.state;
    if (!!communication) {
      const bookingId = this.props.match.params.id;
      const fromPiid = this.props.auth.user.personal_info.id;
      try {
        const data = {
          model_id: bookingId,
          from_piid: fromPiid,
          model_type: 'booking',
          comm_type: 'family',
          from_type: 'family',
          to_type: 'admin',
          message: communication,
        };
        const response = await communicationsService.store(data);
        console.log(response);
        if (response.status === 201) {
          toast.success(
            `Hooray! Your Communication has been sent to the admins and will be reviewed shortly.`
          );
          this.getCommunications();
        }
      } catch (e) {
        console.log(e);
        toast.error('Uh oh! Something went wrong!');
      }
    } else {
      toast.error('You must enter a message before sending it.');
    }
  };
  handleChange = (e) => {
    const value = e.target.value;
    this.setState({
      communication: value,
    });
  };
  setCurrentTab = (tab) => {
    this.setState({
      currentTab: tab,
    });
  };
  toggleCancelModal = () => {
    this.setState({
      showCancelModal: !this.state.showCancelModal,
    });
  };
  cancelProgram = () => {
    if (!this.state.cancel_reason) {
      toast.error('You must specify a cancel reason.');
    } else {
      this.setState(
        {
          accepted: false,
          newProgramStatus: 'cancelled',
        },
        this.updateProgramStatus
      );
    }
  };
  handleCancelReasonChange = (e) => {
    this.setState({
      cancel_reason: e.target.value,
    });
  };
  render() {
    const {
      loading,
      appointments,
      months,
      canDecreaseDate,
      family,
      signingUp,
      children,
      booking,
      programStartDate,
      programEndDate,
      unavailableAppointments,
      showSubmitModal,
      hasStripeUser,
      communication,
      communications,
      currentSitter,
      currentTab,
      showCancelModal,
      cancel_reason,
    } = this.state;
    const { balance } = this.props.balance;
    const { windowWidth } = this.props;

    if (!!loading) {
      return (
        <main className="user-programs program-detail family">
          <div className="mobile-header">
            <h4 className="section-label">
              <i className="fas fa-spinner fa-spin"></i>
              &nbsp;Loading Your Consistent Care Request...
            </h4>
          </div>
          <div className="program-inner">
            <h4 className="section-label">
              <i className="fas fa-spinner fa-spin"></i>
              &nbsp;Loading Your Consistent Care Request...
            </h4>
          </div>
        </main>
      );
    }

    return (
      <main className="user-programs program-detail family">
        <div className="mobile-header">
          <h4 className="section-label">
            <i className="fas fa-calendar-star"></i>&nbsp;Your Consistent Care
            Request #{booking.id}
          </h4>
          <div className={`mobile-tab-selection`}>
            <div
              className={`mobile-tab calendar-tab ${
                currentTab === 'calendar' ? 'active' : ''
              }`}
              onClick={() => {
                this.setCurrentTab('calendar');
              }}
            >
              <h3>
                <i className="far fa-calendar-alt"></i>
                &nbsp;Calendar
              </h3>
              <hr />
            </div>
            <div
              className={`mobile-tab details-tab ${
                currentTab === 'details' ? 'active' : ''
              }`}
              onClick={() => {
                this.setCurrentTab('details');
              }}
            >
              <h3>
                <i className="far fa-list-alt"></i>&nbsp;Details
              </h3>
              <hr />
            </div>
          </div>
        </div>
        {(windowWidth > 1199 || currentTab === 'calendar') && (
          <div className="program-inner">
            <h4 className="section-label">
              <i className="fas fa-calendar-star"></i>&nbsp;Your Consistent Care
              Request #{booking.id}
            </h4>
            <div className="program-calendar">
              <div className="calendar-wrapper">
                <button
                  className={`arrow prev ${!canDecreaseDate ? 'disabled' : ''}`}
                  onClick={this.loadOldMonths}
                >
                  <i className="fas fa-arrow-alt-left"></i>
                </button>
                <button className={`arrow next`} onClick={this.loadMoreMonths}>
                  <i className="fas fa-arrow-alt-right"></i>
                </button>
                <div className="calendars">
                  {months.map((month, i) => {
                    return (
                      <Calendar
                        key={i}
                        appointments={appointments}
                        showAppointments={false}
                        date={month}
                        onSelectedDay={this.onSelectedDay}
                        type="program"
                        unavailableAppointments={unavailableAppointments}
                        signingUp={signingUp}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="program-appointments">
              {appointments.map((appointment) => (
                <div
                  className="program-appointment"
                  key={`program-appointment-${appointment.id}`}
                >
                  <div className="program-col date">
                    <h4>Day</h4>
                    <p>
                      {!!appointment.overnight
                        ? `${moment(appointment.start_time).format(
                            'MMM Do'
                          )} - ${moment(appointment.end_time).format('MMM Do')}`
                        : moment(appointment.start_time).format('MMMM Do')}
                    </p>
                  </div>
                  <div className="program-col start">
                    <h4>Start Time</h4>
                    <p>{moment(appointment.start_time).format('h:mm a')}</p>
                  </div>
                  <div className="program-col end">
                    <h4>End Time</h4>
                    <p>{moment(appointment.end_time).format('h:mm a')}</p>
                  </div>
                  <div className="program-col wiggle">
                    <h4>Wiggle Room?</h4>
                    <p>
                      {!!appointment.flex
                        ? `Yes (${appointment.flex_room} mins)`
                        : 'No'}
                    </p>
                  </div>
                  <div className="program-col driving">
                    <h4>Driving?</h4>
                    <p>
                      {!!appointment.driving_needed
                        ? `Yes (${appointment.driving_distance} mi)`
                        : 'No'}
                    </p>
                  </div>
                  <div className="program-col children">
                    <h4>Age{appointment.children.length > 1 ? 's' : ''}</h4>
                    <p>{this.displayChildrenAges(appointment.children)}</p>
                  </div>
                  {this.displaySitter(appointment.sitters)}
                </div>
              ))}
            </div>
            {!!currentSitter && (
              <div className="sitter-profile-modal">
                <div
                  className="close-profile"
                  onClick={this.closeCurrentSitter}
                >
                  <i className="fas fa-times"></i>
                </div>
                <div className="profile-wrapper">
                  <SitterProfile
                    data={{
                      personalInfo: currentSitter,
                      profileSitter: currentSitter.profile_sitter,
                    }}
                    omitActions
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {(windowWidth > 1199 || currentTab === 'details') && (
          <div className="program-sidebar sidebar-right">
            {booking.status === 'proposal-sent' && (
              <div className="sidebar-actions">
                <button
                  className="theme-btn primary-green tall"
                  onClick={this.toggleSubmitModal}
                >
                  <i className="fas fa-check"></i>&nbsp;Accept Proposal
                </button>
                <button
                  className="theme-btn error-red small"
                  onClick={this.rejectProposal}
                >
                  <i className="fas fa-times"></i>&nbsp;Reject
                </button>
                <Modal
                  title="Accept Consistent Care Proposal"
                  isOpen={showSubmitModal}
                  closeModal={this.toggleSubmitModal}
                  content={
                    <StripeProvider apiKey={stripeKey}>
                      <Elements fonts={fontsObj}>
                        <ProgramFeeForm
                          booking={booking}
                          appointments={appointments}
                          hasStripeUser={hasStripeUser}
                          onSuccessfulPayment={this.acceptProposal}
                        />
                      </Elements>
                    </StripeProvider>
                  }
                  secondaryButton={'Close'}
                />
              </div>
            )}
            <div className="info-section status">
              <h4>Status</h4>
              <p className={`program-status-pill ${slugify(booking.status)}`}>
                {booking.status === 'assigning' ||
                booking.status === 'assigning-pending'
                  ? 'Open'
                  : booking.status
                      .split('-')
                      .map((str) => upperCaseFirst(str))
                      .join(' ')}
              </p>
            </div>
            <div className="info-section">
              <h4>Dates of Care</h4>
              <p>
                {programStartDate.format('MMMM Do')} to{' '}
                {programEndDate.format('MMMM Do')}
              </p>
            </div>
            <div className="info-section">
              <h4>Member Notes</h4>
              <p> {!!booking.notes ? booking.notes : 'None'}</p>
            </div>
            <div className="info-section">
              <h4>House Rules</h4>
              <p> {!!family.house_rules ? family.house_rules : 'None'}</p>
            </div>
            <div className="info-section">
              <h4>Total Appointments</h4>
              <p>
                <b>{appointments.length}</b>
              </p>
            </div>
            <div className="info-section communications">
              <h4>Message Our Office Team</h4>
              <Textarea
                className="communication"
                name={`program-communication`}
                label={'Message'}
                value={communication}
                onChange={this.handleChange}
                required={false}
              />
              <button
                className="theme-btn small slate-blue"
                onClick={this.sendNewMessage}
              >
                Send
              </button>
              {communications.map((communication) => (
                <div
                  className="communication-item"
                  key={`comm-${communication.id}`}
                >
                  {communication.from_type === 'admin' && <h4>From Admin</h4>}
                  {communication.from_type === 'family' && <h4>To Admin</h4>}
                  <p>{communication.message}</p>
                  <p className="info">
                    Note By {communication.firstname}&nbsp;
                    {communication.lastname}
                  </p>
                  <p className="info">
                    {moment(communication.created_at).format(
                      'dddd M/D/YYYY [at] h:mm a'
                    )}
                  </p>
                </div>
              ))}
            </div>
            {booking.status !== 'cancelled' && (
              <div className="info-section cancel">
                <p onClick={this.toggleCancelModal}>
                  <b>I would like to cancel this consistent care request.</b>
                </p>
                <Modal
                  title="Cancel Consistent Care Request"
                  isOpen={showCancelModal}
                  closeModal={this.toggleCancelModal}
                  content={
                    <div className="cancel-program-modal">
                      <Textarea
                        className="cancel_reason"
                        name={`program-cancel_reason`}
                        label={'Message'}
                        value={cancel_reason}
                        onChange={this.handleCancelReasonChange}
                        required={true}
                      />
                    </div>
                  }
                  primaryButton={'Cancel Request'}
                  handleClick={this.cancelProgram}
                  secondaryButton={'Close'}
                />
              </div>
            )}
          </div>
        )}
      </main>
    );
  }
}

function mapState(state) {
  return {
    auth: state.auth,
    balance: state.balance,
  };
}

export default connect(mapState)(ProgramDetail);
