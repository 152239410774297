import { Grid, GridColumn, } from '@progress/kendo-react-grid';
import moment from 'moment-timezone';
import React from 'react';
import { findLinkGroupByTitle } from '../../../helpers';
import reportsService from '../../../services/reportsService';
import Select from '../../shared/Form/Select';
import Sidebar from '../Sidebar';
import { BooleanCell, ColumnMenu, DateTimeCell, ManageCell, } from './KendoComponents';
const makeMonthOptions = () => {
    const systemStartDate = moment('2020-08-01', 'YYYY-MM-DD');
    const thisMonth = moment().startOf('month');
    const numMonths = thisMonth.diff(systemStartDate, 'months');
    const months = [];
    for (let i = 0; i <= numMonths; i++) {
        const rawMonth = moment(systemStartDate).add(i, 'months');
        const month = {
            label: rawMonth.format('MMMM YYYY'),
            value: rawMonth.format('YYYY-MM-DD'),
            id: rawMonth.format('YYYY-MM-DD'),
            name: rawMonth.format('MMMM YYYY'),
        };
        months.push(month);
    }
    return months;
};
const INITIAL_DATA_STATE = {
    sort: [],
    filter: {
        logic: 'and',
        filters: [],
    },
    filterOperators: {
        text: [
            { text: 'grid.filterContainsOperator', operator: 'contains' },
            { text: 'grid.filterEqOperator', operator: 'eq' },
        ],
    },
    skip: 0,
    take: 50,
    total: 0,
};
class ReportsGrid extends React.Component {
    state = {
        report: [],
        result: [],
        loading: true,
        linkGroup: findLinkGroupByTitle('Settings'),
        selectedMonth: moment().startOf('month').format('YYYY-MM-DD'),
        reportType: null,
        currentReport: '',
        months: [],
        dataState: { ...INITIAL_DATA_STATE },
    };
    componentDidMount = () => {
        this.setReportType();
    };
    componentDidUpdate = (prevProps) => {
        const currentType = this.props.match.params.type;
        const prevType = prevProps.match.params.type;
        if (currentType !== prevType) {
            this.setReportType();
        }
    };
    setReportType = () => {
        const months = makeMonthOptions();
        const { type } = this.props.match.params;
        let currentReport = '';
        if (type === 'hours-worked') {
            currentReport = 'Hours Worked';
        }
        if (type === 'appointments-cancelled') {
            currentReport = 'Appointments Cancelled';
        }
        this.setState({
            reportType: type,
            report: [],
            result: [],
            currentReport,
            months,
            dataState: { ...INITIAL_DATA_STATE },
        }, this.triggerDataReload);
    };
    triggerDataReload = () => {
        this.setState({
            loading: true,
        });
        const { type } = this.props.match.params;
        if (type === 'hours-worked') {
            this.getHoursWorkedReport();
        }
        if (type === 'appointments-cancelled') {
            this.getAppointmentsCancelledReport();
        }
    };
    getHoursWorkedReport = async () => {
        try {
            const { dataState, selectedMonth } = this.state;
            const type = 'providerHoursWorked';
            const month = selectedMonth;
            const response = await reportsService.list(type, {
                date: month,
                skip: dataState.skip,
                take: dataState.take,
                sort: dataState.sort,
                filter: dataState.filter,
            });
            if (response.status === 200) {
                const { report, count } = response.data.data;
                this.setState({
                    report,
                    result: report,
                    dataState: {
                        ...dataState,
                        total: count,
                    },
                    loading: false,
                });
            }
        }
        catch (error) {
            console.error(error);
            this.setState({
                loading: false,
            });
        }
    };
    getAppointmentsCancelledReport = async () => {
        try {
            const { dataState } = this.state;
            const type = 'appointmentCancellation';
            const response = await reportsService.list(type, {
                skip: dataState.skip,
                take: dataState.take,
                sort: dataState.sort,
                filter: dataState.filter,
            });
            if (response.status === 200) {
                const { report, count } = response.data.data;
                this.setState({
                    report,
                    result: report,
                    dataState: {
                        ...dataState,
                        total: count,
                    },
                    loading: false,
                });
            }
        }
        catch (error) {
            console.error(error);
            this.setState({
                loading: false,
            });
        }
    };
    pageChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                skip: event.page.skip,
                take: event.page.take,
            },
        }, () => {
            this.triggerDataReload();
        });
    };
    sortChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                sort: event.sort,
            },
        }, () => {
            this.triggerDataReload();
        });
    };
    filterChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                filter: event.filter,
                skip: 0,
            },
        }, () => {
            this.triggerDataReload();
        });
    };
    handleMonthChange = (event) => {
        const value = event.target.value;
        this.setState({
            selectedMonth: value,
        }, this.triggerDataReload);
    };
    render() {
        const { loading, linkGroup, dataState, result, reportType, currentReport, months, selectedMonth, } = this.state;
        return (React.createElement("main", { className: "admin admin-listing with-sidebar appointments" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content" },
                !!loading && (React.createElement("h4", { className: "section-label" },
                    React.createElement("i", { className: "fas fa-spinner spin" }),
                    "\u00A0Loading",
                    ' ',
                    currentReport,
                    " Report...")),
                !loading && (React.createElement("h4", { className: "section-label" },
                    React.createElement("i", { className: "fas fa-calendar-star" }),
                    "\u00A0Viewing ",
                    currentReport,
                    " Report")),
                reportType === 'hours-worked' && months.length > 0 && (React.createElement(Select, { className: "report_month_select", name: "report_month_select", label: "Select Month", options: months, value: selectedMonth, onChange: this.handleMonthChange })),
                React.createElement("div", { className: "kendo-table" },
                    reportType === 'hours-worked' && (React.createElement(Grid, { data: result, pageable: true, sortable: true, ...dataState, onPageChange: this.pageChange, onSortChange: this.sortChange, onFilterChange: this.filterChange },
                        React.createElement(GridColumn, { title: "First Name", field: "firstname", filter: 'text', columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Last Name", field: "lastname", filter: 'text', columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Hours Worked", field: "hours", filter: 'text', columnMenu: ColumnMenu }))),
                    reportType === 'appointments-cancelled' && (React.createElement(Grid, { data: result, pageable: true, sortable: true, ...dataState, onPageChange: this.pageChange, onSortChange: this.sortChange, onFilterChange: this.filterChange },
                        React.createElement(GridColumn, { title: "Date", field: "date", filter: 'text', cell: DateTimeCell, columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Start Time", field: "start_time", filter: 'text', cell: DateTimeCell, columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "End Time", field: "end_time", filter: 'text', cell: DateTimeCell, columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Family", field: "family_name", filter: 'text', columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Provider", field: "provider_name", filter: 'text', columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Cancelled At", field: "cancelled_at", filter: 'text', cell: DateTimeCell, columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Amount Collected", field: "amount", filter: 'text', columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Paid?", field: "paid", filter: 'text', columnMenu: ColumnMenu, cell: BooleanCell }),
                        React.createElement(GridColumn, { title: " ", field: "id", width: 100, cell: (props) => React.createElement(ManageCell, { ...props, ...this.props }) })))))));
    }
}
export default ReportsGrid;
