import axios from 'axios';
import Joi from 'joi';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authActions } from '../../actions/authActions';
import { userHasCompletedRegistration, userHasRole } from '../../helpers';
import { mapAuthState } from '../../helpers/mapAuthState';
import Form from './Form';
class Login extends Form {
    state = {
        data: { email: '', password: '' },
        errors: {},
        submitting: false,
    };
    schema = Joi.object({
        email: Joi.string().required().label('Email'),
        password: Joi.string().required().label('Password'),
    });
    componentDidMount = () => {
        const { search } = this.props.location;
        if (!search) {
            return;
        }
        if (search.includes('email_changed') && search.includes('true')) {
            toast.success('Email successfully verified! You may now use your new email to log in.');
        }
    };
    doSubmit = async (callback) => {
        try {
            const { data } = this.state;
            const response = await this.props.login(data);
            if (response.status === 200 && response.data.message === 'OK') {
                // worthless check but just in case
                const { user } = this.props.auth;
                if (!!user) {
                    const { step } = user;
                    if (!step && userHasRole(user, 'Companion Care')) {
                        this.props.history.push('/register/companion-care/complete-signup');
                    }
                    if (!step && userHasRole(user, 'Care Provider')) {
                        this.props.history.push('/register/care-provider/application');
                    }
                    if (!userHasCompletedRegistration(user) && step) {
                        // The register section for each side determines where they need to go
                        switch (step.slug) {
                            case 'care-provider-signup':
                            case 'care-provider-application-questions':
                            case 'care-provider-application-pending':
                            case 'care-provider-application-approved':
                            case 'care-provider-private-profile':
                            case 'care-provider-public-profile':
                            case 'care-provider-availability':
                            case 'care-provider-application-finished':
                                this.props.history.push('/register/care-provider');
                                break;
                            case 'companion-care-signup':
                            case 'companion-care-complete-signup':
                            case 'companion-care-household-info':
                            case 'companion-care-care-profiles':
                            case 'companion-care-emergency-contacts':
                            case 'companion-care-pets':
                            case 'companion-care-payment':
                                this.props.history.push('/register/companion-care');
                                break;
                        }
                    }
                }
            }
            else if (response.status === 200 && response.data.message !== 'OK') {
                const { message } = response.data;
                toast.error(message);
                callback();
            }
            else {
                // when the response errors out, it has a different format
                callback();
            }
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    toast.error('Wrong email and/or password combination.');
                }
            }
            else {
                console.error(error);
                toast.error('Whoa! Something went majorly wrong!');
            }
            callback();
        }
    };
    render() {
        return (React.createElement("main", { className: "login" },
            React.createElement("div", { className: "inner" },
                React.createElement("form", { onSubmit: this.handleSubmit },
                    React.createElement("h2", null, "Login"),
                    this.renderInput('email', 'Email'),
                    this.renderInput('password', 'Password', 'password'),
                    React.createElement(Link, { className: "forgot", to: "/password/reset", title: "Forgot Password" }, "Forgot password?"),
                    this.renderButton('Login', 'Logging In...', 'theme-btn blue')))));
    }
}
const actionCreators = {
    login: authActions.login,
};
export default connect(mapAuthState, actionCreators)(Login);
