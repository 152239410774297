import { displayProfileColor } from '@ss/helpers';
import { mapAuthState } from '@ss/helpers/mapAuthState';
import childService from '@ss/services/childService';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCareRecipientNeedsOptionsRequest } from '../../../services/careRecipientNeedsOptionsService';
import ChildForm from '../../Forms/ChildForm';
import { Modal, ProfileColorPicker } from '../../shared';
const AvatarDisplayComponent = (props) => {
    return (React.createElement("div", { className: "avatar" },
        React.createElement("div", { className: "avatar-wrapper" },
            props.value && React.createElement("img", { src: props.value }),
            !props.value && React.createElement("i", { className: "far fa-camera" }))));
};
class ChildProfile extends ChildForm {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            loading: true,
            id: null,
            creating: true,
            family_id: null,
            showRemoveChild: false,
            removingChild: false,
        };
    }
    componentDidMount = () => {
        this.getCareRecipientNeeds();
        const id = Number.parseInt(this.props.match.params.id, 10);
        if (Number.isNaN(id) === false) {
            this.getChild(id);
        }
        else {
            this.setState({
                data: { ...this.state.freshSchema },
                creating: true,
                family_id: this.props.auth.user.personal_info.family_id,
            });
        }
    };
    getCareRecipientNeeds = async () => {
        const careRecipientNeeds = await getCareRecipientNeedsOptionsRequest();
        this.setState({
            careRecipientNeeds: careRecipientNeeds.map((item) => ({
                value: item.id,
                label: item.name,
            })),
        });
    };
    getChild = async (id) => {
        try {
            const response = await childService.get(id);
            if (response.status === 200) {
                const { data } = response.data;
                this.formatData(data);
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    formatData = ({ personalInfo, profileChild, selectedRecipientNeeds, }) => {
        const { DOB, avatar_filename, firstname, lastname, family_id, profile_color, type, } = personalInfo;
        const { notes, id, fall_risk, help_standing_up, wheelchair } = profileChild;
        const { data } = this.state;
        const selected_recipient_needs = selectedRecipientNeeds.map((item) => ({
            value: item.id,
            label: item.name,
        }));
        data.firstname = firstname;
        data.lastname = lastname;
        data.avatar_filename = avatar_filename;
        const dobPieces = DOB.split('-');
        if (dobPieces.length === 3) {
            // We assume format "YYYY-MM-DD"
            // so after splitting, it should be: [ "YYYY", "MM", "DD" ]
            data.dob_year = dobPieces[0];
            data.dob_month = dobPieces[1];
            data.dob_day = dobPieces[2];
        }
        data.fall_risk = fall_risk;
        data.help_standing_up = help_standing_up;
        data.notes = notes;
        data.profile_color =
            profile_color !== null ? profile_color : 'primary-coral';
        data.wheelchair = wheelchair;
        data.selected_recipient_needs = selected_recipient_needs;
        this.setState({
            data,
            creating: false,
            personalInfo,
            profileChild,
            id,
            family_id,
            allowDelete: type !== 'companion_care_self',
        });
    };
    toggleRemoveChild = () => {
        this.setState((prevState) => ({
            ...prevState,
            showRemoveChild: !prevState.showRemoveChild,
        }));
    };
    doRemoveChild = async () => {
        try {
            const id = Number.parseInt(this.props.match.params.id, 10);
            if (Number.isNaN(id) === false) {
                const response = await childService.delete(id);
                if (response.status === 204) {
                    toast.success('This Care Profile has been removed from your account.');
                    this.props.history.push('/profile');
                }
                else if (response.status === 200) {
                    const { message } = response.data;
                    toast.error(message);
                    this.setState({
                        showRemoveChild: false,
                    });
                }
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    handleProfileColorChange = (colorClass) => {
        this.setState({ data: { ...this.state.data, profile_color: colorClass } });
    };
    doSubmit = async (callback) => {
        try {
            const response = await this.submitFormData();
            callback();
            toast.success('Hooray! Your changes are saved.');
            if (this.state.creating) {
                const { data } = response.data;
                const { id } = data.profileChild;
                this.formatData(data);
                this.setState({
                    creating: false,
                }, () => {
                    this.props.history.replace(`/profile/care-recipient/${id}`);
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    render() {
        const { data, creating, removingChild, showRemoveChild, allowDelete } = this.state;
        return (React.createElement("main", { className: "account profile child" },
            React.createElement("form", { onSubmit: this.handleSubmit },
                React.createElement("div", { className: `profile-header ${displayProfileColor(data.profile_color, 'gradient')}` },
                    React.createElement("div", { className: "inner" },
                        this.renderFile('avatar_filename', 'Upload Your Public Profile Image', 'image/jpg, image/png', false, AvatarDisplayComponent),
                        React.createElement("h2", { className: "theme-h2" },
                            data.firstname,
                            "\u00A0",
                            data.lastname))),
                React.createElement("div", { className: "profile-body" },
                    React.createElement("h2", null, "Care Recipient Profile"),
                    React.createElement(ProfileColorPicker, { value: data.profile_color, onProfileColorChange: this.handleProfileColorChange }),
                    this.renderForm(),
                    this.renderButton('Save Care Recipient Details', 'Saving...', 'theme-btn blue'),
                    React.createElement("div", { className: "cancel-btn" },
                        !creating && allowDelete && (React.createElement("button", { className: "theme-btn small error-red", type: "button", onClick: this.toggleRemoveChild }, "Delete")),
                        React.createElement(Link, { className: "theme-btn small slate-blue", to: "/profile" }, "Cancel")),
                    !allowDelete && !creating && (React.createElement("div", { className: "self-recipient-delete-account-info" },
                        React.createElement("small", null, "You cannot remove your own care recipient profile."))))),
            React.createElement(Modal, { title: "Confirm Deletion", isOpen: showRemoveChild, closeModal: this.toggleRemoveChild, content: 'Are you sure you want to remove this care recipient from your account?', primaryButton: removingChild ? ' Removing' : 'Remove', secondaryButton: 'Close', handleClick: this.doRemoveChild })));
    }
}
export default connect(mapAuthState)(ChildProfile);
