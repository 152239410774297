import React from 'react';
import { connect } from 'react-redux';
import Signup from './Signup';
import Application from './Application';
import ApplicationSubmitted from './ApplicationSubmitted';
import ScheduleInterview from './ScheduleInterview';
import PrivateProfile from './PrivateProfile';
import PublicProfile from './PublicProfile';
import Availability from './Availability';
import SitterOnboarding from './SitterOnboarding';
import Complete from './Complete';
import { mapAuthState } from '../../../helpers/mapAuthState';
class SitterRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 'signup',
            steps: [
                'signup',
                'application',
                'application-submitted',
                'schedule-interview',
                'private-profile',
                'public-profile',
                'availability',
                'onboarding',
                'complete',
            ],
        };
    }
    componentDidMount() {
        if (!!this.props.auth.user) {
            if (!!this.props.auth.user.step) {
                this.setStepFromUser();
            }
            else {
                this.setStepFromUrl();
            }
        }
        else {
            this.setStepFromUrl();
        }
    }
    componentDidUpdate(prevProps) {
        if (!!this.props.auth.user && !!prevProps.auth.user) {
            if (this.props.auth.user.step_id !== prevProps.auth.user.step_id) {
                this.setStepFromUser();
            }
        }
        else if (!!this.props.auth.user && !prevProps.auth.user) {
            // user signed in
            this.setStepFromUser();
        }
    }
    setStepFromUser = () => {
        const { user } = this.props.auth;
        const { step } = user;
        let pageStep = '';
        switch (step.slug) {
            case 'care-provider-signup':
                pageStep = 'application';
                break;
            case 'care-provider-application-questions':
                pageStep = 'application-submitted';
                break;
            case 'care-provider-application-pending':
                pageStep = 'schedule-interview';
                break;
            case 'care-provider-application-approved':
                pageStep = 'private-profile';
                break;
            case 'care-provider-private-profile':
                pageStep = 'public-profile';
                break;
            case 'care-provider-public-profile':
                pageStep = 'availability';
                break;
            case 'care-provider-availability':
                pageStep = 'onboarding';
                break;
            case 'care-provider-onboarding':
                pageStep = 'complete';
                break;
        }
        if (pageStep) {
            this.setState({ step: pageStep }, this.updateUrl);
        }
    };
    setStepFromUrl = () => {
        const { step } = this.props.match.params;
        if (step) {
            const { steps } = this.state;
            if (steps.includes(step)) {
                this.setState({ step });
                return;
            }
            this.setState({
                step: steps[0],
            }, this.updateUrl);
        }
    };
    advanceStep = () => {
        const { step, steps } = this.state;
        let idx = steps.indexOf(step);
        if (idx < steps.length - 1) {
            idx += 1;
            const nextStep = steps[idx];
            this.setState({ step: nextStep }, this.updateUrl);
        }
    };
    updateUrl = () => {
        const { step } = this.state;
        switch (step) {
            case 'signup':
                window.history.replaceState(null, null, '/register/care-provider/signup');
                break;
            case 'application':
                window.history.replaceState(null, null, '/register/care-provider/application');
                break;
            case 'application-submitted':
                window.history.replaceState(null, null, '/register/care-provider/application-submitted');
                break;
            case 'schedule-interview':
                window.history.replaceState(null, null, '/schedule-interview');
                break;
            case 'private-profile':
                window.history.replaceState(null, null, '/register/care-provider/private-profile');
                break;
            case 'public-profile':
                window.history.replaceState(null, null, '/register/care-provider/public-profile');
                break;
            case 'onboarding':
                window.history.replaceState(null, null, '/register/care-provider/onboarding');
                break;
            case 'complete':
                window.history.replaceState(null, null, '/register/care-provider/complete');
                break;
        }
    };
    render() {
        const { step } = this.state;
        const { windowWidth } = this.props;
        return (React.createElement("main", { className: "registration sitter" },
            step === 'signup' && React.createElement(Signup, null),
            step === 'application' && React.createElement(Application, null),
            step === 'application-submitted' && React.createElement(ApplicationSubmitted, null),
            step === 'schedule-interview' && React.createElement(ScheduleInterview, null),
            step === 'private-profile' && React.createElement(PrivateProfile, null),
            step === 'public-profile' && React.createElement(PublicProfile, null),
            step === 'availability' && React.createElement(Availability, { windowWidth: windowWidth }),
            step === 'onboarding' && React.createElement(SitterOnboarding, null),
            step === 'complete' && React.createElement(Complete, null)));
    }
}
export default connect(mapAuthState)(SitterRegistration);
