import { displayAddress, displayAge, displayAppointmentDate, displayAppointmentDuration, displayAppointmentTime, findLinkGroupByTitle, upperCaseFirst, } from '@ss/helpers';
import appointmentService from '@ss/services/appointmentService';
import coverRequestService from '@ss/services/coverRequestService';
import logService from '@ss/services/logService';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Avatar, Modal } from '../../shared';
import ReviewsGrid from '../ReviewsGrid/ReviewsGrid';
import Sidebar from '../Sidebar';
import AppointmentsEdit from './AppointmentsEdit';
import AssignSitter from './AssignSitter';
class AppointmentsDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            activities: [],
            appointment: null,
            booking: {},
            children: [],
            family: null,
            owner: null,
            pets: [],
            sitters: [],
            rejectedSitters: [],
            loading: true,
            linkGroup: findLinkGroupByTitle('Appointments'),
            hasSitLog: false,
            assigningSitter: false,
            showingCancelAppt: false,
            cancelling: false,
            editing: false,
            confirmingTimeChange: false,
            rejectingTimeChange: false,
            showPendingCoverModal: false,
            covering: false,
            decliningCover: false,
            approvingCover: false,
            denyingCover: false,
            coverRequests: [],
            coverRequest: null,
            coverRequestSitter: null,
            cancelledHours: 0,
            communications: [],
            opening: false,
            submitting: false,
            sitterSignupApproving: false,
            sitterSignupRejecting: false,
            sitterRespondingTo: null,
            activity: [],
            activityLoading: false,
        };
    }
    componentDidMount = () => {
        this.getAppointment();
        this.getActivity();
    };
    getAppointment = async () => {
        try {
            const { id } = this.props.match.params;
            const response = await appointmentService.get(id);
            if (response.status === 200) {
                const { activities, appointment, booking, children, family, owner, pets, sitters, rejected_sitters, has_sit_log, cover_requests, booking_appointment_communications, } = response.data.data;
                let coverRequestSitter = null;
                let coverRequest = null;
                if (cover_requests.length > 0) {
                    // Only 1 sitter can sign up for a sub-requested appointment at a time. Then the appt moves
                    // out of the Open Cover Requests section. So we assume the last item in the array is the
                    // most recent cover request and use it specifically.
                    // Note: this might not be perfect 100% of the time but I think it should work...
                    coverRequest = cover_requests[cover_requests.length - 1];
                    const { requested_sitter_info } = coverRequest;
                    if (!!requested_sitter_info && requested_sitter_info !== undefined) {
                        coverRequestSitter = requested_sitter_info;
                    }
                }
                let cancelledHours = 0;
                if (appointment.status === 'cancelled') {
                    cancelledHours = moment
                        .duration(moment(appointment.start_time).diff(moment(appointment.cancelled_at)))
                        .asHours();
                }
                this.setState({
                    activities,
                    appointment,
                    booking,
                    children,
                    family,
                    owner,
                    pets,
                    sitters,
                    rejectedSitters: rejected_sitters,
                    coverRequests: cover_requests,
                    coverRequest,
                    coverRequestSitter,
                    communications: booking_appointment_communications,
                    loading: false,
                    hasSitLog: !!has_sit_log,
                    cancelledHours,
                });
            }
        }
        catch {
            this.setState({
                loading: false,
            });
        }
    };
    getActivity = async () => {
        this.setState({
            activityLoading: true,
        });
        try {
            const { id } = this.props.match.params;
            const response = await logService.appointment(id);
            if (response.status === 200) {
                const { logs } = response.data.data;
                this.setState({
                    activity: logs,
                    activityLoading: false,
                });
            }
        }
        catch (error) {
            console.error(error);
            this.setState({
                activityLoading: false,
            });
        }
    };
    setProvider = () => {
        this.setState({
            assigningSitter: true,
        });
    };
    onSitterAssigned = ({ appointment, sitters, }) => {
        this.setState({
            appointment,
            sitters,
            assigningSitter: false,
        });
    };
    toggleCancelAppointment = () => {
        this.setState((prevState) => ({
            showingCancelAppt: !prevState.showingCancelAppt,
        }));
    };
    toggleEditAppointment = () => {
        this.setState((prevState) => ({
            editing: !prevState.editing,
        }));
    };
    cancelAppointment = async () => {
        this.setState({
            cancelling: true,
        });
        try {
            const { id } = this.state.appointment;
            const response = await appointmentService.cancel(id);
            if (response.status === 200) {
                toast.success('Appointment has been cancelled.');
                const { appointment } = response.data.data;
                this.setState({
                    cancelling: false,
                    showingCancelAppt: false,
                    appointment,
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    confirmTimeChange = async () => {
        this.setState({
            confirmingTimeChange: true,
        });
        try {
            const { id } = this.state.appointment;
            const response = await appointmentService.confirmTimeChange(id);
            if (response.status === 200) {
                toast.success('Appointment time change request has been approved. The Member and Provider have been notified.');
                const { appointment } = response.data.data;
                this.setState({
                    confirmingTimeChange: false,
                    appointment,
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    rejectTimeChange = async () => {
        this.setState({
            rejectingTimeChange: true,
        });
        try {
            const { id } = this.state.appointment;
            const response = await appointmentService.rejectTimeChange(id);
            if (response.status === 200) {
                toast.success('Appointment time change request has been rejected. The Member and Provider have been notified.');
                const { appointment } = response.data.data;
                this.setState({
                    rejectingTimeChange: false,
                    appointment,
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    sendToPending = async () => {
        try {
            const { id } = this.state.appointment;
            const response = await appointmentService.updateAppointmentStatus(id, 'pending-list');
            if (response.status === 200) {
                toast.success('Appointment has been sent to the Pending list');
                this.getAppointment();
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    togglePendingCoverModal = () => {
        this.setState((prevState) => ({
            showPendingCoverModal: !prevState.showPendingCoverModal,
        }));
    };
    declineCoverRequest = async () => {
        this.setState({
            covering: true,
            decliningCover: true,
        });
        try {
            const coverRequestId = this.state.coverRequest.id;
            const response = await coverRequestService.declineCoverRequest(coverRequestId);
            if (response.status === 200) {
                this.setState({
                    showPendingCoverModal: false,
                    covering: false,
                    decliningCover: false,
                });
                toast.success("The original Provider's cover request has been declined.");
                this.getAppointment();
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    approveCoverRequest = async () => {
        this.setState({
            covering: true,
            approvingCover: true,
        });
        try {
            const coverRequestId = this.state.coverRequest.id;
            const response = await coverRequestService.approveCoverRequest(coverRequestId);
            if (response.status === 200) {
                this.setState({
                    showPendingCoverModal: false,
                    covering: false,
                    approvingCover: false,
                });
                toast.success('Hooray! The Sub Provider Request has been approved. The member & provider(s) have been notified!');
                this.getAppointment();
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    denyCoverRequest = async () => {
        this.setState({
            covering: true,
            denyingCover: true,
        });
        try {
            const coverRequestId = this.state.coverRequest.id;
            const response = await coverRequestService.denyCoverRequest(coverRequestId);
            if (response.status === 200) {
                this.setState({
                    showPendingCoverModal: false,
                    covering: false,
                    denyingCover: false,
                });
                toast.success("This Provider's cover request fill-in has been rejected.");
                this.getAppointment();
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    openAppointmentForCovers = async () => {
        if (this.state.sitters.length > 0) {
            this.setState({
                covering: true,
                opening: true,
            });
            try {
                const data = {
                    appointment_id: this.state.appointment.id,
                    sitter_piid: this.state.sitters[0].id,
                    sitter_reason: 'Triggered by Admin',
                };
                const response = await coverRequestService.storeCoverRequest(data);
                this.setState({
                    covering: false,
                    opening: false,
                });
                if (response.status === 201) {
                    toast.success('This appointment has been posted to providers and is now available for sign up');
                    this.getAppointment();
                }
                else {
                    this.setState({ submitting: false });
                }
            }
            catch {
                this.setState({ submitting: false });
            }
        }
        else {
            toast.error('This appointment does not yet have an assigned Provider and cannot be covered. You may assign a Provider normally using the Find Provider button.');
        }
    };
    respondToSitterSignup = async (sitter, approved) => {
        this.setState({
            sitterRespondingTo: sitter.id,
            sitterSignupApproving: !!approved,
            sitterSignupRejecting: !approved,
        });
        try {
            const { id } = this.state.appointment;
            const response = await appointmentService.respondToSitterSignup(sitter.id, id, !!approved);
            if (response.status === 200) {
                toast.success(`Sitter signup has been ${!!approved ? 'approved' : 'rejected'}`);
                const { appointment } = response.data.data;
                this.setState({
                    sitterSignupApproving: false,
                    sitterSignupRejecting: false,
                    sitterRespondingTo: null,
                    appointment,
                }, () => {
                    this.getAppointment();
                });
            }
        }
        catch (error) {
            console.error(error);
            this.setState({
                sitterSignupApproving: false,
                sitterSignupRejecting: false,
                sitterRespondingTo: null,
            }, () => {
                this.getAppointment();
            });
        }
    };
    render() {
        const { onSitterAssigned, approveCoverRequest, togglePendingCoverModal, declineCoverRequest, denyCoverRequest, setProvider, confirmTimeChange, rejectTimeChange, toggleEditAppointment, openAppointmentForCovers, toggleCancelAppointment, cancelAppointment, sendToPending, respondToSitterSignup, props, state: { loading, linkGroup, appointment, children, family, owner, sitters, rejectedSitters, assigningSitter, showingCancelAppt, cancelling, editing, hasSitLog, confirmingTimeChange, rejectingTimeChange, showPendingCoverModal, coverRequest, coverRequestSitter, covering, opening, decliningCover, approvingCover, denyingCover, cancelledHours, communications, sitterSignupApproving, sitterSignupRejecting, sitterRespondingTo, activityLoading, activity, }, } = this;
        const respondingToTimeChange = !!confirmingTimeChange || !!rejectingTimeChange;
        if (!!loading) {
            return (React.createElement("main", { className: "admin admin-detail with-sidebar appointments" },
                React.createElement("div", { className: "sidebar-wrapper" },
                    React.createElement(Sidebar, { ...props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
                React.createElement("div", { className: "content" },
                    React.createElement("h3", null, "Loading Appointment Details..."))));
        }
        return (React.createElement("main", { className: "admin admin-detail with-sidebar appointments" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: `appointment-detail-view ${assigningSitter ? 'assigning-sitter' : ''}` },
                !!assigningSitter && (React.createElement(AssignSitter, { appointment: appointment, onSitterAssigned: onSitterAssigned })),
                React.createElement("div", { className: "appointment-detail appointment" },
                    React.createElement("div", { className: "content" },
                        !assigningSitter && React.createElement("h3", null,
                            "Appointment #",
                            appointment.id),
                        React.createElement("div", { className: "appt-people top" },
                            React.createElement("div", { className: "admin-avatar-section family" },
                                React.createElement("h4", { className: "section-label" }, "Family"),
                                React.createElement("div", { className: "wrapper" },
                                    React.createElement(Avatar, { imageSrc: family.avatar_filename, profileColor: owner.profile_color }),
                                    React.createElement("div", { className: "info" },
                                        React.createElement("h1", null,
                                            React.createElement(Link, { to: `/families/members/${family.id}` }, family.name)),
                                        React.createElement("p", null, displayAddress(appointment, owner))))),
                            !assigningSitter && (React.createElement("div", { className: `admin-avatar-section sitters ${appointment.status === 'pending-cover' ||
                                    appointment.status === 'cover-acceptance-needed'
                                    ? 'pending-cover'
                                    : ''}` },
                                React.createElement("h4", { className: "section-label" }, "Providers"),
                                appointment.status === 'pending-admin' && (React.createElement(React.Fragment, null,
                                    React.createElement("p", null, "Auto-assigned providers have all declined this appointment."),
                                    React.createElement("p", null,
                                        React.createElement("b", null,
                                            rejectedSitters.length,
                                            " selected providers have declined this appointment.")))),
                                appointment.status !== 'pending-admin' && (React.createElement(React.Fragment, null, sitters.length > 0 && (React.createElement("div", { className: "sitters" },
                                    appointment.status === 'submitted-timed' && (React.createElement("p", null,
                                        "Auto-Assigning (",
                                        rejectedSitters.length,
                                        " of 3)")),
                                    sitters.map((sitter) => (React.createElement("div", { className: "wrapper mb-4", key: `sitter-${sitter.id}` },
                                        React.createElement(Avatar, { imageSrc: sitter.avatar_filename, profileColor: owner.profile_color }),
                                        React.createElement("div", { className: "info" },
                                            React.createElement("h1", null,
                                                React.createElement(Link, { to: `/providers/edit/${sitter.id}` },
                                                    sitter.firstname,
                                                    "\u00A0",
                                                    sitter.lastname)),
                                            (appointment.status === 'submitted' || appointment.status === 'submitted-timed') && (React.createElement("p", null, "Pending Acceptance")),
                                            (appointment.status === 'accepted') && (React.createElement("p", null, "Confirmed Provider")),
                                            appointment.status !== 'submitted-timed' && (React.createElement("p", null,
                                                "Cover Requests Remaining:",
                                                ' ',
                                                sitter.cover_requests_remaining)),
                                            (appointment.status === 'pending-list' || appointment.status === 'pending-list-approval') && (React.createElement("div", { className: "sitter-actions mt-2" },
                                                ((!sitterSignupApproving && !sitterSignupRejecting) || (sitterRespondingTo !== sitter.id)) && (React.createElement("button", { className: "theme-btn primary-green", onClick: () => {
                                                        respondToSitterSignup(sitter, 1);
                                                    } },
                                                    React.createElement("span", null,
                                                        React.createElement("i", { className: "fas fa-check-circle" }),
                                                        "\u00A0Accept"))),
                                                !!sitterSignupApproving && sitterRespondingTo === sitter.id && (React.createElement("button", { className: "theme-btn primary-green", onClick: (e) => {
                                                        e.preventDefault();
                                                    } },
                                                    React.createElement("span", null,
                                                        React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                                        "\u00A0Accepting..."))),
                                                ((!sitterSignupRejecting && !sitterSignupApproving) || (sitterRespondingTo !== sitter.id)) && (React.createElement("button", { className: "theme-btn red", onClick: () => {
                                                        respondToSitterSignup(sitter, 0);
                                                    } },
                                                    React.createElement("span", null,
                                                        React.createElement("i", { className: "fas fa-times-circle" }),
                                                        "\u00A0Reject"))),
                                                !!sitterSignupRejecting && sitterRespondingTo === sitter.id && (React.createElement("button", { className: "theme-btn red", onClick: (e) => {
                                                        e.preventDefault();
                                                    } },
                                                    React.createElement("span", null,
                                                        React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                                        "\u00A0Rejecting...."))))))))),
                                    (appointment.status === 'pending-cover' ||
                                        appointment.status ===
                                            'cover-acceptance-needed') &&
                                        !!coverRequest && (React.createElement(Modal, { title: "Sub Provider Request Details", isOpen: showPendingCoverModal, closeModal: togglePendingCoverModal, content: React.createElement("div", { className: "admin-cover-request-modal" },
                                            React.createElement("div", { className: "cover-request-section original-provider" },
                                                React.createElement("h4", { className: "section-label" }, "Original Provider"),
                                                sitters.map((sitter) => (React.createElement("div", { className: "wrapper", key: `sitter-cover-${sitter.id}` },
                                                    React.createElement(Avatar, { imageSrc: sitter.avatar_filename, profileColor: owner.profile_color }),
                                                    React.createElement("div", { className: "info" },
                                                        React.createElement("h1", null,
                                                            sitter.firstname,
                                                            "\u00A0",
                                                            sitter.lastname),
                                                        React.createElement("p", null,
                                                            "Sub Provider Requests Remaining:",
                                                            ' ',
                                                            sitter.cover_requests_remaining)),
                                                    React.createElement("div", { className: "reason" },
                                                        React.createElement("p", null,
                                                            React.createElement("b", null, "Reason for Request:")),
                                                        React.createElement("p", null, coverRequest.sitter_reason))))),
                                                React.createElement("div", { className: "btn-wrapper" },
                                                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: declineCoverRequest, disabled: covering },
                                                        !!decliningCover && (React.createElement("span", null,
                                                            React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                                            "\u00A0Declining...")),
                                                        !decliningCover && (React.createElement("span", null,
                                                            React.createElement("i", { className: "fas fa-times-circle" }),
                                                            "\u00A0Decline"))))),
                                            !!coverRequestSitter && (React.createElement("div", { className: "cover-request-section original-provider" },
                                                React.createElement("h4", { className: "section-label" }, "Pending Provider"),
                                                React.createElement("div", { className: "wrapper", key: `sitter-${coverRequestSitter.id}` },
                                                    React.createElement(Avatar, { imageSrc: coverRequestSitter.avatar_filename, profileColor: coverRequestSitter.profile_color }),
                                                    React.createElement("div", { className: "info" },
                                                        React.createElement("h1", null,
                                                            coverRequestSitter.firstname,
                                                            "\u00A0",
                                                            coverRequestSitter.lastname))),
                                                React.createElement("div", { className: "btn-wrapper" },
                                                    React.createElement("button", { className: "theme-btn primary-green tall", onClick: approveCoverRequest, disabled: covering },
                                                        !!approvingCover && (React.createElement("span", null,
                                                            React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                                            "\u00A0Accepting...")),
                                                        !approvingCover && (React.createElement("span", null,
                                                            React.createElement("i", { className: "fas fa-check-circle" }),
                                                            "\u00A0Accept"))),
                                                    React.createElement("button", { className: "theme-btn error-red tall", onClick: denyCoverRequest, disabled: covering },
                                                        !!denyingCover && (React.createElement("span", null,
                                                            React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                                            "\u00A0Declining...")),
                                                        !denyingCover && (React.createElement("span", null,
                                                            React.createElement("i", { className: "fas fa-times-circle" }),
                                                            "\u00A0Decline")))))),
                                            !coverRequestSitter && (React.createElement("div", { className: "cover-request-section original-provider" },
                                                React.createElement("h4", { className: "section-label" }, "No Provider Sign-Ups Yet")))), secondaryButton: 'Close' })))))),
                                (sitters.length === 0 ||
                                    appointment.status === 'pending-admin') && (React.createElement("div", { className: "btn-wrapper" },
                                    React.createElement("button", { onClick: setProvider, className: "theme-btn primary-green" }, "Find Provider"),
                                    React.createElement("button", { onClick: sendToPending, className: "theme-btn blue ml-3" }, "Send to Pending"))),
                                sitters.length > 0 &&
                                    appointment.status !== 'pending-admin' && (React.createElement("div", { className: "btn-wrapper" },
                                    appointment.status !== 'pending-cover' &&
                                        appointment.status !==
                                            'cover-acceptance-needed' && (React.createElement("button", { onClick: setProvider, className: "theme-btn slate-blue" }, "Change Provider")),
                                    (appointment.status === 'pending-cover' ||
                                        appointment.status ===
                                            'cover-acceptance-needed') && (React.createElement("button", { onClick: togglePendingCoverModal, className: "theme-btn slate-blue" }, "Review"))))))),
                        !editing && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "detail-table" },
                                React.createElement("h4", { className: "section-label" }, "Details"),
                                React.createElement("div", { className: "table-row" },
                                    React.createElement("div", { className: "table-cell header" },
                                        React.createElement("p", null, "Status")),
                                    React.createElement("div", { className: "table-cell value" },
                                        React.createElement("p", { className: `status ${appointment.status}` },
                                            (appointment.status === 'pending' ||
                                                appointment.status === 'pending-cover' ||
                                                appointment.status === 'pending-admin' ||
                                                appointment.status ===
                                                    'cover-acceptance-needed') && (React.createElement("i", { className: "fas fa-exclamation-circle" })),
                                            appointment.status === 'booked' && (React.createElement("i", { className: "fas fa-check-circle" })),
                                            appointment.status === 'completed' && (React.createElement("i", { className: "fas fa-check-circle" })),
                                            appointment.status === 'pending-cover' && (React.createElement(React.Fragment, null, "Sub Provider Request Pending Review")),
                                            appointment.status === 'submitted-timed' && (React.createElement(React.Fragment, null,
                                                React.createElement("i", { className: "fas fa-exclamation-circle" }),
                                                "Auto-Assigning (",
                                                rejectedSitters.length,
                                                " of 3)")),
                                            appointment.status === 'cover-acceptance-needed' && (React.createElement(React.Fragment, null, "Sub Provider Sign Up Pending Review")),
                                            appointment.status !== 'pending-cover' &&
                                                appointment.status !== 'submitted-timed' &&
                                                appointment.status !==
                                                    'cover-acceptance-needed' && (React.createElement(React.Fragment, null, appointment.status
                                                .split('-')
                                                .map((str) => upperCaseFirst(str))
                                                .join(' '))),
                                            appointment.status === 'cancelled' &&
                                                appointment.cancelled_at !== null && (React.createElement("span", null,
                                                ' ',
                                                "-",
                                                ' ',
                                                moment(appointment.cancelled_at).format('MMMM Do @ h:mm a')))))),
                                appointment.status === 'cancelled' && (React.createElement("div", { className: "table-row" },
                                    React.createElement("div", { className: "table-cell header" },
                                        React.createElement("p", null, "Cancel Reason")),
                                    React.createElement("div", { className: "table-cell value" },
                                        React.createElement("p", null, appointment.cancel_reason
                                            ? appointment.cancel_reason
                                            : 'N/A')))),
                                appointment.status === 'cancelled' && cancelledHours <= 3 && (React.createElement("div", { className: "table-row" },
                                    React.createElement("div", { className: "table-cell header" },
                                        React.createElement("p", null, "Paid Out?")),
                                    React.createElement("div", { className: "table-cell value" },
                                        React.createElement("p", null, appointment.paid ? 'Yes' : 'No')))),
                                React.createElement("div", { className: "table-row" },
                                    React.createElement("div", { className: "table-cell header" },
                                        React.createElement("p", null, "Date")),
                                    React.createElement("div", { className: "table-cell value" },
                                        React.createElement("p", null, displayAppointmentDate(appointment)))),
                                React.createElement("div", { className: "table-row" },
                                    React.createElement("div", { className: "table-cell header" },
                                        React.createElement("p", null, "Time")),
                                    React.createElement("div", { className: "table-cell value" },
                                        React.createElement("p", null, `${displayAppointmentTime(appointment)} (${displayAppointmentDuration(appointment)})`))),
                                React.createElement("div", { className: "table-row" },
                                    React.createElement("div", { className: "table-cell header" },
                                        React.createElement("p", null, "Flex")),
                                    React.createElement("div", { className: "table-cell value" },
                                        React.createElement("p", null, appointment.flex
                                            ? `Yes (${appointment.flex_room} min)`
                                            : 'No'))),
                                React.createElement("div", { className: "table-row" },
                                    React.createElement("div", { className: "table-cell header" },
                                        React.createElement("p", null, "Driving")),
                                    React.createElement("div", { className: "table-cell value" },
                                        React.createElement("p", null, appointment.driving_needed
                                            ? `Yes (${appointment.driving_distance} mi)`
                                            : 'No'))),
                                React.createElement("div", { className: "table-row" },
                                    React.createElement("div", { className: "table-cell header" },
                                        React.createElement("p", null, "Rate")),
                                    React.createElement("div", { className: "table-cell value" },
                                        React.createElement("p", null, "Standard Rate"))),
                                React.createElement("div", { className: "table-row" },
                                    React.createElement("div", { className: "table-cell header" },
                                        React.createElement("p", null, "Requested Provider(s)")),
                                    React.createElement("div", { className: "table-cell value" },
                                        React.createElement("p", null, appointment.requested_provider || 'None'))),
                                React.createElement("div", { className: "table-row" },
                                    React.createElement("div", { className: "table-cell header" },
                                        React.createElement("p", null, "Notes")),
                                    React.createElement("div", { className: "table-cell value" },
                                        React.createElement("p", null, appointment.notes || 'None'))),
                                React.createElement("div", { className: "table-row" },
                                    React.createElement("div", { className: "table-cell header" },
                                        React.createElement("p", null, "Admin-Only Notes")),
                                    React.createElement("div", { className: "table-cell value" },
                                        React.createElement("p", null, appointment.admin_notes || 'None'))),
                                React.createElement("div", { className: "table-row" },
                                    React.createElement("div", { className: "table-cell header" },
                                        React.createElement("p", null, "Admin Notes To Provider")),
                                    React.createElement("div", { className: "table-cell value" },
                                        React.createElement("p", null, appointment.sitter_notes || 'None')))),
                            React.createElement("div", { className: "detail-table" },
                                React.createElement("h4", { className: "section-label" }, "Detail Change Messages"),
                                communications.length > 0 &&
                                    communications.map((communication) => (React.createElement("div", { className: "table-row", key: `communication-id-${communication.id}` },
                                        React.createElement("div", { className: "table-cell header" },
                                            React.createElement("p", null, moment(communication.created_at).format('M/D [@] h:mm a'))),
                                        React.createElement("div", { className: "table-cell value" },
                                            React.createElement("p", null, communication.message)))))),
                            React.createElement("div", { className: "appt-people bottom" },
                                React.createElement("div", { className: "admin-avatar-section children" },
                                    React.createElement("h4", { className: "section-label" },
                                        "Care Recipients (",
                                        children.length,
                                        ")"),
                                    children.length > 0 && (React.createElement("div", { className: "children-wrapper" }, children.map((child) => (React.createElement("div", { className: "wrapper", key: `child-${child.id}` },
                                        React.createElement(Avatar, { imageSrc: child.avatar_filename, profileColor: owner.profile_color }),
                                        React.createElement("div", { className: "info" },
                                            React.createElement("h1", null, child.firstname),
                                            React.createElement("p", null, displayAge(child.DOB))))))))),
                                React.createElement("div", { className: "divider" }),
                                React.createElement("h4", { className: "section-label", style: { marginTop: 50 } }, "Reviews"),
                                React.createElement(ReviewsGrid, { appointmentId: appointment.id })))),
                        !assigningSitter && editing && (React.createElement(AppointmentsEdit, { ...props, appointment: appointment, cancelledHours: cancelledHours }))),
                    !assigningSitter && (React.createElement("div", { className: "sidebar-right appointment-detail admin-tools" },
                        React.createElement("h4", null,
                            React.createElement("i", { className: "fas fa-user-cog" }),
                            "\u00A0Admin Tools"),
                        !!hasSitLog && (React.createElement("div", { className: "button-row" },
                            React.createElement(Link, { to: `/appointments/log/${appointment.id}`, className: "theme-btn slate-blue" }, "\u00A0Sit Summary"))),
                        appointment.status === 'time change requested' && (React.createElement("div", { className: "time-change-request" },
                            React.createElement("h4", null, "Pending Time Change"),
                            React.createElement("table", null,
                                React.createElement("tbody", null,
                                    React.createElement("tr", null,
                                        React.createElement("td", null,
                                            React.createElement("p", null,
                                                React.createElement("b", null, "Previous:"))),
                                        React.createElement("td", null,
                                            React.createElement("p", null,
                                                displayAppointmentTime(appointment),
                                                " (",
                                                displayAppointmentDuration(appointment),
                                                ")"))),
                                    React.createElement("tr", null,
                                        React.createElement("td", null,
                                            React.createElement("p", null,
                                                React.createElement("b", null, "New:"))),
                                        React.createElement("td", null,
                                            React.createElement("p", null,
                                                displayAppointmentTime(appointment, true),
                                                " (",
                                                displayAppointmentDuration(appointment, true),
                                                ")"))))),
                            !!respondingToTimeChange && (React.createElement("div", { className: "button-row" },
                                !!confirmingTimeChange && (React.createElement("button", { disabled: true, className: "theme-btn slate-blue small" }, "Confirming...")),
                                !confirmingTimeChange && (React.createElement("button", { disabled: true, className: "theme-btn slate-blue small" }, "Confirm")),
                                !!rejectingTimeChange && (React.createElement("button", { disabled: true, className: "theme-btn error-red small" }, "Rejecting...")),
                                !rejectingTimeChange && (React.createElement("button", { disabled: true, className: "theme-btn error-red small" }, "Reject")))),
                            !respondingToTimeChange && (React.createElement("div", { className: "button-row" },
                                React.createElement("button", { onClick: confirmTimeChange, className: "theme-btn slate-blue small" }, "Confirm"),
                                React.createElement("button", { onClick: rejectTimeChange, className: "theme-btn error-red small" }, "Reject"))))),
                        !editing && (React.createElement("div", { className: "sidebar-section" },
                            React.createElement("div", { className: "button-row" },
                                React.createElement("button", { onClick: toggleEditAppointment, className: "theme-btn primary-green" },
                                    React.createElement("i", { className: "fas fa-edit" }),
                                    "\u00A0Edit"),
                                React.createElement("button", { onClick: toggleCancelAppointment, className: "theme-btn error-red" },
                                    React.createElement("i", { className: "fas fa-times-circle" }),
                                    "\u00A0Cancel")),
                            appointment.status === 'accepted' && (React.createElement("div", { className: "button-row" },
                                !!opening && (React.createElement("button", { disabled: true, className: "theme-btn blue" },
                                    React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                    "\u00A0Opening...")),
                                !opening && (React.createElement("button", { onClick: openAppointmentForCovers, className: "theme-btn blue" },
                                    React.createElement("i", { className: "fas fa-user-circle" }),
                                    "\u00A0Search for Provider")))))),
                        editing && (React.createElement("div", { className: "sidebar-section" },
                            React.createElement("div", { className: "button-row" },
                                React.createElement("button", { onClick: toggleEditAppointment, className: "theme-btn slate-blue" }, "\u00A0Back")))),
                        React.createElement("div", { className: "sidebar-section" },
                            React.createElement("h4", null, "House Rules"),
                            React.createElement("p", null, family.house_rules || 'None')),
                        React.createElement("div", { className: "sidebar-section" },
                            React.createElement("h4", null, "Admin Notes on Family to Providers"),
                            React.createElement("p", null, family.admin_family_notes || 'None')),
                        React.createElement("div", { className: "sidebar-section" },
                            React.createElement("h4", null, "Appointment History"),
                            !!activityLoading && React.createElement("p", null, "Loading history..."),
                            !activityLoading && activity.length === 0 && (React.createElement("p", null, "No history to show yet.")),
                            activity.map((activityLog) => (React.createElement("div", { className: "mb-1", key: `activity-log-${activityLog.id}` },
                                React.createElement("p", null, activityLog.action),
                                React.createElement("p", null, activityLog.notation),
                                React.createElement("p", null, moment(activityLog.created_at).format('MMMM Do YYYY, h:mma')))))))))),
            React.createElement(Modal, { title: "Confirm Cancellation", isOpen: showingCancelAppt, closeModal: toggleCancelAppointment, content: 'Are you sure you want to cancel this appointment? The Family and Provider will be both be automatically notified.', primaryButton: cancelling ? ' Cancelling...' : 'Cancel Appointment', secondaryButton: 'Close', handleClick: cancelAppointment })));
    }
}
export default AppointmentsDetail;
