/*
 *  This component's purpose is to handle routing and login checks
 *  Try to keep this component's functionality strictly to routing, and
 *  maybe add a child component for extra global functionality
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Rollbar from 'rollbar';
import { authActions } from '../actions/authActions';
import { appEnv, rollbarToken } from '../config';
import { registerPageView, userHasCompletedRegistration, userHasRole, } from '../helpers';
import { mapAuthState } from '../helpers/mapAuthState';
import AdminSection from './Admin';
import FamilySection from './Family';
import PublicSection from './Public';
import SitterSection from './Sitter';
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rollbar: new Rollbar({
                accessToken: rollbarToken,
                captureUncaught: true,
                captureUnhandledRejections: true,
                payload: {
                    environment: appEnv,
                },
            }),
        };
    }
    componentDidMount() {
        const { user } = this.props.auth;
        if (!!user) {
            this.refreshToken();
        }
    }
    componentDidUpdate(prevProps) {
        const currentPath = this.props.location.pathname;
        const prevPath = prevProps.location.pathname;
        if (currentPath !== prevPath) {
            // Navigating to new page - do stuff
            document.querySelector('body').scrollTo(0, 0);
            document.querySelector('html').scrollTo(0, 0);
            registerPageView(currentPath);
        }
    }
    refreshToken = async () => {
        try {
            await this.props.refreshToken();
        }
        catch (error) {
            console.error(error);
        }
    };
    render() {
        const { user } = this.props.auth;
        if (!user) {
            // If there is no user, send them to the public section
            return React.createElement(PublicSection, null);
        }
        if (userHasRole(user, 'Super User') || userHasRole(user, 'Admin')) {
            // Admin dashboard
            return React.createElement(AdminSection, null);
        }
        if (!userHasCompletedRegistration(user)) {
            // If there is a user, but they are in the middle of filling out the registration form,
            // send them to that section to finish the registration
            return React.createElement(PublicSection, null);
        }
        if (userHasRole(user, 'Care Provider')) {
            // Sitter dashboard
            return React.createElement(SitterSection, null);
        }
        if (userHasRole(user, 'Companion Care')) {
            // Family dashboard
            return React.createElement(FamilySection, null);
        }
        return (React.createElement("div", null,
            React.createElement("h2", null, "Whoops! Something went wrong! Please try again.")));
    }
}
const actionCreators = {
    refreshToken: authActions.refreshToken,
};
export default withRouter(connect(mapAuthState, actionCreators)(App));
