import React from 'react';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

import appointmentService from '../../services/appointmentService';
import { firstInitial, upperCaseFirst } from '../../helpers';

class SitLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      sitLog: {},
    };
  }
  componentDidMount = () => {
    if (!!this.props.match.params.id) {
      const { id } = this.props.match.params;
      if (id == Number(id)) {
        this.getSitLog(id);
      }
    }
  };

  getSitLog = async (id) => {
    try {
      const response = await appointmentService.getSitLog(id);
      if (response.status === 200) {
        const { sitLog } = response.data.data;
        this.setState({ sitLog, loading: false });
      }
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    const { sitLog, loading } = this.state;

    if (!!loading) {
      return (
        <main className="sit-log family">
          <div className="family-view-sit-log">
            <h2 className="theme-h2">Loading Appointment Log...</h2>
          </div>
        </main>
      );
    }

    return (
      <main className="sit-log family">
        <div className="family-view-sit-log">
          <h2 className="theme-h2">Appointment Summary</h2>
          <h4 className="section-label">Details</h4>
          <p>
            <b>Start Time:</b>
            <br />
            {moment(sitLog.appointment.start_time).format(
              'dddd, MMMM Do YYYY, h:mm a'
            )}
          </p>
          <p>
            <b>End Time:</b>
            <br />
            {moment(sitLog.appointment.end_time).format(
              'dddd, MMMM Do YYYY, h:mm a'
            )}
          </p>
          {!!sitLog.appointment.sitters.length && (
            <p>
              <b>
                Provider
                {sitLog.appointment.sitters.length > 1 ? 's' : ''}:
              </b>
              <br />
              {sitLog.appointment.sitters.map((sitter, idx) => (
                <span key={`sitter-log-${sitter.id}`}>
                  {upperCaseFirst(sitter.firstname)}&nbsp;
                  {firstInitial(sitter.lastname)}
                  {idx < sitLog.appointment.sitters.length - 1 ? ', ' : ''}
                </span>
              ))}
            </p>
          )}
          {!!sitLog.sitter_notes && (
            <p>
              <b>Sitter Notes:</b>
              <br />
              {sitLog.sitter_notes}
            </p>
          )}
          <h4 className="section-label">Activities</h4>
          {sitLog.activities.map((activity) => (
            <div className="sit-log-activity" key={`activity-${activity.id}`}>
              <p>
                <b>{activity.name}:</b>
                <br />
                {activity.description}
                {activity.start_time && (
                  <React.Fragment>
                    <br />
                    <span>
                      Start Time:{' '}
                      {moment(activity.start_time).format(
                        'dddd, MMMM Do YYYY, h:mm a'
                      )}
                    </span>
                  </React.Fragment>
                )}
                {activity.end_time && (
                  <React.Fragment>
                    <br />
                    <span>
                      End Time:{' '}
                      {moment(activity.end_time).format(
                        'dddd, MMMM Do YYYY, h:mm a'
                      )}
                    </span>
                  </React.Fragment>
                )}
              </p>
            </div>
          ))}
        </div>
      </main>
    );
  }
}

export default SitLog;
