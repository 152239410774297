import { filter } from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { pets } from '../../../data';
import { mapAuthState } from '../../../helpers/mapAuthState';
import petService from '../../../services/petService';
import PetForm from '../../Forms/PetForm';
import { Modal, ProfileColorPicker } from '../../shared';
const AvatarDisplayComponent = (props) => {
    return (React.createElement("div", { className: "avatar" },
        React.createElement("div", { className: "avatar-wrapper" },
            props.value && React.createElement("img", { src: props.value }),
            !props.value && React.createElement("i", { className: "far fa-camera" }))));
};
class PetProfile extends PetForm {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            loading: true,
            id: null,
            creating: true,
            family_id: null,
            showRemovePet: false,
            removingPet: false,
        };
    }
    componentDidMount = () => {
        const { id } = this.props.match.params;
        if (id === 'new') {
            this.setState({
                data: { ...this.state.freshSchema },
                creating: true,
                family_id: this.props.auth.user.personal_info.family_id,
            });
        }
        else {
            this.getPet(Number.parseInt(id, 10));
        }
    };
    getPet = async (id) => {
        try {
            const response = await petService.get(id);
            this.formatData(response.data.data);
        }
        catch (error) {
            console.error(error);
        }
    };
    formatData = ({ personalInfo, profilePet, }) => {
        const { avatar_filename, firstname, family_id, profile_color } = personalInfo;
        const { age_years, notes, type, veterinarian_info, id } = profilePet;
        let showOther = false;
        const { data } = this.state;
        data.avatar_filename = avatar_filename;
        data.firstname = firstname;
        data.age_years = age_years;
        data.notes = notes;
        data.veterinarian_info = veterinarian_info;
        data.profile_color =
            profile_color !== null ? profile_color : 'primary-coral';
        const petType = filter(pets, (pet) => {
            return pet.id === type;
        });
        if (petType.length === 0) {
            data.type = 'Other';
            data.other = type;
            showOther = true;
        }
        else {
            data.type = type;
        }
        this.setState({
            data,
            creating: false,
            personalInfo,
            profilePet,
            id,
            family_id,
            showOther,
        });
    };
    toggleRemovePet = () => {
        this.setState({
            showRemovePet: !this.state.showRemovePet,
        });
    };
    doRemovePet = async () => {
        try {
            const id = Number.parseInt(this.props.match.params.id, 10);
            if (Number.isNaN(id) === false) {
                const response = await petService.delete(id);
                if (response.status === 204) {
                    toast.success('This pet has been removed from your account.');
                    this.props.history.push('/profile');
                }
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    handleProfileColorChange = (colorClass) => {
        this.setState({ data: { ...this.state.data, profile_color: colorClass } });
    };
    doSubmit = async (callback) => {
        try {
            const response = await this.submitFormData();
            callback();
            toast.success('Hooray! Your changes are saved.');
            if (this.state.creating) {
                const { data } = response.data;
                const { id } = data.profilePet;
                this.formatData(data);
                this.setState({
                    creating: false,
                }, () => {
                    this.props.history.replace(`/profile/pet/${id}`);
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    render() {
        const { data, creating, removingPet, showRemovePet } = this.state;
        return (React.createElement("main", { className: "account profile pet" },
            React.createElement("form", { onSubmit: this.handleSubmit },
                React.createElement("div", { className: `profile-header profile-color gradient ${!!data.profile_color ? data.profile_color : 'primary-coral'}` },
                    React.createElement("div", { className: "inner" },
                        this.renderFile('avatar_filename', 'Upload Your Public Profile Image', 'image/jpg, image/png', false, AvatarDisplayComponent),
                        React.createElement("h2", { className: "theme-h2" },
                            data.firstname,
                            "\u00A0"))),
                React.createElement("div", { className: "profile-body" },
                    React.createElement("h2", null, "Pet Profile"),
                    React.createElement(ProfileColorPicker, { value: data.profile_color, onProfileColorChange: this.handleProfileColorChange }),
                    this.renderForm(),
                    this.renderButton('Save Pet Details', 'Saving...', 'theme-btn blue'),
                    React.createElement("div", { className: "cancel-btn" },
                        !creating && (React.createElement("button", { className: "theme-btn small error-red", type: "button", onClick: this.toggleRemovePet }, "Delete")),
                        React.createElement(Link, { className: "theme-btn small slate-blue", to: "/profile" }, "Cancel")))),
            React.createElement(Modal, { title: "Confirm Deletion", isOpen: showRemovePet, closeModal: this.toggleRemovePet, content: 'Are you sure you want to remove this pet from your account?', primaryButton: removingPet ? ' Removing' : 'Remove', secondaryButton: 'Close', handleClick: this.doRemovePet })));
    }
}
export default connect(mapAuthState)(PetProfile);
