import React from 'react';
import Sidebar from '../Sidebar';
import { findLinkGroupByTitle } from '../../../helpers';
import { toast } from 'react-toastify';
import FamilyEditForm from '../../Forms/FamilyEditForm';
import familyService from '../../../services/familyService';
class FamiliesEdit extends FamilyEditForm {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            saving: false,
            linkGroup: findLinkGroupByTitle('Families'),
            creating: false,
            loading: true,
            id: null,
        };
    }
    componentDidMount = () => {
        const { id } = this.props.match.params;
        const parsedId = Number.parseInt(id, 10);
        if (Number.isNaN(parsedId) === false) {
            this.getFamilyInfo(parsedId);
        }
        else {
            this.setState({
                data: {
                    ...this.state.freshSchema,
                    creating: true,
                },
                creating: true,
            });
        }
    };
    getFamilyInfo = async (id) => {
        try {
            const response = await familyService.get(id);
            if (response.status === 200) {
                const { data } = response.data;
                this.formatData(data);
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    formatData = ({ profileFamily }) => {
        const { name, smoke, sexual_criminal_offense, house_rules, admin_family_notes, id, } = profileFamily;
        const data = { ...this.state.data };
        data.name = name;
        data.smoke = smoke;
        data.sexual_criminal_offense = sexual_criminal_offense;
        data.house_rules = house_rules;
        data.admin_family_notes = admin_family_notes;
        this.setState({
            data,
            creating: false,
            id,
        });
    };
    doSubmit = async (callback) => {
        this.setState({ saving: true });
        try {
            await this.submitFormData();
            callback();
            toast.success('Hooray! Your changes are saved.');
            this.goBack();
        }
        catch (error) {
            console.error(error);
        }
        this.setState({ saving: false });
    };
    goBack = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.props.history.goBack();
    };
    render() {
        const { creating, saving, linkGroup } = this.state;
        return (React.createElement("main", { className: "dashboard admin manage" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content" },
                React.createElement("div", { className: "providers" },
                    creating && React.createElement("h2", null, "Create Family"),
                    !creating && React.createElement("h2", null, "Edit Family"),
                    React.createElement("div", { className: "providers-form form" },
                        this.renderForm(),
                        React.createElement("div", { className: "divider" }),
                        React.createElement("div", { className: "align_center row" },
                            React.createElement("button", { type: "button", className: "theme-btn blue large save-btn", disabled: saving, onClick: this.handleSubmit },
                                saving && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                saving ? ' Saving...' : 'Save')))))));
    }
}
export default FamiliesEdit;
