import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn, } from '@progress/kendo-react-grid';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { findLinkGroupByTitle } from '../../../helpers';
import appointmentService from '../../../services/appointmentService';
import familyLedgerService from '../../../services/familyLedgerService';
import familyService from '../../../services/familyService';
import logService from '../../../services/logService';
import { Modal } from '../../shared';
import { CancelledCell, ManageCell, SittersCell, StatusCell, TimeCell, } from '../Appointments/KendoComponents';
import ReviewsGrid from '../ReviewsGrid/ReviewsGrid';
import Sidebar from '../Sidebar';
import { BlockSitterCell, BooleanCell, ColumnMenu, DateCell, FavoriteSitterCell, ManageChildCell, ManageContactCell, ManagePetCell, ManageSitterCell, } from './KendoComponents';
const INITIAL_APPOINTMENT_DATA_STATE = {
    sort: [{ field: 'start_time', dir: 'asc' }],
    filter: {
        logic: 'and',
        filters: [],
    },
    filterOperators: {
        text: [
            { text: 'grid.filterContainsOperator', operator: 'contains' },
            { text: 'grid.filterEqOperator', operator: 'eq' },
        ],
    },
    skip: 0,
    take: 25,
    total: 0,
};
const INITIAL_ACTIVITY_DATA_STATE = {
    sort: [{ field: 'id', dir: 'desc' }],
    filter: {
        logic: 'and',
        filters: [],
    },
    filterOperators: {
        text: [
            { text: 'grid.filterContainsOperator', operator: 'contains' },
            { text: 'grid.filterEqOperator', operator: 'eq' },
        ],
    },
    skip: 0,
    take: 25,
    total: 0,
};
class MembersGrid extends React.Component {
    state = {
        profileFamily: null,
        contacts: [],
        resultContacts: null,
        children: [],
        resultChildren: null,
        pets: [],
        resultPets: null,
        favoriteSitters: [],
        blockedSitters: [],
        resultFavorites: null,
        resultBlocked: null,
        family: null,
        appointments: [],
        loading: true,
        appointmentLoading: true,
        activityLoading: true,
        appointmentStatus: 'all',
        linkGroup: findLinkGroupByTitle('Families'),
        dataState: {
            sort: [{ field: 'name', dir: 'asc' }],
            skip: 0,
            take: 10,
            total: 0,
        },
        appointmentDataState: {
            ...INITIAL_APPOINTMENT_DATA_STATE,
        },
        activityDataState: {
            ...INITIAL_ACTIVITY_DATA_STATE,
        },
        filter: {
            logic: 'and',
            filters: [],
        },
        transactions: [],
        balance: '0.00',
        newBalance: '0',
        balanceNotes: '',
        showManageBalance: false,
        balanceMode: 'credit',
        addingBalance: false,
        showTransactions: false,
        subscription: null,
        subscriptionActive: false,
        activity: [],
        sitterPreferences: [],
    };
    componentDidMount = () => {
        this.getFamily();
        this.getTransactions();
        this.getAppointments();
        this.getActivity();
        this.getSitterPreferences();
    };
    getActivity = async () => {
        try {
            const { id } = this.props.match.params;
            const { activityDataState } = this.state;
            const response = await logService.family(id, {
                skip: activityDataState.skip,
                take: activityDataState.take,
                sort: activityDataState.sort,
                filter: activityDataState.filter,
            });
            if (response.status === 200) {
                const { logs, count } = response.data.data;
                this.setState({
                    activity: logs,
                    activityDataState: {
                        ...activityDataState,
                        total: count,
                    },
                    activityLoading: false,
                });
            }
        }
        catch (error) {
            console.error(error);
            this.setState({
                activityLoading: false,
            });
        }
    };
    getSitterPreferences = async () => {
        try {
            const { id } = this.props.match.params;
            const response = await familyService.getSitterPreferences(id);
            if (response.status === 200) {
                const { sitterPreferences } = response.data.data;
                this.setState({
                    sitterPreferences,
                });
            }
        }
        catch (error) {
            console.error(error);
            this.setState({
                activityLoading: false,
            });
        }
    };
    getAppointments = async () => {
        try {
            const { id } = this.props.match.params;
            const { appointmentStatus, appointmentDataState } = this.state;
            const response = await appointmentService.familyAppointments({
                id,
                status: appointmentStatus,
                skip: appointmentDataState.skip,
                take: appointmentDataState.take,
                sort: appointmentDataState.sort,
                filter: appointmentDataState.filter,
            });
            if (response.status === 200) {
                const { appointments, count } = response.data.data;
                this.setState({
                    appointments,
                    appointmentDataState: {
                        ...appointmentDataState,
                        total: count,
                    },
                    appointmentLoading: false,
                });
            }
        }
        catch (error) {
            console.error(error);
            this.setState({
                appointmentLoading: false,
            });
        }
    };
    getFamily = async () => {
        try {
            const { id } = this.props.match.params;
            const response = await familyService.get(id);
            if (response.status === 200) {
                const { profileFamily, contacts, children, pets, subscription, subscriptionActive, favoriteSitters, blockedSitters, } = response.data.data;
                this.setState({
                    resultContacts: process([...contacts].slice(0), this.state.dataState),
                    resultChildren: process([...children].slice(0), this.state.dataState),
                    resultPets: process([...pets].slice(0), this.state.dataState),
                    resultFavorites: process([...favoriteSitters].slice(0), this.state.dataState),
                    resultBlocked: process([...blockedSitters].slice(0), this.state.dataState),
                    profileFamily,
                    contacts,
                    pets,
                    children,
                    loading: false,
                    subscription,
                    subscriptionActive,
                });
            }
        }
        catch (error) {
            console.error(error);
            this.setState({
                loading: false,
            });
        }
    };
    getTransactions = async () => {
        try {
            const { id } = this.props.match.params;
            const response = await familyService.getTransactions(id);
            if (response.status === 200) {
                const { balance, transactions } = response.data.data;
                this.setState({ balance, transactions });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    handleDataStateChange = (e) => {
        const dataState = e.data;
        this.setState({
            resultContacts: process([...this.state.contacts].slice(0), dataState),
            resultChildren: process([...this.state.children].slice(0), dataState),
            resultPets: process([...this.state.pets].slice(0), dataState),
            dataState,
        });
    };
    toggleShowManageBalance = () => {
        this.setState((prevState) => ({
            ...prevState,
            showManageBalance: !prevState.showManageBalance,
        }));
    };
    toggleTransactions = (event) => {
        event.preventDefault();
        this.setState((prevState) => ({
            ...prevState,
            showTransactions: !prevState.showTransactions,
        }));
    };
    setBalanceMode = (mode) => {
        this.setState({
            balanceMode: mode,
        });
    };
    onNewBalanceChange = (event) => {
        this.setState({
            newBalance: event.target.value.replace(/[^\d.-]/g, ''),
        });
    };
    onNotesChange = (event) => {
        this.setState({ balanceNotes: event.target.value });
    };
    setNewBalance = async () => {
        try {
            const { profileFamily, newBalance, balanceNotes, balanceMode } = this.state;
            const { id } = profileFamily;
            const amount = balanceMode === 'credit' ? newBalance : -newBalance;
            const data = {
                amount,
                notes: balanceNotes,
                family_id: id,
            };
            const response = await familyLedgerService.store(id, data);
            if (response.status === 201) {
                this.setState({
                    newBalance: '0',
                    balanceNotes: '',
                    showManageBalance: false,
                }, () => {
                    this.getFamily();
                });
                toast.success(`Hooray! Your ${balanceMode} of ${newBalance} was added to the ${profileFamily.name} Family!`);
            }
            else {
                toast.error('Unusual error response from the server!');
            }
        }
        catch (error) {
            console.error(error);
            toast.error('Something went wrong!');
        }
    };
    onKeyDown = (event) => {
        const invalidChars = ['-', '+', 'e'];
        if (invalidChars.includes(event.key)) {
            event.preventDefault();
        }
    };
    setFilter = (filter) => {
        const currentFilter = filter ? filter : 'pending';
        this.setState({
            appointmentStatus: currentFilter,
            appointmentLoading: true,
            appointmentDataState: { ...INITIAL_APPOINTMENT_DATA_STATE },
        }, () => {
            this.getAppointments();
        });
    };
    pageChange = (event, type) => {
        if (type === 'appointment') {
            this.setState({
                appointmentDataState: {
                    ...this.state.appointmentDataState,
                    skip: event.page.skip,
                    take: event.page.take,
                },
            }, () => {
                this.getAppointments();
            });
        }
        else {
            this.setState({
                activityDataState: {
                    ...this.state.activityDataState,
                    skip: event.page.skip,
                    take: event.page.take,
                },
            }, () => {
                this.getActivity();
            });
        }
    };
    sortChange = (event, type) => {
        if (type === 'appointment') {
            this.setState({
                appointmentDataState: {
                    ...this.state.appointmentDataState,
                    sort: event.sort,
                },
            }, () => {
                this.getAppointments();
            });
        }
        else {
            this.setState({
                activityDataState: {
                    ...this.state.activityDataState,
                    sort: event.sort,
                },
            }, () => {
                this.getActivity();
            });
        }
    };
    filterChange = (event, type) => {
        if (type === 'appointment') {
            this.setState({
                appointmentDataState: {
                    ...INITIAL_APPOINTMENT_DATA_STATE,
                    filter: event.filter,
                    skip: 0,
                },
            }, () => {
                this.getAppointments();
            });
        }
        else {
            this.setState({
                activityDataState: {
                    ...INITIAL_ACTIVITY_DATA_STATE,
                    filter: event.filter,
                    skip: 0,
                },
            }, () => {
                this.getActivity();
            });
        }
    };
    onFavoriteClick = async (piid) => {
        try {
            const { id } = this.props.match.params;
            const response = await familyService.favoriteSitter(Number(id), piid, {
                favorite: 0,
            });
            if (response.status === 200) {
                this.getFamily();
            }
        }
        catch (e) {
            console.log(e);
        }
    };
    onBlockClick = async (piid) => {
        try {
            const { id } = this.props.match.params;
            const response = await familyService.blockSitter(Number(id), piid, {
                excluded: 0,
            });
            if (response.status === 200) {
                this.getFamily();
            }
        }
        catch (e) {
            console.log(e);
        }
    };
    render() {
        const { loading, profileFamily, linkGroup, dataState, resultContacts, resultChildren, resultPets, resultFavorites, resultBlocked, balance, showManageBalance, balanceMode, newBalance, balanceNotes, showTransactions, transactions, appointments, appointmentLoading, appointmentDataState, appointmentStatus, subscription, subscriptionActive, activityLoading, activity, activityDataState, sitterPreferences, } = this.state;
        const { id } = this.props.match.params;
        if (loading) {
            return (React.createElement("main", { className: "admin admin-listing with-sidebar appointments" },
                React.createElement("div", { className: "sidebar-wrapper" },
                    React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
                React.createElement("div", { className: "content" },
                    React.createElement("h4", { className: "section-label" },
                        React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                        "\u00A0Loading Family..."))));
        }
        if (!profileFamily) {
            return (React.createElement("main", { className: "admin admin-listing with-sidebar appointments" },
                React.createElement("div", { className: "sidebar-wrapper" },
                    React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
                React.createElement("div", { className: "content" },
                    React.createElement("h4", { className: "section-label" },
                        React.createElement("i", { className: "fas fa-calendar-star" }),
                        "\u00A0Family Not Found"),
                    React.createElement("p", null,
                        "We couldn\u2019t find a family with ID ",
                        id))));
        }
        return (React.createElement("main", { className: "admin admin-listing with-sidebar with-sidebar-right appointments" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content" },
                React.createElement("h4", { className: "section-label" },
                    React.createElement("i", { className: "fas fa-calendar-star" }),
                    "\u00A0Manage",
                    ' ',
                    profileFamily.name,
                    " Family"),
                React.createElement("div", { className: "btn-wrapper" },
                    React.createElement(Link, { to: `/families/appointments/book/${id}`, className: "theme-btn blue" },
                        React.createElement("i", { className: "fas fa-plus-circle" }),
                        "\u00A0Casual Care"),
                    React.createElement(Link, { to: `/families/programs/book/${id}`, className: "theme-btn blue" },
                        React.createElement("i", { className: "fas fa-plus-circle" }),
                        "\u00A0Consistent Care")),
                React.createElement("br", null),
                React.createElement("h4", { className: "section-label" },
                    "\u00A0Subscription: ",
                    subscriptionActive ? 'Active' : 'Not Active',
                    subscriptionActive && subscription.renews_at != null
                        ? ' (Renewal Date: ' +
                            moment(subscription.renews_at).format('MMMM Do, YYYY') +
                            ')'
                        : '',
                    subscription &&
                        subscription.ends_at &&
                        ' (Ends On: ' +
                            moment(subscription.ends_at).format('MMMM Do, YYYY') +
                            ')',
                    subscription &&
                        subscription.trial_ends_at &&
                        ' (Trail Ends On: ' +
                            moment(subscription.trial_ends_at).format('MMMM Do, YYYY') +
                            ')'),
                React.createElement("br", null),
                React.createElement("h4", { className: "section-label" },
                    appointmentLoading && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                    "\u00A0Appointments"),
                React.createElement("div", { className: "filter-btn-wrapper" },
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('all');
                        } }, "All"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('pending');
                        } }, "Pending"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('24hours');
                        } }, "Next 24 Hours"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('accepted');
                        } }, "Accepted"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('completed');
                        } }, "Completed"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('cancelled');
                        } }, "Cancelled"),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: () => {
                            this.setFilter('time change requested');
                        } }, "Time Change Requested")),
                React.createElement("div", { className: "kendo-table" },
                    React.createElement(Grid, { data: appointments, pageable: true, sortable: true, ...appointmentDataState, onPageChange: (e) => this.pageChange(e, 'appointment'), onSortChange: (e) => this.sortChange(e, 'appointment'), onFilterChange: (e) => this.filterChange(e, 'appointment') },
                        React.createElement(GridColumn, { title: "Status", field: "status", sortable: false, cell: StatusCell, width: 80 }),
                        React.createElement(GridColumn, { title: "Date", field: "start_time", cell: DateCell, width: 125 }),
                        React.createElement(GridColumn, { title: "Start Time", field: "start_time", width: 250, cell: TimeCell }),
                        appointmentStatus === 'cancelled' && (React.createElement(GridColumn, { title: "Cancelled At", field: "cancelled_at", cell: CancelledCell, width: 200 })),
                        React.createElement(GridColumn, { title: "Flex", field: "flex", cell: BooleanCell, width: 70 }),
                        React.createElement(GridColumn, { title: "Provider", field: "sitters", sortable: false, cell: (props) => (React.createElement(SittersCell, { ...props, ...this.props, sitterPreferences: sitterPreferences })), columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "ID", field: "id", width: 60 }),
                        React.createElement(GridColumn, { title: " ", field: "id", width: 100, cell: (props) => React.createElement(ManageCell, { ...props, ...this.props }) }))),
                React.createElement("br", null),
                React.createElement("h4", { className: "section-label" },
                    activityLoading && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                    "\u00A0Activity"),
                React.createElement("div", { className: "kendo-table" },
                    React.createElement(Grid, { data: activity, pageable: true, sortable: true, ...activityDataState, onPageChange: (e) => this.pageChange(e, 'activity'), onSortChange: (e) => this.sortChange(e, 'activity'), onFilterChange: (e) => this.filterChange(e, 'activity') },
                        React.createElement(GridColumn, { title: "Time", field: "created_at", cell: DateCell, width: 140 }),
                        React.createElement(GridColumn, { title: "IP", field: "ip", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "User", field: "user", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Activity Type", field: "activity_type", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Action", field: "action", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Notation", field: "notation", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "ID", field: "id", width: 60 }))),
                React.createElement("br", null),
                React.createElement("h4", { className: "section-label" },
                    "\u00A0Care Recipients",
                    ' ',
                    React.createElement(Link, { to: `/families/care-recipients/edit/new/${id}` },
                        React.createElement("i", { className: "fad fa-plus-circle" }))),
                React.createElement("div", { className: "kendo-table" },
                    React.createElement(Grid, { data: resultChildren, pageable: true, sortable: true, ...dataState, onDataStateChange: this.handleDataStateChange },
                        React.createElement(GridColumn, { title: "DOB", field: "DOB", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "First Name", field: "firstname", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Last Name", field: "lastname", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: " ", field: "profile_child.id", width: 100, cell: (props) => React.createElement(ManageChildCell, { ...props, ...this.props }) }))),
                React.createElement("br", null),
                React.createElement("h4", { className: "section-label" },
                    "\u00A0Contacts",
                    ' ',
                    React.createElement(Link, { to: `/families/contacts/edit/new/${id}` },
                        React.createElement("i", { className: "fad fa-plus-circle" }))),
                React.createElement("div", { className: "kendo-table" },
                    React.createElement(Grid, { data: resultContacts, pageable: true, sortable: true, ...dataState, onDataStateChange: this.handleDataStateChange },
                        React.createElement(GridColumn, { title: "Relationship", field: "profile_care_contact.relationship", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "First Name", field: "firstname", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Last Name", field: "lastname", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Authorized", field: "profile_care_contact.authorized_contact", cell: (props) => React.createElement(BooleanCell, { ...props, ...this.props }) }),
                        React.createElement(GridColumn, { title: " ", field: "profile_care_contact.id", width: 100, cell: (props) => (React.createElement(ManageContactCell, { ...props, ...this.props })) }))),
                React.createElement("br", null),
                React.createElement("h4", { className: "section-label" },
                    "\u00A0Pets",
                    ' ',
                    React.createElement(Link, { to: `/families/pets/edit/new/${id}` },
                        React.createElement("i", { className: "fad fa-plus-circle" }))),
                React.createElement("div", { className: "kendo-table" },
                    React.createElement(Grid, { data: resultPets, pageable: true, sortable: true, ...dataState, onDataStateChange: this.handleDataStateChange },
                        React.createElement(GridColumn, { title: "Type", field: "profile_pet.type", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Name", field: "firstname", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: " ", field: "profile_pet.id", width: 100, cell: (props) => React.createElement(ManagePetCell, { ...props, ...this.props }) }))),
                React.createElement("br", null),
                React.createElement("h4", { className: "section-label" },
                    "\u00A0Favorite Sitters",
                    ' ',
                    React.createElement(Link, { to: `/families/sitters/${id}/favorite` },
                        React.createElement("i", { className: "fad fa-plus-circle" }))),
                React.createElement("div", { className: "kendo-table" },
                    React.createElement(Grid, { data: resultFavorites, pageable: true, sortable: true, ...dataState, onDataStateChange: this.handleDataStateChange },
                        React.createElement(GridColumn, { title: "First Name", field: "firstname", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Last Name", field: "lastname", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: " ", field: "id", cell: (props) => (React.createElement(FavoriteSitterCell, { onFavoriteClick: this.onFavoriteClick, ...props, ...this.props })) }),
                        React.createElement(GridColumn, { title: " ", field: "id", width: 100, cell: (props) => (React.createElement(ManageSitterCell, { ...props, ...this.props })) }))),
                React.createElement("br", null),
                React.createElement("h4", { className: "section-label" },
                    "\u00A0Blocked Sitters",
                    ' ',
                    React.createElement(Link, { to: `/families/sitters/${id}/block` },
                        React.createElement("i", { className: "fad fa-plus-circle" }))),
                React.createElement("div", { className: "kendo-table" },
                    React.createElement(Grid, { data: resultBlocked, pageable: true, sortable: true, ...dataState, onDataStateChange: this.handleDataStateChange },
                        React.createElement(GridColumn, { title: "First Name", field: "firstname", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Last Name", field: "lastname", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: " ", field: "id", cell: (props) => (React.createElement(BlockSitterCell, { onBlockClick: this.onBlockClick, ...props, ...this.props })) }),
                        React.createElement(GridColumn, { title: " ", field: "id", width: 100, cell: (props) => (React.createElement(ManageSitterCell, { ...props, ...this.props })) }))),
                React.createElement("br", null),
                React.createElement("h4", { className: "section-label" }, "\u00A0Provider Reviews "),
                React.createElement(ReviewsGrid, { familyId: Number(id) })),
            React.createElement("div", { className: "sidebar-right family-detail" },
                React.createElement("h4", null,
                    React.createElement("i", { className: "fas fa-user-cog" }),
                    "\u00A0Admin Tools"),
                React.createElement("div", { className: "sidebar-section" },
                    React.createElement("div", { className: "button-row" },
                        React.createElement(Link, { to: `/families/members/${id}/edit`, className: "theme-btn primary-green" },
                            React.createElement("i", { className: "fas fa-edit" }),
                            "\u00A0Edit Family"))),
                React.createElement("div", { className: "sidebar-section" },
                    React.createElement("h4", null, "House Rules"),
                    React.createElement("p", null, !!profileFamily.house_rules ? profileFamily.house_rules : 'None')),
                React.createElement("div", { className: "sidebar-section" },
                    React.createElement("h4", null, "Admin Notes on Family to Providers"),
                    React.createElement("p", null, profileFamily.admin_family_notes || 'None')),
                React.createElement("div", { className: "sidebar-section" },
                    React.createElement("h4", null, "Balance"),
                    React.createElement("p", { className: `${Number.parseFloat(balance) < 0 ? 'balance' : 'credit'}` },
                        React.createElement("b", null,
                            Number.parseFloat(balance) < 0 ? '-' : '',
                            "$",
                            Math.abs(Number.parseFloat(balance)),
                            "\u00A0",
                            Number.parseFloat(balance) > 0
                                ? 'credit available'
                                : 'balance due')),
                    React.createElement("button", { className: "theme-btn slate-blue small", onClick: this.toggleShowManageBalance }, "Manage"),
                    React.createElement("br", null),
                    React.createElement("a", { className: "view", href: "#", onClick: this.toggleTransactions },
                        !!showTransactions ? 'Hide' : 'View',
                        " credit/balance history"),
                    React.createElement("br", null),
                    React.createElement("br", null),
                    !!showTransactions && (React.createElement(React.Fragment, null, transactions.map((transaction) => (React.createElement(React.Fragment, { key: `transaction-${transaction.id}` },
                        React.createElement("p", { key: `transaction-${transaction.id}` },
                            React.createElement("b", null, moment(transaction.created_at).format('M/D/YY h:mma')),
                            React.createElement("br", null),
                            "$",
                            Math.abs(Number.parseFloat(transaction.amount)),
                            "\u00A0",
                            Number.parseFloat(transaction.amount) < 0
                                ? 'Balance'
                                : 'Credit',
                            "\u00A0Applied",
                            React.createElement("br", null),
                            !!transaction.notes && (React.createElement("span", null,
                                "Notes: ",
                                transaction.notes))),
                        React.createElement("br", null)))))),
                    React.createElement(Modal, { title: "Manage Credit/Balance", isOpen: showManageBalance, closeModal: this.toggleShowManageBalance, secondaryButton: 'Close', content: React.createElement("div", { className: "admin-manage-balance" },
                            React.createElement("div", { className: "button-toggler" },
                                React.createElement("div", { className: `option credit ${balanceMode === 'credit' ? 'active' : ''}`, onClick: () => {
                                        this.setBalanceMode('credit');
                                    } },
                                    React.createElement("p", null, "Credit")),
                                React.createElement("div", { className: `option balance ${balanceMode === 'balance' ? 'active' : ''}`, onClick: () => {
                                        this.setBalanceMode('balance');
                                    } },
                                    React.createElement("p", null, "Balance"))),
                            React.createElement("input", { type: "number", onChange: this.onNewBalanceChange, value: newBalance, onKeyDown: this.onKeyDown, name: "new_balance", step: "0.01" }),
                            React.createElement("textarea", { placeholder: "Notes", name: "notes", rows: 4, onChange: this.onNotesChange, value: balanceNotes }),
                            React.createElement("button", { className: `theme-btn ${balanceMode === 'credit' ? 'primary-green' : 'error-red'}`, onClick: this.setNewBalance },
                                React.createElement("i", { className: "far fa-check-circle" }),
                                "\u00A0Assign ",
                                balanceMode)) })))));
    }
}
export default MembersGrid;
