import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { mapAuthState } from '../../../helpers/mapAuthState';
import SitterAvailabilityForm from '../../Forms/SitterAvailabilityForm';
import { FormStepTracker } from '../../shared';
import trackerSteps from './trackerSteps';
class Availability extends SitterAvailabilityForm {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            availability: [],
            loading: true,
            numMonths: 3,
            months: [moment(), moment().add(1, 'months'), moment().add(2, 'months')],
            id: null,
            dirty: false,
        };
    }
    componentDidMount = () => {
        if (this.props.windowWidth >= 1200 && this.state.numMonths !== 3) {
            this.setState({
                numMonths: 3,
                months: [
                    moment(),
                    moment().add(1, 'months'),
                    moment().add(2, 'months'),
                ],
            });
        }
        else if (this.props.windowWidth < 1200 && this.state.numMonths !== 1) {
            this.setState({
                numMonths: 1,
                months: [moment()],
            });
        }
        window.addEventListener('beforeunload', this.onBeforeUnload);
    };
    onBeforeUnload = () => {
        const { dirty } = this.state;
        return dirty
            ? 'Changes will be LOST if you navigate away from the page. Are you sure you want to proceed?'
            : null;
    };
    componentWillUnmount = () => {
        window.removeEventListener('beforeunload', this.onBeforeUnload);
    };
    componentDidUpdate = (prevProps, prevState) => {
        const prevData = prevState.data;
        const { data, numMonths } = this.state;
        if (this.props.windowWidth !== prevProps.windowWidth) {
            if (this.props.windowWidth >= 1200 && numMonths !== 3) {
                this.setState({
                    numMonths: 3,
                    months: [
                        moment(),
                        moment().add(1, 'months'),
                        moment().add(2, 'months'),
                    ],
                });
            }
            else if (this.props.windowWidth < 1200 && numMonths !== 1) {
                this.setState({
                    numMonths: 1,
                    months: [moment()],
                });
            }
        }
        if (data.recurring_start_time !== null &&
            prevData.recurring_start_time !== data.recurring_start_time) {
            this.filterEndTimes();
        }
    };
    doSubmit = async (callback) => {
        try {
            await this.submitFormData(callback);
        }
        catch (error) {
            console.error(error);
            callback();
        }
    };
    postSubmit = async () => {
        try {
            const apiData = { ...this.state.data };
            const { id } = this.props.auth.user.personal_info;
            const response = await this.props.updateUser(`/profileSitter/update/${id}`, apiData);
            if (response.status === 200) {
                this.advanceStep();
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    advanceStep = async (event) => {
        if (event) {
            event.preventDefault();
        }
        try {
            const { id } = this.props.auth.user;
            await this.props.updateUserStep('care-provider-availability', id);
        }
        catch (error) {
            console.error(error);
        }
    };
    render() {
        return (React.createElement("div", { className: "registration sitter step-6 availability" },
            React.createElement("div", { className: "form", style: { maxWidth: 1200 } },
                React.createElement("div", { className: "inner" },
                    React.createElement(FormStepTracker, { steps: trackerSteps, currentStep: 3 }),
                    React.createElement("div", { className: "form-header" },
                        React.createElement("h2", null, "Set your schedule"),
                        this.renderForm())))));
    }
}
const actionCreators = {
    updateUserStep: authActions.updateUserStep,
    updateUser: authActions.updateUser,
};
export default connect(mapAuthState, actionCreators)(Availability);
