import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mobileBp } from '../../config';
import { mapAuthState } from '../../helpers/mapAuthState';
import { AppointmentListing, SystemNotices } from '../shared';
import CalendarContainer from '../shared/Appointments/CalendarContainer';
import AppointmentsPending from './AppointmentsPending';
class SitterDashboard extends React.Component {
    render() {
        const { appointments, systemNotices, windowWidth, appointmentStatus, changeAppointmentStatus, submittedAppointments, coverRequestCount, } = this.props;
        return (React.createElement("main", { className: "dashboard sitter" },
            windowWidth <= mobileBp && systemNotices.length > 0 && (React.createElement(SystemNotices, { systemNotices: systemNotices })),
            windowWidth <= mobileBp && coverRequestCount > 0 && (React.createElement("div", { className: "notice-container" },
                React.createElement("div", { className: "notice orange" },
                    React.createElement("h3", { className: "info" },
                        React.createElement("i", { className: "fas fa-exclamation-circle" }),
                        "\u00A0Pending Sub Provider Request",
                        coverRequestCount > 0 ? 's' : ''),
                    React.createElement("p", null,
                        "Our managers are reviewing your Sub Provider Request",
                        coverRequestCount > 0 ? 's' : '',
                        " and will provide an update as soon possible. If the request is within 24 hours, please contact the STL Care Office ASAP.")))),
            React.createElement("div", { className: "sidebar" },
                React.createElement(AppointmentsPending, { appointments: submittedAppointments }),
                React.createElement("div", { className: "sitter-resources" },
                    React.createElement("div", { className: "sitter-resource" },
                        React.createElement(Link, { to: "/programs" }, "Available Consistent Care Requests")),
                    React.createElement("div", { className: "sitter-resource" },
                        React.createElement(Link, { to: "/cover-requests" }, "Sub Provider Requests")),
                    React.createElement("div", { className: "sitter-resource" },
                        React.createElement(Link, { to: "/unclaimed-appointments" }, "Pending Appointments")),
                    React.createElement("div", { className: "sitter-resource" },
                        React.createElement("a", { href: "https://www.canva.com/design/DAE-P5PynR0/nJt412izaXbdhTFYIU2N4A/view", target: "_blank", rel: "noopener noreferrer" }, "Getting Started Guide")),
                    React.createElement("div", { className: "sitter-resource" },
                        React.createElement("a", { href: "https://form.jotform.com/201765390463154", target: "_blank", rel: "noopener noreferrer" }, "Account Status")))),
            React.createElement("div", { className: "list-view" },
                windowWidth > mobileBp && systemNotices.length > 0 && (React.createElement(SystemNotices, { systemNotices: systemNotices })),
                windowWidth > mobileBp && coverRequestCount > 0 && (React.createElement("div", { className: "notice-container" },
                    React.createElement("div", { className: "notice orange" },
                        React.createElement("h3", { className: "info" },
                            React.createElement("i", { className: "fas fa-exclamation-circle" }),
                            "\u00A0Pending Sub Provider Request",
                            coverRequestCount > 0 ? 's' : ''),
                        React.createElement("p", null,
                            "Our managers are reviewing your Sub Provider Request",
                            coverRequestCount > 0 ? 's' : '',
                            " and will provide an update as soon possible. If the request is within 24 hours, please contact the STL Care Office ASAP.")))),
                React.createElement(AppointmentListing, { appointments: appointments, type: "sitter", appointmentStatus: appointmentStatus, changeAppointmentStatus: changeAppointmentStatus, windowWidth: windowWidth })),
            windowWidth > mobileBp && (React.createElement("div", { className: "calendar-view" },
                React.createElement(CalendarContainer, { type: "dashboard", appointments: appointments, history: this.props.history })))));
    }
}
export default connect(mapAuthState)(SitterDashboard);
