import { GridColumnMenuCheckboxFilter, GridColumnMenuFilter, } from '@progress/kendo-react-grid';
import { get } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
export const ColumnMenu = (props) => {
    return (React.createElement("div", null,
        React.createElement(GridColumnMenuFilter, { ...props, expanded: true })));
};
export const ColumnMenuCheckboxFilter = (props) => {
    return (React.createElement("div", null,
        React.createElement(GridColumnMenuCheckboxFilter, { ...props, data: props.sitters, expanded: true })));
};
export const DateCell = (props) => {
    if (props.dataItem[props.field] !== null) {
        return React.createElement("td", null, moment(props.dataItem[props.field]).format('M/D/YY'));
    }
    return React.createElement("td", null);
};
export const RelationshipCell = (props) => {
    const field = get(props.dataItem, props.var);
    if (props.isBool) {
        return React.createElement("td", null, !!field ? 'Yes' : 'No');
    }
    return React.createElement("td", null, field);
};
export const SubscriptionCell = (props) => {
    const field = get(props.dataItem, props.var);
    return React.createElement("td", null, !!field ? 'Active' : 'Inactive');
};
export const ManageCell = (props) => {
    const id = get(props.dataItem, props.field);
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement(Link, { className: "theme-btn blue small", to: `/families/members/${id}` }, "Manage")));
};
export const ManageMemberCell = (props) => {
    const id = get(props.dataItem, props.field);
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement(Link, { className: "theme-btn blue small", to: `/families/members/edit/${id}` }, "Manage")));
};
export const ManageContactCell = (props) => {
    const id = get(props.dataItem, props.field);
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement(Link, { className: "theme-btn blue small", to: `/families/contacts/edit/${id}` }, "Manage")));
};
export const ManageChildCell = (props) => {
    const id = get(props.dataItem, props.field);
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement(Link, { className: "theme-btn blue small", to: `/families/care-recipients/edit/${id}` }, "Manage")));
};
export const ManagePetCell = (props) => {
    const id = get(props.dataItem, props.field);
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement(Link, { className: "theme-btn blue small", to: `/families/pets/edit/${id}` }, "Manage")));
};
export const BooleanCell = (props) => {
    const bool = get(props.dataItem, props.field);
    return React.createElement("td", null, !!bool ? 'Yes' : 'No');
};
export const ManageSitterCell = (props) => {
    const id = get(props.dataItem, props.field);
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement(Link, { className: "theme-btn blue small", to: `/providers/edit/${id}` }, "Manage")));
};
export const FavoriteSitterCell = (props) => {
    const id = get(props.dataItem, props.field);
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement("button", { className: "theme-btn red small", onClick: () => { props.onFavoriteClick(id); } }, "Unfavorite")));
};
export const BlockSitterCell = (props) => {
    const id = get(props.dataItem, props.field);
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement("button", { className: "theme-btn red small", onClick: () => { props.onBlockClick(id); } }, "Unblock")));
};
export const SitterActionCell = (props) => {
    const id = get(props.dataItem, props.field);
    const { type } = props;
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement("button", { className: "theme-btn red small", onClick: () => { props.onActionClick(id); } }, type === 'block' ? 'Block' : 'Favorite')));
};
