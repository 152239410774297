import BooleanInput from '@ss/components/shared/Form/BooleanInput';
import ChildCheckbox from '@ss/components/shared/Form/ChildCheckbox';
import Input from '@ss/components/shared/Form/Input';
import Radio from '@ss/components/shared/Form/Radio';
import Select from '@ss/components/shared/Form/Select';
import Textarea from '@ss/components/shared/Form/Textarea';
import { booleanRadioOptions, states } from '@ss/data';
import { displayAddress } from '@ss/helpers';
import { mapAuthState } from '@ss/helpers/mapAuthState';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
class BookingDetailsRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDrivingDistance: false,
            addressConfirmed: false,
            editingAddress: false,
            errors: {},
        };
    }
    componentDidMount() {
        this.syncPersonalInfo();
        this.scrollTop();
    }
    componentDidUpdate(prevProps) {
        const prevAppt = prevProps.appointment;
        const { appointment } = this.props;
        // If driving is true, show the driving_distance field
        if (appointment.driving_needed === 1 &&
            prevAppt.driving_needed !== appointment.driving_needed) {
            this.setState({
                showDrivingDistance: true,
            });
        }
        else if (appointment.driving_needed === 0 &&
            prevAppt.driving_needed !== appointment.driving_needed) {
            this.setState({
                showDrivingDistance: false,
            });
        }
    }
    scrollTop = () => {
        window.scrollTo(0, 0);
    };
    syncPersonalInfo = () => {
        const { idx, auth } = this.props;
        const { personal_info } = auth.user;
        const { address1, address2 = '', city, state, zip } = personal_info;
        this.props.handleBulkApptChange(idx, {
            address1,
            address2: !!address2 ? address2 : '',
            city,
            state,
            zip,
        });
    };
    toggleEditAddress = (event) => {
        event.preventDefault();
        this.setState((prevState) => ({
            ...prevState,
            editingAddress: !prevState.editingAddress,
        }));
    };
    confirmAddress = () => {
        this.setState((prevState) => ({
            ...prevState,
            addressConfirmed: !prevState.addressConfirmed,
        }));
    };
    handleChildCheckboxChange = ({ currentTarget: input, }) => {
        let children = [...this.props.appointment.selected_children];
        if (input.checked) {
            children.push(input.value);
        }
        else {
            // remove from array
            children = children.filter(function (item) {
                return item !== input.value;
            });
        }
        const { idx } = this.props;
        if (!children) {
            children = [];
        }
        this.props.handleApptChange(idx, 'selected_children', children);
    };
    isAppointmentCompleted = () => {
        const { appointment } = this.props;
        const { addressConfirmed, editingAddress } = this.state;
        if (!addressConfirmed) {
            if (editingAddress) {
                if (!appointment.address1 ||
                    !appointment.city ||
                    !appointment.state ||
                    !appointment.zip) {
                    return false;
                }
            }
            else {
                return false;
            }
        }
        if (!!appointment.driving_needed && !appointment.driving_distance) {
            return false;
        }
        if (appointment.selected_children.length === 0) {
            return false;
        }
        return true;
    };
    render() {
        const { familyChildren, idx, appointment, numAppointments, onFormComplete, nextAppointment, } = this.props;
        const { errors, showDrivingDistance, editingAddress, addressConfirmed } = this.state;
        const canAdvanceStep = this.isAppointmentCompleted();
        return (React.createElement("div", { className: "booking-details-row" },
            React.createElement("h2", null,
                "Details for Appointment #",
                idx + 1),
            React.createElement("div", { className: "booking-info" },
                !!appointment.overnight && !!appointment.num_nights && (React.createElement("div", { className: "date" },
                    React.createElement("h3", null,
                        moment(appointment.date).format('MMM D'),
                        "\u00A0-\u00A0",
                        React.createElement("span", null, moment(appointment.date)
                            .add(appointment.num_nights, 'days')
                            .format('MMM D'))),
                    React.createElement("h3", { className: "weekday" },
                        moment(appointment.date).format('ddd'),
                        "\u00A0-\u00A0",
                        React.createElement("span", null, moment(appointment.date)
                            .add(appointment.num_nights, 'days')
                            .format('ddd'))))),
                (!appointment.overnight || !appointment.num_nights) && (React.createElement("div", { className: "date" },
                    React.createElement("h3", null, moment(appointment.date).format('MMM D')),
                    React.createElement("h3", { className: "weekday" }, moment(appointment.date).format('dddd')))),
                React.createElement("div", { className: "time" },
                    React.createElement("p", null,
                        moment(appointment.start_time, 'HH:mm').format('h:mm a'),
                        "\u00A0-\u00A0",
                        moment(appointment.end_time, 'HH:mm').format('h:mm a')),
                    !!appointment.flex && (React.createElement("p", { className: "wiggle" },
                        "(",
                        appointment.flex_room,
                        " min Wiggle Room)"))),
                React.createElement("div", { className: "remove", onClick: () => {
                        this.props.removeAppointment(idx);
                    } },
                    React.createElement("button", { className: "theme-btn small error-red" },
                        React.createElement("i", { className: "fas fa-trash-alt" }),
                        "\u00A0Remove"))),
            React.createElement("div", { className: "booking-details-form" },
                React.createElement("p", null,
                    React.createElement("b", null, "Appointment Details:")),
                editingAddress && (React.createElement("div", { className: "form-group address" },
                    React.createElement(Input, { className: "address1", name: `apt-${idx}-address1`, label: "Address", type: "text", value: appointment.address1, error: errors.address1, onChange: (e) => {
                            this.props.handleApptChange(idx, 'address1', e.target.value);
                        }, required: true }),
                    React.createElement(Input, { className: "address2", name: `apt-${idx}-address2`, label: "Apt/Suite", type: "text", value: appointment.address2, error: errors.address2, onChange: (e) => {
                            this.props.handleApptChange(idx, 'address2', e.target.value);
                        }, required: false }),
                    React.createElement(Input, { className: "city", name: `apt-${idx}-city`, label: "City", type: "text", value: appointment.city, error: errors.city, onChange: (e) => {
                            this.props.handleApptChange(idx, 'city', e.target.value);
                        }, required: true }),
                    React.createElement(Select, { className: "state", name: `apt-${idx}-state`, label: "State", options: states, value: appointment.state, error: errors.state, onChange: (e) => {
                            this.props.handleApptChange(idx, 'state', e.target.value);
                        }, required: true }),
                    React.createElement(Input, { className: "zip", name: `apt-${idx}-zip`, label: "ZIP", type: "text", value: appointment.zip, error: errors.zip, onChange: (e) => {
                            this.props.handleApptChange(idx, 'zip', e.target.value);
                        }, required: true }))),
                !editingAddress && (React.createElement("div", { className: "form-group address" },
                    React.createElement(Input, { className: "address", name: `apt-${idx}-address`, label: "Address", type: "text", value: displayAddress(appointment), error: errors.driving_distance, onChange: (e) => e.preventDefault(), required: true, disabled: true }),
                    React.createElement("div", { className: "confirm-address" },
                        React.createElement(BooleanInput, { className: "confirm_address", name: `apt-${idx}-confirm_address`, label: "Confirm Address", value: addressConfirmed, error: errors.driving_distance, onChange: this.confirmAddress }),
                        React.createElement("a", { href: "#", onClick: this.toggleEditAddress }, "Edit Address")))),
                React.createElement("div", { className: "form-group" },
                    React.createElement(Radio, { className: "driving_needed", name: `apt-${idx}-driving_needed`, label: "Will this appointment require driving care recipients?", options: booleanRadioOptions, value: appointment.driving_needed, error: errors.driving_needed, onChange: (e) => {
                            this.props.handleApptChange(idx, 'driving_needed', e.target.value, 'number');
                        }, required: true }),
                    !!showDrivingDistance && (React.createElement(Input, { className: "driving_distance", name: `apt-${idx}-driving_distance`, label: "Approx. how many miles required?", type: "number", value: appointment.driving_distance, error: errors.driving_distance, onChange: (e) => {
                            this.props.handleApptChange(idx, 'driving_distance', e.target.value);
                        }, required: true }))),
                React.createElement(Textarea, { className: "notes", name: `apt-${idx}-notes`, label: "Notes for this appointment", value: appointment.notes, error: errors.notes, onChange: (e) => {
                        this.props.handleApptChange(idx, 'notes', e.target.value);
                    }, required: false }),
                React.createElement(ChildCheckbox, { className: "selected_children", name: `apt-${idx}-selected_children`, label: "Select recipients that need care for this appointment", options: familyChildren, value: appointment.selected_children, error: errors.selected_children, onChange: this.handleChildCheckboxChange, required: true })),
            React.createElement("div", { className: "booking-footer" },
                idx === numAppointments - 1 && canAdvanceStep && (React.createElement("button", { className: "theme-btn blue", onClick: onFormComplete }, "Next Step - Select Providers")),
                idx === numAppointments - 1 && !canAdvanceStep && (React.createElement("button", { className: "theme-btn blue disabled", onClick: (e) => e.preventDefault() }, "Next Step - Select Providers")),
                idx < numAppointments - 1 && canAdvanceStep && (React.createElement("button", { className: "theme-btn primary-green", onClick: nextAppointment },
                    React.createElement("i", { className: "far fa-plus-circle" }),
                    "\u00A0Add Details for Sit #",
                    idx + 2)),
                idx < numAppointments - 1 && !canAdvanceStep && (React.createElement("button", { className: "theme-btn primary-green disabled", onClick: (e) => e.preventDefault() },
                    React.createElement("i", { className: "far fa-plus-circle" }),
                    "\u00A0Add Details for Sit #",
                    idx + 2)))));
    }
}
export default connect(mapAuthState)(BookingDetailsRow);
