import $ from 'jquery';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import httpService from '../../../services/httpService';
import Form from '../../shared/Form';

import Joi from 'joi';
import { mapAuthState } from '../../../helpers/mapAuthState';

const radioOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
];

class CareProfileForm extends Form {
  schema = Joi.object({
    firstname: Joi.string().required().label('First Name'),
    lastname: Joi.string().required().label('Last Name'),
    dob_year: Joi.number().integer().min(1900).required().label('DOB - Year'),
    dob_month: Joi.number()
      .integer()
      .min(1)
      .max(12)
      .required()
      .label('DOB - Month'),
    dob_day: Joi.number()
      .integer()
      .min(1)
      .max(31)
      .required()
      .label('DOB - Day'),
      selected_recipient_needs: Joi.array()
      .optional()
      .label('Selected Companion Needs'),
    notes: Joi.string()
      .optional()
      .allow(null)
      .allow('')
      .label('Notes and Rules'),
    fall_risk: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .required()
      .label('Fall Risk'),
    help_standing_up: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .required()
      .label('Help Standing Up'),
    wheelchair: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .required()
      .label('Wheelchair'),
  });
  constructor(props) {
    super(props);
    this.state = {
      data: {
        firstname: '',
        lastname: '',
        dob_year: '',
        dob_month: '',
        dob_day: '',
        selected_recipient_needs: [],
        notes: '',
        wheelchair: '',
        fall_risk: '',
        help_standing_up: '',
      },
      errors: {},
    };
  }
  componentDidMount() {
    const { user } = this.props.auth;
    if (!user) {
      this.sendUserBack();
    } else {
      this.mergeData();
    }
  };
  mergeData = () => {
    const { child } = this.props;
    const data = { ...this.state.data };
    if (!!child) {

      const { firstname, lastname, profile_child, DOB, selected_recipient_needs } = child;
      const { wheelchair, fall_risk, help_standing_up, notes } = profile_child;

      data['firstname'] = child.firstname;
      data['lastname'] = child.lastname;
      if (wheelchair !== null) data['wheelchair'] = wheelchair;
      if (fall_risk !== null) data['fall_risk'] = fall_risk;
      if (help_standing_up !== null) data['help_standing_up'] = help_standing_up;
      if (notes !== null) data['notes'] = notes;

      if (!!DOB) {
        const dobPieces = DOB.split('-');
        if (dobPieces.length === 3) {
          // We assume format "YYYY-MM-DD"
          // so after splittig, it should be: [ "YYYY", "MM", "DD" ]
          data['dob_year'] = dobPieces[0];
          data['dob_month'] = dobPieces[1];
          data['dob_day'] = dobPieces[2];
        }
      }

      data['selected_recipient_needs'] = selected_recipient_needs.map(r => `${r.id}`);
      
      this.setState({ data });
    };
  };
  sendUserBack() {
    alert('You must sign up or log in to view this page.');
    window.open('/', '_self');
  };
  doSubmit = async (callback) => {
    let apiData = { ...this.state.data };
    const { family_id } = this.props.auth.user.personal_info;
    const { dob_day, dob_year, dob_month } = apiData;

    apiData['DOB'] = moment(
      `${dob_year}-${dob_month}-${dob_year}`,
      'YYYY-M-D'
    ).format('YYYY-MM-DD');
    apiData['family_id'] = family_id;

    delete apiData['dob_day'];
    delete apiData['dob_year'];
    delete apiData['dob_month'];

    const url = !!this.props.child ? `/api/profileChild/update/${this.props.child.profile_child.id}` : '/api/profileChild/store';

    try {
      const response = await httpService.post(url, apiData);
      console.log(response);
      callback();
      // "add child" has response 201
      // "update child" has response 200
      if (response.status === 200 || response.status === 201) {
        this.props.onProfileComplete();
      } else {
        $('.registration').animate({ scrollTop: 0 });
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  handleCancel = (e) => {
    e.preventDefault();
    this.props.handleCancel();
  };
  render() {
    const { submitting } = this.state;
    const { careRecipientNeeds } = this.props;
    return (
      <div className="care-profile-form">
        <div className="form-header">
          <h2>Care Profile</h2>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group justify-space-between">
            <h3>Recipient of Care Information</h3>
            {this.renderInput('firstname', 'First Name', 'text', true)}
            {this.renderInput('lastname', 'Last Name', 'text', true)}
            <div className="form-field-group field-with-checkbox">
              {this.renderTextDatePicker(
                'dob',
                'Date of Birth (MM/DD/YYYY)',
                true
              )}
            </div>
            {this.renderCheckbox(
              'selected_recipient_needs',
              'Please select all care that applies to the companion\'s needs:',
              careRecipientNeeds
            )}
            {this.renderTextarea(
              'notes',
              'Notes',
              false
            )}
            {this.renderRadio(
              'help_standing_up',
              'Does the companion need help transfering from a seated postion to a standing position?',
              radioOptions,
              true
            )}
            {this.renderRadio(
              'fall_risk',
              'Is the companion at risk of falling or have fallen recently?',
              radioOptions,
              true
            )}
            {this.renderRadio(
              'wheelchair',
              'Is the companion in a wheelchair?',
              radioOptions,
              true
            )}
          </div>
          <div className="form-field button next-step">
            {!!submitting && (
              <button
                type="submit"
                className="theme-btn blue"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <i className="fas fa-spinner fa-spin"></i> Saving...
              </button>
            )}
            {!submitting && (
              <button
                type="submit"
                className="theme-btn blue"
                onClick={this.handleSubmit}
              >
                Save
              </button>
            )}
            <br/>
            <br/>
            <button
              className="theme-btn slate-blue small"
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const actionCreators = {
  updateUserStep: authActions.updateUserStep,
};

export default connect(mapAuthState, actionCreators)(CareProfileForm);
