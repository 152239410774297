export default {
    family: [
        {
            group_name: false,
            group_links: [
                {
                    title: 'Appointments',
                    href: '/dashboard',
                },
                {
                    title: 'Care Providers',
                    href: '/care-providers',
                },
                {
                    title: 'My Household',
                    href: '/profile',
                },
                {
                    title: 'Account Settings',
                    href: '/settings/account',
                },
                {
                    title: 'Billing Settings',
                    href: '/settings/billing',
                },
                {
                    title: 'Consistent Care Requests',
                    href: '/programs',
                },
            ],
        },
        {
            group_name: 'External Resources',
            group_links: [
                {
                    title: 'New Member Guide',
                    href: 'https://www.canva.com/design/DAE-eLKYX-c/view',
                    external: true,
                },
                {
                    title: 'Terms & Conditions',
                    href: 'https://www.stlcare.io/terms-conditions',
                    external: true,
                },
            ],
        },
    ],
    sitter: [
        {
            group_name: 'About Me',
            group_links: [
                {
                    title: 'My Profile',
                    href: '/profile',
                },
                {
                    title: 'My Schedule',
                    href: '/profile/availability',
                },
                {
                    title: 'Account Settings',
                    href: '/settings/account',
                },
            ],
        },
    ],
    admin: [
        {
            group_name: 'Programs',
            group_link: '/programs',
            group_links: [
                {
                    title: 'Manage',
                    href: '/programs',
                },
            ],
        },
        {
            group_name: 'Appointments',
            group_link: '/appointments',
            group_links: [
                {
                    title: 'All Appointments',
                    href: '/appointments/all',
                },
                {
                    title: 'Pending Appointments',
                    href: '/appointments/pending',
                },
                {
                    title: 'Admin Priority',
                    href: '/appointments/pending-admin',
                },
                {
                    title: 'Next 24 Hours Appointments',
                    href: '/appointments/24hours',
                },
                {
                    title: 'Accepted Appointments',
                    href: '/appointments/accepted',
                },
                {
                    title: 'Completed Appointments',
                    href: '/appointments/completed',
                },
                {
                    title: 'Cancelled Appointments',
                    href: '/appointments/cancelled',
                },
                {
                    title: 'Auto-Assigning',
                    href: '/appointments/submitted-timed',
                },
            ],
        },
        {
            group_name: 'Families',
            group_link: '/families/manage',
            group_links: [
                {
                    title: 'Manage',
                    href: '/families/manage',
                },
            ],
        },
        {
            group_name: 'Providers',
            group_link: '/providers/manage',
            group_links: [
                {
                    title: 'Manage',
                    href: '/providers/manage',
                },
                {
                    title: 'Applications',
                    href: '/providers/applications',
                },
                {
                    title: 'Availability',
                    href: '/providers/availability',
                },
                {
                    title: 'Reviews',
                    href: '/providers/reviews',
                },
            ],
        },
        {
            group_name: 'Settings',
            group_link: '/settings/pricing',
            group_links: [
                {
                    title: 'Pricing',
                    href: '/settings/pricing',
                },
                {
                    title: 'Logs',
                    href: '/settings/logs',
                },
                {
                    title: 'Coupons',
                    href: '/settings/coupons',
                },
                {
                    title: 'Announcements',
                    href: '/settings/announcements',
                },
                {
                    title: 'Hours Worked Report',
                    href: '/reports/hours-worked',
                },
                {
                    title: 'Appointment Cancellation Report',
                    href: '/reports/appointments-cancelled',
                },
            ],
        },
    ],
};
