import $ from 'jquery';
import { each, findIndex, map, sortBy } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from 'react-stripe-elements';
import { toast } from 'react-toastify';
import { balanceActions } from '../../../actions/balanceActions';
import {
  additionalServicesOptions,
  bookingFreshSchema as freshSchema,
  bulkBookingFee,
  states,
  stripeFieldStyles as fieldStyles,
} from '../../../data';
import { findFeeBySlug, upperCaseFirst } from '../../../helpers';
import { PaymentHelper } from '../../shared';
import appointmentService from '../../../services/appointmentService';
import familyService from '../../../services/familyService';
import feeService from '../../../services/feeService';
import httpService from '../../../services/httpService';
import { CouponChecker } from '../../shared';
import Input from '../../shared/Form/Input';
import Select from '../../shared/Form/Select';
import BulkAppointments from './BulkAppointments';
import BulkCheckout from './BulkCheckout';
import BulkDetails from './BulkDetails';
import BulkNeeds from './BulkNeeds';
import { getCareRecipientNeedsOptionsRequest } from '../../../services/careRecipientNeedsOptionsService';

const UNSAVED_CHANGES_MESSAGE =
  'Changes will be LOST if you navigate away from the page. Are you sure you want to proceed?';

class BulkBooking extends PaymentHelper {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        using_balance: false,
        firstname: '',
        lastname: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',

        program_start_date: '',
        program_num_weeks: '',
        program_selected_children: [],

        program_address1: '',
        program_address2: '',
        program_city: '',
        program_state: '',
        program_zip: '',

        program_notes: '',
        program_requested_sitter: '',
        program_selected_recipient_needs: [],
        program_additional_services: [],
        program_additional_services_notes: '',

        program_terms_accepted: 0,

        repeatingAppointments: [],
        randomAppointments: [],
      },
      freshSchema, // imported from data
      dirty: false,
      step: 1,
      errors: {},
      today: moment(),
      children: [],
      fees: [],
      careRecipientNeeds: [],
      stripeUser: null,
      holidays: [],
      hasStripeUser: false,
      changingPaymentInfo: false,
      sameTimes: false,

      bookingFee: bulkBookingFee,
      actualFee: bulkBookingFee,
      coupon: false,

      submitting: false,
    };
  }
  componentDidMount = () => {
    this.getBalance();
    this.getChildren();
    this.getFees();
    this.getStripeCustomer();
    this.syncPersonalInfo();

    window.onbeforeunload = () => {
      const { dirty } = this.state;
      return dirty ? UNSAVED_CHANGES_MESSAGE : null;
    };
  };
  componentWillUnmount = () => {
    window.onbeforeunload = () => {
      return null;
    };
  };
  componentDidUpdate(prevProps, prevState) {
    // Balance checking
    const prevBalance = prevProps.balance.balance;
    const { balance } = this.props.balance;
    if (parseFloat(balance) < 0) {
      toast.error(
        `Uh oh! Looks like you have an outstanding balance of $${parseFloat(
          Math.abs(balance)
        )}. You must pay this before you are allowed to book programs.`
      );
      this.props.history.push('/settings/billing');
    }

    // Check for anything that needs to be done when the step advances
    const { step } = this.state;
    const prevStep = prevState.step;
    if (step !== prevStep) {
      this.scrollTop();
      if (step === 2) {
        // Once you're on step 2, you want to be warned before you navigate away
        this.setState({ dirty: true });
        // Activities aren't needed until step 3, but preload them anyway
        this.getCareRecipientNeeds();
      }
      if (step === 3) {
        // Fees aren't seen until step 4, but might as well calculate them early
        this.calculateAllAppointmentFees();
      }
    }
  }
  syncPersonalInfo = () => {
    const { personal_info } = this.props.auth.user;
    const { address1, address2 = '', city, state, zip } = personal_info;
    const data = { ...this.state.data };
    data['program_address1'] = address1;
    data['program_address2'] = !!address2 ? address2 : '';
    data['program_city'] = city;
    data['program_state'] = state;
    data['program_zip'] = zip;
    this.setState({ data });
  };
  getFees = async () => {
    const response = await feeService.get();
    if (response.status === 200) {
      const { fees } = response.data.data;
      this.setState({
        fees,
      });
    }
  };
  getChildren = async () => {
    try {
      const { family_id } = this.props.auth.user.personal_info;
      const response = await familyService.get(family_id);
      if (response.status === 200) {
        const { children } = response.data.data;
        let selected_children = map(children, (child) => {
          return child.id + '';
        });
        const data = { ...this.state.data };
        data['program_selected_children'] = selected_children;
        this.setState({
          children,
          data,
        });
      }
    } catch (e) {
      console.log(e);
      toast.error('Uh oh! Something went wrong! Please refresh and try again!');
    }
  };
  getCareRecipientNeeds = async () => {
    try {
      const careRecipientNeeds = await getCareRecipientNeedsOptionsRequest();

      this.setState({ careRecipientNeeds });
    } catch (error) {
      console.error(error);
    }
  };
  calculateAllAppointmentFees = () => {
    // Calculate all the appointment fees
    // It is important to know that this is BEFORE the 20% program discount is applied
    // Anywhere the discount needs to be applied, just multiply by 0.8 to get the discounted total,
    // or multiply by 0.2 to get the discount amount.
    const data = { ...this.state.data };
    const { fees } = this.state;
    const selectedChildren = data['program_selected_children'];
    const repeatingAppointments = [...data['repeatingAppointments']];
    const randomAppointments = [...data['randomAppointments']];
    each(repeatingAppointments, (appointment) => {
      let fee = this.calculateAppointmentFee(
        appointment,
        selectedChildren,
        'repeating',
        fees
      );
      appointment['fee'] = fee;
    });
    each(randomAppointments, (appointment) => {
      let fee = this.calculateAppointmentFee(
        appointment,
        selectedChildren,
        'random',
        fees
      );
      appointment['fee'] = fee;
    });
    data['repeatingAppointments'] = repeatingAppointments;
    data['randomAppointments'] = randomAppointments;
    this.setState({ data });
  };
  calculateAppointmentFee = (appointment, children, type, fees) => {
    let totalFee = 0;
    let {
      date,
      weekday,
      repeatingOptions,

      overnight,
      num_nights,
      start_time,
      end_time,
      driving_needed,
      driving_distance,
      flex,
      flex_room,
    } = appointment;

    // here we need to get the date of the appt so we can get the hourly date & duration. since our 2 appt types have
    // different formats, we need to handle them differently
    let startDate =
      type === 'repeating'
        ? // The thing that matters (since it's a repeating appointment) is the weekday it's on and the duration. so we just
          // calculate the start date here based on today's date and the selected weekday.
          moment().startOf('week').add(weekday, 'days').format('YYYY-MM-DD')
        : // if the type is a random day, the date object itself is enough.
          moment(date).format('YYYY-MM-DD');

    let startDateAndTime = moment(
      `${startDate} ${start_time}`,
      'YYYY-MM-DD HH:mm'
    );
    let endDateAndTime = !!overnight
      ? moment(
          `${moment(startDate, 'YYYY-MM-DD')
            .add(num_nights, 'days')
            .format('YYYY-MM-DD')} ${end_time}`,
          'YYYY-MM-DD HH:mm'
        )
      : moment(`${startDate} ${end_time}`, 'YYYY-MM-DD HH:mm');
    // Get the # of hours
    let duration = moment
      .duration(endDateAndTime.diff(startDateAndTime))
      .asHours();

    let hourlyRate = this.getHourlyRate(startDateAndTime, children, fees);
    let hourlyFee = Math.max(hourlyRate * duration, 30);
    totalFee += hourlyFee;

    if (!!flex) {
      // Flex is in 15-minute increments. The flex fee is # increments * 1/4 of the hourly rate
      // So here we get the 1/4 of the hourly rate
      let flexRate = hourlyRate / 4;
      // And here we get the number of 15-min increments
      let flexIncrements = parseInt(flex_room) / 15;
      totalFee += flexRate * flexIncrements;
    }

    // Calculate driving fee
    if (!!driving_needed) {
      const drivingFeeRate = parseFloat(
        findFeeBySlug('mileage_rate', fees).amount
      );
      const minDrivingFeeRate = parseFloat(
        findFeeBySlug('mileage_min', fees).amount
      );
      const dist = !!driving_distance ? driving_distance : 0;
      let drivingDistanceFee = drivingFeeRate * parseFloat(dist);

      // There is a minimum fee of $5 for driving
      totalFee += parseFloat(Math.max(drivingDistanceFee, minDrivingFeeRate));
    }

    if (type === 'repeating') {
      // Since each repeating appt is exactly the same, just multiply by # appts.
      totalFee *= appointment.repeatingOptions.length;
    }

    return totalFee;
  };
  getHourlyRate = (startDateAndTime, children, fees) => {
    const weekday = startDateAndTime.format('d');
    const hour = startDateAndTime.format('H');
    if (Number(weekday) === 6 && Number(hour) >= 15) {
      return parseFloat(findFeeBySlug('surge_pricing', fees).amount);
    } else {
      const numChildren = children.length;
      let childFeeSlug = 'one_child_per_hour';
      if (numChildren >= 4) childFeeSlug = 'four_plus_children_per_hour';
      else if (numChildren >= 2) childFeeSlug = 'two_three_children_per_hour';
      return parseFloat(findFeeBySlug(childFeeSlug, fees).amount);
    }
  };
  scrollTop = () => {
    $('.booking-component-inner').animate({ scrollTop: 0 }, 0);
  };
  advanceStep = (e) => {
    if (!!e) e.preventDefault();
    let { step } = this.state;
    step += 1;
    this.setState({
      step,
    });
  };
  handleChange = (key, value) => {
    // This handles data changes on the base data object
    const data = { ...this.state.data };
    data[key] = value;
    this.setState({ data });
  };
  addNewAppointment = (type, appointment) => {
    const data = { ...this.state.data };
    if (type === 'repeating') {
      // I know these assignments look weird but we need to make sure we are creating a new data object before setting the state
      // That way we know we are creating changes instead of modifying the original. That's important in our componentDidUpdate functions
      // since if the original is modified, prevProps/State and the current might be the same so it's much harder to detect changes
      let repeatingAppointments = [...data['repeatingAppointments']];
      repeatingAppointments.push(appointment);
      repeatingAppointments = sortBy(repeatingAppointments, [
        'weekday',
        'start_time',
      ]);
      data['repeatingAppointments'] = repeatingAppointments;
    } else {
      let randomAppointments = [...data['randomAppointments']];
      randomAppointments.push(appointment);
      randomAppointments = sortBy(randomAppointments, ['date', 'start_time']);
      data['randomAppointments'] = randomAppointments;
    }
    this.setState({ data });
  };
  saveEditedAppointment = (type, appointment) => {
    const data = { ...this.state.data };
    let appointments = [...data[`${type}Appointments`]];
    const idx = findIndex(appointments, (appt) => {
      return appt.rid === appointment.rid;
    });
    appointments[idx] = appointment;
    data[`${type}Appointments`] = appointments;
    this.setState({ data });
  };
  deleteAppointmentByRid = (type, apptRid) => {
    const data = { ...this.state.data };
    const key = `${type}Appointments`;
    const appointments = [...data[key]];
    const idx = findIndex(appointments, (appt) => apptRid === appt.rid);
    appointments.splice(idx, 1);
    data[key] = appointments;
    this.setState({
      data,
    });
  };
  setStep = (step) => {
    let currentStep = this.state.step;
    // We do not want them to go forward in steps
    // As in, if they click on 3, and are on step 1, then 1 > 3 = false, and nothing happens.
    if (currentStep > step) {
      this.setState({ step });
    }
  };
  doSubmit = async () => {
    this.setState({
      submitting: true,
    });
    // submitPayment function is within PaymentHelper component
    this.submitPayment();
  };
  onPaymentComplete = () => {
    this.submitBooking();
  };
  submitBooking = async () => {
    try {
      const piid = this.props.auth.user.personal_info.id;
      const data = { ...this.state.data };

      const startDate = moment(data['program_start_date']);

      const selectedChildren = data['program_selected_children'];

      const appointments = [];
      const randomAppointments = [...data['randomAppointments']];
      const repeatingAppointments = [...data['repeatingAppointments']];

      const repeating_days = [];

      each(randomAppointments, (appt) => {
        let appointment = { ...appt };
        let startDate = moment(appointment['date']).format('YYYY-MM-DD');
        appointment['start_time'] = moment(
          `${startDate} ${appointment['start_time']}`,
          'YYYY-MM-DD HH:mm:ss'
        ).format();
        appointment['end_time'] = !!appointment.overnight
          ? moment(
              `${moment(startDate, 'YYYY-MM-DD')
                .add(appointment['num_nights'], 'days')
                .format('YYYY-MM-DD')} ${appointment['end_time']}`,
              'YYYY-MM-DD HH:mm:ss'
            ).format()
          : moment(
              `${startDate} ${appointment['end_time']}`,
              'YYYY-MM-DD HH:mm:ss'
            ).format();
        delete appointment['rid'];
        delete appointment['date'];
        appointment['selected_children'] = selectedChildren;
        appointment['address1'] = data['program_address1'];
        appointment['address2'] = data['program_address2'];
        appointment['city'] = data['program_city'];
        appointment['state'] = data['program_state'];
        appointment['zip'] = data['program_zip'];
        appointments.push(appointment);
      });

      each(repeatingAppointments, (appt) => {
        let repeatingApptTemplate = { ...appt };
        repeating_days.push(appt.weekday);
        for (
          var i = 0;
          i < repeatingApptTemplate.repeatingOptions.length;
          i++
        ) {
          let appointment = { ...repeatingApptTemplate };
          let weekNum = parseInt(appointment.repeatingOptions[i]);
          let weekday = appointment.weekday;
          let apptStartDate = moment(startDate)
            .add(weekNum - 1, 'weeks')
            .startOf('week')
            .add(weekday, 'days')
            .format('YYYY-MM-DD');
          appointment['start_time'] = moment(
            `${apptStartDate} ${appointment['start_time']}`,
            'YYYY-MM-DD HH:mm:ss'
          ).format();
          appointment['end_time'] = !!appointment.overnight
            ? moment(
                `${moment(apptStartDate, 'YYYY-MM-DD')
                  .add(appointment['num_nights'], 'days')
                  .format('YYYY-MM-DD')} ${appointment['end_time']}`,
                'YYYY-MM-DD HH:mm:ss'
              ).format()
            : moment(
                `${apptStartDate} ${appointment['end_time']}`,
                'YYYY-MM-DD HH:mm:ss'
              ).format();
          delete appointment['rid'];
          delete appointment['weekday'];
          delete appointment['repeatingOptions'];
          delete appointment['fee'];
          appointment['selected_children'] = selectedChildren;
          appointment['address1'] = data['program_address1'];
          appointment['address2'] = data['program_address2'];
          appointment['city'] = data['program_city'];
          appointment['state'] = data['program_state'];
          appointment['zip'] = data['program_zip'];
          appointments.push(appointment);
        }
      });
      const additionalServices = [];
      each(additionalServicesOptions, (service) => {
        if (
          data['program_additional_services'].indexOf(`${service.value}`) > -1
        ) {
          if (service.value === 1000) {
            additionalServices.push(data['program_additional_services_notes']);
          } else {
            additionalServices.push(service.label);
          }
        }
      });
      const sortedAppointments = sortBy(appointments, [
        'start_time',
        'end_time',
      ]);
      const programStartDate = moment(sortedAppointments[0].start_time).format(
        'YYYY-MM-DD'
      );
      const programEndDate = moment(sortedAppointments[0].end_time).format(
        'YYYY-MM-DD'
      );
      const apiData = {
        booking_appointments: appointments,
        requested_sitter: !!data['program_requested_sitter']
          ? data['program_requested_sitter']
          : null,
        selected_recipient_needs: data['program_selected_recipient_needs'],
        additional_services:
          additionalServices.length > 0 ? additionalServices.join(', ') : null,
        start_date: programStartDate,
        end_date: programEndDate,
        type: 'program',
        repeating_days: repeating_days,
        notes: data['program_notes'],
        refresh_tags: 1,
      };
      const response = await appointmentService.create(piid, apiData);
      if (response.status === 200) {
        toast.success(`Your program booking has been submitted.`);
        this.setState({
          step: 5,
          dirty: false,
        });
      } else {
        toast.error('Unusual error response from the server!');
        this.setState({
          submitting: false,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        submitting: false,
      });
    }
  };
  render() {
    const {
      step,
      data,
      errors,
      children,
      careRecipientNeeds,
      bookingFee,
      actualFee,
      coupon,
      hasStripeUser,
      changingPaymentInfo,
      submitting,
      dirty,
    } = this.state;
    const { using_balance } = data;
    const { currentAppointments } = this.props;
    const { balance } = this.props.balance;
    const { user } = this.props.auth;

    return (
      <div className={`booking-component bulk-booking step-${step}`}>
        <div className="booking-component-inner">
          <div className="regular-booking-wrapper">
            <Prompt when={dirty} message={UNSAVED_CHANGES_MESSAGE} />
            {step < 5 && (
              <div className="booking-header">
                <div className="inner">
                  <div
                    className={`booking-step ${
                      step === 1 ? 'active' : step > 1 ? 'completed' : ''
                    }`}
                    onClick={() => {
                      this.setStep(1);
                    }}
                  >
                    <i className="fas fa-check"></i>
                    <p>Establish Needs</p>
                  </div>
                  <div
                    className={`booking-step ${
                      step === 2 ? 'active' : step > 2 ? 'completed' : ''
                    }`}
                    onClick={() => {
                      this.setStep(2);
                    }}
                  >
                    <i className="fas fa-calendar-star"></i>
                    <p>Set Dates</p>
                  </div>
                  <div
                    className={`booking-step ${
                      step === 3 ? 'active' : step > 3 ? 'completed' : ''
                    }`}
                    onClick={() => {
                      this.setStep(3);
                    }}
                  >
                    <i className="fas fa-list-alt"></i>
                    <p>Final Details</p>
                  </div>
                </div>
              </div>
            )}
            <div className="booking-body">
              {step === 1 && (
                <div className="book-appointment-form needs">
                  <h2>Establish Your Needs</h2>
                  <BulkNeeds
                    handleChange={this.handleChange}
                    data={data}
                    errors={errors}
                    children={children}
                    onFormComplete={this.advanceStep}
                  />
                </div>
              )}
              {step === 2 && (
                <div className="book-appointment-form appointments">
                  <h2>Consistent Care Calendar</h2>
                  <p>Booking for {data['program_num_weeks']} Weeks</p>
                  <div className="bulk-notice red">
                    <p>
                      Below you will find a calendar with your selected date
                      range! You can create a repeating schedule easily by
                      “Adding a Repeating Day” and/or “Add Random Days”.
                    </p>
                    <div className="tooltip-wrapper">
                      <div className="inner">
                        <i className="fas fa-info-circle"></i>
                        <div className="tooltip-item">
                          <p>
                            Please give us the most accurate information as
                            possible, because additional changes might delay the
                            process!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <BulkAppointments
                    handleChange={this.handleChange}
                    data={data}
                    errors={errors}
                    currentAppointments={currentAppointments}
                    children={children}
                    onFormComplete={this.advanceStep}
                    addNewAppointment={this.addNewAppointment}
                    saveEditedAppointment={this.saveEditedAppointment}
                    deleteAppointmentByRid={this.deleteAppointmentByRid}
                  />
                </div>
              )}
              {step === 3 && (
                <div className="book-appointment-form appointments">
                  <h2>Your Perfect Match</h2>
                  <BulkDetails
                    handleChange={this.handleChange}
                    data={data}
                    careRecipientNeeds={careRecipientNeeds}
                    errors={errors}
                    onFormComplete={this.advanceStep}
                  />
                </div>
              )}
              {step === 4 && (
                <div className="book-appointment-form appointments">
                  <h2>Payment and Checkout</h2>
                  <BulkCheckout
                    data={data}
                    onFormComplete={this.doSubmit}
                    handleChange={this.handleChange}
                    balance={balance}
                    submitting={submitting}
                    enteringPaymentInfo={
                      !hasStripeUser || !!changingPaymentInfo
                    }
                    bookingFee={bookingFee}
                    actualFee={actualFee}
                    coupon={coupon}
                    PaymentSection={
                      <div className="booking-payment-info bulk">
                        <div className="payment-info">
                          {parseFloat(balance) > 0 && (
                            <React.Fragment>
                              {this.renderBoolean(
                                'using_balance',
                                `You have a credit of $${balance}. Would you like to use it today?`
                              )}
                            </React.Fragment>
                          )}
                          {hasStripeUser && !changingPaymentInfo && (
                            <div className="payment-info-item">
                              <h3>
                                {user.card_brand !== null
                                  ? upperCaseFirst(user.card_brand)
                                  : user.card_brand}
                              </h3>
                              <p>
                                ....&nbsp;
                                {user.card_last_four}
                              </p>
                              <div className="btn-wrapper">
                                <button
                                  className="theme-btn small blue"
                                  onClick={this.changePaymentInfo}
                                >
                                  Change
                                </button>
                              </div>
                            </div>
                          )}
                          <CouponChecker
                            className="program"
                            type="booking"
                            applyCouponCode={this.applyCouponCode}
                          />
                          {(!hasStripeUser || !!changingPaymentInfo) && (
                            <div className="payment-fields">
                              <div className="payment-billing-address">
                                <Input
                                  className="firstname"
                                  name={`program-payment-firstname`}
                                  label="First Name"
                                  type="text"
                                  value={data['firstname']}
                                  error={errors['firstname']}
                                  onChange={(e) => {
                                    this.handleChange(
                                      'firstname',
                                      e.target.value
                                    );
                                  }}
                                  required={true}
                                />
                                <Input
                                  className="lastname"
                                  name={`program-payment-lastname`}
                                  label="Last Name"
                                  type="text"
                                  value={data['lastname']}
                                  error={errors['lastname']}
                                  onChange={(e) => {
                                    this.handleChange(
                                      'lastname',
                                      e.target.value
                                    );
                                  }}
                                  required={true}
                                />
                                <Input
                                  className="address1"
                                  name={`program-payment-address1`}
                                  label="Address"
                                  type="text"
                                  value={data['address1']}
                                  error={errors['address1']}
                                  onChange={(e) => {
                                    this.handleChange(
                                      'address1',
                                      e.target.value
                                    );
                                  }}
                                  required={true}
                                />
                                <Input
                                  className="address2"
                                  name={`program-payment-address2`}
                                  label="Apt/Suite"
                                  type="text"
                                  value={data['address2']}
                                  error={errors['address2']}
                                  onChange={(e) => {
                                    this.handleChange(
                                      'address2',
                                      e.target.value
                                    );
                                  }}
                                  required={false}
                                />
                                <Input
                                  className="city"
                                  name={`program-payment-city`}
                                  label="City"
                                  type="text"
                                  value={data['city']}
                                  error={errors['city']}
                                  onChange={(e) => {
                                    this.handleChange('city', e.target.value);
                                  }}
                                  required={true}
                                />
                                <Select
                                  className="state"
                                  name={`program-payment-state`}
                                  label="State"
                                  options={states}
                                  value={data['state']}
                                  error={errors['state']}
                                  onChange={(e) => {
                                    this.handleChange('state', e.target.value);
                                  }}
                                  required={true}
                                />
                                <Input
                                  className="zip"
                                  name={`program-payment-zip`}
                                  label="ZIP"
                                  type="text"
                                  value={data['zip']}
                                  error={errors['zip']}
                                  onChange={(e) => {
                                    this.handleChange('zip', e.target.value);
                                  }}
                                  required={true}
                                />
                              </div>
                              <div className="payment-field card-number">
                                <CardNumberElement
                                  className="card-number"
                                  style={{
                                    base: fieldStyles,
                                  }}
                                />
                                <label>
                                  Card Number
                                  <span className="required">&nbsp;*</span>
                                </label>
                              </div>
                              <div className="payment-field card-expiry">
                                <CardExpiryElement
                                  className="card-expiry"
                                  placeholder="Expiration"
                                  style={{
                                    base: fieldStyles,
                                  }}
                                />
                                <label>
                                  Expiration Date
                                  <span className="required">&nbsp;*</span>
                                </label>
                              </div>
                              <div className="payment-field card-cvc-num">
                                <CardCvcElement
                                  className="card-cvc-num"
                                  style={{
                                    base: fieldStyles,
                                  }}
                                />
                                <label>
                                  CSV
                                  <span className="required">&nbsp;*</span>
                                </label>
                              </div>
                              {!!hasStripeUser && (
                                <div
                                  className="btn-wrapper"
                                  style={{
                                    marginTop: '16px',
                                  }}
                                >
                                  <button
                                    className="theme-btn small blue"
                                    onClick={this.changePaymentInfo}
                                  >
                                    Use Default Card
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    }
                  />
                </div>
              )}
              {step === 5 && (
                <div className="book-appointment-form completed">
                  <h2>Consistent Care Request Submitted & Received!</h2>
                  <div className="booking-confirmation">
                    <p>
                      <b>
                        Please note, this is NOT a confirmation, but a work in
                        progress!
                      </b>
                    </p>
                    <p>
                      We will review your request details, post your needs to
                      our team, and provide a Care Proposal, within 7-10
                      business days. Our office will send this proposal via
                      email as soon as your request is ready for review. We will
                      be in touch very soon with updates; thank you!
                    </p>
                    <Link
                      className="theme-btn small slate-blue"
                      to={'/dashboard'}
                    >
                      Back to Dashboard
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return {
    auth: state.auth,
    balance: state.balance,
  };
}

const actionCreators = {
  getBalance: balanceActions.getBalance,
};

export default connect(mapState, actionCreators)(injectStripe(BulkBooking));
