import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import {
  SystemNotices,
  AppointmentDetail,
  Page,
  PasswordResetSend,
  PasswordResetSet,
  Header,
  Login,
} from '../shared';
import { userHasCompletedRegistration, userHasRole } from '../../helpers';
import CompanionCareRegistration from './CompanionCareRegistration';
import SitterRegistration from './SitterRegistration';
import Landing from './Landing';

import registerSteps from '../../data/registerSteps';
import { mapAuthState } from '../../helpers/mapAuthState';

class PublicSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,

      windowWidth: false,
    };
  }
  componentDidMount() {
    this.onWindowResize();
    $(window).on('resize', this.onWindowResize);

    const { user } = this.props.auth;

    if (!!user) {
      const { step } = user;

      if (!step && userHasRole(user, 'Companion Care')) {
        this.props.history.push('/register/family/profile');
      }
      if (!step && userHasRole(user, 'Care Provider')) {
        this.props.history.push('/register/care-provider/application');
      }

      if (!!step && !userHasCompletedRegistration(user)) {
        switch (step.slug) {
          case 'care-provider-application-pending':
            this.props.history.push(
              '/register/care-provider/application-submitted'
            );
            break;
          case 'care-provider-application-approved':
            this.props.history.push('/schedule-interview');
            break;
          case 'care-provider-private-profile':
            this.props.history.push('/register/care-provider/private-profile');
            break;
          case 'care-provider-public-profile':
            this.props.history.push('/register/care-provider/public-profile');
            break;
          case 'care-provider-availability':
            this.props.history.push('/register/care-provider/availability');
            break;
          case 'care-provider-onboarding':
            this.props.history.push('/register/care-provider/onboarding');
            break;
          case 'care-provider-application-finished':
            this.props.history.push('/register/care-provider/complete');
            break;
          case 'companion-care-signup':
            this.props.history.push('/register/companion-care/complete-signup');
            return;
          case 'companion-care-complete-signup':
            this.props.history.push('/register/companion-care/household-info');
            return;
          case 'companion-care-household-info':
            this.props.history.push('/register/companion-care/care-profiles');
            return;
          case 'companion-care-care-profiles':
            this.props.history.push(
              '/register/companion-care/emergency-contacts'
            );
            return;
          case 'companion-care-emergency-contacts':
            this.props.history.push('/register/companion-care/pets');
            return;
          case 'companion-care-pets':
            this.props.history.push('/register/companion-care/payments');
            return;
          case 'companion-care-payment':
            this.props.history.push('/register/companion-care/complete');
            return;
          // 15 means they are done and ready to go to the dashboard
        }
      }
    }
  }
  componentWillUnmount() {
    $(window).off('resize', this.onWindowResize);
  }
  onWindowResize = () => {
    // const windowWidth = $(window).width();
    const windowWidth = document.documentElement.clientWidth;
    this.setState({ windowWidth });
  };
  toggleMenu = () => {
    let { menuOpen } = this.state;
    menuOpen = !menuOpen;
    this.setState({ menuOpen });
  };
  render() {
    const { menuOpen, windowWidth } = this.state;

    return (
      <Page
        id="public-section"
        menuOpen={menuOpen}
        header={
          <Header
            type="public"
            className="public"
            menuOpen={menuOpen}
            toggleMenu={this.toggleMenu}
          />
        }
      >
        <Switch>
          <Route
            path="/register/care-provider"
            exact={true}
            render={(props) => (
              <SitterRegistration {...props} windowWidth={windowWidth} />
            )}
          />
          <Route
            path="/register/care-provider/:step"
            exact={true}
            render={(props) => (
              <SitterRegistration {...props} windowWidth={windowWidth} />
            )}
          />
          <Route
            path="/register/companion-care"
            exact={true}
            component={CompanionCareRegistration}
          />
          <Route
            path="/register/companion-care/:step"
            exact={true}
            component={CompanionCareRegistration}
          />
          <Route
            path="/schedule-interview"
            exact={true}
            render={(props) => (
              <SitterRegistration {...props} windowWidth={windowWidth} />
            )}
          />
          <Route
            path="/login"
            exact={true}
            render={(props) => <Login {...props} />}
          />
          <Route
            path="/password/reset"
            exact={true}
            component={PasswordResetSend}
          />
          <Route
            path="/password/reset/:hash"
            exact={true}
            component={PasswordResetSet}
          />
          <Route path="/" exact={true} render={() => <Landing />} />
          <Redirect to="/login" />
        </Switch>
      </Page>
    );
  }
}

export default withRouter(connect(mapAuthState)(PublicSection));
