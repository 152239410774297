import { buildApiUrl } from '../helpers/buildApiUrl';
import { getNextCursor } from '../helpers/pagination';
import httpService from './httpService';
const list = (data = {}) => {
    const apiUrl = buildApiUrl('/profileFamily');
    return httpService.request('get', apiUrl, data);
};
const get = (id, piid = null) => {
    let apiUrl = buildApiUrl(`/profileFamily/details/${id}`);
    if (piid !== null) {
        apiUrl += `/${piid}`;
    }
    return httpService.get(apiUrl);
};
const getBalance = (id) => {
    return httpService.get(buildApiUrl(`/profileFamily/balance/${id}`));
};
const getTransactions = (id) => {
    return httpService.get(buildApiUrl(`/profileFamily/transactions/${id}`));
};
const getSitters = (id, data) => {
    return httpService.post(buildApiUrl(`/familyPreference/${id}`), data);
};
const getSitterPreferences = (id) => {
    return httpService.get(buildApiUrl(`/familyPreference/list/${id}`));
};
const getSitterFamilyPreference = async (familyId, sitterPiid) => {
    const response = await httpService.get(buildApiUrl(`/familyPreference/sitter/${familyId}/${sitterPiid}`));
    return response.data.data;
};
const getFamilySitters = async ({ pageParam, perPage = 10, }) => {
    const params = new URLSearchParams({
        skip: String(pageParam * perPage),
        take: String(perPage),
    });
    const response = await httpService.get(buildApiUrl(`/familySitters?${params.toString()}`));
    const { data, meta } = response.data;
    return { data, nextCursor: getNextCursor(meta) };
};
const saveSitter = (familyId, sitterPiid, data) => {
    const apiUrl = buildApiUrl(`/familyPreference/interested/${familyId}/${sitterPiid}`);
    return httpService.post(apiUrl, data);
};
const favoriteSitter = (familyId, sitterPiid, data) => {
    const apiUrl = buildApiUrl(`/familyPreference/favorite/${familyId}/${sitterPiid}`);
    return httpService.post(apiUrl, data);
};
const blockSitter = (familyId, sitterPiid, data) => {
    const apiUrl = buildApiUrl(`/familyPreference/excluded/${familyId}/${sitterPiid}`);
    return httpService.post(apiUrl, data);
};
const update = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/profileFamily/update/${id}`);
    return httpService.post(apiUrl, data, headers);
};
const store = (data, headers) => {
    const apiUrl = buildApiUrl('/profileFamily/create');
    return httpService.post(apiUrl, data, headers);
};
const invite = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/profileFamily/invite/${id}`);
    return httpService.post(apiUrl, data, headers);
};
const deleteMember = (id) => {
    const apiUrl = buildApiUrl(`/profileFamily/deleteMember/${id}`);
    return httpService.delete(apiUrl);
};
const getCareRecipients = (id) => {
    const idSuffix = id ? `/${id}` : '';
    // This function automatically retrieves based on logged in user, OR provided ID
    const apiUrl = buildApiUrl(`/profileFamily/children${idSuffix}`);
    return httpService.get(apiUrl);
};
const getCareContacts = (id) => {
    const idSuffix = id ? `/${id}` : '';
    // This function automatically retrieves based on logged in user, OR provided ID
    const apiUrl = buildApiUrl(`/profileFamily/contacts${idSuffix}`);
    return httpService.get(apiUrl);
};
const requestCancellation = (id, data = {}) => {
    const apiUrl = buildApiUrl(`/user/requestCancellation/${id}`);
    return httpService.post(apiUrl, data);
};
const removeRequestCancellation = (id, data = {}) => {
    const apiUrl = buildApiUrl(`/user/removeRequestCancellation/${id}`);
    return httpService.post(apiUrl, data);
};
const cancelSubscription = (id) => {
    const apiUrl = buildApiUrl(`/user/cancelSubscription/${id}`);
    return httpService.post(apiUrl);
};
export default {
    blockSitter,
    cancelSubscription,
    deleteMember,
    favoriteSitter,
    get,
    getBalance,
    getFamilySitters,
    getSitterFamilyPreference,
    getSitterPreferences,
    getSitters,
    getTransactions,
    invite,
    list,
    removeRequestCancellation,
    requestCancellation,
    saveSitter,
    store,
    update,
    getCareRecipients,
    getCareContacts,
};
