import React from 'react';
import { connect } from 'react-redux';
import { mapAuthState } from '../../../../helpers/mapAuthState';
import BookingDetailsRow from './BookingDetailsRow';

class BookingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAppointment: 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { appointments } = this.props;
    const length = appointments.length;
    const prevAppointments = prevProps.appointments;

    if (length !== prevAppointments.length) {
      const { currentAppointment } = this.state;
      if (currentAppointment >= length) {
        this.props.onFormComplete();
      }
    }
  }
  nextAppointment = () => {
    let { currentAppointment } = this.state;
    let length = this.props.appointments.length;
    if (currentAppointment < length - 1) {
      this.setState({
        currentAppointment: (currentAppointment += 1),
      });
    } else {
      this.props.onFormComplete();
    }
  };
  render() {
    const { currentAppointment } = this.state;
    const { appointments, children, sitters } = this.props;
    const length = appointments.length;
    return (
      <div className="booking-form details">
        {appointments.map((appointment, idx) => {
          // we do it this way so every time we move to a new appointment, the component re-mounts
          if (idx === currentAppointment) {
            return (
              <BookingDetailsRow
                key={`booking-details-apt-${appointment.rid}`}
                idx={currentAppointment}
                numAppointments={length}
                appointment={appointment}
                nextAppointment={this.nextAppointment}
                onFormComplete={this.props.onFormComplete}
                removeAppointment={this.props.removeAppointment}
                handleApptChange={this.props.handleApptChange}
                handleBulkApptChange={this.props.handleBulkApptChange}
                familyChildren={children}
                sitters={sitters}
                personalSitters={[]}
              />
            );
          } else {
            return <div key={`booking-details-apt-${idx}`} />;
          }
        })}
      </div>
    );
  }
}

export default connect(mapAuthState)(BookingDetails);
