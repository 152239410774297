import React from 'react';
import { connect } from 'react-redux';

import Signup from './Signup';
import SignupComplete from './SignupComplete';
import HouseholdInfo from './HouseholdInfo';
import CareProfiles from './CareProfiles';
import CareContacts from './CareContacts';
import Pets from './Pets';
import Payment from './Payment';
import Complete from './Complete';
import { mapAuthState } from '../../../helpers/mapAuthState';

class FamilyRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'signup',
      steps: ['signup', 'complete-signup', 'household-info', 'care-profiles', 'emergency-contacts', 'pets', 'payment', 'complete'],
    };
  }
  componentDidMount() {
    if (!!this.props.auth.user) {
      if (!!this.props.auth.user.step) {
        this.setStepFromUser();
      } else {
        this.setStepFromUrl();
      }
    } else {
      this.setStepFromUrl();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (!!this.props.auth.user && !!prevProps.auth.user) {
      if (this.props.auth.user.step_id !== prevProps.auth.user.step_id) {
        this.setStepFromUser();
      }
    } else if (!!this.props.auth.user && !prevProps.auth.user) {
      // user signed in
      this.setStepFromUser();
    }
  }
  setStepFromUser = () => {
    const { user } = this.props.auth;
    const { step } = user;
    let pageStep = false;
    switch (step.slug) {
      case 'companion-care-signup':
        pageStep = 'complete-signup';
        break;
      case 'companion-care-complete-signup':
        pageStep = 'household-info';
        break;
      case 'companion-care-household-info':
        pageStep = 'care-profiles';
        break;
      case 'companion-care-care-profiles':
        pageStep = 'emergency-contacts';
        break;
      case 'companion-care-emergency-contacts':
        pageStep = 'pets';
        break;
      case 'companion-care-pets':
        pageStep = 'payment';
        break;
      case 'companion-care-payment':
        pageStep = 'complete';
        break;
      // step 15 is complete and ready to go to dashboard
    }
    if (!!pageStep) this.setState({ step: pageStep }, this.updateUrl);
  };
  setStepFromUrl = () => {
    const { step } = this.props.match.params;
    console.log(step);
    if (!!step) {
      const { steps } = this.state;
      if (steps.indexOf(step) === -1) {
        this.setState(
          {
            step: steps[0],
          },
          this.updateUrl
        );
      } else {
        this.setState({ step });
      }
    }
  };
  advanceStep = () => {
    let { step, steps } = this.state;
    let idx = steps.indexOf(step);
    if (idx < steps.length - 1) {
      idx += 1;
      step = steps[idx];
      this.setState({ step }, this.updateUrl);
    }
  };
  updateUrl = () => {
    const { step } = this.state;
    window.history.replaceState(null, null, `/register/companion-care/${step}`);
  };
  render() {
    const { step } = this.state;
    const { user } = this.props.auth;
    return (
      <main className="registration family">
        {step === 'signup' && <Signup />}
        {step === 'complete-signup' && <SignupComplete user={user} />}
        {step === 'household-info' && <HouseholdInfo user={user} />}
        {step === 'care-profiles' && <CareProfiles user={user} />}
        {step === 'emergency-contacts' && <CareContacts user={user} />}
        {step === 'pets' && <Pets user={user} />}
        {step === 'payment' && <Payment user={user} />}
        {step === 'complete' && <Complete user={user} />}
      </main>
    );
  }
}

export default connect(mapAuthState)(FamilyRegistration);
