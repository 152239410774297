import React from 'react';
import { Link } from 'react-router-dom';
import { mobileBp } from '../../config';
import { getPendingReviewsCount } from '../../services/appointmentReviewService';
import { AppointmentListing, SystemNotices } from '../shared';
import CalendarContainer from '../shared/Appointments/CalendarContainer';
class FamilyDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingReviewsCount: 0,
        };
    }
    componentDidMount = () => {
        this.props.refreshAppointments();
        this.getPendingReviewsCount();
    };
    getPendingReviewsCount = async () => {
        try {
            const pendingReviewsCount = await getPendingReviewsCount();
            this.setState({ pendingReviewsCount });
        }
        catch (error) {
            console.error(error);
        }
    };
    render() {
        const { appointments, systemNotices, windowWidth, appointmentStatus, changeAppointmentStatus, } = this.props;
        const { pendingReviewsCount } = this.state;
        return (React.createElement("main", { className: "dashboard family" },
            windowWidth <= mobileBp && systemNotices.length > 0 && (React.createElement(SystemNotices, { systemNotices: systemNotices })),
            React.createElement("div", { className: "list-view" },
                windowWidth > mobileBp && systemNotices.length > 0 && (React.createElement(SystemNotices, { systemNotices: systemNotices })),
                React.createElement(AppointmentListing, { appointments: appointments, type: "family", appointmentStatus: appointmentStatus, changeAppointmentStatus: changeAppointmentStatus, windowWidth: windowWidth, pendingReviewsCount: pendingReviewsCount })),
            windowWidth > mobileBp && (React.createElement("div", { className: "calendar-view" },
                React.createElement(CalendarContainer, { type: "dashboard", appointments: appointments, history: this.props.history }))),
            windowWidth <= mobileBp && (React.createElement("div", { className: "book-appt-btn" },
                React.createElement(Link, { to: "/appointments", className: "theme-btn blue font-body" }, "Book Appointment")))));
    }
}
export default FamilyDashboard;
